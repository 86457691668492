import { memo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useActionAsync } from '@core/shared/useAction'
import { getLiveWellRequestAction } from '@core/app/articles/actions'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { CircularLoader } from '@core/components/app/CircularLoader'

type HealthWellbeingProps = {
  text: {
    pageHeader: string
    pageTitle: string
    exercise: {
      title: string
      link: string
      'link-label': string
    }
    sleep: {
      title: string
      link: string
      'link-label': string
    }
    sexualHealth: {
      title: string
      link: string
      'link-label': string
    }
    healthyWeight: {
      title: string
      link: string
      'link-label': string
    }
    healthyBody: {
      title: string
      link: string
      'link-label': string
    }
    eatWell: {
      title: string
      link: string
      'link-label': string
    }
  }
}

export const HealthWellbeing = memo(function HealthWellbeing(
  props: HealthWellbeingProps,
) {
  const { text } = props
  const history = useHistory()

  const [isLoading, setLoading] = useState<boolean>(false)

  const getLiveWellRequest = useActionAsync(getLiveWellRequestAction)

  const onClickHandle = useCallback(
    async (path: string, subPaths: string[] = []) => {
      try {
        setLoading(true)
        await getLiveWellRequest({ path, subPaths })
        setLoading(false)
        history.push(ScreenRoutes.ARTICLE)
      } catch (e) {
        console.error(e, 'err')
        setLoading(false)
      }
    },
    [getLiveWellRequest, history],
  )

  return (
    <div className="page health-wellbeing-page">
      <PageHeader showBg backBtn title={text.pageHeader} />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="page-title">
            <h2 className="text-weight-md text-color-primary">
              {text.pageTitle}
            </h2>
          </div>
          <div className="content">
            <div className="health-top">
              <div className="health-multiple">
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('healthy-weight', [
                      'healthy-weight/bmi-calculator',
                    ])
                  }
                  aria-label="Healthy weight"
                >
                  <div className="health-card">
                    <div className="card-image-block healthy-weight">
                      <h3 className="card-title">{text.healthyWeight.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.healthyWeight.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('bone-health', [
                      'bone-health/food-for-strong-bones/',
                    ])
                  }
                  aria-label="Healthy body"
                >
                  <div className="health-card">
                    <div className="card-image-block healthy-body">
                      <h3 className="card-title">{text.healthyBody.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.healthyBody.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div className="health-single">
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('exercise', [
                      'exercise/exercise-health-benefits',
                    ])
                  }
                  aria-label="Exercise"
                >
                  <div className="health-card">
                    <div className="card-image-block exercise">
                      <h3 className="card-title">{text.exercise.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.exercise.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div className="health-bottom">
              <div className="health-multiple">
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('sexual-health', [
                      'sexual-health/where-can-i-get-sexual-health-advice-now',
                    ])
                  }
                  aria-label="Sexual health"
                >
                  <div className="health-card">
                    <div className="card-image-block sexual-health">
                      <h3 className="card-title">{text.sexualHealth.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.sexualHealth.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('sleep-and-tiredness', [
                      'sleep-and-tiredness/why-am-i-tired-all-the-time',
                    ])
                  }
                  aria-label="Sleep and tiredness"
                >
                  <div className="health-card">
                    <div className="card-image-block sleep">
                      <h3 className="card-title">{text.sleep.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.sleep.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
              </div>
              <div className="health-single">
                <button
                  className="health-card-button"
                  onClick={() =>
                    onClickHandle('eat-well', [
                      'eat-well/how-to-eat-a-balanced-diet/eating-a-balanced-diet',
                    ])
                  }
                  aria-label="Eat well"
                >
                  <div className="health-card">
                    <div className="card-image-block eat-well">
                      <h3 className="card-title">{text.eatWell.title}</h3>
                    </div>
                    <div className="healthy-card-footer">
                      <p className="card-footer-link">
                        {text.eatWell.link}
                        <RightChevronIcon className="ml-1" />
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <CircularLoader /> : null}
      <PageFooter stickyBottom />
    </div>
  )
})
