export const fileToBlobString = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsBinaryString(file)
    reader.onload = () => resolve(reader?.result as string)
    reader.onerror = (error) => reject(error)
  })

export const fileToDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader?.result)
    reader.onerror = (error) => reject(error)
  })

export const fileToBase64 = async (file: File) => {
  const binary = await fileToBlobString(file)
  return btoa(binary)
}
