import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'
import './config/config'
import './assets/styles/all.scss'
import App from './App'
import { configureStore } from '@core/app/configureStore'
import { FeaturesProvider } from '@core/components/app/features/features'
import { getClientVariables, getFeatures } from './config/features'
import { initMonitoring } from '@core/shared/monitoring'
import { ClientVariablesProvider } from '@core/components/app/features/variables'

const store = configureStore()
store.runSideEffects()

ReactDOM.render(
  <FeaturesProvider features={getFeatures()}>
    <ClientVariablesProvider variables={getClientVariables()}>
      <BrowserRouter>
        <CompatRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </CompatRouter>
      </BrowserRouter>
    </ClientVariablesProvider>
  </FeaturesProvider>,
  document.getElementById('root'),
)

initMonitoring()
