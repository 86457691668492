import cx from 'clsx'

interface IconProps {
  isActive?: boolean
  className?: string
}

export const ArticlesIcon = (props: IconProps) => {
  const { isActive, className } = props
  return isActive ? (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('article-icon', 'active', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="fg" fill="#242D38" fillRule="nonzero">
          <path d="M22.25,9 L1.75,9 C0.785,9 0,8.21 0,7.25 L0,1.75 C0,0.78 0.785,0 1.75,0 L22.25,0 C23.215,0 24,0.78 24,1.75 L24,7.25 C24,8.21 23.215,9 22.25,9 Z"></path>
          <path d="M22.25,24 L14.75,24 C13.785,24 13,23.21 13,22.25 L13,12.25 C13,11.28 13.785,10.5 14.75,10.5 L22.25,10.5 C23.215,10.5 24,11.28 24,12.25 L24,22.25 C24,23.21 23.215,24 22.25,24 Z"></path>
          <path d="M10.25,12 L0.75,12 C0.336,12 0,11.66 0,11.25 C0,10.84 0.336,10.5 0.75,10.5 L10.25,10.5 C10.664,10.5 11,10.84 11,11.25 C11,11.66 10.664,12 10.25,12 Z"></path>
          <path d="M10.25,15 L0.75,15 C0.336,15 0,14.66 0,14.25 C0,13.84 0.336,13.5 0.75,13.5 L10.25,13.5 C10.664,13.5 11,13.84 11,14.25 C11,14.66 10.664,15 10.25,15 Z"></path>
          <path d="M10.25,18 L0.75,18 C0.336,18 0,17.66 0,17.25 C0,16.84 0.336,16.5 0.75,16.5 L10.25,16.5 C10.664,16.5 11,16.84 11,17.25 C11,17.66 10.664,18 10.25,18 Z"></path>
          <path d="M10.25,21 L0.75,21 C0.336,21 0,20.66 0,20.25 C0,19.84 0.336,19.5 0.75,19.5 L10.25,19.5 C10.664,19.5 11,19.84 11,20.25 C11,20.66 10.664,21 10.25,21 Z"></path>
          <path d="M10.25,24 L0.75,24 C0.336,24 0,23.66 0,23.25 C0,22.84 0.336,22.5 0.75,22.5 L10.25,22.5 C10.664,22.5 11,22.84 11,23.25 C11,23.66 10.664,24 10.25,24 Z"></path>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('article-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className="fg"
          fill="#242D38"
          fillRule="nonzero"
          stroke="#242D38"
          strokeWidth="0.6"
        >
          <path d="M22.25,9 L1.75,9 C0.785,9 0,8.21 0,7.25 L0,1.75 C0,0.78 0.785,0 1.75,0 L22.25,0 C23.215,0 24,0.78 24,1.75 L24,7.25 C24,8.21 23.215,9 22.25,9 Z M1.75,1.5 C1.612,1.5 1.5,1.61 1.5,1.75 L1.5,7.25 C1.5,7.39 1.612,7.5 1.75,7.5 L22.25,7.5 C22.388,7.5 22.5,7.39 22.5,7.25 L22.5,1.75 C22.5,1.61 22.388,1.5 22.25,1.5 L1.75,1.5 Z"></path>
          <path d="M22.25,24 L14.75,24 C13.785,24 13,23.21 13,22.25 L13,12.25 C13,11.28 13.785,10.5 14.75,10.5 L22.25,10.5 C23.215,10.5 24,11.28 24,12.25 L24,22.25 C24,23.21 23.215,24 22.25,24 Z M14.75,12 C14.612,12 14.5,12.11 14.5,12.25 L14.5,22.25 C14.5,22.39 14.612,22.5 14.75,22.5 L22.25,22.5 C22.388,22.5 22.5,22.39 22.5,22.25 L22.5,12.25 C22.5,12.11 22.388,12 22.25,12 L14.75,12 Z"></path>
          <path d="M10.25,12 L0.75,12 C0.336,12 0,11.66 0,11.25 C0,10.84 0.336,10.5 0.75,10.5 L10.25,10.5 C10.664,10.5 11,10.84 11,11.25 C11,11.66 10.664,12 10.25,12 Z"></path>
          <path d="M10.25,15 L0.75,15 C0.336,15 0,14.66 0,14.25 C0,13.84 0.336,13.5 0.75,13.5 L10.25,13.5 C10.664,13.5 11,13.84 11,14.25 C11,14.66 10.664,15 10.25,15 Z"></path>
          <path d="M10.25,18 L0.75,18 C0.336,18 0,17.66 0,17.25 C0,16.84 0.336,16.5 0.75,16.5 L10.25,16.5 C10.664,16.5 11,16.84 11,17.25 C11,17.66 10.664,18 10.25,18 Z"></path>
          <path d="M10.25,21 L0.75,21 C0.336,21 0,20.66 0,20.25 C0,19.84 0.336,19.5 0.75,19.5 L10.25,19.5 C10.664,19.5 11,19.84 11,20.25 C11,20.66 10.664,21 10.25,21 Z"></path>
          <path d="M10.25,24 L0.75,24 C0.336,24 0,23.66 0,23.25 C0,22.84 0.336,22.5 0.75,22.5 L10.25,22.5 C10.664,22.5 11,22.84 11,23.25 C11,23.66 10.664,24 10.25,24 Z"></path>
        </g>
      </g>
    </svg>
  )
}
