import { Epic, combineEpics } from 'redux-observable'

import authEpics from '../app/auth/epics'
import userEpics from '../app/user/epics'
import appEpics from '../app/app/epics'
import appointmentEpics from '../app/appointment/epics'
import patienrRecordEpics from '../app/patientRecord/epics'
import bookingEpics from '../app/bookings/epics'
import articleEpics from '../app/articles/epics'

const epics: Epic[] = []

export default combineEpics(
  ...epics.concat(
    appEpics,
    authEpics,
    userEpics,
    appointmentEpics,
    patienrRecordEpics,
    bookingEpics,
    articleEpics,
  ),
)
