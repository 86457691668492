import { Action } from '@core/shared/types/Action'
import { BookingType } from '@core/shared/types/bookings'
import { CancelBookingParams } from '@core/shared/types/bookings/BookingsApi'

export enum BookingActionTypes {
  GET_UPCOMING_BOOKINGS = 'GET_UPCOMING_BOOKINGS',
  GET_UPCOMING_BOOKINGS_SUCCESS = 'GET_UPCOMING_BOOKINGS_SUCCESS',
  GET_UPCOMING_BOOKINGS_FAIL = 'GET_UPCOMING_BOOKINGS_FAIL',

  SELECT_UPCOMING_BOOKING = 'SELECT_UPCOMING_BOOKING',
  SELECT_COMPLETED_BOOKING = 'SELECT_COMPLETED_BOOKING',

  GET_BOOKING_ATTACHMENTS = 'GET_BOOKING_ATTACHMENTS',
  GET_BOOKING_ATTACHMENTS_SUCCESS = 'GET_BOOKING_ATTACHMENTS_SUCCESS',
  GET_BOOKING_ATTACHMENT_FAIL = 'GET_BOOKING_ATTACHMENT_FAIL',

  DELETE_BOOKING_ATTACHMENT = 'DELETE_BOOKING_ATTACHMENT',
  DELETE_BOOKING_ATTACHMENT_SUCCESS = 'DELETE_BOOKING_ATTACHMENT_SUCCESS',
  DELETE_BOOKING_ATTACHMENT_FAIL = 'DELETE_BOOKING_ATTACHMENT_FAIL',

  CANCEL_BOOKING = 'CANCEL_BOOKING',
  CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAIL = 'CANCEL_BOOKING_FAIL',

  UPDATE_BOOKING = 'UPDATE_BOOKING',
  UPDATE_BOOKING_SUCCESS = 'UPDATE_BOOKING_SUCCESS',
  UPDATE_BOOKING_FAIL = 'UPDATE_BOOKING_FAIL',

  SUBMIT_BOOKING = 'SUBMIT_BOOKING',
  SUBMIT_BOOKING_SUCCESS = 'SUBMIT_BOOKING_SUCCESS',
  SUBMIT_BOOKING_FAIL = 'SUBMIT_BOOKING_FAIL',

  CREATE_BOOKING_JOINING_TOKEN = 'CREATE_BOOKING_JOINING_TOKEN',
  CREATE_BOOKING_JOINING_TOKEN_SUCCESS = 'CREATE_BOOKING_JOINING_TOKEN_SUCCESS',
  CREATE_BOOKING_JOINING_TOKEN_FAIL = 'CREATE_BOOKING_JOINING_TOKEN_FAIL',

  DOWNLOAD_BOOKING_ATTACHMENT = 'DOWNLOAD_BOOKING_ATTACHMENT',
  DOWNLOAD_BOOKING_ATTACHMENT_SUCCESS = 'DOWNLOAD_BOOKING_ATTACHMENT_SUCCESS',
  DOWNLOAD_BOOKING_ATTACHMENT_FAIL = 'DOWNLOAD_BOOKING_ATTACHMENT_FAIL',

  GET_BOOKING_BY_ID = 'GET_BOOKING_BY_ID',
  GET_BOOKING_BY_ID_SUCCESS = 'GET_BOOKING_BY_ID_SUCCESS',
  GET_BOOKING_BY_ID_FAIL = 'GET_BOOKING_BY_ID_FAIL',
}

export type GetUpcomingBookingsAction = Action<
  typeof BookingActionTypes.GET_UPCOMING_BOOKINGS,
  {
    userId: string
  }
>

export type GetUpcomingBookingsActionSuccess = Action<
  typeof BookingActionTypes.GET_UPCOMING_BOOKINGS_SUCCESS,
  {
    upcomingBookings: BookingType[]
    completedBookings: BookingType[]
  }
>

export type SelectUpcomingBookingAction = Action<
  typeof BookingActionTypes.SELECT_UPCOMING_BOOKING,
  {
    bookingId?: string | null
    attachments?: Array<any>
  }
>

export type SelectCompletedBookingAction = Action<
  typeof BookingActionTypes.SELECT_COMPLETED_BOOKING,
  {
    bookingId?: string | null
    attachments?: Array<any>
  }
>

export type CancelBookingAction = Action<
  typeof BookingActionTypes.CANCEL_BOOKING,
  {
    userId: string
    bookingId: string
    params?: CancelBookingParams
    IsPaymentEligibilityEnabled: boolean
  }
>

export type CancelBookingActionSuccess = Action<
  typeof BookingActionTypes.CANCEL_BOOKING_SUCCESS,
  {
    bookingId: string
  }
>

export type GetBookingAttachmentsAction = Action<
  typeof BookingActionTypes.GET_BOOKING_ATTACHMENTS,
  {
    userId: string
    bookingId: string
    isCompletedBooking?: boolean
  }
>

export type GetBookingAttachmentsActionSuccess = Action<
  typeof BookingActionTypes.GET_BOOKING_ATTACHMENTS_SUCCESS,
  {
    attachments: Array<any>
    isCompletedBooking?: boolean
  }
>

export type UpdateBookingAction = Action<
  typeof BookingActionTypes.UPDATE_BOOKING,
  {
    userId: string
    bookingId: string
    symptoms?: string[]
    files?: File[]
    reasonForBooking?: string
    contactDetails?: {
      phoneNumber?: string | null
      address?: {
        addressLine1?: string
        addressLine2?: string
        city?: string
        countryCode?: string
        postCode?: string
      }
    }
  }
>

export type UpdateBookingActionSuccess = Action<
  typeof BookingActionTypes.UPDATE_BOOKING_SUCCESS
>

export type DeleteBookingAttachment = Action<
  typeof BookingActionTypes.DELETE_BOOKING_ATTACHMENT,
  {
    userId: string
    bookingId: string
    attachmentId: string
  }
>

export type DeleteBookingAttachmentSuccess = Action<
  typeof BookingActionTypes.DELETE_BOOKING_ATTACHMENT_SUCCESS
>

export type SubmitBookingAction = Action<
  typeof BookingActionTypes.SUBMIT_BOOKING,
  {
    userId: string
    data: any
    IsPaymentEligibilityEnabled: boolean
  }
>

export type SubmitBookingSuccessAction = Action<
  typeof BookingActionTypes.SUBMIT_BOOKING_SUCCESS
>

export type SubmitBookingFailAction = Action<
  typeof BookingActionTypes.SUBMIT_BOOKING_FAIL
>

export type CreateBookingJoiningTokenAction = Action<
  typeof BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN,
  {
    userId: string
    consultationId: string
    clientId: number
  }
>

export type CreateBookingJoiningTokenSuccessAction = Action<
  typeof BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN_SUCCESS
>

export type CreateBookingJoiningTokenFailAction = Action<
  typeof BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN_FAIL
>

export type DownloadBookingAttachmentRequestAction = Action<
  typeof BookingActionTypes.DOWNLOAD_BOOKING_ATTACHMENT,
  {
    userId: string
    fileId: string
    fileName: string
    mimeType?: string
  }
>

export type DownloadBookingAttachmentSuccessAction = Action<
  typeof BookingActionTypes.DOWNLOAD_BOOKING_ATTACHMENT_SUCCESS
>

export type GetBookingByIdRequestAction = Action<
  typeof BookingActionTypes.GET_BOOKING_BY_ID,
  {
    userId: string
    bookingId: string
    isPastBookingSelect?: boolean
    isUpcomingBookingSelect?: boolean
  }
>

export type GetBookingByIdSuccessAction = Action<
  typeof BookingActionTypes.GET_BOOKING_BY_ID_SUCCESS,
  {
    upcomingBookings: BookingType[]
    completedBookings: BookingType[]
    selectCompletedBooking?: {
      id: string | null
      attachments?: any[]
    }
    selectedUpcomingBooking?: {
      id: string | null
      attachments?: any[]
    }
  }
>

export type GetBookingByIdFailAction = Action<
  typeof BookingActionTypes.GET_BOOKING_BY_ID_FAIL
>
