import React from 'react'
import clsx from 'clsx'
import styles from './TertiaryButtonLink.module.scss'

type Props = {
  label: string
  className?: string
  onClick?: () => void
  disabled?: boolean
  testid?: string
  startIcon?: JSX.Element
  endIcon?: JSX.Element
}

export const TertiaryButtonLink: React.FC<Props> = (props) => {
  const {
    label,
    className,
    onClick,
    testid,
    disabled = false,
    startIcon,
    endIcon,
  } = props

  const buttonStyles = () => {
    const combinedStyles = []
    disabled && combinedStyles.push(styles.disabled)
    startIcon && combinedStyles.push(styles.startIcon)
    endIcon && combinedStyles.push(styles.endIcon)

    return combinedStyles.join()
  }

  return (
    <button
      type="button"
      className={clsx(styles.textButton, buttonStyles(), className)}
      onClick={onClick}
      data-testid={testid}
    >
      {startIcon}
      {label}
      {endIcon}
    </button>
  )
}
