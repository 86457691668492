import { memo, ReactNode } from 'react'
import cx from 'clsx'

export interface LabelPaperProps {
  className?: string
  children?: ReactNode
}

export const LabelPaper = memo<LabelPaperProps>(function LabelPaper(props) {
  const { className, children } = props
  return <label className={cx('paper', className)}>{children}</label>
})
