import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { ConfirmModal } from '@core/components/app/ConfirmModal'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { getDependentPatientRecordAction } from '@core/app/patientRecord/actions'
import { selectDependentsRecordsList } from '@core/app/patientRecord/selectors'
import { getPatienName } from '@core/shared/patient'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { NotificationItem } from '@core/components/app/NotificationItem'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { SideMenu } from '@core/components/app/SideMenu'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { selectAppUser } from '@core/app/user/selectors'
import { deleteDependent } from '@core/api/patientRecord'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

const defaultCopy = (
  <div
    className="section text-smd text-color-primary"
    data-testid="disclaimer-section"
  >
    <p className="mt-1">
      Your dependants are also eligible to use the service. This means anyone
      who lives within your household.
    </p>
    <p className="mt-1">
      Dependants under the age of 16 can be added to your account by pressing
      the <strong>Add new dependant</strong> button below and filling in the
      form. They can then be selected during the booking process.
    </p>
    <p className="mt-1">
      If your dependant is over 16 years, they will need to make their own
      account. To do so, log out and press <strong>Create account</strong>.
    </p>
  </div>
)

interface Props {
  overrideExplanation?: JSX.Element
}

export const MyContacts = (props: Props) => {
  const { overrideExplanation } = props
  const history = useHistory()

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [depId, setDepId] = useState('')

  const dependents = useSelector(selectDependentsRecordsList)
  const appUser = useSelector(selectAppUser)
  const getRecords = useActionAsync(getDependentPatientRecordAction)
  const showNotification = useAction(showNotificationAction)

  const Explanation = () => {
    if (overrideExplanation) {
      return overrideExplanation
    }
    return defaultCopy
  }

  useEffect(() => {
    const fetchRecords = async () => {
      if (!appUser?.id) {
        history.push(ScreenRoutes.PROFILE)
        return
      }
      try {
        await getRecords()
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchRecords()
  }, [appUser, getRecords, history])

  const toggleDeleteContactModal = (
    e: React.SyntheticEvent,
    dependentId?: string,
  ) => {
    e.preventDefault()

    setDepId(dependentId || '')
    setShowDeleteModal(!showDeleteModal)
  }

  const handleDeleteContact = useCallback(async () => {
    setShowDeleteModal(false)
    try {
      await deleteDependent(appUser?.id || '', depId)
      await getRecords()
      // delete
      showNotification({
        type: 'success',
        title: 'Dependant deleted',
      })
    } catch (error) {
      showNotification({
        type: 'error',
        title: 'Failed to delete dependant',
      })
      console.error(error)
    }
  }, [appUser, depId, getRecords, showNotification])

  return (
    <div className="page my-contacts-page">
      <PageHeader showBg backBtn title="Dependants" />

      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <NotificationItem />
          <Explanation />
          <div className="section contact-list">
            {dependents.map((patient) => (
              <div className="paper" key={patient.id}>
                <div className="paper-content w-full">
                  <Link
                    to={ScreenRoutes.EDIT_CONTACT.replace(':id', patient.id)}
                    className="edit-contact-nav-item flex-row space-between"
                    data-testid="dependant-info-item"
                  >
                    <div className="paper-header text-weight-md text-md contact-header">
                      {getPatienName(patient)}{' '}
                      <button
                        className="delete-contact-button text-sm ml-1 action-cursor"
                        data-testid="delete-button"
                        onClick={(e) => toggleDeleteContactModal(e, patient.id)}
                      >
                        Delete
                      </button>
                    </div>
                    <div className="contact-info-wrapper">
                      <div className="text-color-primary">
                        <RightChevronIcon />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className="section add-new-person-button flex-center">
            <Link
              to={ScreenRoutes.ADD_NEW_CONTACT}
              className="btn btn-primary add-contact-button"
              data-testid="add-new-dependant-btn"
            >
              Add new dependant
            </Link>
          </div>

          {showDeleteModal && (
            <ConfirmModal
              title="Are you sure you want to delete the dependant?"
              modalContent="You will not be able to restore your dependant after you delete them"
              buttonSubmitText="Delete dependant"
              buttonCanceledText="Back"
              toggleModal={toggleDeleteContactModal}
              actionOnConfirm={handleDeleteContact}
            />
          )}
        </div>
      </div>

      {isLoading && !dependents?.length ? <CircularLoader /> : null}

      <PageFooter stickyBottom />
    </div>
  )
}
