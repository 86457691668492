import { produce } from 'immer'

import { DefaultBooking, BookingType } from '@core/shared/types/bookings'
import {
  BookingActionTypes,
  GetUpcomingBookingsActionSuccess,
  SelectUpcomingBookingAction,
  GetBookingAttachmentsActionSuccess,
  CancelBookingActionSuccess,
  SelectCompletedBookingAction,
  GetBookingByIdSuccessAction,
} from './actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

type BookingActions =
  | GetUpcomingBookingsActionSuccess
  | SelectUpcomingBookingAction
  | GetBookingAttachmentsActionSuccess
  | CancelBookingActionSuccess
  | SelectCompletedBookingAction
  | GetBookingByIdSuccessAction
  | LogoutAction

export const initialBookings = (): DefaultBooking => ({
  upcomingBookings: [],
  completedBookings: [],
  selectedUpcomingBooking: {
    id: null,
    attachments: [],
  },
  selectedCompletedBooking: {
    id: null,
    attachments: [],
  },
})

const BookingsReducer = produce(
  (state = initialBookings(), action: BookingActions): DefaultBooking => {
    switch (action.type) {
      case BookingActionTypes.GET_UPCOMING_BOOKINGS_SUCCESS:
        return {
          ...state,
          upcomingBookings: action.payload.upcomingBookings,
          completedBookings: action.payload.completedBookings,
        }
      case BookingActionTypes.SELECT_UPCOMING_BOOKING:
        return {
          ...state,
          selectedUpcomingBooking: {
            ...state.selectedUpcomingBooking,
            id: action.payload.bookingId,
            attachments: action.payload.attachments,
          },
        }
      case BookingActionTypes.SELECT_COMPLETED_BOOKING:
        return {
          ...state,
          selectedCompletedBooking: {
            ...state.selectedCompletedBooking,
            id: action.payload.bookingId,
            attachments: action.payload.attachments,
          },
        }
      case BookingActionTypes.GET_BOOKING_ATTACHMENTS_SUCCESS:
        if (action.payload.isCompletedBooking) {
          return {
            ...state,
            selectedCompletedBooking: {
              ...state.selectedCompletedBooking,
              attachments: action.payload.attachments,
            },
          }
        } else {
          return {
            ...state,
            selectedUpcomingBooking: {
              ...state.selectedUpcomingBooking,
              attachments: action.payload.attachments,
            },
          }
        }

      case BookingActionTypes.CANCEL_BOOKING_SUCCESS:
        return {
          ...state,
          upcomingBookings: state.upcomingBookings.filter(
            (el: BookingType) => el.id !== action.payload.bookingId,
          ),
        }

      case BookingActionTypes.GET_BOOKING_BY_ID_SUCCESS:
        return {
          ...state,
          upcomingBookings: action.payload.upcomingBookings,
          completedBookings: action.payload.completedBookings,
          selectedCompletedBooking: action.payload.selectCompletedBooking,
        }
      case AuthActionTypes.LOGOUT:
        return initialBookings()
      default:
        return state
    }
  },
)

export default BookingsReducer

export type { DefaultBooking }
