import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const SymptomAssessmentIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      width="22"
      height="29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('symptom-assessment-icon', className)}
      aria-hidden="true"
    >
      <path
        d="M7.393 3.607a3.607 3.607 0 0 1 7.213 0c0 .897-.914 2.4-.914 2.4s-1.046-.613-2.83-.613a6.4 6.4 0 0 0-2.63.524 3.592 3.592 0 0 1-.839-2.311ZM5.95 16.972v8.895c0 .709.241 1.306.663 1.727a2.12 2.12 0 0 0 1.502.617 2.12 2.12 0 0 0 1.501-.617c.422-.421.663-1.018.663-1.727 0-1.092 0-1.844.174-3.14.268-2.011.826-2.011 1.094 0 .173 1.296.174 2.048.174 3.14 0 .709.24 1.306.662 1.727a2.12 2.12 0 0 0 1.502.617 2.12 2.12 0 0 0 1.502-.617c.421-.421.662-1.018.662-1.727V20.24l-1.983-1.983a6.802 6.802 0 0 1-3.205.797 6.81 6.81 0 0 1-4.91-2.082ZM10.861 9.394a2.83 2.83 0 1 0 0 5.66 2.83 2.83 0 0 0 0-5.66ZM.617 8.596c.421-.421 1.018-.662 1.727-.662h3.201a6.87 6.87 0 0 0-1.514 4.328H2.344c-.709 0-1.306-.24-1.727-.662A2.12 2.12 0 0 1 0 10.098c0-.539.202-1.087.617-1.502ZM21.38 8.597c-.421-.422-1.031-.662-1.74-.662h-3.45a6.802 6.802 0 0 1 1.51 4.327h1.94c.709 0 1.319-.24 1.74-.662.415-.414.62-.962.62-1.502 0-.539-.205-1.087-.62-1.501Z"
        fill="#fff"
      />
      <path
        d="m14.277 15.64 4.898 4.897m-3.483-8.313a4.83 4.83 0 1 1-9.661 0 4.83 4.83 0 0 1 9.66 0Z"
        stroke="#8CDBC9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
