import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const MessageIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 24 22"
      className={cx('message-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#8A93A1" fillRule="nonzero">
          <path d="M20.6,5.68434189e-14 L3.28,5.68434189e-14 C1.47,5.68434189e-14 -3.41060513e-13,1.473 -3.41060513e-13,3.284 L-3.41060513e-13,13.883 C-3.41060513e-13,15.69 1.47,17.16 3.27,17.167 L3.27,21.976 L10.18,17.167 L20.6,17.167 C22.41,17.167 23.89,15.694 23.89,13.883 L23.89,3.284 C23.89,1.473 22.41,5.68434189e-14 20.6,5.68434189e-14 Z M22.49,13.883 C22.49,14.922 21.64,15.767 20.6,15.767 L9.75,15.767 L4.67,19.297 L4.67,15.767 L3.28,15.767 C2.24,15.767 1.4,14.922 1.4,13.883 L1.4,3.284 C1.4,2.245 2.24,1.4 3.28,1.4 L20.6,1.4 C21.64,1.4 22.49,2.245 22.49,3.284 L22.49,13.883 Z"></path>
          <polygon points="6.39 4.945 17.49 4.945 17.49 6.345 6.39 6.345"></polygon>
          <polygon points="6.39 7.931 17.49 7.931 17.49 9.331 6.39 9.331"></polygon>
          <polygon points="6.39 10.917 17.49 10.917 17.49 12.317 6.39 12.317"></polygon>
        </g>
      </g>
    </svg>
  )
}
