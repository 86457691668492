import React, { memo, useEffect, ReactNode, useRef } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'

import { BackBtn } from './BackBtn'

export interface PageHeaderProps {
  title: string | ReactNode
  backBtn?: boolean
  showBg?: boolean
  children?: ReactNode
  withStepper?: boolean
  backBtnHandler?: (e: React.MouseEvent) => void
}

export const PageHeader = memo<PageHeaderProps>(function PageHeader(props) {
  const { title, backBtn = false, showBg, withStepper, backBtnHandler } = props

  const headerNode = useRef<HTMLHeadingElement>(null)
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
    if (process.env.REACT_APP_GA_ID) {
      //@ts-ignore
      window.gtag('config', process.env.REACT_APP_GA_ID, {
        page_path: history.location.pathname,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    headerNode?.current?.focus()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx('page-header', showBg && 'primary-bg')}>
      <header>
        <div className={clsx('page-header-wrap')}>
          <div
            className={clsx(
              'page-header-content',
              withStepper && 'page-header-content-stepper',
            )}
          >
            {backBtn && <BackBtn backBtnHandler={backBtnHandler} />}
            <h1
              tabIndex={-1}
              ref={headerNode}
              data-testid="page-heading"
              className={clsx(
                'text-heading-s page-header-text',
                !backBtn && 'centered',
              )}
            >
              {title}
            </h1>
          </div>
          {props.children}
        </div>
      </header>
    </div>
  )
})
