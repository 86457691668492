import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { BackIcon } from '../icons/BackIcon'
interface BackBtnProps {
  backBtnHandler?: (e: any) => void
}

export const BackBtn = ({ backBtnHandler }: BackBtnProps) => {
  const history = useHistory()

  const goBack = useCallback(
    (e: any) => (backBtnHandler ? backBtnHandler(e) : history.goBack()),
    [backBtnHandler, history],
  )

  return (
    <button onClick={goBack} className="back-btn" aria-label="back button">
      <BackIcon />
    </button>
  )
}
