import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const EmotionalWellbeingIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 43"
      className={cx('emotional-wellbeing-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#475a79" cx="21.5" cy="21.5" r="21.5"></circle>
          <path
            d="M14.906,31.085 L14.906,26.695 C12.168,24.163 11.263,20.214 12.628,16.745 C13.992,13.276 17.347,10.996 21.08,10.9999947 C27.994,10.9999947 29.487,16.683 31.952,23.065 C32.037,23.288 32.008,23.539 31.872,23.736 C31.737,23.933 31.513,24.051 31.274,24.051 L29.435,24.051 L29.435,26.952 C29.435,28.553 28.134,29.852 26.529,29.852 L25.076,29.852 L25.076,31.085"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M26.529,18.976 C26.529,18.576 26.204,18.251 25.803,18.251 L23.623,18.251 L23.623,16.076 C23.623,15.675 23.298,15.351 22.897,15.351 L19.991,15.351 C19.59,15.351 19.265,15.675 19.265,16.076 L19.265,18.251 L17.085,18.251 C16.684,18.251 16.359,18.576 16.359,18.976 L16.359,21.876 C16.359,22.277 16.685,22.601 17.086,22.601 L19.265,22.601 L19.265,24.777 C19.265,25.177 19.59,25.502 19.991,25.502 L22.897,25.502 C23.298,25.502 23.623,25.177 23.623,24.777 L23.623,22.601 L25.803,22.601 C26.204,22.601 26.529,22.277 26.529,21.876 L26.529,18.976 Z"
            stroke="#FFFFFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </svg>
  )
}
