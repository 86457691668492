import { memo } from 'react'
import cx from 'clsx'

export interface CardProps {
  className?: string
  children?: any
}
export const Card = memo<CardProps>(function Card(props) {
  const { className, children } = props
  return <div className={cx('card', className)}>{children}</div>
})
