import React from 'react'
import clsx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const EyeCrossedIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={clsx('eye-icon eye-crossed-icon', className)}
      viewBox="0 0 20 17"
      fill="none"
      role="img"
    >
      <title>Show password</title>
      <path
        d="M17.7811 0.330054C17.4954 0.0252087 17.0301 0.0295023 16.7403 0.330054L14.1283 3.07796C10.8918 1.53226 7.43896 1.9101 4.14531 4.17283C1.69242 5.85592 0.202726 7.97266 0.141506 8.06283C-0.0543987 8.34621 -0.046236 8.73263 0.161913 9.00742C1.55773 10.8107 3.01886 12.2147 4.51263 13.1937L2.21483 15.611C1.92914 15.9115 1.92914 16.401 2.21483 16.7059C2.35768 16.8561 2.54542 16.9334 2.73316 16.9334C2.9209 16.9334 3.10865 16.8561 3.25149 16.7059L17.7811 1.42492C18.0668 1.12437 18.0668 0.6349 17.7811 0.330054ZM8.18176 9.33374C8.07565 9.08041 8.02259 8.80562 8.02259 8.51795C8.02259 7.96408 8.22666 7.44026 8.60214 7.04525C9.18985 6.42697 10.0592 6.28099 10.7775 6.60301L8.18176 9.33374ZM11.8631 5.4652C10.5285 4.56355 8.72866 4.7267 7.56548 5.95038C6.91246 6.63735 6.55738 7.5476 6.55738 8.51366C6.55738 9.21781 6.74921 9.8919 7.10428 10.4715L5.5901 12.0645C4.26367 11.2573 2.95764 10.0636 1.68834 8.50507C2.23116 7.83527 3.37801 6.5429 4.94933 5.4652C7.66751 3.60178 10.3734 3.19818 13.01 4.2587L11.8631 5.4652Z"
        fill="#8A93A1"
      />
      <path
        d="M19.8381 8.02895C18.8301 6.7237 17.7852 5.62454 16.7241 4.75294C16.4017 4.49103 15.9405 4.55114 15.6874 4.88604C15.4385 5.22094 15.4956 5.70611 15.8139 5.97232C16.6547 6.66359 17.4955 7.52231 18.3117 8.52701C17.8301 9.11952 16.8914 10.1843 15.618 11.159C13.1651 13.0353 10.6878 13.7137 8.25936 13.1727C7.86347 13.0825 7.47166 13.353 7.39003 13.7695C7.30432 14.186 7.56144 14.5982 7.95734 14.684C8.58995 14.8257 9.22664 14.8944 9.86741 14.8944C10.8306 14.8944 11.802 14.7355 12.7692 14.4178C14.0385 14.0013 15.2997 13.3144 16.52 12.3784C18.577 10.794 19.8055 9.04653 19.8586 8.97354C20.0545 8.69016 20.0463 8.30374 19.8381 8.02895Z"
        fill="#8A93A1"
      />
    </svg>
  )
}
