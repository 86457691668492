import { memo } from 'react'
import { useHistory } from 'react-router-dom'

import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useAction } from '@core/shared/useAction'

export interface RedirectOnPageRefreshProps {
  message?: string
  destination?: string
  messageType?: 'error' | 'success' | 'warning' | 'info'
  messageDuration?: number
}

/**
 * Used in booking flow. When user refreshes page, booking data being lost,
 * so, all that we can do in that case is redirect user to starting point and show the message
 * which describes app behaviour
 */
export const RedirectOnPageRefresh = memo<RedirectOnPageRefreshProps>(
  function RedirectOnPageRefresh(props) {
    const {
      messageType = 'error',
      message = 'Your booking details were not saved due to a page refresh. Please try again.',
      destination = ScreenRoutes.HOME,
      messageDuration = 8000,
    } = props

    const history = useHistory()
    const showNotification = useAction(showNotificationAction)

    history.replace(destination)
    showNotification({
      type: messageType,
      title: message,
      duration: messageDuration,
    })

    return null
  },
)
