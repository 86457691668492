import { useState, useEffect } from 'react'
import { NotificationIcons } from '../../icons/NotificationIcons'
import { IIdleTimer } from 'react-idle-timer'
import { useSelector } from 'react-redux'
import { idlePromptVisibility } from '@core/app/common/IdlePrompt/selectors'
import { idleTimerStates } from '../SessionTimeout'

let countdownInterval: NodeJS.Timeout

type Props = {
  idleTimer: IIdleTimer
}

export const IdleBanner = ({ idleTimer }: Props): JSX.Element | null => {
  const isVisible = useSelector(idlePromptVisibility)

  const [timeoutDisplay, setTimeoutDisplay] = useState('')

  const getTimeoutDisplay = (timeoutSeconds: number): string => {
    const minutes = Math.floor(timeoutSeconds / 60)
    const seconds = timeoutSeconds - minutes * 60
    return `${minutes}m ${seconds}s`
  }

  const onHandleStayLoggedIn = () => {
    idleTimer.reset()
    idleTimer.message(idleTimerStates.onReset, true)
    clearInterval(countdownInterval)
  }

  useEffect(() => {
    if (isVisible) {
      countdownInterval = setInterval(() => {
        const timeoutSeconds = Math.floor(idleTimer.getRemainingTime() / 1000)
        if (timeoutSeconds > 0) {
          const timeoutDisplay = getTimeoutDisplay(timeoutSeconds)
          setTimeoutDisplay(timeoutDisplay)
        }
      }, 1000)
      return () => {
        clearInterval(countdownInterval)
      }
    }
  }, [idleTimer, isVisible])

  return isVisible && timeoutDisplay ? (
    <div className="session-timeout">
      <div className="idle-banner-container notification-item-wrapper">
        <div className="notification-item flex-column">
          <div className="notification-title-container flex-row-center w-full mb-2">
            <NotificationIcons type="warning" />
            <p
              data-testid="idle-banner-title"
              className="text-weight-lg ml-2 text-smd"
              role="alert"
            >
              Your session is about to expire due to inactivity. Do you want to
              stay logged in?
            </p>
          </div>
          <div className="banner-content flex-center action-cursor w-full">
            <span className="text-smd flex-align-self">{timeoutDisplay}</span>
            <div className="continue-btn-container flex-center">
              <button
                className="w-full btn btn-primary"
                onClick={() => onHandleStayLoggedIn()}
              >
                Yes, keep me logged in!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
