import { useEffect, useRef, useCallback } from 'react'
import { setTemporaryUserRecordRequestAction } from './user/actions'
import { useAction } from '@core/shared/useAction'

export const useClearTemporaryRecord = () => {
  const shouldClearTemporaryRecord = useRef<boolean>(true)
  const setTemporaryUserRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )

  const clearTemporaryRecord = useCallback(() => {
    if (shouldClearTemporaryRecord.current) {
      setTemporaryUserRecordRequest({
        userRecord: null,
        serviceType: '',
      })
    }
  }, [setTemporaryUserRecordRequest, shouldClearTemporaryRecord])

  useEffect(() => {
    return () => {
      clearTemporaryRecord()
    }
  }, [clearTemporaryRecord])

  return shouldClearTemporaryRecord
}
