import React from 'react'
import Slider from 'react-slick'

import { VideoCallIcon } from '@core/components/icons/VideoCallIcon'
import { MessageIcon } from '@core/components/icons/MessageIcon'
import { useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { getLandingSliderSettings } from '@core/components/app/Slider/settings'
import clsx from 'clsx'
import styles from './Slider.module.scss'
import { PhoneCallIcon } from '@core/components/icons/PhoneCallIcon'

export type SliderProps = {
  slider: React.RefObject<any>
  slideNum: number
  handleSlideChange: any
}

export const SliderComponent = (props: SliderProps) => {
  const { slider, handleSlideChange } = props
  const { messageGp } = useFeatures()
  const {
    landingVariables: {
      consultText = 'Consult with clinicians via',
      landingSlider,
    },
  } = useClientVariables()

  const settings = getLandingSliderSettings(landingSlider, handleSlideChange)

  const consultationOfferings = [
    {
      label: 'Video call',
      icon: <VideoCallIcon />,
    },
    {
      label: 'Phone call',
      icon: <PhoneCallIcon />,
    },
  ]

  if (messageGp) {
    consultationOfferings.push({
      label: 'Message',
      icon: <MessageIcon />,
    })
  }

  return (
    <div className={styles.slideInContent} data-testid="slide">
      <Slider ref={slider} {...settings}>
        <div className={styles.slideOne} data-testid="slide-page1">
          <h2 className="text-body text-center">{consultText}</h2>
          <div className={clsx('flex-center w-full', styles.icons)}>
            {consultationOfferings.map((item) => (
              <div key={item.label} className={styles.icon}>
                <div
                  className={styles.iconImgContainer}
                  data-testid="slider-icon"
                >
                  {item.icon}
                </div>
                <p className="text-body-s mt-3">{item.label}</p>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.slideTwo} data-testid="slide-page2">
          <ul data-testid="slide-two-list">
            {landingSlider?.slide2Content?.map((line, index) => (
              <li key={index} className="text-body-s">
                {line}
              </li>
            ))}
          </ul>
        </div>
      </Slider>
    </div>
  )
}
