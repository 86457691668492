import { ofType, Epic } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import dayjs from 'dayjs'
import FileSaver from 'file-saver'

import {
  BookingActionTypes,
  GetUpcomingBookingsAction,
  CancelBookingAction,
  GetBookingAttachmentsAction,
  UpdateBookingAction,
  DeleteBookingAttachment,
  SubmitBookingAction,
  CreateBookingJoiningTokenAction,
  DownloadBookingAttachmentRequestAction,
  GetBookingByIdRequestAction,
} from './actionTypes'
import {
  getUpcomingBookingsSuccess,
  getBookingAttachmentsSuccess,
  cancelBookingSuccess,
  submitBookingFailAction,
  submitBookingSuccessAction,
  downloadBookingAttachmentSuccessAction,
  createBookingJoiningTokenSuccessAction,
  getBookingByIdSuccessAction,
} from './actions'
import {
  getUpcomingBookings,
  cancelBooking,
  getBookingAttachments,
  updateBooking,
  uploadFile,
  createBookingAttachment,
  deleteBookingAttachment,
  submitBooking,
  createJoiningToken,
  downloadFile,
  getBookingById,
} from '../../api/bookings'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import {
  BookingType,
  BookingAttachmentType,
  BookingIncludedFileType,
  IncludedAppointment,
  IncludedUser,
  IncludedPatient,
  AddBookingAttachmentData,
  IncludedConsultation,
  BookingAttachments,
} from '@core/shared/types/bookings'
import { fileToBase64 } from '@core/shared/file'

import startVideoConsultationTrick from '@core/shared/startVideoConsultationTrick'
import makeJoinLink from '@core/shared/makeJoinLink'
import { ResponseCodes } from '../responseCodes'
import { getMessageByCode, mapResponseErrors } from '../responseMessages'

const mapUpcomingBookings = (data: any) => {
  return data.data.map((booking: BookingType) => {
    const appoinmentRel = data.included.find(
      (item: IncludedAppointment) =>
        item.type === booking?.relationships?.appointment?.data?.type &&
        item.id === booking?.relationships?.appointment?.data?.id,
    )
    const consultationRel = data?.included?.find(
      (item: IncludedConsultation) =>
        item.type === booking?.relationships?.consultation?.data?.type &&
        item.id === booking?.relationships?.consultation?.data?.id,
    )
    booking.relationships.appointment.attributes = appoinmentRel.attributes
    booking.relationships.user.attributes = data?.included?.find(
      (item: IncludedUser) =>
        item.type === booking?.relationships?.user?.data?.type &&
        item.id === booking?.relationships?.user?.data?.id,
    ).attributes
    booking.relationships.patientRecord.attributes = data?.included?.find(
      (item: IncludedPatient) =>
        item.type === booking?.relationships?.patientRecord?.data?.type &&
        item.id === booking?.relationships?.patientRecord?.data?.id,
    ).attributes
    booking.relationships.consultation.attributes = consultationRel.attributes
    booking.relationships.consultation.outcome = data?.included?.find(
      (item: IncludedConsultation) =>
        item.type === consultationRel?.relationships?.outcome?.data?.type &&
        item.id === consultationRel?.relationships?.outcome?.data?.id,
    )
    if (consultationRel.relationships.outputs?.data?.length) {
      const includedOutputs = consultationRel.relationships.outputs?.data?.map(
        (el: any) => ({
          ...el,
          attributes: data?.included?.find(
            (item: IncludedConsultation) =>
              item.type === el.type && item.id === el.id,
          ),
        }),
      )
      const includedOutputsWithFiles = includedOutputs.map((el: any) => ({
        ...el,
        attributes: {
          ...el.attributes,
          relationships: {
            file: {
              ...el.attributes.relationships.file,
              attributes: data?.included?.find(
                (item: any) =>
                  item.type === el.attributes.relationships.file.data.type &&
                  item.id === el.attributes.relationships.file.data.id,
              ).attributes,
            },
          },
        },
      }))

      booking.relationships.consultation.outputs = includedOutputsWithFiles
    }

    booking.relationships.clinicalPractitioner = data?.included?.find(
      (item: IncludedAppointment | IncludedUser | IncludedPatient) =>
        item.type === 'clinicalPractitioners' &&
        item.id === appoinmentRel.relationships.clinicalPractitioner.data.id,
    ).attributes

    return booking
  })
}

const mapBookingAttachments = (data: BookingAttachments) =>
  data.data.map((el: BookingAttachmentType) => {
    const file = data.included.find(
      (item: BookingIncludedFileType) =>
        item.type === el.relationships.file.data.type &&
        item.id === el.relationships.file.data.id,
    )
    return {
      ...el,
      relationships: {
        file: {
          ...file,
        },
      },
    }
  })

const filterUpcomingBookings = (bookings: BookingType[]) =>
  bookings.filter(
    (booking: BookingType) =>
      booking.relationships.appointment.attributes.status !== 'Completed' &&
      booking.attributes.status !== 'Cancelled' &&
      booking.attributes.status !== 'Completed' &&
      booking.attributes.status !== 'PendingFollowUpActions',
  )

const filterPastBookings = (bookings: BookingType[]) =>
  bookings.filter(
    (booking: BookingType) =>
      booking.attributes.status === 'Completed' ||
      booking.relationships.appointment.attributes.status === 'Completed' ||
      booking.attributes.status === 'Cancelled' ||
      booking.attributes.status === 'PendingFollowUpActions',
  )

const addBookingAttachment = async (payload: AddBookingAttachmentData) => {
  const { files, userId, bookingId } = payload
  await Promise.all(
    files.map(async (file: File) => {
      try {
        const base64String = await fileToBase64(file)

        const response = await uploadFile({
          userId,
          data: {
            type: 'files',
            attributes: {
              content: base64String,
              fileName: file.name,
            },
          },
        })

        await createBookingAttachment({
          userId,
          bookingId,
          data: {
            type: 'attachments',
            attributes: {
              description: response.data.data.attributes.fileName,
            },
            relationships: {
              file: {
                data: {
                  type: 'files',
                  id: response.data.data.id,
                },
              },
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }),
  )
}

export const getUpcomingBookingsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.GET_UPCOMING_BOOKINGS),
    mergeMap(async (action: GetUpcomingBookingsAction) => {
      try {
        const { userId } = action.payload
        const response = await getUpcomingBookings({ userId })

        const mappedBookings = mapUpcomingBookings(response.data)
        notifySuccess(action)
        return getUpcomingBookingsSuccess({
          upcomingBookings: filterUpcomingBookings(mappedBookings).sort(
            (a: BookingType, b: BookingType) =>
              Number(dayjs(a.relationships.appointment.attributes.start)) -
              Number(dayjs(b.relationships.appointment.attributes.start)),
          ),
          completedBookings: filterPastBookings(mappedBookings).sort(
            (a: BookingType, b: BookingType) =>
              Number(dayjs(b.relationships.appointment.attributes.start)) -
              Number(dayjs(a.relationships.appointment.attributes.start)),
          ),
        })
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const getBookingAttachmentsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.GET_BOOKING_ATTACHMENTS),
    mergeMap(async (action: GetBookingAttachmentsAction) => {
      try {
        const response = await getBookingAttachments(action.payload)

        notifySuccess(action)
        return getBookingAttachmentsSuccess({
          attachments: mapBookingAttachments(response.data),
          isCompletedBooking: action.payload.isCompletedBooking,
        })
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const cancelBookingEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.CANCEL_BOOKING),
    mergeMap(async (action: CancelBookingAction) => {
      try {
        const response = await cancelBooking(action.payload)

        notifySuccess(action)

        return cancelBookingSuccess({
          bookingId: response.data.data.id,
        })
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const updateBookingEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.UPDATE_BOOKING),
    mergeMap(async (action: UpdateBookingAction) => {
      try {
        const {
          bookingId,
          symptoms,
          userId,
          files,
          reasonForBooking,
          contactDetails,
        } = action.payload
        const dataSent = {
          userId,
          updatedBooking: {
            id: bookingId,
            type: 'bookings',
            attributes: {
              symptoms,
              reasonForBooking,
              contactDetails: contactDetails,
            },
          },
        }

        if (files?.length) {
          await addBookingAttachment({
            files,
            bookingId,
            userId,
          })
        }

        await updateBooking(dataSent)
        notifySuccess(action)
        return []
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const deleteBookingAttachmentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.DELETE_BOOKING_ATTACHMENT),
    mergeMap(async (action: DeleteBookingAttachment) => {
      try {
        await deleteBookingAttachment(action.payload)
        notifySuccess(action)
        return []
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const submitBookingEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.SUBMIT_BOOKING),
    mergeMap(async (action: SubmitBookingAction) => {
      const { userId, data, IsPaymentEligibilityEnabled } = action.payload
      const { files = [], ...rest } = data

      try {
        const booking = await submitBooking(
          userId,
          rest,
          IsPaymentEligibilityEnabled,
        )
        if (files.length) {
          await addBookingAttachment({
            bookingId: booking.data.data.id,
            files: data.files,
            userId,
          })
        }
        notifySuccess(action)
        return submitBookingSuccessAction()
      } catch (e) {
        const errors = mapResponseErrors(e.response?.data)

        const reason =
          errors?.map((el) => el?.message)?.join(' ') ||
          'Failed to submit booking'

        notifyFail(action, reason)

        return submitBookingFailAction()
      }
    }),
    concatActions(),
  )

export const createJoiningTokenEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN),
    mergeMap(async (action: CreateBookingJoiningTokenAction) => {
      try {
        const response = await createJoiningToken(action.payload)

        notifySuccess(action)

        startVideoConsultationTrick(
          makeJoinLink(
            action.payload.userId,
            response.data.data.attributes.oneTimeToken,
            action.payload.consultationId,
          ),
        )

        return createBookingJoiningTokenSuccessAction()
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const downloadBookingAttachmentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.DOWNLOAD_BOOKING_ATTACHMENT),
    mergeMap(async (action: DownloadBookingAttachmentRequestAction) => {
      try {
        const response = await downloadFile(action.payload)

        const file = new File([response.data], action.payload.fileName, {
          type: action.payload.mimeType,
        })

        FileSaver.saveAs(file)

        notifySuccess(action)

        return downloadBookingAttachmentSuccessAction()
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const getBookingByIdEpic: Epic = (action$) =>
  action$.pipe(
    ofType(BookingActionTypes.GET_BOOKING_BY_ID),
    mergeMap(async (action: GetBookingByIdRequestAction) => {
      try {
        const { userId, bookingId, isPastBookingSelect } = action.payload
        const response = await getBookingById({ userId, bookingId })

        const newResponse = {
          ...response.data,
          data: [response.data.data],
        }

        const mappedBooking = mapUpcomingBookings(newResponse)

        notifySuccess(action)

        return getBookingByIdSuccessAction({
          upcomingBookings: filterUpcomingBookings(mappedBooking),
          completedBookings: filterPastBookings(mappedBooking),
          selectCompletedBooking: {
            id: isPastBookingSelect ? bookingId : null,
          },
        })
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export default [
  getUpcomingBookingsEpic,
  getBookingAttachmentsEpic,
  cancelBookingEpic,
  updateBookingEpic,
  deleteBookingAttachmentEpic,
  submitBookingEpic,
  createJoiningTokenEpic,
  downloadBookingAttachmentEpic,
  getBookingByIdEpic,
]
