import * as React from 'react'
import { SpecialtiesItemsType } from '../../specialitiesMap'
import { ButtonStyleEnum } from '../../Button/Button'
import { NativeSelectOptions } from '../../form/NativeSelect'
import { LayoutTypes } from '../Services/SpecialtiesSlider'
import { Service } from './features'

export type NavItem = {
  /** Icon to use for this nav item. */
  Icon: (props: any) => JSX.Element
  /** The text to display for this nav item. */
  title: string
  /** The href of the link. */
  href: string
  /** Routes that the link should be in an "active" state for. */
  routes?: string[]
}

/**
 * Variables that clients are permitted to set in order to
 * customize various aspects of the patient portal look and feel.
 */
export type ClientVariables = {
  clientVariables: {
    phoneNumber: string
    phoneNumberInternational?: string
    phoneInputPlaceholder: string
    clientIdentifierLabel: string
    countryList?: NativeSelectOptions[]
    baseCountryCode: string
    mapsPostcodePlaceholderValue?: string
    pinLength?: number
    clientIdentifierParam?: string
    clientName: string
    bookings?: {
      [S in Service]?: {
        addToCalendar?: {
          subject?: string
          details?: string
        }
      }
    }
  }
  dashboardVariables: {
    logoSmallWidth: number
    logoLargeWidth: number
    logoAlt: string
    hasBackground: boolean
    backgroundStyles?: object
    customSpecialties?: Map<string, Partial<SpecialtiesItemsType>>
    specialtiesLayout: keyof typeof LayoutTypes
    specialtiesHeader?: string
    customSlot1?: React.ReactNode
    customSlot2?: React.ReactNode
    customSlot3?: React.ReactNode
    customSlot4?: React.ReactNode
  }
  landingVariables: {
    mainHeader?: string | JSX.Element
    logoAlt?: string
    logoWidth?: number
    missionStatement?: string
    partnershipText?: string
    customBlock?: JSX.Element
    consultText?: string
    backgroundStyles: Record<string, string>
    landingSlider?: {
      speed?: number // optional as there's a default
      numSlides: number
      pagination?: Array<number>
      slide2Content?: Array<string>
    }
  }
  clientData?: {
    navigation?: {
      mobile?: {
        left?: NavItem
        center?: NavItem
        right?: NavItem
      }
      home?: {
        Icon?: (props: any) => JSX.Element
        title?: string
      }
      articles?: {
        Icon?: (props: any) => JSX.Element
        title?: string
      }
      about?: {
        title?: string
      }
      tandc?: {
        title?: string
      }
      signOut?: {
        title?: string
        redirectUrl?: string
      }
    }
  }
  SSOVariables?: {
    termsAndCondsAcceptedURL?: string
    unauthenticatedRedirectUrl?: string
  }
  /**
   * Custom theme values for the client.
   */
  theme?: {
    buttonStyle?: keyof typeof ButtonStyleEnum
    fontFamily?: string
  }
}

const ClientVariablesContext = React.createContext<ClientVariables | null>(null)

export type ClientVariablesProviderProps = {
  children: React.ReactNode
  variables: ClientVariables
}
export const ClientVariablesProvider = ({
  children,
  variables,
}: ClientVariablesProviderProps) => {
  return (
    <ClientVariablesContext.Provider value={variables}>
      {children}
    </ClientVariablesContext.Provider>
  )
}

export const useClientVariables = () => {
  const context = React.useContext(ClientVariablesContext)
  if (context === null) {
    throw new Error(
      'useClientVariables must be called from within a <ClientVariablesProvider> tag.',
    )
  }
  return context
}
