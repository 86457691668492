import { combineReducers } from 'redux'
import NotificationItemReducer, {
  DefaultNotificationItem,
} from './NotificationItem/reducer'
import IdlePromptReducer from './IdlePrompt/reducer'

export interface CommonState {
  notificationItem: DefaultNotificationItem
  isIdlePromptVisible: boolean
}

export default combineReducers<CommonState>({
  notificationItem: NotificationItemReducer,
  isIdlePromptVisible: IdlePromptReducer,
})
