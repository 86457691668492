import isValidDate from 'date-fns/isValid'
import format from 'date-fns/format'

import addDays from 'date-fns/addDays'

import addYears from 'date-fns/addYears'

import getDay from 'date-fns/getDay'

import getYear from 'date-fns/getYear'

import setMonth from 'date-fns/setMonth'

import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'

import startOfWeek from 'date-fns/startOfWeek'

import dfIsEqual from 'date-fns/isEqual'
import dfIsSameDay from 'date-fns/isSameDay'
import dfIsSameMonth from 'date-fns/isSameMonth'
import dfIsSameYear from 'date-fns/isSameYear'

import defaultLocale from 'date-fns/locale/en-GB'

// ** Date Localization **

export function registerLocale(localeName: string, localeData: Locale) {
  const scope = typeof window !== 'undefined' ? window : global

  // @ts-ignore
  if (!scope.__localeData__) {
    // @ts-ignore
    scope.__localeData__ = {}
  }
  // @ts-ignore
  scope.__localeData__[localeName] = localeData
}

export function setDefaultLocale(localeName: string) {
  const scope = typeof window !== 'undefined' ? window : global

  // @ts-ignore
  scope.__localeId__ = localeName
}

export function getDefaultLocale() {
  const scope = typeof window !== 'undefined' ? window : global

  // @ts-ignore
  return scope.__localeId__ || 'en'
}

export function getLocaleObject(localeSpec: string | Locale): Locale {
  if (typeof localeSpec === 'string') {
    // Treat it as a locale name registered by registerLocale
    const scope = typeof window !== 'undefined' ? window : global
    // @ts-ignore
    return scope.__localeData__ ? scope.__localeData__[localeSpec] : null
  } else {
    // Treat it as a raw date-fns locale object
    return localeSpec
  }
}

export function toIsoDateString(date: Date, dateFormat?: string) {
  return format(date, dateFormat || 'yyyy-MM-dd')
}
export function formatDate(
  date: Date,
  formatStr: string,
  locale: string | Locale,
) {
  if (locale === 'en') {
    return format(date, formatStr)
  }
  let localeObj = getLocaleObject(locale)
  if (locale && !localeObj) {
    console.warn(
      `A locale object was not found for the provided string ["${locale}"].`,
    )
  }

  if (
    !localeObj &&
    !!getDefaultLocale() &&
    !!getLocaleObject(getDefaultLocale())
  ) {
    localeObj = getLocaleObject(getDefaultLocale())
  }

  return format(date, formatStr, {
    locale: localeObj ? localeObj : undefined,
  })
}

export function safeDateFormat(
  date: Date,
  dateFormat: string | string[],
  locale: string,
) {
  return date
    ? formatDate(
        date,
        Array.isArray(dateFormat) ? dateFormat[0] : dateFormat,
        locale,
      )
    : ''
}

// ** Date Setters **

// export function getFormattedWeekdayInLocale(date: Date, formatFunc, locale) {
//   return formatFunc(formatDate(date, 'EEEE', locale))
// }

export function getWeekdayMinInLocale(date: Date, locale: string | Locale) {
  return formatDate(date, 'EEEEEE', locale)
}

export function getWeekdayShortInLocale(date: Date, locale: string | Locale) {
  return formatDate(date, 'EEE', locale)
}

export function getMonthInLocale(month: number, locale: string | Locale) {
  return formatDate(setMonth(new Date(), month), 'LLLL', locale)
}

export function getMonthShortInLocale(month: number, locale: string | Locale) {
  return formatDate(setMonth(new Date(), month), 'LLL', locale)
}

export function getStartOfWeek(date: Date, locale?: string | Locale) {
  const localeObj = locale
    ? getLocaleObject(locale)
    : getLocaleObject(getDefaultLocale())
  return startOfWeek(date, { locale: localeObj })
}

export function isOutOfBounds(
  day: Date,
  { min, max }: { min?: Date; max?: Date } = {},
) {
  return (
    (min && differenceInCalendarDays(day, min) < 0) ||
    (max && differenceInCalendarDays(day, max) > 0)
  )
}

export function isSameDay(date1: Date, date2: Date) {
  if (date1 && date2) {
    return dfIsSameDay(date1, date2)
  } else {
    return !date1 && !date2
  }
}

export function isSameYear(date1: Date, date2: Date) {
  if (date1 && date2) {
    return dfIsSameYear(date1, date2)
  } else {
    return !date1 && !date2
  }
}

export function isEqual(date1: Date, date2: Date) {
  if (date1 && date2) {
    return dfIsEqual(date1, date2)
  } else {
    return !date1 && !date2
  }
}

export function isDayDisabled(
  day: Date,
  {
    min,
    max,
    excludeDates,
    includeDates,
    filterDate,
  }: {
    min?: Date
    max?: Date
    excludeDates?: Date[]
    includeDates?: Date[]
    filterDate?: (date: Date | null) => boolean
  } = {},
) {
  return (
    isOutOfBounds(day, { min, max }) ||
    (excludeDates &&
      excludeDates.some((excludeDate) => isSameDay(day, excludeDate))) ||
    (includeDates &&
      !includeDates.some((includeDate) => isSameDay(day, includeDate))) ||
    (filterDate && !filterDate(new Date(day.getTime()))) ||
    false
  )
}

export function isYearDisabled(
  year: number,
  {
    min,
    max,
  }: {
    min?: Date
    max?: Date
  } = {},
) {
  const left = new Date(year, 11, 31)
  const right = new Date(year, 0, 1)
  return isOutOfBounds(left, { min, max }) && isOutOfBounds(right, { min, max })
}

export function isWeekend(date: Date) {
  const dayOfWeek = getDay(date)
  return dayOfWeek === 0 || dayOfWeek === 6
}

export function isSameMonth(date1?: Date, date2?: Date) {
  if (date1 && date2) {
    return dfIsSameMonth(date1, date2)
  } else {
    return !date1 && !date2
  }
}

export function isWeekInMonth(startOfWeek: Date, date: Date) {
  const endOfWeek = addDays(startOfWeek, 6)
  return isSameMonth(startOfWeek, date) || isSameMonth(endOfWeek, date)
}

export function getYearsPeriod(date: Date, yearItemNumber: number) {
  const endPeriod = Math.ceil(getYear(date) / yearItemNumber) * yearItemNumber
  const startPeriod = endPeriod - (yearItemNumber - 1)

  const period: number[] = []
  for (let year = startPeriod; year <= endPeriod; year++) {
    period.push(year)
  }

  return period
}
