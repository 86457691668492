import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useParams, useHistory } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { TextAreaInput } from '@core/components/form/TextAreaInput'
import {
  selectedCompletedBooking,
  selectedCompletedBookingAttachments,
  selectedCompletedBookingOutputs,
} from '@core/app/bookings/selectors'
import { selectAppUser } from '@core/app/user/selectors'
import {
  getBookingAttachments as getBookingAttachmentsAction,
  selectCompletedBooking as selectCompletedBookingAction,
  downloadBookingAttachmentRequestAction,
  getBookingByIdRequestAction,
} from '@core/app/bookings/actions'
import { useActionAsync, useAction } from '@core/shared/useAction'
import {
  BookingAttachmentType,
  BookingMappedOutputsType,
  CPSpecialties,
  CPSpecialtiesEnum,
} from '@core/shared/types/bookings'
import { SideMenu } from '@core/components/app/SideMenu'
import { DownloadAttachmentIcon } from '@core/components/icons/DownloadAttachmentIcon'
import getConsultationTitle from '@core/components/consultationOutcome'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { OutputItem } from '@core/pages/Consultations/Past/components/OutputItem'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { getPractitionerLabel } from '@core/components/specialityPractitionerLabels'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { AppointmentType } from '@core/shared/types/appointment'

export const ViewPastConsultation = memo(function ViewPastConsultationItem() {
  const history = useHistory()
  const { bookingId } = useParams<{ bookingId: string }>()
  const selectedBooking = useSelector(selectedCompletedBooking)
  const attachments = useSelector(selectedCompletedBookingAttachments)
  const selectedBookingOutputs = useSelector(selectedCompletedBookingOutputs)
  const user = useSelector(selectAppUser)
  const getBookingAttachments = useActionAsync(getBookingAttachmentsAction)
  const downloadBookingAttachmentRequest = useActionAsync(
    downloadBookingAttachmentRequestAction,
  )
  const getBookingByIdRequest = useActionAsync(getBookingByIdRequestAction)

  const selectCompletedBooking = useAction(selectCompletedBookingAction)

  const [isLoading, setLoading] = useState<boolean>(false)

  const fetchBookingById = useCallback(
    async ({ userId, bookingId }: { userId: string; bookingId: string }) => {
      setLoading(true)
      try {
        await getBookingByIdRequest({
          userId,
          bookingId,
          isPastBookingSelect: true,
        })
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
        history.push(ScreenRoutes.DASHBOARD)
      }
    },
    [getBookingByIdRequest, history],
  )

  const downloadBookingAttachment = useCallback(
    async (attachment: BookingAttachmentType) => {
      await downloadBookingAttachmentRequest({
        userId: selectedBooking?.relationships.user.data.id,
        fileName: attachment.attributes.description,
        fileId: attachment.relationships.file.id,
        mimeType: attachment?.relationships?.file?.attributes?.mimeType,
      })
    },
    [downloadBookingAttachmentRequest, selectedBooking],
  )

  const downloadBookingOutput = useCallback(
    async (attachment: BookingMappedOutputsType) => {
      await downloadBookingAttachmentRequest({
        userId: selectedBooking?.relationships.user.data.id,
        fileName: attachment.attributes.attributes.description,
        fileId: attachment.attributes.relationships.file.data.id,
        mimeType:
          attachment?.attributes?.relationships?.file?.attributes?.mimeType,
      })
    },
    [downloadBookingAttachmentRequest, selectedBooking],
  )

  const patientData = selectedBooking?.relationships?.patientRecord?.attributes

  const isOnsiteConsultation =
    selectedBooking?.attributes?.consultationType === AppointmentType.Onsite
  const consultationTypeText = useMemo(
    () =>
      selectedBooking?.attributes?.consultationType ===
      AppointmentType.VideoCall
        ? 'video call'
        : isOnsiteConsultation
        ? 'on-site appointment'
        : 'phone call',
    [isOnsiteConsultation, selectedBooking],
  )

  useEffect(() => {
    if (!selectedBooking && user?.id && bookingId) {
      fetchBookingById({ userId: user.id, bookingId })
    }
  }, [selectedBooking, user, fetchBookingById, bookingId])

  useEffect(() => {
    const fetchBookingAttachments = async () => {
      try {
        if (user && selectedBooking) {
          await getBookingAttachments({
            userId: user?.id,
            bookingId: selectedBooking?.id,
            isCompletedBooking: true,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchBookingAttachments()
  }, [getBookingAttachments, selectedBooking, user])

  useEffect(() => {
    return () => {
      selectCompletedBooking({
        bookingId: null,
        attachments: [],
      })
    }
  }, [selectCompletedBooking])

  const practitionerLabel = getPractitionerLabel(
    selectedBooking?.relationships.clinicalPractitioner?.specialties?.[0],
  )
  const doctor = selectedBooking?.relationships?.clinicalPractitioner || {}

  const { pastConsultations } = useFeatures()
  const {
    clientVariables: { phoneNumber },
  } = useClientVariables()

  const canViewNotesAndDocuments =
    !isOnsiteConsultation && pastConsultations.notesAndDocuments.isEnabled

  const cpSpecialties =
    selectedBooking?.relationships?.clinicalPractitioner?.specialties
  const isCounselling =
    Array.isArray(cpSpecialties) &&
    cpSpecialties.includes(CPSpecialties[CPSpecialtiesEnum.COUNSELLOR])

  return (
    <div className="page view-past-consultation-page">
      <PageHeader showBg backBtn title="Past GP Consultation" />

      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <div className="section">
            <h3 className="view-past-consultation-page-title mb-2 text-md text-weight-md">
              {getConsultationTitle({
                booking: selectedBooking,
                bookingType: consultationTypeText,
              })}
            </h3>

            <div className="consultation-info">
              <div className="consultation-info-item-wrapper">
                <div className="consultation-info-item-title">
                  Date and time:
                </div>
                <div className="consultation-info-item-data">
                  {dayjs(
                    selectedBooking?.relationships?.appointment?.attributes
                      ?.start,
                  ).format('DD MMMM YYYY HH:mm')}
                </div>
              </div>
              <div className="consultation-info-item-wrapper">
                <div className="consultation-info-item-title">Patient:</div>
                <div className="consultation-info-item-data">{`${patientData?.firstName} ${patientData?.lastName}`}</div>
              </div>
              {!isOnsiteConsultation ? (
                <div className="consultation-info-item-wrapper">
                  <div className="consultation-info-item-title">
                    Preferred contact number:
                  </div>
                  <div className="consultation-info-item-data">
                    {patientData?.phoneNumber}
                  </div>
                </div>
              ) : null}
              <div className="consultation-info-item-wrapper">
                <div className="consultation-info-item-title">Email:</div>
                <div className="consultation-info-item-data">
                  {patientData?.email}
                </div>
              </div>
              <div className="consultation-info-item-wrapper">
                <div className="consultation-info-item-title">
                  {practitionerLabel}:
                </div>
                <div className="consultation-info-item-data">
                  {`${doctor.firstName} ${doctor.lastName}`}
                </div>
              </div>
            </div>

            <div className="consultation-symptoms">
              <div className="details-row">
                <TextAreaInput
                  label="Reason for booking"
                  name="symptoms"
                  className="text-area-input w-full mb-2"
                  value={selectedBooking?.attributes.reasonForBooking}
                  readOnly
                />
                {!isCounselling ? (
                  <Feature name="hasSymptomsField">
                    <TextAreaInput
                      label="Your symptoms"
                      name="symptoms"
                      className="text-area-input w-full mb-2"
                      value={selectedBooking?.attributes.symptoms.join(', ')}
                      readOnly
                    />
                  </Feature>
                ) : null}
              </div>
              <div className="consultation-attachment-uploaded">
                <ul className="consultation-attachment-uploaded-list attachments-list mb-2">
                  {attachments?.map((attachment: BookingAttachmentType) => (
                    <li
                      className="consultation-attachment-uploaded-list-item"
                      key={attachment.id}
                    >
                      <span
                        data-tip
                        data-for={attachment.id}
                        className="list-item-title text-sm"
                      >
                        {attachment.attributes.description}
                      </span>
                      <ReactTooltip
                        id={attachment.id}
                        place="top"
                        effect="solid"
                        multiline={true}
                        className="custom-tooltip"
                      >
                        <span className="text-sm book-tooltip-text">
                          {attachment.attributes.description}
                        </span>
                      </ReactTooltip>
                      <div
                        className="list-item-delete-button action-cursor"
                        onClick={() => downloadBookingAttachment(attachment)}
                      >
                        <DownloadAttachmentIcon />
                      </div>
                    </li>
                  ))}
                </ul>
                {canViewNotesAndDocuments ? (
                  <>
                    <h4 className="consultation-attachment-uploaded-title mb-1 text-md text-weight-md mt-2">
                      Consultation notes and documents
                    </h4>
                    {selectedBookingOutputs?.length ? (
                      <>
                        <div className="mb-2">
                          <p className="past-consultation-section-text text-sm">
                            Your consultation reports are available for
                            download. To open the documents, please enter the
                            patient’s date of birth in the format DDMMYYYY
                          </p>
                        </div>
                        <ul className="consultation-attachment-uploaded-list">
                          {selectedBookingOutputs.map(
                            (attachment: BookingMappedOutputsType) => (
                              <OutputItem
                                key={attachment.id}
                                attachment={attachment}
                                downloadBookingOutput={downloadBookingOutput}
                              />
                            ),
                          )}
                        </ul>
                      </>
                    ) : (
                      <div>
                        <p className="past-consultation-section-text text-sm">
                          There are no documents available yet, you’ll receive
                          an email at{' '}
                          <span className="text-weight-md">
                            {patientData?.email}
                          </span>{' '}
                          when they will be available for download.
                        </p>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
              <div className="details-row">
                <div className="consultation-need-to-speak">
                  <h4 className="view-past-consultation-page-title mb-2 text-md text-weight-md mt-2">
                    Need to speak to someone?
                  </h4>
                  <p className="consultation-need-to-speak-subtitle text-sm mb-1">
                    Contact our customer service team on:
                  </p>
                  <p className="consultation-need-to-speak-phone-number text-sm text-weight-md text-color-primary-pink">
                    <a
                      href={
                        // Phone number field is sometimes used to store an email
                        phoneNumber.includes('@')
                          ? `mailto:${phoneNumber}`
                          : `tel:${phoneNumber}`
                      }
                    >
                      {phoneNumber}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <CircularLoader /> : null}

      <PageFooter stickyBottom />
    </div>
  )
})
