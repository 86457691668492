import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import cx from 'clsx'
import dayjs from 'dayjs'

import { PageHeader } from '@core/components/app/PageHeader'
import { TextInput } from '@core/components/form/TextInput'
import { NativeSelect } from '@core/components/form/NativeSelect'
import { PasswordInput } from '@core/components/form/PasswordInput'
import {
  isValidEmailStrict,
  useEmailValidation,
} from '@core/components/validation/email'
import { isValidPhoneNumberStrict } from '@core/shared/validation/phone'
import { useActionAsync, useAction } from '@core/shared/useAction'
import {
  registerAction,
  setTemporaryUserRecordRequestAction,
} from '@core/app/user/actions'
import { LabelPaper } from '@core/components/form/LabelPaper'
import { PaperContent, PaperHeader } from '@core/components/paper'
import { Radio } from '@core/components/form/Radio'
import { newPasswordValidators } from '@core/shared/validation/newPassword'
import { ErrorIcon } from '@core/components/icons/ErrorIcon'
import { useHistory, useLocation } from 'react-router-dom'
import { required } from '@core/shared/validation/required'
import { ageAfter16 } from '@core/shared/validation/underAgeValidate'
import { env } from '@core/shared/configuration'
import {
  loadReCaptcha,
  rewriteRecaptchaErrorMessage,
} from '@core/shared/recaptcha'
import { DateInputKnown } from '@core/components/form/DateInputKnown/DateInputKnown'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { PinInput } from '@core/components/form/PinInput'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { postCodePlaceholders } from '@core/shared/data/postCodePlaceholders'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import { useSelector } from 'react-redux'
import { ServiceType } from '@core/shared/types/patient/PatientRecord'
import { setTemporaryRecordDOB, getError } from '@core/shared/utils/formUtils'
import {
  ServiceDetailOptions,
  ServiceValues,
} from '@core/components/app/HealthServiceDetails/ServiceDetailOptions'
import { GpServiceError } from '@core/components/app/HealthServiceDetails/ServiceDetailsError/GpServiceError'
import { PharmacyServiceError } from '@core/components/app/HealthServiceDetails/ServiceDetailsError/PharmacyServiceError'
import { useOnlyOffersCounselling } from '@core/components/app/services'
import { serviceIsNotEmpty } from '@core/components/healthServiceDataUtils'

const errorsMap: Record<string, string> = {
  phoneNumber:
    'Enter valid phone number. Must include country code and + symbol',
  email: 'Enter valid email address',
  required: 'Required field',
  completePin: 'Required field',
  confirmEmail: 'Confirmation Email does not match Email',
  dateOfBirth:
    "Please check you have entered a valid date of birth and that you are aged 16 or over. Over 16's are able to book consultations for dependants through their account.",
}

interface RequirementPasswordRules {
  charactersLength?: boolean
  capitalLetter?: boolean
  number?: boolean
  specialSymbol?: boolean
  lowercaseLetter?: boolean
}

const TermsConditions = (
  <p className="text-smd text-color-primary text-center text-weight-md mt-4">
    By using this service you are agreeing to our{' '}
    <a
      href="https://www.healthhero.com/terms-and-conditions/"
      rel="noopener noreferrer"
      target="_blank"
      className="create-account-external-link"
      aria-label="HealthHero terms and conditions"
    >
      terms and conditions
    </a>{' '}
    and{' '}
    <a
      href="https://www.healthhero.com/privacy-policy/"
      rel="noopener noreferrer"
      target="_blank"
      className="create-account-external-link"
      aria-label="HealthHero privacy policy"
    >
      privacy policy
    </a>
    .
  </p>
)

interface CreateAccountProps {
  pinDescription?: string | React.ReactNode
  termsConditions?: React.ReactNode
}

export const CreateAccountPage = memo<CreateAccountProps>(
  function CreateAccountPage(props) {
    const { pinDescription, termsConditions } = props

    const temporaryUserRecord = useSelector(selectTemporaryUserRecord)
    const setTemporaryUserRecordRequest = useAction(
      setTemporaryUserRecordRequestAction,
    )

    const defaultFormValues = useMemo(
      () =>
        temporaryUserRecord?.userRecord
          ? {
              ...temporaryUserRecord.userRecord,
              nominatedPharmacy:
                temporaryUserRecord.serviceType === ServiceType.PHARMACY
                  ? null
                  : {
                      ...temporaryUserRecord.userRecord.nominatedPharmacy,
                    },
              gp:
                temporaryUserRecord.serviceType === ServiceType.GPSURGERY
                  ? null
                  : {
                      ...temporaryUserRecord.userRecord.gp,
                    },

              dateOfBirth: setTemporaryRecordDOB(
                temporaryUserRecord.userRecord.dateOfBirth,
              ),
            }
          : { isRegisteredWithGP: true },
      [temporaryUserRecord],
    )

    function getGPDetails(data: any) {
      // SAPI requires gpName (until it becomes not required / removed) yet we don't have it in the UI
      const gpName = 'Not known'
      const gp = {
        ...data?.gp,
        gpName,
      }
      return gp
    }

    function setDataOnSubmit(data: any) {
      const dateOfBirth = dayjs(data?.dateOfBirth).format('YYYY-MM-DD')
      const isRegisteredWithGP = data?.isRegisteredWithGP
      const coreData = {
        ...data,
        dateOfBirth: dateOfBirth,
        ...(clientIdentifierToSAPI && {
          clientIdentifier: clientIdentifierParams || data.clientIdentifier,
        }),
      }

      if (!isRegisteredWithGP) {
        coreData[ServiceValues.GP] = null
      }
      const pharmacyIsEmpty = !serviceIsNotEmpty(
        coreData?.nominatedPharmacy,
        ServiceValues.PHARMACY,
      )

      if (data?.gp) {
        coreData[ServiceValues.GP] = getGPDetails(data)
      }

      if (data?.nominatedPharmacy) {
        coreData[ServiceValues.PHARMACY] = data?.nominatedPharmacy
      }

      if (pharmacyIsEmpty && !isPharmacyRequired) {
        coreData[ServiceValues.PHARMACY] = null
      }

      return coreData
    }

    const methods = useForm({
      defaultValues: defaultFormValues as any,
      mode: 'onChange',
    })
    const {
      register,
      handleSubmit,
      errors,
      watch,
      formState,
      reset,
      setValue,
    } = methods

    const { clientIdentifierToSAPI, isPharmacyRequired } = useFeatures()
    const {
      clientVariables: {
        phoneInputPlaceholder,
        clientIdentifierLabel,
        baseCountryCode,
        countryList = [],
        pinLength = 12,
        clientIdentifierParam,
      },
    } = useClientVariables()

    const location = useLocation()

    const validateEmail = useEmailValidation()

    const hideGPAndPharmaFields = useOnlyOffersCounselling()

    let clientIdentifierParams: string | null
    if (clientIdentifierParam) {
      clientIdentifierParams = new URLSearchParams(
        location.search.toLowerCase(),
      ).get(clientIdentifierParam)
    }

    const createAccount = useActionAsync(registerAction)
    const history = useHistory()
    const [requirementPasswordRules, setRequirementPasswordRules] =
      useState<RequirementPasswordRules>({
        charactersLength: false,
        capitalLetter: false,
        number: false,
        specialSymbol: false,
      })

    const termsComponent = termsConditions || TermsConditions

    const [submitErrors, setSubmitErrors] = useState<Record<string, string>[]>(
      [],
    )

    const isValidPassword = Object.values(requirementPasswordRules).every(
      (v) => v,
    )

    const handlePasswordValidator = (value: any) => {
      const validFields: string[] = []

      newPasswordValidators.forEach((rule: any) => {
        if (rule.test instanceof RegExp) {
          if (rule.test.test(value)) {
            validFields.push(rule.messageType)
          }
        } else if (typeof rule.test === 'function') {
          if (rule.test(value)) {
            validFields.push(rule.messageType)
          }
        }
      })

      return validFields
    }

    const config = env()
    useEffect(() => loadReCaptcha(config.recaptcha.siteKey), [config])

    const onHandlePasswordFieldValidation = useCallback((password: string) => {
      let checkedRequirement = {
        charactersLength: false,
        capitalLetter: false,
        number: false,
        specialSymbol: false,
        lowercaseLetter: false,
      }

      const validatedFields = handlePasswordValidator(password)

      if (validatedFields && validatedFields.length) {
        validatedFields.forEach((el) => {
          checkedRequirement = {
            ...checkedRequirement,
            [`${el}`]: true,
          }
        })
      }
      setRequirementPasswordRules(checkedRequirement)
    }, [])

    useEffect(() => {
      if (temporaryUserRecord?.userRecord) {
        onHandlePasswordFieldValidation(
          temporaryUserRecord.userRecord?.password,
        )
      }
    }, [onHandlePasswordFieldValidation, temporaryUserRecord])

    const handleOnChangeInput = (event: any) => {
      if (event.target.name === 'password') {
        onHandlePasswordFieldValidation(event.target.value)
      }
    }

    const onSubmit = async (data: any) => {
      try {
        const newData = setDataOnSubmit(data)
        setSubmitErrors([])
        const recaptchaToken = await grecaptcha.execute(
          config.recaptcha.siteKey,
          { action: 'create_account' },
        )
        await createAccount({ ...newData, recaptchaToken })
        reset()
        setTemporaryUserRecordRequest({
          userRecord: null,
          serviceType: '',
        })
        history.push(ScreenRoutes.ACCOUNT_CREATED)
      } catch (e) {
        if (Array.isArray(e)) {
          setSubmitErrors(rewriteRecaptchaErrorMessage(e))
        } else {
          setSubmitErrors([{ message: 'something went wrong' }])
        }
        console.error(e, 'err')
      }
    }

    const fields = watch(['gender', 'userName'])

    const hasError = {
      gender: Boolean(errors.gender),
      dateOfBirth: Boolean(errors.dateOfBirth),
    }

    function onHandleBackBtn() {
      history.goBack()
      setTemporaryUserRecordRequest({
        userRecord: null,
        serviceType: '',
      })
    }

    return (
      <div className="page create-account-page">
        <PageHeader
          showBg
          backBtn
          title="Create account"
          backBtnHandler={onHandleBackBtn}
        />
        <div role="main" className="page-content">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="section">
                <p className="text-color-primary">
                  All fields are required unless marked as ‘optional’.
                </p>
              </div>
              <Feature name="clientIdentifierPin">
                <div className="section">
                  <PinInput
                    inputRef={register({
                      validate: {
                        required,
                        completePin: (val: string) =>
                          val.trim().length >= pinLength,
                      },
                    })}
                    name="clientIdentifier"
                    label={clientIdentifierLabel}
                    symbolNum={pinLength}
                    defaultValue={defaultFormValues?.clientIdentifier}
                    description={pinDescription}
                    error={getError(errors, 'clientIdentifier', errorsMap)}
                  />
                </div>
              </Feature>

              <Feature name="clientIdentifierField">
                <div className="section client-identifier">
                  <label className="section-title mb-1">
                    {clientIdentifierLabel}
                    {pinDescription && <p>{pinDescription}</p>}
                    <TextInput
                      label=""
                      required
                      placeholder={clientIdentifierLabel}
                      className="w-full"
                      name="clientIdentifier"
                      error={getError(errors, 'clientIdentifier', errorsMap)}
                      inputRef={register({
                        validate: {
                          required,
                        },
                      })}
                    />
                  </label>
                </div>
              </Feature>

              <div className="section">
                <h3 className="section-title">Personal details</h3>
                <div className="details-row">
                  <TextInput
                    label="First name"
                    placeholder="First name"
                    className="w-full mr-1"
                    name="firstName"
                    error={getError(errors, 'firstName', errorsMap)}
                    inputRef={register({ validate: { required } })}
                    required
                  />
                  <TextInput
                    label="Last name"
                    placeholder="Last name"
                    className="w-full"
                    name="lastName"
                    error={getError(errors, 'lastName', errorsMap)}
                    inputRef={register({ validate: { required } })}
                    required
                  />
                </div>

                <div className="details-row">
                  <div
                    role="radiogroup"
                    aria-labelledby="gender-pick-option"
                    className="form-control"
                    aria-required="true"
                  >
                    <label id="gender-pick-option" className="flex-row-center">
                      Sex assigned at birth
                      <ErrorIcon
                        className={cx(
                          'error-icon-sm-size',
                          !hasError.gender && 'hidden',
                        )}
                      />
                    </label>
                    <div className="flex-row">
                      <LabelPaper
                        className={cx(
                          'mr-1 display-flex flex-1 radio-control',
                          hasError.gender && 'has-error',
                        )}
                      >
                        <PaperContent className="flex-1">
                          <PaperHeader className="text-weight-smd flex-row  space-between">
                            Male
                            <Radio
                              inputRef={register({ required: true })}
                              defaultChecked={fields.gender === 'Male'}
                              name="gender"
                              value={'Male'}
                            />
                          </PaperHeader>
                        </PaperContent>
                      </LabelPaper>
                      <LabelPaper
                        className={cx(
                          'ml-1 display-flex flex-1 flex-between radio-control',
                          hasError.gender && 'has-error',
                        )}
                      >
                        <PaperContent className="flex-1">
                          <PaperHeader className="text-weight-smd flex-row space-between">
                            Female
                            <Radio
                              defaultChecked={fields.gender === 'Female'}
                              inputRef={register({ required: true })}
                              name="gender"
                              value={'Female'}
                            />
                          </PaperHeader>
                        </PaperContent>
                      </LabelPaper>
                    </div>
                    {hasError.gender ? (
                      <div role="alert" className="error-message">
                        Required field
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="section" data-testid="dob">
                <DateInputKnown
                  legend="Date of birth"
                  name="dateOfBirth"
                  initialDate={defaultFormValues.dateOfBirth}
                  inputRef={register('dateOfBirth', {
                    validate: {
                      dateOfBirth: ageAfter16,
                    },
                  })}
                  required
                  onUpdateValue={setValue}
                  error={getError(errors, 'dateOfBirth', errorsMap)}
                />
              </div>

              <div className="section">
                <h3 className="section-title mb-2">Contact details</h3>
                <TextInput
                  label="Email address"
                  placeholder="Email address"
                  className="w-full"
                  name="userName"
                  error={getError(errors, 'userName', errorsMap)}
                  inputRef={register({
                    validate: {
                      email: isValidEmailStrict,
                      filter: (v) =>
                        validateEmail(v) ||
                        'Email address does not match requirement. Please use your company email address',
                    },
                  })}
                  required
                />

                <Feature name="confirmEmailAddress">
                  <div className="details-row">
                    <TextInput
                      label="Confirm email address"
                      placeholder="Confirm email address"
                      className="w-full"
                      name="confirmEmail"
                      required
                      error={getError(errors, 'confirmEmail', errorsMap)}
                      inputRef={register({
                        validate: {
                          confirmEmail: (val: string) => {
                            return val === fields.userName
                          },
                        },
                      })}
                    />
                  </div>
                </Feature>

                <TextInput
                  label="Address"
                  placeholder="First line of address"
                  className="w-full"
                  name="addressLine1"
                  error={getError(errors, 'addressLine1', errorsMap)}
                  inputRef={register({ validate: { required } })}
                  required
                />
                <TextInput
                  label="Second line of address"
                  placeholder="Second line of address (optional)"
                  className="w-full"
                  name="addressLine2"
                  inputRef={register}
                  withoutLabel={true}
                />
                <div className="details-row">
                  <div
                    style={{ display: 'flex' }}
                    className="city-post-line mb-1"
                  >
                    <div style={{ display: 'flex', flex: 1 }}>
                      <TextInput
                        label="Town or city"
                        placeholder="Town or city"
                        className="w-full"
                        name="city"
                        error={getError(errors, 'city', errorsMap)}
                        inputRef={register({ validate: { required } })}
                        required
                        withoutLabel={true}
                      />
                    </div>
                    <div style={{ width: '30%', marginLeft: '20px' }}>
                      <TextInput
                        label={postCodePlaceholders[baseCountryCode]}
                        placeholder={postCodePlaceholders[baseCountryCode]}
                        className="postcode-input"
                        name="postCode"
                        error={getError(errors, 'postCode', errorsMap)}
                        inputRef={register({ validate: { required } })}
                        required
                        withoutLabel={true}
                      />
                    </div>
                  </div>
                  <NativeSelect
                    className="w-full"
                    label="Country"
                    options={countryList}
                    name="countryCode"
                    selectRef={register({ validate: { required } })}
                    placeholder="Country"
                    error={getError(errors, 'countryCode', errorsMap)}
                    withoutLabel={true}
                    aria-label="Country"
                  />
                </div>

                <TextInput
                  label="Phone number (please include the country code and + symbol)"
                  placeholder={phoneInputPlaceholder}
                  className="w-full"
                  name="phoneNumber"
                  required
                  error={getError(errors, 'phoneNumber', errorsMap)}
                  inputRef={register({
                    validate: {
                      required,
                      phoneNumber: isValidPhoneNumberStrict,
                    },
                  })}
                />
              </div>

              {!hideGPAndPharmaFields ? (
                <div className="section">
                  <div className="mb-2">
                    <ServiceDetailOptions serviceType={ServiceValues.GP} />
                  </div>
                  <div>
                    <ServiceDetailOptions
                      serviceType={ServiceValues.PHARMACY}
                    />
                  </div>
                </div>
              ) : null}
              <div className="section new-password-modal-content">
                <h3 className="section-title">Create password</h3>
                <PasswordInput
                  label=""
                  placeholder="Password"
                  inputRef={register({ validate: { required } })}
                  name="password"
                  required
                  onChange={handleOnChangeInput}
                  aria-label="Password must contain at least 10 characters with 1 number 1 capital letter and 1 special character"
                />
                <div className="requirement-handle-value mt-2 mb-2">
                  <div className="requirement-handle-value-title text-sm mb-1 text-color-primary">
                    Passwords must include at least:
                  </div>
                  <ul className="requirement-handle-value-list">
                    <li className="requirement-handle-value-list-item">
                      <div className="requirement-item">
                        <div
                          className={cx(
                            'requirement-icon',
                            requirementPasswordRules.charactersLength &&
                              'checked',
                          )}
                        ></div>
                        <div className="requirement-icon-text">
                          10 characters
                        </div>
                      </div>
                    </li>
                    <li className="requirement-handle-value-list-item">
                      <div className="requirement-item">
                        <div
                          className={cx(
                            'requirement-icon',
                            requirementPasswordRules.lowercaseLetter &&
                              'checked',
                          )}
                        ></div>
                        <div className="requirement-icon-text">
                          1 lowercase letter
                        </div>
                      </div>
                    </li>
                    <li className="requirement-handle-value-list-item">
                      <div className="requirement-item">
                        <div
                          className={cx(
                            'requirement-icon',
                            requirementPasswordRules.capitalLetter && 'checked',
                          )}
                        ></div>
                        <div className="requirement-icon-text">
                          1 capital letter
                        </div>
                      </div>
                    </li>
                    <li className="requirement-handle-value-list-item">
                      <div className="requirement-item">
                        <div
                          className={cx(
                            'requirement-icon',
                            requirementPasswordRules.number && 'checked',
                          )}
                        ></div>
                        <div className="requirement-icon-text">1 number</div>
                      </div>
                    </li>
                    <li className="requirement-handle-value-list-item">
                      <div className="requirement-item">
                        <div
                          className={cx(
                            'requirement-icon',
                            requirementPasswordRules.specialSymbol && 'checked',
                          )}
                        ></div>
                        <div className="requirement-icon-text">
                          1 special symbol
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div data-testid="terms-and-conditions">{termsComponent}</div>

                {!formState.isValid && formState.isSubmitted && (
                  <div className="section">
                    <div className="validation-errors flex-row-center">
                      <ErrorIcon />
                      <div role="alert" className="tooltip warning ml-1">
                        Some required fields are missing. Please complete these
                        before proceeding.
                      </div>
                    </div>
                  </div>
                )}

                <GpServiceError />
                <PharmacyServiceError />

                {submitErrors.length !== 0 ? (
                  <div className="section">
                    <div className="validation-errors flex-row-center">
                      <ErrorIcon />
                      <div role="alert" className="tooltip warning ml-1">
                        <ul>
                          {submitErrors.map((err, idx) => (
                            <li key={`err-${idx}`} className="err-list-item">
                              {err.message}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="btn-container">
                  <button
                    disabled={!isValidPassword || formState.isSubmitting}
                    className="btn btn-primary w-full mb-2 mt-2"
                    type="submit"
                  >
                    {formState.isSubmitting ? 'Please wait...' : 'Next'}
                  </button>
                </div>
                <p
                  data-testid="recaptcha-policy"
                  className="recaptcha-policy content paragraph text-smd text-color-primary text-weight-md text-center w-full mb-2"
                >
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="create-account-external-link"
                    href="https://policies.google.com/privacy"
                    aria-label="Google Privacy Policy"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    className="create-account-external-link"
                    href="https://policies.google.com/terms"
                    aria-label="Google Terms of Service"
                  >
                    Terms of Service
                  </a>{' '}
                  apply.
                </p>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    )
  },
)
