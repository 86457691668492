import { memo } from 'react'
import dayjs from 'dayjs'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectLoadedArticle } from '@core/app/articles/selectors'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { ArticleItem } from '@core/pages/Articles/components/ArticleItem'
import NHSAttribution from '@core/styles/images/nhs_attribution.png'

import { ArticleSubResourcesList } from './ArticleSubResourceList'
import { useMutationObserver } from '@core/shared/useMutationObserver'

export const ArticlePage = memo(function Article() {
  const history = useHistory()
  const loadedArticle = useSelector(selectLoadedArticle)
  const hasSubResources = loadedArticle?.subResources?.length

  if (loadedArticle === null) {
    history.goBack()
  }

  function getReviewDate(option: string) {
    const reviewOptions: Record<string, number> = {
      lastReviewed: 0,
      nextReviewed: 1,
    }
    const index = reviewOptions[option]
    let date
    if (!hasSubResources) {
      date = loadedArticle?.lastReviewed?.[index]
    } else if (hasSubResources) {
      date = loadedArticle.subResources[0].content.lastReviewed?.[index]
    }
    return dayjs(date).format('DD MMMM YYYY')
  }

  const onTitleElMutation: MutationCallback = (mutationList, observer) => {
    const prevTitleVal = mutationList[0].removedNodes[0].nodeValue
    if (prevTitleVal !== document.title) {
      document.title = prevTitleVal ? prevTitleVal : '' // Improvement: set a default string > empty string
      // Stop observing as element changed, otherwise = a continous loop
      if (observer) observer.disconnect()
    }
  }
  // Overrides unwanted behaviour (changing document's title) by a script loaded by a H&W article
  const titleEl = document.querySelector('title')
  useMutationObserver(titleEl, { childList: true }, onTitleElMutation)

  return (
    <div className="page article-page">
      <PageHeader showBg backBtn title={loadedArticle?.about?.name} />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="content">
            {hasSubResources
              ? loadedArticle.subResources.map((item: any, index: number) => (
                  <ArticleItem item={item.content} key={index} />
                ))
              : loadedArticle?.mainEntityOfPage?.map(
                  (item: any, index: number) => (
                    <ArticleItem item={item} key={index} />
                  ),
                )}
            {hasSubResources ? (
              <ArticleSubResourcesList item={loadedArticle} />
            ) : null}
            <div>
              <p className="text-weight-md">
                Last reviewed:
                {getReviewDate('lastReviewed')}
              </p>
            </div>
            <div>
              <p className="text-weight-md">
                Next review due:
                {getReviewDate('nextReviewed')}
              </p>
            </div>
            <div className="mt-2">
              <a
                href={loadedArticle?.author?.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={NHSAttribution}
                  alt="Content supplied by the NHS website logo"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <PageFooter stickyBottom />
    </div>
  )
})
