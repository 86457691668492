import { Action } from '@core/shared/types/Action'
import { AppointmentDate } from '@core/shared/types/appointment/AppointmentDate'
import {
  AppointmentSlot,
  AppointmentBookingSlotStatus,
  AppointmentFilter,
} from '@core/shared/types/appointment'

export enum AppointmentActionTypes {
  SUBMIT_BOOKING_STEP_1 = 'SUBMIT_BOOKING_STEP_1',
  SUBMIT_BOOKING_STEP_2 = 'SUBMIT_BOOKING_STEP_2',

  GET_BOOKING_DATES = 'GET_BOOKING_DATES',
  GET_BOOKING_DATES_SUCCESS = 'GET_BOOKING_DATES_SUCCESS',
  GET_BOOKING_DATES_FAIL = 'GET_BOOKING_DATES_FAIL',

  GET_BOOKING_SLOTS = 'GET_BOOKING_SLOTS',
  GET_BOOKING_SLOTS_SUCCESS = 'GET_BOOKING_SLOTS_SUCCESS',
  GET_BOOKING_SLOTS_FAIL = 'GET_BOOKING_SLOTS_FAIL',

  HOLD_APPOINTMENT = 'HOLD_APPOINTMENT',
  HOLD_APPOINTMENT_SUCCESS = 'HOLD_APPOINTMENT_SUCCESS',
  HOLD_APPOINTMENT_FAIL = 'HOLD_APPOINTMENT_FAIL',

  RELEASE_APPOINTMENT = 'RELEASE_APPOINTMENT',
  RELEASE_APPOINTMENT_SUCCESS = 'RELEASE_APPOINTMENT_SUCCESS',
  RELEASE_APPOINTMENT_FAIL = 'RELEASE_APPOINTMENT_FAIL',

  SELECT_BOOKING_DATE = 'SELECT_BOOKING_DATE',
}

export type SelectBookingDate = Action<
  typeof AppointmentActionTypes.SELECT_BOOKING_DATE,
  {
    date: string
  }
>
export type SubmitAppointmentBookingStep1Action = Action<
  typeof AppointmentActionTypes.SUBMIT_BOOKING_STEP_1,
  Record<string, any>
>

export type SubmitAppointmentBookingStep2Action = Action<
  typeof AppointmentActionTypes.SUBMIT_BOOKING_STEP_2,
  Record<string, any>
>

export type GetAppointmentBookingDatesAction = Action<
  typeof AppointmentActionTypes.GET_BOOKING_DATES,
  {
    filters?: AppointmentFilter
  }
>

export type GetAppointmentBookingDatesSuccessAction = Action<
  typeof AppointmentActionTypes.GET_BOOKING_DATES_SUCCESS,
  AppointmentDate[]
>

export type GetAppointmentBookingDatesFailAction = Action<
  typeof AppointmentActionTypes.GET_BOOKING_DATES_FAIL
>

export type GetAppointmentBookingSlotsAction = Action<
  typeof AppointmentActionTypes.GET_BOOKING_SLOTS,
  {
    id: string
  }
>

export type GetAppointmentBookingSlotsSuccessAction = Action<
  typeof AppointmentActionTypes.GET_BOOKING_SLOTS_SUCCESS,
  AppointmentSlot[]
>

export type HoldAppointmentAction = Action<
  typeof AppointmentActionTypes.HOLD_APPOINTMENT,
  {
    appointmentId: string
  }
>

export type HoldAppointmentSuccessAction = Action<
  typeof AppointmentActionTypes.HOLD_APPOINTMENT_SUCCESS
>

export type HoldAppointmentFailAction = Action<
  typeof AppointmentActionTypes.HOLD_APPOINTMENT_FAIL
>

export type ReleaseAppointmentAction = Action<
  typeof AppointmentActionTypes.RELEASE_APPOINTMENT,
  {
    appointmentId: string
  }
>

export type ReleaseAppointmentSuccessAction = Action<
  typeof AppointmentActionTypes.RELEASE_APPOINTMENT_SUCCESS
>

export type ReleaseAppointmentFailAction = Action<
  typeof AppointmentActionTypes.RELEASE_APPOINTMENT_FAIL
>
