import { memo, useMemo } from 'react'
import { KeyboardTrap } from '../a11y/KeyboardTrap'
import { randomString } from '@core/shared/lang'
import { ErrorIcon } from '../icons/ErrorIcon'

export interface ConfirmModalProps {
  title: string
  modalContent: string
  buttonSubmitText: string
  buttonCanceledText: string
  toggleModal: (e: any) => void
  actionOnConfirm: (data: any) => void
}

export const ConfirmModal = memo<ConfirmModalProps>(function ConfirmModal(
  props,
) {
  const {
    title,
    modalContent,
    buttonSubmitText,
    buttonCanceledText,
    toggleModal,
    actionOnConfirm,
  } = props

  const randomId = useMemo(() => randomString(6), [actionOnConfirm])

  const titleId = `confirm-title-${randomId}`
  const descId = `confirm-description-${randomId}`

  return (
    <div
      role="alertdialog"
      aria-modal="true"
      className="confirm-modal-wrapper"
      aria-labelledby={titleId}
      aria-describedby={descId}
    >
      <div className="confirm-modal">
        <div className="confirm-modal-header modal-title">
          <ErrorIcon />
        </div>
        <KeyboardTrap>
          <div className="confirm-modal-content">
            <div id={titleId} className="modal-title">
              {title}
            </div>
            <div id={descId} className="modal-text">
              {modalContent}
            </div>
          </div>
          <div className="confirm-modal-footer">
            <div className="confirm-modal-btn-container">
              <button
                className="btn btn-primary modal-button cancel-button text-weight-md"
                onClick={toggleModal}
              >
                {buttonCanceledText}
              </button>
              <button
                className="btn btn-primary modal-button submit-button text-weight-md"
                onClick={actionOnConfirm}
              >
                {buttonSubmitText}
              </button>
            </div>
          </div>
        </KeyboardTrap>
      </div>
    </div>
  )
})
