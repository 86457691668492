/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'

interface PaymentEligibilityContextInterface {
  IsPaymentEligibilityEnabled: boolean
  setIsPaymentEligibilityEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const PaymentEligibilityContext =
  React.createContext<PaymentEligibilityContextInterface>({
    IsPaymentEligibilityEnabled: false,
    setIsPaymentEligibilityEnabled: () => {},
  })
PaymentEligibilityContext.displayName = 'PaymentEligibilityContext'

export default PaymentEligibilityContext
