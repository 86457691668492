import { memo, ReactNode } from 'react'
import cx from 'clsx'

export interface PaperHeaderProps {
  className?: string
  children?: ReactNode
}

export const PaperHeader = memo<PaperHeaderProps>(function PaperHeader(props) {
  const { className, children } = props
  return (
    <div data-testid="paper-header" className={cx('paper-header', className)}>
      {children}
    </div>
  )
})
