import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import clsx from 'clsx'

import { LabelPaper } from '../../../form/LabelPaper'
import { PaperContent, PaperHeader } from '../../../paper'
import { Radio } from '../../../form/Radio'
import { TextAreaInput } from '../../../form/TextAreaInput'
import { removeEmptyProperties } from '@core/shared/lang'
import { ErrorIcon } from '../../../icons/ErrorIcon'
import { useAction, useActionAsync } from '@core/shared/useAction'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { useSelector } from 'react-redux'
import { selectAppUser } from '@core/app/user/selectors'
import { usePaymentEligibilityWrapper } from '../../paymentEligibility/usePaymentEligibilityWrapper'
import { useHistory } from 'react-router-dom'
import { cancelBooking as cancelBookingAction } from '@core/app/bookings/actions'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Banner } from '../../Banners/banner/Banner'

interface CancelReasonsType {
  name: string
  value: string
}

export const cancelReasons: CancelReasonsType[] = [
  {
    name: 'No Longer Relevant',
    value: 'NoLongerRelevant',
  },
  {
    name: 'Unable To Attend',
    value: 'UnableToAttend',
  },
  {
    name: 'Booked In Error',
    value: 'BookedInError',
  },
  {
    name: 'Other',
    value: 'Other',
  },
]

interface CancelModalProps {
  title: string
  modalContent: string
  buttonSubmitText: string
  buttonCanceledText: string
  toggleModal: () => void
  bookingId: string
}

export const CancelModal: React.FC<CancelModalProps> = ({
  title,
  modalContent,
  buttonSubmitText,
  buttonCanceledText,
  toggleModal,
  bookingId,
}) => {
  const showNotification = useAction(showNotificationAction)
  const cancelBooking = useActionAsync(cancelBookingAction)
  const history = useHistory()
  const [error, setError] = useState('')
  const user = useSelector(selectAppUser)
  const { IsPaymentEligibilityEnabled } = usePaymentEligibilityWrapper()
  const { register, handleSubmit, watch } = useForm()

  const fields = watch(['cancellationReason'])

  const handleOnSubmit = async (data: any) => {
    setError('')
    const newData = {
      data: {
        type: 'bookingCancellations',
        id: bookingId,
        attributes: {
          cancellationReason: data.cancellationReason,
          cancellationReasonNotes:
            data.cancellationReason === 'Other'
              ? data.cancellationReasonNotes
              : '',
          isCancelledByPatient: true,
        },
      },
    }

    try {
      if (user && bookingId) {
        await cancelBooking({
          userId: user.id,
          bookingId: bookingId,
          params: newData,
          IsPaymentEligibilityEnabled,
        })
        showNotification({
          type: 'success',
          title: 'Consultation cancelled',
        })
        history.push(ScreenRoutes.DASHBOARD)
      }
    } catch (error) {
      setError('Unable to cancel your appointment. Please try again.')
    }
  }

  return (
    <div role="main" className="confirm-modal-wrapper">
      <div className="confirm-modal">
        <div className="confirm-modal-header modal-title">
          <ErrorIcon />
        </div>
        <div className="confirm-modal-content">
          <h2 className="modal-title">{title}</h2>
          <p className="modal-text">{modalContent}</p>
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="cancel-reason-form w-full"
          >
            <h3 id="cancel-consultation-type-title" className="mb-1">
              Please select your reason for cancellation:
            </h3>
            <div
              role="radiogroup"
              aria-labelledby="cancel-consultation-type-title"
              className="confirm-modal-options-container"
            >
              {cancelReasons.map((el, index) => (
                <div className="cancel-option" key={index}>
                  <LabelPaper className={clsx('radio-container flex-1')}>
                    <PaperContent className="flex-1">
                      <PaperHeader className="text-weight-md text-sm flex-row space-between">
                        <label
                          htmlFor={el.value}
                          className="flex-row radio-label"
                        >
                          {el.name}
                        </label>
                        <Radio
                          defaultChecked={false}
                          inputRef={register}
                          name="cancellationReason"
                          value={el.value}
                          id={el.value}
                          aria-describedby="cancel-consultation-type-title"
                        />
                      </PaperHeader>
                    </PaperContent>
                  </LabelPaper>
                </div>
              ))}
              <div className="w-full">
                <TextAreaInput
                  label=""
                  disabled={fields.cancellationReason !== 'Other'}
                  placeholder="If you selected ‘Other’, please specify."
                  className="w-full"
                  withoutLabel
                  name="cancellationReasonNotes"
                  inputRef={register}
                />
              </div>
            </div>
            {error && (
              <Banner
                type="error"
                description={error}
                className="mb-2"
                testid="cancel-modal-error"
              />
            )}
            <div className="confirm-modal-btn-container">
              <button
                className="btn btn-primary modal-button cancel-button text-weight-md"
                onClick={toggleModal}
                type="button"
              >
                {buttonCanceledText}
              </button>
              <button
                className="btn btn-primary modal-button submit-button text-weight-md"
                type="submit"
              >
                {buttonSubmitText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
