import { memo, SelectHTMLAttributes } from 'react'
import cx from 'clsx'
import { ErrorIcon } from '../icons/ErrorIcon'
import { randomString } from '@core/shared/lang'

export interface NativeSelectOptions {
  value: any
  label: string
}

export interface NativeSelectProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: NativeSelectOptions[]
  label: string
  selectRef?: any
  error?: string | null
  withoutErrorText?: boolean
  wrapperClasses?: string
  withoutLabel?: boolean
}

const SelectOptions = memo<{ options: NativeSelectOptions[] }>(
  function SelectOptions(props) {
    const { options } = props
    const nonce = randomString(8)

    return (
      <>
        {options.map((opt) => (
          <option key={`${opt.value}-${nonce}`} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </>
    )
  },
)
export const NativeSelect = memo<NativeSelectProps>(function NativeSelect(
  props,
) {
  const {
    options,
    className,
    label,
    selectRef,
    defaultValue,
    placeholder,
    error,
    withoutErrorText = false,
    wrapperClasses,
    withoutLabel,
    ...selectProps
  } = props
  const classes = className

  return (
    <div className={cx('form-control', withoutLabel && 'no-label')}>
      {withoutLabel ? null : <label>{label}</label>}
      <div
        className={cx(
          'native-select-container',
          Boolean(error) && 'has-error',
          wrapperClasses,
        )}
      >
        <select
          ref={selectRef}
          {...selectProps}
          className={classes}
          defaultValue=""
        >
          {!defaultValue && !!placeholder ? (
            <option value="">{placeholder}</option>
          ) : null}
          <SelectOptions options={options} />
        </select>
        {error ? <ErrorIcon /> : null}
      </div>
      {error && !withoutErrorText ? (
        <div role="alert" className="error-message">
          {error}
        </div>
      ) : null}
    </div>
  )
})

export default NativeSelect
