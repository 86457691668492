import {
  UserActionTypes,
  RegisterAction,
  UpadteProfileAction,
  SaveNewPasswordAction,
  UdatePasscodeAction,
  GetMeAction,
  GetMeSuccessAction,
  GetMeFailAction,
  ResetPasswordStartAction,
  ResetPasswordCompleteAction,
  ResendEmailConfirmationAction,
  ResendEmailConfirmationFailAction,
  ResendEmailConfirmationSuccessAction,
  CurrentUserAfterRegisterSuccessAction,
  SetTemporaryUserRecordRequestAction,
  GetCurrentUserAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const registerAction = createAction<RegisterAction>(
  UserActionTypes.REGISTER,
)

export const updateProfileAction = createAction<UpadteProfileAction>(
  UserActionTypes.UPDATE_PROFILE,
)

export const saveNewPasswordAction = createAction<SaveNewPasswordAction>(
  UserActionTypes.SAVE_NEW_PASSWORD,
)

export const updatePasscodeAction = createAction<UdatePasscodeAction>(
  UserActionTypes.UPDATE_PASSCODE,
)

export const getMeAction = createAction<GetMeAction>(UserActionTypes.GET_ME)

export const getCurrentUserAction = createAction<GetCurrentUserAction>(
  UserActionTypes.GET_CURRENT_USER,
)

export const getMeSuccessAction = createAction<GetMeSuccessAction>(
  UserActionTypes.GET_ME_SUCCCESS,
)

export const getMeFailAction = createAction<GetMeFailAction>(
  UserActionTypes.GET_ME_FAIL,
)

export const resetPasswordStartAction = createAction<ResetPasswordStartAction>(
  UserActionTypes.RESET_PASSWORD_START,
)

export const resetPasswordCompleteAction =
  createAction<ResetPasswordCompleteAction>(
    UserActionTypes.RESET_PASSWORD_COMPLETE,
  )

export const resendEmailConfirmationAction =
  createAction<ResendEmailConfirmationAction>(
    UserActionTypes.RESEND_EMAIL_CONFIRMATION,
  )

export const resendEmailConfirmationSuccessAction =
  createAction<ResendEmailConfirmationSuccessAction>(
    UserActionTypes.RESEND_EMAIL_CONFIRMATION_SUCCESS,
  )

export const resendEmailConfirmationFailAction =
  createAction<ResendEmailConfirmationFailAction>(
    UserActionTypes.RESEND_EMAIL_CONFIRMATION_FAIL,
  )

export const currentUserAfterRegisterSuccessAction =
  createAction<CurrentUserAfterRegisterSuccessAction>(
    UserActionTypes.CURRENT_USER_AFTER_REGISTER_SUCCESS,
  )

export const setTemporaryUserRecordRequestAction =
  createAction<SetTemporaryUserRecordRequestAction>(
    UserActionTypes.SET_TEMPORARY_USER_RECORD,
  )
