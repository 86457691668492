import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import reducer from './reducer'
import { createEpicMiddleware } from 'redux-observable'
import rootEpic from './epic'
import storeAuthEnhancer from '../app/auth/storeAuthEnhancer'

export function configureStore(initialState = {}) {
  const composeEnhancers =
    // eslint-disable-next-line
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  const epicMiddleware = createEpicMiddleware()

  const enhancer =
    process.env.REACT_APP_APP_ENV !== 'production' //is dev
      ? composeEnhancers(
          storeAuthEnhancer,
          applyMiddleware(epicMiddleware),
          applyMiddleware(logger),
        )
      : composeEnhancers(storeAuthEnhancer, applyMiddleware(epicMiddleware))

  const store = createStore(reducer, initialState, enhancer)

  return {
    ...store,
    // Don't start any saga inside configureStore!!!
    // sagas should be started when we need them so
    // we will not spawn sagas when we're in unit-tests
    runSideEffects: () => epicMiddleware.run(rootEpic),
  }
}
