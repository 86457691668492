import React from 'react'
import cx from 'clsx'

interface NotificationIconsProps {
  className?: string
  type: string
}

export const NotificationIcons = (props: NotificationIconsProps) => {
  const { className, type } = props

  return (
    <svg
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('notification-icon', className)}
      aria-hidden="true"
    >
      {type === 'success' && (
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g>
            <path
              d="M7,14 C10.866,14 14,10.866 14,7 C14,3.134 10.866,0 7,0 C3.134,0 0,3.134 0,7 C0,10.866 3.134,14 7,14 Z"
              fill="#F0F2F7"
              fillRule="nonzero"
            ></path>
            <path
              d="M6.063,9.884 C5.987,9.958 5.883,10 5.775,10 C5.666,10 5.562,9.958 5.486,9.884 L3.18,7.657 C2.94,7.425 2.94,7.05 3.18,6.819 L3.468,6.54 C3.708,6.309 4.095,6.309 4.335,6.54 L5.775,7.931 L9.665,4.174 C9.905,3.942 10.293,3.942 10.532,4.174 L10.82,4.452 C11.06,4.684 11.06,5.059 10.82,5.289 L6.063,9.884 Z"
              fill="#2EBB77"
              fillRule="nonzero"
            ></path>
            <path
              d="M7,14 C10.866,14 14,10.866 14,7 C14,3.134 10.866,0 7,0 C3.134,0 0,3.134 0,7 C0,10.866 3.134,14 7,14 Z"
              fill="#FFFFFF"
            ></path>
            <path
              d="M6.063,9.884 C5.987,9.958 5.883,10 5.775,10 C5.666,10 5.562,9.958 5.486,9.884 L3.18,7.657 C2.94,7.425 2.94,7.05 3.18,6.819 L3.468,6.54 C3.708,6.309 4.095,6.309 4.335,6.54 L5.775,7.931 L9.665,4.174 C9.905,3.942 10.293,3.942 10.532,4.174 L10.82,4.452 C11.06,4.684 11.06,5.059 10.82,5.289 L6.063,9.884 Z"
              fill="#2EBB77"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      )}
      {type === 'info' && (
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g>
            <path
              d="M7,14 C10.866,14 14,10.866 14,7 C14,3.134 10.866,0 7,0 C3.134,0 0,3.134 0,7 C0,10.866 3.134,14 7,14 Z"
              fill="#FFFFFF"
            ></path>
            <g transform="translate(6.000000, 2.000000)" fill="#2f96b4">
              <g transform="translate(1.000000, 5.000000) rotate(-180.000000) translate(-1.000000, -5.000000) ">
                <rect x="0" y="0" width="2" height="7.36842105" rx="1"></rect>
                <ellipse cx="1" cy="8.98718837" rx="1" ry="1"></ellipse>
              </g>
            </g>
          </g>
        </g>
      )}
      {type === 'error' && (
        <g>
          <g transform="matrix(0.02491204064149881,0,0,0.023927035517996145,-96.22774099985615,92.3530243572558) ">
            <path
              fill="#FAFAFA"
              d="m4143.69965,-3844.09899c-141.16406,0 -256,114.83594 -256,256s114.83594,256 256,256s256,-114.83594 256,-256s-114.83594,-256 -256,-256zm0,0"
            />
            <path
              fill="#f44336"
              d="m4237.97309,-3523.99352c8.33984,8.34375 8.33984,21.82422 0,30.16797c-4.16016,4.16015 -9.6211,6.25 -15.08594,6.25c-5.46094,0 -10.92187,-2.08985 -15.08203,-6.25l-64.10547,-64.10938l-64.10547,64.10938c-4.16016,4.16015 -9.62109,6.25 -15.08203,6.25c-5.46484,0 -10.92578,-2.08985 -15.08594,-6.25c-8.33984,-8.34375 -8.33984,-21.82422 0,-30.16797l64.10938,-64.10547l-64.10938,-64.10547c-8.33984,-8.34375 -8.33984,-21.82422 0,-30.16797c8.34375,-8.33984 21.82422,-8.33984 30.16797,0l64.10547,64.10938l64.10547,-64.10938c8.34375,-8.33984 21.82422,-8.33984 30.16797,0c8.33984,8.34375 8.33984,21.82422 0,30.16797l-64.10938,64.10547l64.10938,64.10547zm0,0"
            />
          </g>
        </g>
      )}
      {type === 'warning' && (
        <g>
          <g stroke="null">
            <path
              stroke="null"
              fill="#FFFFFF"
              d="m14,6.49999c0,1.72934 -0.72301,3.35692 -2.03572,4.58301c-1.31197,1.2251 -3.05844,1.90578 -4.91835,1.91669c-0.00459,0 -0.00961,0.0003 -0.01421,0c-0.01036,0.0003 -0.02104,0.0003 -0.03172,0.0003c-1.86952,0 -3.62743,-0.67593 -4.94954,-1.9038c-1.32233,-1.22758 -2.05046,-2.86002 -2.05046,-4.5962s0.72813,-3.36832 2.05046,-4.5962c1.32211,-1.22768 3.08002,-1.9038 4.94954,-1.9038c0.01068,0 0.02104,0 0.03172,0.0003c0.00491,0 0.00983,0 0.01474,0.0002c1.8597,0.01121 3.60606,0.6917 4.91782,1.91679c1.31271,1.22609 2.03572,2.85357 2.03572,4.58271zm0,0"
            />
            <path
              stroke="null"
              fill="#FFFFFF"
              d="m14,6.49999c0,1.72934 -0.72301,3.35692 -2.03572,4.58301c-1.31197,1.2251 -3.05844,1.90578 -4.91835,1.91669c-0.00459,0 -0.00961,0.0003 -0.01421,0l0,-12.99941c0.00491,0 0.00983,0 0.01474,0.0002c1.8597,0.01121 3.60606,0.6917 4.91782,1.91679c1.31271,1.22609 2.03572,2.85357 2.03572,4.58271zm0,0"
            />
            <path
              stroke="null"
              fill="#FFFFFF"
              d="m7.03461,2.73721c0.01143,-0.0002 -0.00993,0 0,0zm0,0"
            />
            <path
              stroke="null"
              fill="#F89406"
              className="mark"
              d="m8.20099,3.82028l0,2.90564c0,0.59727 -0.52316,1.08317 -1.16628,1.08317l-0.00299,0c-0.30046,-0.00813 -0.61064,-0.1208 -0.82191,-0.31709c-0.22014,-0.20461 -0.34158,-0.47657 -0.34158,-0.76608l0,-2.90564c0,-0.59063 0.52808,-1.06621 1.1635,-1.08317l0.00299,0c0.64311,0 1.16628,0.48599 1.16628,1.08317zm0,0"
            />
            <path
              stroke="null"
              fill="#FFFFFF"
              d="m7.03461,7.80919c-0.00993,0 0.00993,0.0002 0,0zm0,0"
            />
            <path
              stroke="null"
              fill="#F89406"
              className="mark"
              d="m8.13861,9.18227c0,0.56544 -0.49518,1.02525 -1.10411,1.02525l-0.00278,0c-0.60733,-0.00129 -1.10112,-0.4606 -1.10112,-1.02525c0,-0.56445 0.49379,-1.02376 1.10112,-1.02505l0.00278,0c0.60893,0 1.10411,0.45981 1.10411,1.02505zm0,0"
            />
            <g stroke="null" id="svg_8" fill="#ddebf0">
              <path
                stroke="null"
                fill="#F89406"
                className="mark"
                d="m8.13861,9.18227c0,0.56544 -0.49518,1.02525 -1.10411,1.02525l-0.00278,0l0,-2.05029l0.00278,0c0.60893,0 1.10411,0.45981 1.10411,1.02505zm0,0"
              />
              <path
                stroke="null"
                fill="#F89406"
                className="mark"
                d="m8.20099,3.82028l0,2.90564c0,0.59727 -0.52316,1.08317 -1.16628,1.08317l-0.00299,0l0,-5.07198l0.00299,0c0.64311,0 1.16628,0.48599 1.16628,1.08317zm0,0"
              />
            </g>
          </g>
        </g>
      )}
    </svg>
  )
}
