import { env } from '@core/shared/configuration'
import sapiClient from './sapiClient'

const config = env()

export const getMedicalInformation = (payload: { search: string }) => {
  return sapiClient.get(
    `${config.nhs.apiHost}/conditions?synonyms=true&category=${payload.search}`,
    {
      headers: {
        Accept: 'application/json',
        'subscription-key': config.nhs.primaryKey,
      },
    },
  )
}

export const getMoreMedicalInformation = (payload: { url: string }) => {
  return sapiClient.get(payload.url, {
    headers: {
      Accept: 'application/json',
      'subscription-key': config.nhs.primaryKey,
    },
  })
}

export const getMedicalInformationByUrl = (payload: { url: string }) => {
  return sapiClient.get(payload.url, {
    headers: {
      Accept: 'application/json',
      'subscription-key': config.nhs.primaryKey,
    },
  })
}

export const getLiveWell = async (payload: {
  path: string
  subPaths?: string[]
}) => {
  const { path, subPaths = [] } = payload
  const subPathResponse = subPaths.map((subPath) => {
    return sapiClient.get(`${config.nhs.apiHost}/live-well/${subPath}`, {
      headers: {
        Accept: 'application/json',
        'subscription-key': config.nhs.primaryKey,
      },
    })
  })
  const subPathResponses = await Promise.all(subPathResponse)
  const subResources = subPathResponses.flatMap((response) => {
    return subPaths.map((subPath) => {
      return {
        path: subPath,
        content: response.data,
      }
    })
  })
  const response = await sapiClient.get(
    `${config.nhs.apiHost}/live-well/${path}`,
    {
      headers: {
        Accept: 'application/json',
        'subscription-key': config.nhs.primaryKey,
      },
    },
  )
  response.data['subResources'] = subResources
  return response
}
