import qs from 'query-string'
import client from './client'
import {
  GetUpcomingBookings,
  GetUpcomingBookingById,
  GetBookingAttachments,
  CancelBooking,
  UpdateBooking,
  CreateBookingAttachment,
  UploadFile,
  DeleteBookingAttachment,
} from '@core/shared/types/bookings'
import { JsonApiResponse, JsonApiEntity } from '@core/shared/types/JsonApi'
import paymentEligibilityWrapper from './paymentEligibilityWrapper'

export const getUpcomingBookings = async (payload: GetUpcomingBookings) => {
  const { userId } = payload
  return client.get(
    `/users/${userId}/bookings?${qs.stringify({
      include:
        'appointment.clinicalPractitioner,user,patientRecord,consultation,consultation.outcome,consultation.outputs,consultation.outputs.file',
      sort: '-id',
    })}`,
  )
}

export const getBookingById = ({
  userId,
  bookingId,
}: GetUpcomingBookingById) => {
  return client.get(
    `/users/${userId}/bookings/${bookingId}?${qs.stringify({
      include:
        'appointment.clinicalPractitioner,user,patientRecord,consultation,consultation.outcome,consultation.outputs,consultation.outputs.file',
    })}`,
  )
}

export const getBookingAttachments = (payload: GetBookingAttachments) => {
  const { userId, bookingId } = payload

  return client.get(
    `/users/${userId}/bookings/${bookingId}/attachments?include=file`,
  )
}

export const cancelBooking = async (payload: CancelBooking) => {
  const { userId, bookingId, params, IsPaymentEligibilityEnabled } = payload

  if (IsPaymentEligibilityEnabled) {
    // Call PEW v1 endpoint
    try {
      return paymentEligibilityWrapper.delete(
        `/users/${userId}/bookings/${bookingId}`,
        { params },
      )
    } catch (error) {
      console.error(error)
    }
  } else {
    // Call SAPI v2 endpoint
    try {
      return client.delete(`/users/${userId}/bookings/${bookingId}`, {
        baseURL: client.defaults.baseURL?.replace('/v1/', '/v2/'),
        data: params,
      })
    } catch (error) {
      console.error(error)
    }
  }
}

export const updateBooking = (payload: UpdateBooking) => {
  const { updatedBooking, userId } = payload

  return client.patch(`users/${userId}/bookings/${updatedBooking.id}`, {
    data: updatedBooking,
  })
}

export const uploadFile = (payload: UploadFile) => {
  const { userId, data } = payload

  return client.post(`users/${userId}/files`, {
    data,
  })
}

export const createBookingAttachment = (payload: CreateBookingAttachment) => {
  const { userId, data, bookingId } = payload

  return client.post(`users/${userId}/bookings/${bookingId}/attachments`, {
    data,
  })
}

export const deleteBookingAttachment = (payload: DeleteBookingAttachment) => {
  const { userId, bookingId, attachmentId } = payload

  return client.delete(
    `users/${userId}/bookings/${bookingId}/attachments/${attachmentId}`,
  )
}

export const submitBooking = async (
  userId: string,
  data: any,
  IsPaymentEligibilityEnabled: boolean,
) => {
  const endpoint = IsPaymentEligibilityEnabled
    ? paymentEligibilityWrapper
    : client

  const config = IsPaymentEligibilityEnabled
    ? {}
    : {
        baseURL: client.defaults.baseURL?.replace('/v1/', '/v2/'),
      }

  try {
    return endpoint.post<JsonApiResponse<JsonApiEntity<Record<string, any>>>>(
      `/users/${userId}/bookings`,
      data,
      config,
    )
  } catch (error) {
    console.error(error)
  }
}

export const createJoiningToken = (payload: {
  consultationId: string
  userId: string
}) => {
  const { consultationId, userId } = payload
  return client.post<
    JsonApiResponse<
      JsonApiEntity<{
        oneTimeToken: string
        accessToken: string
        patientJoiningLink: string
      }>
    >
  >(`/users/${userId}/consultations/${consultationId}/createJoiningToken`)
}

export const downloadFile = (payload: { userId: string; fileId: string }) => {
  return client.get(`/users/${payload.userId}/download/${payload.fileId}`, {
    responseType: 'blob',
  })
}

interface Order {
  serviceOrderId: string
  appointmentId: string
  isPaymentRequired: boolean
  providers: {
    voucher: {
      enabled: boolean
    }
    psp: {
      clientSecret: string
      publishableKey: string
      type: 'stripe'
    }
  }
}

export const createAppointmentOrder = (
  userId: string,
  appointmentId: string,
) => {
  const data = {
    type: 'ClinicianAppointment',
    orderTypeId: appointmentId,
  }
  return paymentEligibilityWrapper.post<Order>(`/users/${userId}/orders`, data)
}
