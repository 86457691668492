export const acceptedFileFormats = [
  'doc',
  'docx',
  'xls',
  'xlsx',
  'jpeg',
  'jpg',
  'png',
  'webp',
  'pdf',
  'svg',
  'txt',
  'jfif',
]
