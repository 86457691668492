import client from './client'
import { JsonApiResponse, JsonApiEntity } from '@core/shared/types/JsonApi'
import qs from 'query-string'

import {
  AppointmentBookingSlotStatus,
  AppointmentSlot,
  AppointmentFilter,
  GpGender,
} from '@core/shared/types/appointment'
import paymentEligibilityWrapper from './paymentEligibilityWrapper'

export const getAppointmentSummary = (filters?: AppointmentFilter) => {
  const params: Record<string, any> = {
    sort: 'start',
  }

  if (filters?.cpSpecialties) {
    params['filter[clinicalPractitioner.specialties]'] = filters.cpSpecialties
  }

  if (filters?.consultationType) {
    params['consultationType'] = filters.consultationType
  }

  if (filters?.gpGender && filters.gpGender !== GpGender.Either) {
    params['filter[clinicalPractitioner.gender]'] = filters.gpGender
  }

  return client.get<
    JsonApiResponse<
      JsonApiEntity<{
        start: string
        end: string
        total: number
        appointmentIds: number[]
      }>[]
    >
  >(
    `/appointmentsSummary?${qs.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
      encode: false,
    })}`,
  )
}

export const getAppointmentSlots = (
  date?: string,
  status?: AppointmentBookingSlotStatus,
  filters?: AppointmentFilter,
) => {
  const params: Record<string, any> = {
    'filter[status]': status,
    'filter[start]': date,
    include: 'clinicalPractitioner',
    sort: 'start',
  }

  if (filters?.cpSpecialties) {
    params['filter[clinicalPractitioner.specialties]'] = filters.cpSpecialties
  }

  if (filters?.consultationType) {
    params['filter[consultationType]'] = filters.consultationType
  }

  if (filters?.gpGender && filters.gpGender !== GpGender.Either) {
    params['filter[clinicalPractitioner.gender]'] = filters.gpGender
  }

  return client.get<JsonApiResponse<AppointmentSlot[]>>(
    `/appointments?${qs.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
      encode: false,
    })}`,
  )
}

export const getAppointmentSlot = (id: string) => {
  return client.get<JsonApiResponse<AppointmentSlot>>(
    `/appointments/${id}?include=clinicalPractitioner`,
  )
}

export const holdAppointment = async (appointmentId: string) => {
  return client.post<void>(`/appointments/${appointmentId}/hold`)
}

export const releaseAppointment = (appointmentId: string) =>
  client.post<void>(`/appointments/${appointmentId}/release`)
