import { Elements } from '@stripe/react-stripe-js'
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from '@stripe/stripe-js'
import { CheckoutForm } from './CheckoutForm'

type Props = {
  clientSecret: string
  publishableKey: string
}
export const StripeForm = (props: Props) => {
  const { clientSecret, publishableKey } = props
  const stripePromise = loadStripe(publishableKey)

  const appearance: Appearance = {
    theme: 'stripe',
  }

  const options: StripeElementsOptions = {
    clientSecret,
    appearance,
  }
  return (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}
