import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { createJoiningToken } from '@core/api/bookings'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { selectAppUser } from '@core/app/user/selectors'
import { PageHeader } from '@core/components/app/PageHeader'
import makeJoinLink from '@core/shared/makeJoinLink'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const JoinConsultationPage = () => {
  const params = useParams<{ consultationId: string }>()

  const history = useHistory()
  const { consultationId } = params
  const isLoggedIn = useSelector(isLoggedInSelector)
  const user = useSelector(selectAppUser)
  const loginUrl = `${
    ScreenRoutes.LOGIN
  }?ret=${ScreenRoutes.JOIN_CONSULTATION.replace(
    ':consultationId',
    consultationId,
  )}`

  const [joinLink, setJoinLink] = useState('')

  useEffect(() => {
    const getJoinLink = async () => {
      if (!user) {
        return
      }
      try {
        const resp = await createJoiningToken({
          userId: user.id,
          consultationId,
        })
        setJoinLink(
          makeJoinLink(
            user.id,
            resp.data.data.attributes.oneTimeToken,
            consultationId,
          ),
        )
      } catch (e) {
        console.error(e)
      }
    }

    getJoinLink()
  }, [isLoggedIn, consultationId, user])

  return (
    <div className="page">
      <PageHeader showBg title="Join your consultation" />
      <div className="page-wrapper no-sidebar">
        <div className="page-content join-consultation-page">
          {isLoggedIn ? (
            <div className="section">
              {joinLink.length === 0 ? (
                <h2 className="section-title text-center content-padding">
                  Preparing your session&hellip;
                </h2>
              ) : (
                <>
                  <h2 className="section-title text-center content-padding">
                    Join link for your consultation ready.
                  </h2>
                  <div className="btn-container content-padding">
                    <a
                      className="btn btn-primary active-button"
                      href={joinLink}
                      rel="noopener noreferrer"
                    >
                      Join your consultation
                    </a>
                  </div>
                  <div className="btn-container content-padding">
                    <button
                      onClick={() => history.push(ScreenRoutes.DASHBOARD)}
                      className="btn btn-secondary active-button"
                    >
                      Go to dashboard
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="section content-padding">
              <h2 className="section-title text-center content-padding">
                Please log in to proceed
              </h2>
              <div className="btn-container">
                <button
                  onClick={() => history.push(loginUrl)}
                  className="btn btn-primary active-button"
                >
                  Log in
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
