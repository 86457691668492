import { combineReducers } from 'redux'
import auth, { RAuth } from '../app/auth/reducer'
import appointment, { RAppointment } from '../app/appointment/reducer'
import user, { RUser } from '../app/user/reducer'
import patientRecord, { RPatientRecord } from '../app/patientRecord/reducer'
import bookings, { DefaultBooking } from '../app/bookings/reducer'
import commonReducer, { CommonState } from '../app/common/reducer'
import app, { RApp } from '../app/app/reducer'
import articles, { RArticles } from '../app/articles/reducer'
import symptomsAssessment, { RAssessment } from './symptomsAssessment/reducer'

export interface AppState {
  online: boolean
  auth: RAuth
  appointment: RAppointment
  user: RUser
  patientRecord: RPatientRecord
  bookings: DefaultBooking
  common: CommonState
  app: RApp
  articles: RArticles
  symptomsAssessment: RAssessment
}

const onlineReducer = (online = true) => online

export default combineReducers<AppState>({
  online: onlineReducer,
  auth,
  appointment,
  user,
  patientRecord,
  bookings,
  app,
  common: commonReducer,
  articles,
  symptomsAssessment,
})
