import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const PhoneCallIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('phone-call-icon', className)}
      width="24"
      height="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g
        clipPath="url(#a)"
        stroke="#8A93A1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M15 2a9.915 9.915 0 0 1 7 7M14 6a5.666 5.666 0 0 1 4 4M7.285 12.078a10.103 10.103 0 0 0 4.678 4.657.949.949 0 0 0 .938-.071l2.998-2a.958.958 0 0 1 .91-.083l5.61 2.404a.955.955 0 0 1 .574.994A5.75 5.75 0 0 1 17.29 23 16.29 16.29 0 0 1 1 6.71a5.75 5.75 0 0 1 5.021-5.703.955.955 0 0 1 .994.575l2.406 5.614a.958.958 0 0 1-.079.903L7.35 11.143a.95.95 0 0 0-.065.935v0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#8A93A1" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
