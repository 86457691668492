import React, { memo } from 'react'
import cx from 'clsx'
import { ErrorIcon } from '../icons/ErrorIcon'

export interface TextAreaInputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  error?: string | null | boolean
  label: string
  inputRef?: any
  inputClassName?: string
  withoutLabel?: boolean
}

export const TextAreaInput = memo<TextAreaInputProps>(function TextAreaInput(
  props,
) {
  const {
    label,
    error,
    inputRef,
    className,
    inputClassName,
    required,
    withoutLabel = false,
    ...inputProps
  } = props

  const hasErrorText = typeof error === 'string' && error.length !== 0
  const hasError = error === true || hasErrorText

  return (
    <div className="form-control">
      {withoutLabel ? null : (
        <label>
          {label}
          {required && Boolean(label) ? <sup>*</sup> : null}
          {<ErrorIcon className={cx(!hasError && 'hidden')} />}
        </label>
      )}
      <div className={cx('text-input-wrapper', className)}>
        <textarea
          ref={inputRef}
          aria-label={label}
          aria-required={required ? 'true' : 'false'}
          aria-invalid={hasError ? 'true' : 'false'}
          {...inputProps}
          className={cx(
            'text-area-input',
            inputClassName,
            hasError ? 'has-error' : null,
          )}
        />
      </div>

      {hasErrorText ? (
        <div role="alert" className="error-message">
          {error}
        </div>
      ) : null}
    </div>
  )
})
