import {
  AssessmentActionTypes,
  GetAssessmentAction,
  DeleteAssessmentAction,
  CreateAssessmentAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const createAssessmentAction = createAction<CreateAssessmentAction>(
  AssessmentActionTypes.CREATE_ASSESSMENT_RESULT,
)

export const getAssessmentAction = createAction<GetAssessmentAction>(
  AssessmentActionTypes.GET_ASSESSMENT_RESULT,
)

export const deleteAssessmentAction = createAction<DeleteAssessmentAction>(
  AssessmentActionTypes.DELETE_ASSESSMENT_RESULT,
)

export const setPopulateReasonForBookingAction = createAction(
  AssessmentActionTypes.SET_POPULATE_REASONFORBOOKING,
)
