import { Action } from './types/Action'

export type ActionCreator<
  A extends Action<T, P>,
  T = A['type'],
  P = A['payload'],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = (payload: P, meta?: any) => Action<T, P>

// export function createAction<
//   A extends Action<T, P>,
//   T = A['type'],
//   P = A['payload']
// >(type: T): ActionCreator<A> {
//   return (payload, meta = {}) => ({
//     type,
//     payload,
//     meta,
//   })
// }
export function createAction<
  A extends Action<T, P>,
  T = A['type'],
  P = A['payload'],
>(type: T) {
  return (payload: P, meta = {}) => ({
    type,
    payload,
    meta: meta,
  })
}
