import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { useActionAsync } from '@core/shared/useAction'
import { resetPasswordCompleteAction } from '@core/app/user/actions'
import { NewPasswordComponent } from '@core/components/app/NewPasswordComponent'
import { PageHeader } from '@core/components/app/PageHeader'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const ResetPasswordPage = () => {
  const [error, setError] = useState<string>('')
  const token = new URLSearchParams(window.location.search).get('token') || ''
  const tokenId =
    new URLSearchParams(window.location.search).get('tokenId') || ''
  const resetPasswordComplete = useActionAsync(resetPasswordCompleteAction)
  const history = useHistory()

  const goToLogin = useCallback(
    () => history.push(ScreenRoutes.LOGIN),
    [history],
  )

  const onSubmit = useCallback(
    async (data: { newPassword: string; reEnteredNewPassword: string }) => {
      if (error) {
        setError('')
      }
      try {
        await resetPasswordComplete({
          token,
          tokenId,
          password: data.reEnteredNewPassword,
        })

        goToLogin()
      } catch (e) {
        setError((e as string) || 'Something went wrong')
      }
    },
    [error, goToLogin, resetPasswordComplete, token, tokenId],
  )

  return (
    <div className="page forgot-password-page">
      <PageHeader showBg title="New password" />
      <div className="page-wrapper">
        <div className="page-content" role="main">
          <NewPasswordComponent
            onSubmit={onSubmit}
            onCancel={goToLogin}
            error={error}
            resetPassword
          />
        </div>
      </div>
    </div>
  )
}
