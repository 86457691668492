import React from 'react'
import clsx from 'clsx'
import styles from './Banner.module.scss'
import { ErrorIcon } from '../../../icons'

enum VariantEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

type Props = {
  header?: string
  description?: string
  className?: string
  onClick?: () => void
  // type: defines the purpose of the banner (and its colour)
  type: keyof typeof VariantEnum
  testid?: string
  isDismissable?: boolean
}

const prepareStyles = (type: keyof typeof VariantEnum) => {
  const combinedStyles = []
  type === 'success' && combinedStyles.push(styles.success)
  type === 'info' && combinedStyles.push(styles.info)
  type === 'warning' && combinedStyles.push(styles.warning)
  type === 'error' && combinedStyles.push(styles.error)

  return combinedStyles.join(' ')
}

export const Banner: React.FC<Props> = (props) => {
  const {
    header,
    description,
    className,
    onClick,
    type,
    testid,
    isDismissable = false,
  } = props

  const buttonStyles = prepareStyles(type)

  return (
    <div
      className={clsx(styles.banner, buttonStyles, className)}
      onClick={onClick}
      data-testid={testid}
    >
      {header && (
        <header className={styles.header}>
          <ErrorIcon className="white" />
          <p>
            <strong>{header}</strong>
          </p>
        </header>
      )}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  )
}
