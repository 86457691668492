import {
  ArticlesActionTypes,
  GetMedicalInformationRequestAction,
  GetMedicalInformationSuccessAction,
  GetMedicalInformationFailAction,
  GetLiveWellRequestAction,
  GetLiveWellSuccessAction,
  GetLiveWellFailAction,
  GetMedicalInformationByUrlRequestAction,
  GetMedicalInformationByUrlSuccessAction,
  GetMedicalInformationByUrlFailAction,
  GetMedicalInformationMoreRequestAction,
  GetMedicalInformationMoreSuccessAction,
  GetMedicalInformationMoreFailAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const getMedicalInformationRequestAction =
  createAction<GetMedicalInformationRequestAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION,
  )

export const getMedicalInformationSuccessAction =
  createAction<GetMedicalInformationSuccessAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_SUCCESS,
  )

export const getMedicalInformationFailAction =
  createAction<GetMedicalInformationFailAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_FAIL,
  )

export const getLiveWellRequestAction = createAction<GetLiveWellRequestAction>(
  ArticlesActionTypes.GET_LIVE_WELL,
)

export const getLiveWellSuccessAction = createAction<GetLiveWellSuccessAction>(
  ArticlesActionTypes.GET_LIVE_WELL_SUCCESS,
)

export const getLiveWellFailAction = createAction<GetLiveWellFailAction>(
  ArticlesActionTypes.GET_LIVE_WELL_FAIL,
)

export const getMedicalInformationByUrlRequestAction =
  createAction<GetMedicalInformationByUrlRequestAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL,
  )

export const getMedicalInformationByUrlSuccessAction =
  createAction<GetMedicalInformationByUrlSuccessAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL_SUCCESS,
  )

export const getMedicalInformationByUrlFailAction =
  createAction<GetMedicalInformationByUrlFailAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL_FAIL,
  )

export const getMedicalInformationMoreRequestAction =
  createAction<GetMedicalInformationMoreRequestAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE,
  )

export const getMedicalInformationMoreSuccessAction =
  createAction<GetMedicalInformationMoreSuccessAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE_SUCCESS,
  )

export const getMedicalInformationMoreFailAction =
  createAction<GetMedicalInformationMoreFailAction>(
    ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE_FAIL,
  )
