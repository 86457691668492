import client from './client'
import {
  PatientRecord,
  Patient,
} from '@core/shared/types/patient/PatientRecord'
import { JsonApiResponse } from '@core/shared/types/JsonApi'

export const updateUserPatientRecord = (record: PatientRecord) => {
  return client.patch<JsonApiResponse<PatientRecord>>(
    `/users/${record.id}/patientRecord`,
    { data: { ...record } },
  )
}

export const getUserPatientRecord = (userId: string) => {
  return client.get<JsonApiResponse<PatientRecord>>(
    `/users/${userId}/patientRecord`,
  )
}

export const createDependentPatientRecord = (userId: string, data: Patient) =>
  client.post<JsonApiResponse<PatientRecord>>(`/users/${userId}/dependants`, {
    data: {
      id: userId,
      type: 'patients',
      attributes: data,
    },
  })

export const updateDependentPatientRecord = (
  userId: string,
  record: PatientRecord,
) => {
  const recordId = record.id
  // delete record.id
  return client.patch<JsonApiResponse<PatientRecord>>(
    `/users/${userId}/dependants/${recordId}`,
    { data: record },
  )
}

export const getDependentsPatientRecords = (userId: string) =>
  client.get<JsonApiResponse<PatientRecord[]>>(`/users/${userId}/dependants`)

export const deleteDependent = (userId: string, dependentId: string) => {
  return client.delete(`/users/${userId}/dependants/${dependentId}`)
}
