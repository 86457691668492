export interface NewPasswordTypes {
  test: ((value: string) => boolean) | RegExp
  message: string
  messageType: string
}

export const newPasswordValidators: NewPasswordTypes[] = [
  {
    test: /(?=.*[0-9])[0-9]/,
    message: 'Must have at least 1 digit',
    messageType: 'number',
  },
  {
    test: /(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
    message: 'Must have at least 1 special character',
    messageType: 'specialSymbol',
  },
  {
    test: /(?=.*[A-Z])[A-Z]/,
    message: 'Must have at least 1 uppercase letter',
    messageType: 'capitalLetter',
  },
  {
    test: /(?=.*[a-z])[a-z]/,
    message: 'Must have at least 1 uppercase letter',
    messageType: 'lowercaseLetter',
  },
  {
    test: (value: string) => value.length >= 10,
    message: 'Password can not be < 10 characters',
    messageType: 'charactersLength',
  },
]
