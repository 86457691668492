export const addressTypes = {
  address1: ['route', 'premise'],
  address2: ['sublocality_level_1', 'sublocality'],
  streetNumber: ['street_number'],
  city: ['locality', 'postal_town'],
  postCode: ['postal_code'],
  countryCode: ['country'],
}

export const mapPatientDataWithService = ({
  placeDetail,
  isPharmacy,
}: {
  placeDetail: google.maps.places.PlaceResult
  isPharmacy?: boolean
}) => {
  const mockedPharmacyData = {
    address: {
      addressLine1: '',
      addressLine2: '',
      city: 'N/A',
      countryCode: 'GB',
      postCode: 'N/A',
    },

    email: '',
    name: '',
    phoneNumber: '',
  }

  const mockedGpSurgeryData = {
    gpName: '',
    surgery: {
      address: {
        addressLine1: '',
        addressLine2: '',
        city: 'N/A',
        countryCode: 'GB',
        postCode: 'N/A',
      },
      email: '',
      name: '',
      phoneNumber: '',
    },
  }

  if (isPharmacy) {
    placeDetail?.address_components?.forEach((address: any) => {
      if (
        address.types.some((type: string) =>
          addressTypes.streetNumber.includes(type),
        )
      ) {
        mockedPharmacyData.address.addressLine1 = address.long_name

        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.address1.includes(type),
        )
      ) {
        mockedPharmacyData.address.addressLine1 =
          `${mockedPharmacyData.address.addressLine1} ${address.long_name}`.trim()
        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.address2.includes(type),
        )
      ) {
        mockedPharmacyData.address.addressLine2 = address.long_name

        return
      }
      if (
        address.types.some((type: string) => addressTypes.city.includes(type))
      ) {
        mockedPharmacyData.address.city = address.long_name
        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.postCode.includes(type),
        )
      ) {
        mockedPharmacyData.address.postCode = address.long_name
        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.countryCode.includes(type),
        )
      ) {
        mockedPharmacyData.address.countryCode = address.short_name
        return
      }
    })

    mockedPharmacyData.name = placeDetail?.name || ''

    mockedPharmacyData.phoneNumber =
      placeDetail?.international_phone_number || ''

    if (mockedPharmacyData.address.addressLine1 === '') {
      mockedPharmacyData.address.addressLine1 = 'N/A'
    }
  } else {
    placeDetail?.address_components?.forEach((address: any) => {
      if (
        address.types.some((type: string) =>
          addressTypes.streetNumber.includes(type),
        )
      ) {
        mockedGpSurgeryData.surgery.address.addressLine1 = address.long_name

        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.address1.includes(type),
        )
      ) {
        mockedGpSurgeryData.surgery.address.addressLine1 =
          `${mockedGpSurgeryData.surgery.address.addressLine1} ${address.long_name}`.trim()

        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.address2.includes(type),
        )
      ) {
        mockedGpSurgeryData.surgery.address.addressLine2 = address.long_name

        return
      }
      if (
        address.types.some((type: string) => addressTypes.city.includes(type))
      ) {
        mockedGpSurgeryData.surgery.address.city = address.long_name
        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.postCode.includes(type),
        )
      ) {
        mockedGpSurgeryData.surgery.address.postCode = address.long_name
        return
      }
      if (
        address.types.some((type: string) =>
          addressTypes.countryCode.includes(type),
        )
      ) {
        mockedGpSurgeryData.surgery.address.countryCode = address.short_name
        return
      }
    })

    mockedGpSurgeryData.surgery.name = placeDetail.name
    mockedGpSurgeryData.surgery.phoneNumber =
      placeDetail?.international_phone_number || ''

    if (mockedGpSurgeryData.surgery.address.addressLine1 === '') {
      mockedGpSurgeryData.surgery.address.addressLine1 = 'N/A'
    }
  }

  return isPharmacy ? mockedPharmacyData : mockedGpSurgeryData
}
