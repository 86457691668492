import { useMemo, useState } from 'react'
import addDays from 'date-fns/addDays'
import addMonths from 'date-fns/addMonths'
import addYears from 'date-fns/addYears'

import { isDayDisabled } from './utils'

export interface DatepickerNavigationOptions {
  min?: Date
  max?: Date
}

export type NavigationUnit = 'day' | 'month' | 'year'

export enum NavigationDirection {
  FORWARD = 1,
  BACK = -1,
}

function getDestinationDate(
  currentDate: Date,
  unit: NavigationUnit,
  step: number,
  direction: NavigationDirection,
) {
  const amount = step * direction

  switch (unit) {
    case 'day':
      return addDays(currentDate, amount)
    case 'month':
      return addMonths(currentDate, amount)
    case 'year':
      return addYears(currentDate, amount)
    default:
      throw new Error('Unsupported date interval. day|month|year only')
  }
}

function canNavigateNext(
  currentDate: Date,
  options: DatepickerNavigationOptions,
  unit: NavigationUnit,
  step = 1,
) {
  const dest = getDestinationDate(
    currentDate,
    unit,
    step,
    NavigationDirection.FORWARD,
  )
  if (options.max) {
    return dest.getTime() < options.max.getTime()
  }

  return true
}

function canNavigatePrev(
  currentDate: Date,
  options: DatepickerNavigationOptions,
  unit: NavigationUnit,
  step = 1,
) {
  const dest = getDestinationDate(
    currentDate,
    unit,
    step,
    NavigationDirection.BACK,
  )
  if (options.min) {
    return dest.getTime() > options.min.getTime()
  }

  return true
}

function canNavigateTo(
  destination: Date,
  { min, max }: DatepickerNavigationOptions,
) {
  return isDayDisabled(destination, { min, max })
}

export interface DatepickerNav {
  current: Date
  navigateToPrevMonth: (step?: number) => void
  navigateToNextMonth: (step?: number) => void
  navigateToPrevYear: (step?: number) => void
  navigateToNextYear: (step?: number) => void
  navigateToDate: (date: Date) => void
  canNavigateToNextMonth: (step?: number) => boolean
  canNavigateToPrevMonth: (step?: number) => boolean
  canNavigateToNextYear: (step?: number) => boolean
  canNavigateToPrevYear: (step?: number) => boolean
}
export const useDatepickerNavigation = (
  navigationDate: Date,
  options: DatepickerNavigationOptions = {},
) => {
  const defaultCurrentMax = options.max || new Date()
  const defaultCurrentDate = isDayDisabled(navigationDate, options)
    ? defaultCurrentMax
    : navigationDate

  const [currentDate, setCurrentDate] = useState(defaultCurrentDate)

  const nav = useMemo<DatepickerNav>(() => {
    const navigateTo = (
      step: number,
      unit: NavigationUnit,
      direction: NavigationDirection,
    ) => {
      const dest = getDestinationDate(currentDate, unit, step, direction)

      setCurrentDate(dest)
    }
    return {
      current: currentDate,
      navigateToPrevMonth: (step = 1) => {
        navigateTo(step, 'month', NavigationDirection.BACK)
      },
      navigateToNextMonth: (step = 1) => {
        navigateTo(step, 'month', NavigationDirection.FORWARD)
      },
      navigateToPrevYear: (step = 1) => {
        navigateTo(step, 'year', NavigationDirection.BACK)
      },
      navigateToNextYear: (step = 1) => {
        navigateTo(step, 'year', NavigationDirection.FORWARD)
      },
      canNavigateToNextMonth: (step = 1) =>
        canNavigateNext(currentDate, options, 'month', step),
      canNavigateToPrevMonth: (step = 1) =>
        canNavigatePrev(currentDate, options, 'month', step),
      canNavigateToNextYear: (step = 1) =>
        canNavigateNext(currentDate, options, 'year', step),
      canNavigateToPrevYear: (step = 1) =>
        canNavigatePrev(currentDate, options, 'year', step),
      navigateToDate: (date: Date) => setCurrentDate(date),
    }
  }, [currentDate, options])

  return nav
}
