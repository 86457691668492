import {
  PatientRecordActionTypes,
  UpdateDependentPatientRecordAction,
  UpdateDependentPatientRecordFailAction,
  UpdateDependentPatientRecordSuccessAction,
  UpdateUserPatientRecordAction,
  UpdateUserPatientRecordFailAction,
  UpdateUserPatientRecordSuccessAction,
  CreateDependentPatientRecordAction,
  CreateDependentPatientRecordFailAction,
  CreateDependentPatientRecordSuccessAction,
  GetUserPatientRecordAction,
  GetUserPatientRecordFailAction,
  GetUserPatientRecordSuccessAction,
  GetDependentPatientRecordAction,
  GetDependentPatientRecordFailAction,
  GetDependentPatientRecordSuccessAction,
  DeleteDependentPatientRecordAction,
  DeleteDependentPatientRecordFailAction,
  DeleteDependentPatientRecordSuccessAction,
  CurrentPatientRecordAfterRegisterSuccessAction,
  SetTemporaryPatientRecordRequestAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

// getUserPatientRecord
export const getUserPatientRecordAction =
  createAction<GetUserPatientRecordAction>(
    PatientRecordActionTypes.GET_USER_PATIENT_RECORD,
  )

export const getUserPatientRecordSuccessAction =
  createAction<GetUserPatientRecordSuccessAction>(
    PatientRecordActionTypes.GET_USER_PATIENT_RECORD_SUCCESS,
  )

export const getUserPatientRecordFailAction =
  createAction<GetUserPatientRecordFailAction>(
    PatientRecordActionTypes.GET_USER_PATIENT_RECORD_FAIL,
  )

// createDependentPatientRecord
export const createDependentPatientRecordAction =
  createAction<CreateDependentPatientRecordAction>(
    PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD,
  )

export const createDependentPatientRecordSuccessAction =
  createAction<CreateDependentPatientRecordSuccessAction>(
    PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  )

export const createDependentPatientRecordFailAction =
  createAction<CreateDependentPatientRecordFailAction>(
    PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD_FAIL,
  )

// updateDependentPatientRecord
export const updateDependentPatientRecordAction =
  createAction<UpdateDependentPatientRecordAction>(
    PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD,
  )

export const updateDependentPatientRecordSuccessAction =
  createAction<UpdateDependentPatientRecordSuccessAction>(
    PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  )

export const updateDependentPatientRecordFailAction =
  createAction<UpdateDependentPatientRecordFailAction>(
    PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD_FAIL,
  )

// updateUserPatientRecord
export const updateUserPatientRecordAction =
  createAction<UpdateUserPatientRecordAction>(
    PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD,
  )

export const updateUserPatientRecordSuccessAction =
  createAction<UpdateUserPatientRecordSuccessAction>(
    PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD_SUCCESS,
  )

export const updateUserPatientRecordFailAction =
  createAction<UpdateUserPatientRecordFailAction>(
    PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD_FAIL,
  )

// getDependentPatientRecord
export const getDependentPatientRecordAction =
  createAction<GetDependentPatientRecordAction>(
    PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD,
  )

export const getDependentPatientRecordSuccessAction =
  createAction<GetDependentPatientRecordSuccessAction>(
    PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD_SUCCESS,
  )

export const getDependentPatientRecordFailAction =
  createAction<GetDependentPatientRecordFailAction>(
    PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD_FAIL,
  )

// deleteDependentPatientRecord
export const deleteDependentPatientRecordAction =
  createAction<DeleteDependentPatientRecordAction>(
    PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD,
  )

export const deleteDependentPatientRecordSuccessAction =
  createAction<DeleteDependentPatientRecordSuccessAction>(
    PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  )

export const deleteDependentPatientRecordFailAction =
  createAction<DeleteDependentPatientRecordFailAction>(
    PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD_FAIL,
  )

export const currentPatientRecordAfterRegisterSuccessAction =
  createAction<CurrentPatientRecordAfterRegisterSuccessAction>(
    PatientRecordActionTypes.CURRENT_PATIENT_RECORD_AFTER_REGISTER_SUCCESS,
  )

export const setTemporaryPatientRecordRequestAction =
  createAction<SetTemporaryPatientRecordRequestAction>(
    PatientRecordActionTypes.SET_TEMPORARY_PATIENT_RECORD,
  )
