import { CPSpecialties } from '@core/shared/types/bookings'
import { specialtiesItems } from './specialitiesMap'

export function getPractitionerLabel(practitionerIdentifier: string) {
  // Depending on where it comes from, the practitioner id can be either
  // the enum value (e.g. '1'), or the textual representation (e.g. 'GeneralPractitioner'),
  // so we can account for both types by always mapping to the textual version.
  const specialtyKey =
    practitionerIdentifier in CPSpecialties
      ? CPSpecialties[practitionerIdentifier]
      : practitionerIdentifier

  return specialtiesItems.get(specialtyKey)?.friendlyName ?? 'Doctor'
}
