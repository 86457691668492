import { Action } from '@core/shared/types/Action'

export enum IdlePromptTypes {
  TOGGLE_IDLE_PROMPT = 'TOGGLE_IDLE_PROMPT',
}

export type ToggleIdlePromptAction = Action<
  typeof IdlePromptTypes.TOGGLE_IDLE_PROMPT,
  boolean
>
