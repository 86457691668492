import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const CalendarIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 12 14"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('calendar-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#8A93A1" fillRule="nonzero">
          <path d="M10.6666,1.33 L10,1.33 L10,-1.77635684e-15 L8.6666,-1.77635684e-15 L8.6666,1.33 L3.3333,1.33 L3.3333,-1.77635684e-15 L2,-1.77635684e-15 L2,1.33 L1.3333,1.33 C0.5967,1.33 0.0067,1.93 0.0067,2.67 L0,12 C0,12.74 0.5966,13.33 1.3333,13.33 L10.6666,13.33 C11.4033,13.33 12,12.74 12,12 L12,2.67 C12,1.93 11.4033,1.33 10.6666,1.33 Z M10.6666,12 L1.3333,12 L1.3333,4.67 L10.6666,4.67 L10.6666,12 Z"></path>
          <polygon points="9.0194 6.71 8.3128 6 5.0594 9.25 3.6461 7.84 2.9394 8.55 5.0594 10.67"></polygon>
        </g>
      </g>
    </svg>
  )
}
