import { ofType, Epic } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

import {
  AppointmentActionTypes,
  GetAppointmentBookingDatesAction,
  GetAppointmentBookingSlotsAction,
  HoldAppointmentAction,
  ReleaseAppointmentAction,
} from './actionTypes'
import {
  getBookingDatesSuccessAction,
  getBookingDatesFailAction,
  getBookingSlotsSuccessAction,
  holdAppointmentFailAction,
  holdAppointmentSuccessAction,
  releaseAppointmentFailAction,
  releaseAppointmentSuccessAction,
} from './actions'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import {
  getAppointmentSummary,
  getAppointmentSlots,
  holdAppointment,
  releaseAppointment,
  getAppointmentSlot,
} from '../../api/appointment'
import { AppointmentSlot } from '@core/shared/types/appointment'
import { getMessageByCode, mapResponseErrors } from '../responseMessages'

const mapBookingSlots = (data: any) => {
  const slots = [data.data]

  return slots.map(
    (slot: AppointmentSlot & { relationships: Record<string, any> }) => {
      const relatedPractitioner = slot.relationships
        .clinicalPractitioner as Record<string, any>
      const practitioner = data.included.find(
        (i: any) =>
          i.type === relatedPractitioner.data.type &&
          i.id === relatedPractitioner.data.id,
      )
      slot.relationships.clinicalPractitioner = practitioner
      return slot
    },
  ) as AppointmentSlot[]
}

const removeDuplicateTimeSlots = (myArr: AppointmentSlot[]) =>
  myArr.filter(
    (obj: AppointmentSlot, pos: number, arr: AppointmentSlot[]) =>
      arr
        .map((mapObj: AppointmentSlot) => mapObj.attributes.start)
        .indexOf(obj.attributes.start) === pos,
  )

export const getBookingDatesEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AppointmentActionTypes.GET_BOOKING_DATES),
    mergeMap(async (action: GetAppointmentBookingDatesAction) => {
      try {
        const response = await getAppointmentSummary(action.payload.filters)
        notifySuccess(action)
        return [getBookingDatesSuccessAction(response.data.data)]
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return getBookingDatesFailAction()
      }
    }),
    concatActions(),
  )

export const getBookingSlotsEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AppointmentActionTypes.GET_BOOKING_SLOTS),
    mergeMap(async (action: GetAppointmentBookingSlotsAction) => {
      const { id } = action.payload
      try {
        const response = await getAppointmentSlot(id)
        notifySuccess(action)
        return [
          getBookingSlotsSuccessAction(
            removeDuplicateTimeSlots(mapBookingSlots(response.data)),
          ),
        ]
      } catch (e) {
        console.log(e)
        const errors = mapResponseErrors(e.response?.data)

        const reason =
          errors?.map((el) => el?.message)?.join(' ') || 'Something went wrong'

        notifyFail(action, reason)

        return getBookingDatesFailAction()
      }
    }),
    concatActions(),
  )

export const holdAppointmentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AppointmentActionTypes.HOLD_APPOINTMENT),
    mergeMap(async (action: HoldAppointmentAction) => {
      try {
        await holdAppointment(action.payload.appointmentId)
        notifySuccess(action)
        return holdAppointmentSuccessAction()
      } catch {
        notifyFail(action)
        return holdAppointmentFailAction()
      }
    }),
    concatActions(),
  )

export const releaseAppointmentEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AppointmentActionTypes.RELEASE_APPOINTMENT),
    mergeMap(async (action: ReleaseAppointmentAction) => {
      try {
        await releaseAppointment(action.payload.appointmentId)
        notifySuccess(action)
        return releaseAppointmentSuccessAction()
      } catch {
        notifyFail(action)
        return releaseAppointmentFailAction()
      }
    }),
    concatActions(),
  )

export default [
  getBookingDatesEpic,
  getBookingSlotsEpic,
  holdAppointmentEpic,
  releaseAppointmentEpic,
]
