import { MessageType, useIdleTimer, IIdleTimer } from 'react-idle-timer'
import { logout as logoutAction } from '@core/app/auth/actions'
import { toggleIdlePromptAction } from '@core/app/common/IdlePrompt/actions'
import { useAction } from '@core/shared/useAction'
import { IdleBanner } from './Banners/IdleBanner'
import { BFFConfigType, env } from '@core/shared/configuration'
import { logoutCookies } from '@core/api/user'

export enum idleTimerStates {
  onReset = 'idleTimer:onReset',
}

const config = env()

// Idle timer constants
const timeoutUntilPrompt = 1680000 // 28 mins in ms
const promptTimeout = 120000 // 2 mins in ms
const syncTimersInterval = 200 // syncs timers across tabs

export const SessionTimeout = (props: {
  children: JSX.Element
}): JSX.Element => {
  const logout = useAction(logoutAction)
  const togglePromptVisiblity = useAction(toggleIdlePromptAction)

  const onIdle = (): void => {
    // For persisting autologout banner across tabs
    window.localStorage.setItem('idle', 'idle')
    if (config.auth.bff === BFFConfigType.ImplictIDS4) {
      logoutCookies()
    }
    logout()
    togglePromptVisiblity(false)
  }

  const onPrompt = (): void => {
    togglePromptVisiblity(true)
  }

  // Syncs stay logged in action of the idle banner across tabs
  const onMessage = (message: MessageType): void => {
    if (message === idleTimerStates.onReset) {
      togglePromptVisiblity(false)
    }
  }

  const idleTimer: IIdleTimer = useIdleTimer({
    onIdle,
    onPrompt,
    onMessage,
    timeout: timeoutUntilPrompt,
    promptTimeout,
    syncTimers: syncTimersInterval,
    crossTab: true,
  })

  return (
    <>
      <IdleBanner idleTimer={idleTimer} />
      {props.children}
    </>
  )
}
