import cx from 'clsx'

// Todo: CreateAccountPage can use this rather than repeating code in component
// ^ it also uses a couple of additional classes

interface TermsAndPrivacyProps {
  pageName?: string
  privacyLink?: {
    href?: string
  }
  termsLink?: {
    href?: string
  }
}

export const TermsAndPrivacy = ({
  pageName,
  privacyLink,
  termsLink,
}: TermsAndPrivacyProps) => {
  const extraClasses: Record<string, Array<string> | string> = {
    createAccount: ['text-color-primary', 'text-center'],
  }

  const privacyHref =
    privacyLink?.href || 'https://www.healthhero.com/privacy-policy/'
  const termsHref =
    termsLink?.href || 'https://www.healthhero.com/terms-and-conditions/'

  return (
    <p
      className={cx(
        'text-smd text-weight-md',
        pageName && extraClasses[pageName],
      )}
    >
      By using this service you are agreeing to our{' '}
      <a
        className="text-color-primary"
        href={termsHref}
        rel="noopener noreferrer"
        target="_blank"
      >
        terms and conditions{' '}
      </a>
      and{' '}
      <a
        className="text-color-primary"
        href={privacyHref}
        rel="noopener noreferrer"
        target="_blank"
      >
        privacy policy
      </a>
      .
    </p>
  )
}
