export default {
  encode(str: string) {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          //@ts-ignore
          return String.fromCharCode('0x' + p1)
        },
      ),
    )
  },
  decode(base64Str: string) {
    return decodeURIComponent(
      atob(base64Str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )
  },
}
