import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { useAction } from '@core/shared/useAction'
import { selectCompletedBooking as selectCompletedBookingAction } from '@core/app/bookings/actions'
import { BookingType } from '@core/shared/types/bookings'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import getConsultationTitle from '../../consultationOutcome'
import { GpConsultationIcon } from '../../icons/GpConsultationIcon'
import { RightChevronIcon } from '../../icons/RightChevron'
import { AttachmentIcon } from '../../icons/AttachmentIcon'
import { CalendarIcon } from '../../icons/CalendarIcon'
import { SpecialtiesItemsType } from '../../specialitiesMap'
import styles from './PastConsultations.module.scss'

interface Props {
  pastConsultation: BookingType
  servicesAvailable: Map<string, SpecialtiesItemsType>
}

export const PastConsultationListItem = (props: Props) => {
  const { pastConsultation, servicesAvailable } = props
  const selectCompletedBooking = useAction(selectCompletedBookingAction)

  const consultationSpecialty = servicesAvailable.get(
    pastConsultation?.relationships?.clinicalPractitioner?.specialties[0],
  )

  const consultationType =
    pastConsultation?.attributes?.consultationType === 'Video'
      ? 'video call'
      : 'phone call'
  const selectBooking = useCallback(() => {
    selectCompletedBooking({
      bookingId: pastConsultation.id,
    })
  }, [pastConsultation, selectCompletedBooking])

  return (
    <div className={styles.pastConsultationItem} onClick={selectBooking}>
      <Link
        className={styles.link}
        to={ScreenRoutes.VIEW_LAST_CONSULTATION.replace(
          ':bookingId',
          pastConsultation.id,
        )}
      >
        <div className={styles.icon}>
          <div className="card-header-icon">
            {consultationSpecialty ? (
              <consultationSpecialty.HeaderIcon />
            ) : (
              <GpConsultationIcon />
            )}
          </div>
        </div>
        <div className="mr-1">
          <div className={styles.contentDate}>
            <CalendarIcon />
            <span className="text-small-print text-color-secondary">
              {dayjs(
                pastConsultation?.relationships?.appointment?.attributes?.start,
              ).format('DD.MM.YYYY')}
            </span>
            {pastConsultation?.relationships?.consultation?.outputs?.length ? (
              <AttachmentIcon />
            ) : null}
          </div>
          <h4 className="text-header-s text-color-primary font-bold">
            {consultationSpecialty
              ? consultationSpecialty.headerTitle
              : 'Gp Consultation'}
          </h4>
          <p className="text-body-xs text-color-secondary">
            {getConsultationTitle({
              booking: pastConsultation,
              bookingType: consultationType,
              showDoctorName: true,
            })}
          </p>
        </div>
        <RightChevronIcon className={styles.chevron} />
      </Link>
    </div>
  )
}
