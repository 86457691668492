import { useContext } from 'react'
import PaymentEligibilityContext from './PaymentEligibilityContext'

export const usePaymentEligibilityWrapper = () => {
  const paymentElegibilityWrapper = useContext(PaymentEligibilityContext)
  if (!paymentElegibilityWrapper) {
    throw new Error(
      'paymentElegibilityWrapper must be used inside PaymentEligibilityProvider context',
    )
  }
  return paymentElegibilityWrapper
}
