import { Link } from 'react-router-dom'
import { PageHeader } from '@core/components/app/PageHeader'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const AccountCreatedPage = () => {
  return (
    <div className="page account-created-page">
      <PageHeader backBtn title="Confirm your email" showBg />
      <div className="page-wrapper no-sidebar" role="main">
        <h2>We've sent you an email.</h2>
        <p>Click on the link in the email to confirm your registration.</p>
        <p>
          Didn't get the email? Check your spam folder or resend the email from
          your profile after login.
        </p>
        <div className="btn-container">
          <Link
            to={ScreenRoutes.LOGIN}
            data-testid="login-link"
            className="display-inline-block btn btn-primary no-underline w-full"
          >
            Log in
          </Link>
        </div>
      </div>
    </div>
  )
}
