import { useEffect, useRef, useState } from 'react'
import { AriaCalendarProps, useCalendar, useLocale } from 'react-aria'
import { useCalendarState } from 'react-stately'
import {
  createCalendar,
  today,
  getLocalTimeZone,
  parseDate,
  DateValue,
} from '@internationalized/date'
import { CalendarGrid } from './CalendarGrid'
import { CalendarButton } from './CalendarButton'
import * as styles from './Calendar.module.scss'
import { useSelector } from 'react-redux'
import { selectBookingDatesValues } from '@core/app/appointment/selectors'
import { ChevronLeftIcon, ChevronRightIcon } from '../icons'

export const Calendar = (props: AriaCalendarProps<DateValue>) => {
  const { locale } = useLocale()
  const bookingDates = useSelector(selectBookingDatesValues)
  const [maxDate, setMaxDate] = useState<string>()

  useEffect(() => {
    bookingDates.length && setMaxDate(bookingDates[bookingDates.length - 1].iso)
  }, [bookingDates])

  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
    minValue: today(getLocalTimeZone()),
    maxValue: maxDate !== undefined ? parseDate(maxDate) : undefined,
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useCalendar(props, state)

  return (
    <div {...calendarProps} ref={containerRef} className="calendar">
      <header className={styles.header}>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon />
        </CalendarButton>
        <p>{title}</p>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon />
        </CalendarButton>
      </header>
      <CalendarGrid state={state} />
    </div>
  )
}
