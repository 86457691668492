import { useFormContext } from 'react-hook-form'

import styles from './SelectedServiceCard.module.scss'
import { PhoneCallIcon } from '../../../icons/PhoneCallIcon'
import {
  PatientGpSurgery,
  PatientNominatedPharmacy,
} from '@core/shared/types/patient/PatientRecord'
import { Card } from '../../../card/Card'
import { ServiceValues } from '../ServiceDetailOptions'

type Props = {
  handleClear?: () => void
  onHandleEdit?: () => void
  isEditable?: boolean
  serviceType: ServiceValues
}

type Service = PatientGpSurgery['surgery'] | PatientNominatedPharmacy

export const SelectedServiceCard = (props: Props) => {
  const { handleClear, onHandleEdit, isEditable = true, serviceType } = props
  const { getValues } = useFormContext()

  const services: {
    [key: string]: Service
  } = {
    gp: getValues()?.gp?.surgery,
    nominatedPharmacy: getValues()?.nominatedPharmacy,
  }
  const service: Service = services[serviceType]

  const address = Object.values(service?.address ?? [])
  const name = service?.name
  const phoneNumber = service?.phoneNumber
  return (
    <div
      className={styles.serviceCard}
      data-testid={`selected-${serviceType}-card`}
    >
      <Card>
        <h4
          className="card-header-text text-color-primary"
          data-testid={`${serviceType}-name`}
        >
          {name}
        </h4>
        <address className="card-content">
          {address.map(
            (line: string, index: number) =>
              line && (
                <p
                  key={`address ${index}`}
                  data-testid={`address ${index}`}
                  className="text-color-secondary text-smd text-weight-md"
                >
                  {line}
                </p>
              ),
          )}
        </address>
        <div className="flex-row-center">
          {phoneNumber && (
            <>
              <PhoneCallIcon className="mr-1" />
              <p
                data-testid="phone-number"
                className="text-color-secondary text-smd text-weight-md"
              >
                {phoneNumber}
              </p>
            </>
          )}
          {isEditable && (
            <>
              <button
                type="button"
                data-testid="clear-button"
                className="btn-link action-cursor"
                onClick={handleClear}
              >
                Clear
              </button>
              <button
                type="button"
                data-testid="edit-button"
                className="btn-link action-cursor"
                onClick={onHandleEdit}
              >
                Edit
              </button>
            </>
          )}
        </div>
      </Card>
    </div>
  )
}
