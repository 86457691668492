import {
  AppActionTypes,
  InitAppAction,
  InitAppSuccessAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const initAppAction = createAction<InitAppAction>(
  AppActionTypes.APP_INIT,
)

export const initAppSuccessAction = createAction<InitAppSuccessAction>(
  AppActionTypes.APP_INIT_SUCCESS,
)
