import { useRef } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'

import * as styles from './Calendar.module.scss'

export const CalendarButton = (props: AriaButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { buttonProps } = useButton(props, ref)
  return (
    <button {...buttonProps} ref={ref} className={styles.button}>
      {props.children}
    </button>
  )
}
