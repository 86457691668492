import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { getUpcomingBookings as getUpcomingBookingsAction } from '@core/app/bookings/actions'
import { PageFooter } from '@core/components/app/PageFooter'
import { UpcomingBookings } from '@core/components/app/UpcomingBookings'
import { PastConsultationsList } from '@core/components/app/PastConsultations/PastConsultationsList'
import { selectAppUser } from '@core/app/user/selectors'
import { upcomingBookings } from '@core/app/bookings/selectors'
import { useActionAsync } from '@core/shared/useAction'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { NotificationItem } from '@core/components/app/NotificationItem'
import { SideMenu } from '@core/components/app/SideMenu'
import { PageHeader } from '@core/components/app/PageHeader'
import { acceptInitialUserTerms } from '@core/api/user'
import { ResendEmailConfirmationBanner } from '@core/components/app/Banners/ResendEmailConfirmationBanner'
import { SymptomAssessmentEntry } from './components/SymptomAssessmentEntry'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { SpecialtiesSlider } from '@core/components/app/Services/SpecialtiesSlider'
import { useGenerateClientCopy } from '@core/components/app/Services/components/useGenerateClientCopy'
import HeHeBackground from '../../styles/images/HealthHero-Mobile-Background.jpg'
import styles from './Dashboard.module.scss'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { isEmailVerified } from '@core/app/patientRecord/selectors'
import { Warning } from '@core/components/warning'

interface Props {
  logoSmallUrl: string
  logoLargeUrl: string
  specialtiesImage?: string
  backgroundImage?: string
}

export const Dashboard = (props: Props) => {
  const { logoSmallUrl, logoLargeUrl, backgroundImage, specialtiesImage } =
    props
  const [isLoading, setLoading] = useState(true)
  const user = useSelector(selectAppUser)
  const bookings = useSelector(upcomingBookings)
  const getUpcomingBookings = useActionAsync(getUpcomingBookingsAction)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const isUserEmailVerified = useSelector(isEmailVerified)

  const { hasEligibility } = useFeatures()
  const {
    dashboardVariables: {
      logoSmallWidth,
      logoLargeWidth,
      logoAlt,
      hasBackground,
      backgroundStyles,
      specialtiesLayout,
      specialtiesHeader,
      customSlot1,
      customSlot2,
      customSlot3,
      customSlot4,
    },
  } = useClientVariables()

  const pageBackgroundStyles = {
    ...backgroundStyles,
    backgroundImage: hasBackground
      ? `url(${backgroundImage || HeHeBackground})`
      : 'none',
  }

  const enabledServices = useGenerateClientCopy()

  useEffect(() => {
    const fetchUpcomingBookings = async () => {
      try {
        if (user && isLoggedIn) {
          await getUpcomingBookings({ userId: user?.id })
          setLoading(false)
          await acceptInitialUserTerms(user?.id)
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
    fetchUpcomingBookings()
  }, [getUpcomingBookings, user, isLoggedIn])

  return (
    <div
      className={clsx('page', 'home-page', styles.homepage)}
      style={pageBackgroundStyles}
    >
      <div className={styles.pageHeaderWrapper}>
        <PageHeader
          showBg
          title={
            <img
              width={`${logoLargeWidth}px`}
              alt={logoAlt}
              src={logoLargeUrl}
            />
          }
        ></PageHeader>
      </div>

      <div className={clsx('page-wrapper', styles.pageWrapper)}>
        <div className={styles.pageLogoWrapper}>
          <img
            width={`${logoSmallWidth}px`}
            alt={logoAlt}
            src={logoSmallUrl}
            className={styles.pageLogo}
          />
        </div>
        <SideMenu />
        <main className={clsx('page-content', styles.pageContent)}>
          <NotificationItem />
          <ResendEmailConfirmationBanner />
          {customSlot1}
          <Warning />
          <UpcomingBookings
            bookings={bookings}
            specialtiesItems={enabledServices}
          />
          {customSlot2}
          <Feature name="symptomAssessment">
            <SymptomAssessmentEntry disabled={!isUserEmailVerified} />
          </Feature>
          <Feature name="hasStandardBookingEntry">
            <SpecialtiesSlider
              type={specialtiesLayout}
              headerText={specialtiesHeader}
              image={specialtiesImage}
              hasEligibility={hasEligibility}
              enabledServices={enabledServices}
            />
          </Feature>
          {customSlot3}
          <PastConsultationsList servicesAvailable={enabledServices} />
          {customSlot4}
        </main>
      </div>

      {isLoading && !bookings?.length ? <CircularLoader /> : null}

      <PageFooter stickyBottom />
    </div>
  )
}
