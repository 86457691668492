import React from 'react'
import cx from 'clsx'

interface IconProps {
  isActive?: boolean
  className?: string
}

export const ProfileIcon = (props: IconProps) => {
  const { isActive, className } = props
  return isActive ? (
    <svg
      viewBox="0 0 21 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('profile-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#242D38" fillRule="nonzero">
          <path d="M10.499959,12 C7.191959,12 4.499959,9.31 4.499959,6 C4.499959,2.69 7.191959,0 10.499959,0 C13.807959,0 16.499959,2.69 16.499959,6 C16.499959,9.31 13.807959,12 10.499959,12 Z"></path>
          <path d="M20.249959,24 L0.749959,24 C0.334959,24 -4.1e-05,23.66 -4.1e-05,23.25 L-4.1e-05,18.09 C-4.1e-05,17.31 0.395959,16.58 1.032959,16.19 C6.665959,12.67 14.338959,12.67 19.965959,16.18 C20.603959,16.58 20.999959,17.31 20.999959,18.09 L20.999959,23.25 C20.999959,23.66 20.664959,24 20.249959,24 Z"></path>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 21 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('profile-icon', className)}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-0.000041, 0.000000)"
          fill="#242D38"
          fillRule="nonzero"
        >
          <path d="M10.5,12 C7.192,12 4.5,9.31 4.5,6 C4.5,2.69 7.192,0 10.5,0 C13.808,0 16.5,2.69 16.5,6 C16.5,9.31 13.808,12 10.5,12 Z M10.5,1.5 C8.019,1.5 6,3.52 6,6 C6,8.48 8.019,10.5 10.5,10.5 C12.982,10.5 15,8.48 15,6 C15,3.52 12.981,1.5 10.5,1.5 Z"></path>
          <path d="M20.25,24 L0.75,24 C0.335,24 0,23.66 0,23.25 L0,18.09 C0,17.31 0.396,16.58 1.033,16.19 C6.666,12.67 14.339,12.67 19.966,16.18 C20.604,16.58 21,17.31 21,18.09 L21,23.25 C21,23.66 20.665,24 20.25,24 Z M1.5,22.5 L19.5,22.5 L19.5,18.09 C19.5,17.83 19.374,17.58 19.171,17.46 C14.021,14.24 6.982,14.24 1.828,17.46 C1.626,17.58 1.5,17.83 1.5,18.09 L1.5,22.5 Z"></path>
        </g>
      </g>
    </svg>
  )
}
