type CPSpecialtiesTypes = {
  [key: string]: string
}

export const CPSpecialties: CPSpecialtiesTypes = {
  '1': 'GeneralPractitioner',
  '2': 'Counsellor',
  '3': 'Physiotherapist',
  '4': 'SecondOpinion',
  '5': 'ClinicalPharmacist',
  '6': 'LegalServices',
}

export enum CPSpecialtiesEnum {
  GENERAL_PRACTITIONER = '1',
  COUNSELLOR = '2',
  PHYSIOTHERAPIST = '3',
  SECOND_OPINION = '4',
  CLINICAL_PHARMACIST = '5',
  LEGAL_SUPPORT = '6',
}
