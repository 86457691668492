import { produce } from 'immer'

import {
  NotificationItemTypes,
  ShowNotificationItemAction,
  HideNotificationItemAction,
} from './actionTypes'

export interface DefaultNotificationItem {
  isVisible: boolean
  title: string
  type: 'success' | 'info' | 'error' | 'warning'
  duration?: number | null
}

type NotificationItemActions =
  | ShowNotificationItemAction
  | HideNotificationItemAction

export const initialNotificationItem = (): DefaultNotificationItem => ({
  isVisible: false,
  title: '',
  type: 'success',
  duration: null,
})

const NotificationItemReducer = produce(
  (
    state = initialNotificationItem(),
    action: NotificationItemActions,
  ): DefaultNotificationItem => {
    switch (action.type) {
      case NotificationItemTypes.SHOW_NOTIFICATION:
        return {
          ...action.payload,
          isVisible: true,
        }
      case NotificationItemTypes.HIDE_NOTIFICATION:
        return initialNotificationItem()
      default:
        return state
    }
  },
)

export default NotificationItemReducer
