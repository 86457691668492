import { produce } from 'immer'

import { IdlePromptTypes, ToggleIdlePromptAction } from './actionTypes'

export const initialIdlePrompt = (): boolean => false
const IdlePromptReducer = produce(
  (state = initialIdlePrompt(), action: ToggleIdlePromptAction): boolean => {
    switch (action.type) {
      case IdlePromptTypes.TOGGLE_IDLE_PROMPT:
        return action.payload

      default:
        return state
    }
  },
)

export default IdlePromptReducer
