import React from 'react'
import clsx from 'clsx'
import styles from './Checkbox.module.scss'

type Props = {
  id: string
  label: string
  className?: string
  isChecked?: boolean | unknown
  onCheckHandler?: () => void
  inputRef?: any
  name?: string
}

export const Checkbox: React.FC<Props> = (props) => {
  const { onCheckHandler, isChecked, id, label, className, inputRef, name } =
    props

  return (
    <div className={styles.container}>
      <input
        id={id}
        ref={inputRef}
        type="checkbox"
        checked={!isChecked}
        name={name}
        onChange={onCheckHandler}
        className={clsx(styles.checkbox, className)}
      />
      <label htmlFor={id} className="flex-row text-color-primary">
        {label}
      </label>
    </div>
  )
}
