import { useState, useCallback } from 'react'
import { FieldError, useForm, DeepMap } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { PageHeader } from '@core/components/app/PageHeader'
import { TextInput } from '@core/components/form/TextInput'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useActionAsync } from '@core/shared/useAction'
import { resetPasswordStartAction } from '@core/app/user/actions'
import { isValidEmailStrict } from '@core/components/validation/email'

const errorsMap: Record<string, string> = {
  required: 'Required field',
  email: 'Enter valid email address ',
}

function getError<T>(errors: DeepMap<T, FieldError>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const ForgotPasswordPage = () => {
  const { register, handleSubmit, errors, formState } = useForm<{
    userName: string
  }>()
  const [error, setError] = useState('')
  const [isForgotPasswordRequestSuccess, setForgotPasswordRequestSuccess] =
    useState<boolean>(false)
  const resetPasswordStart = useActionAsync(resetPasswordStartAction)

  const onSubmit = useCallback(
    async (data: { userName: string }) => {
      if (error) {
        setError('')
      }
      try {
        await resetPasswordStart(data)

        setForgotPasswordRequestSuccess(true)
      } catch (e) {
        setForgotPasswordRequestSuccess(true)
      }
    },
    [error, resetPasswordStart],
  )

  return (
    <div className="page forgot-password-page">
      <PageHeader showBg backBtn title="Forgot Password" />
      <div className="page-wrapper no-sidebar">
        <div className="page-content" role="main">
          {isForgotPasswordRequestSuccess ? (
            <div className="text-center">
              <h2
                data-testid="reset-password-title"
                className="text-heading-m mb-5"
              >
                We've sent you an email
              </h2>
              <p
                data-testid="reset-password-subtitle"
                className="text-body-m mb-4"
              >
                Click on the link in the email to create a new password.
              </p>
              <div data-testid="reset-password-email-reminder">
                <p className="text-body-m">Didn't get the email? </p>
                <p className="text-body-m mb-12">
                  Check your spam folder or try again.
                </p>
              </div>
              <Link
                to={ScreenRoutes.LOGIN}
                data-testid="reset-password-login-link"
                className="display-inline-block btn btn-primary w-full text-center no-underline mb-2 mt-2"
              >
                Log in
              </Link>
            </div>
          ) : (
            <section className="text-center">
              <div>
                <h2
                  data-testid="reset-password-title"
                  className="text-heading-m mb-8"
                >
                  No problem
                </h2>
                <p
                  data-testid="reset-password-subtitle"
                  className="text-body-m mb-8"
                >
                  Enter the email address associated with your account.
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                {Boolean(error) && (
                  <div className="error-text text-center mb-1 mt-1">
                    {error}
                  </div>
                )}
                <div className="mb-12">
                  <TextInput
                    label=""
                    placeholder="Enter email address"
                    className="w-full"
                    name="userName"
                    error={getError(errors, 'userName')}
                    inputRef={register({
                      validate: {
                        email: isValidEmailStrict,
                      },
                    })}
                  />
                </div>
                <button
                  disabled={formState.isSubmitting}
                  className="btn btn-primary w-full"
                  type="submit"
                >
                  Send email
                </button>
              </form>
              <p
                data-testid="reset-password-note"
                className="text-body-s text-center mt-1"
              >
                We will email you a password reset link.
              </p>
            </section>
          )}
        </div>
      </div>
    </div>
  )
}
