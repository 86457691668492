import { Patient } from '@core/shared/types/patient/PatientRecord'
import {
  objectIsNotEmpty,
  addressIsNotEmpty,
} from '@core/shared/utils/formUtils'
import { ServiceValues } from './app/HealthServiceDetails/ServiceDetailOptions'

export const formatGpData = (data: Patient['gp']) => {
  return {
    gpName: 'Not known',
    surgery: {
      name: data?.surgery?.name,
      address: {
        addressLine1: data?.surgery?.address?.addressLine1,
        addressLine2: data?.surgery?.address?.addressLine2,
        postCode: data?.surgery?.address?.postCode,
        city: data?.surgery?.address?.city,
        countryCode: data?.surgery?.address?.countryCode,
      },
      phoneNumber: data?.surgery?.phoneNumber || null,
      email: data?.surgery?.email || null,
    },
  }
}

export const formatPharmacyData = (data: Patient['nominatedPharmacy']) => {
  return {
    name: data?.name,
    address: {
      addressLine1: data?.address?.addressLine1,
      addressLine2: data?.address?.addressLine2,
      postCode: data?.address?.postCode,
      city: data?.address?.city,
      countryCode: data?.address?.countryCode,
    },
    phoneNumber: data?.phoneNumber || null,
    email: data?.email || null,
  }
}

const isServiceGP = (
  serviceType: ServiceValues.GP | ServiceValues.PHARMACY,
) => {
  return serviceType === ServiceValues.GP
}

export function hasValidRequiredServiceFields(
  serviceVals: any,
  serviceType: ServiceValues.GP | ServiceValues.PHARMACY,
) {
  const service = isServiceGP(serviceType) ? serviceVals?.surgery : serviceVals
  const validatedFields = {
    name: service?.name,
    addressLine1: service?.address?.addressLine1,
    city: service?.address?.city,
    countryCode: service?.address?.countryCode,
    postCode: service?.address?.postCode,
  }

  if (service) {
    const hasValidFields = Object.values(validatedFields).every((fieldVal) => {
      return Boolean(fieldVal)
    })
    return hasValidFields
  }
}

export const serviceIsNotEmpty = (
  serviceVals: any,
  serviceType: ServiceValues.GP | ServiceValues.PHARMACY,
) => {
  const data = isServiceGP(serviceType) ? serviceVals?.surgery : serviceVals
  const address = data?.address
  const service = {
    ...(data || {}),
    address: undefined,
  }
  return addressIsNotEmpty(address) || objectIsNotEmpty(service)
}
