import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { NotificationIcons } from '../icons/NotificationIcons'
import { useAction } from '@core/shared/useAction'
import { hideNotificationAction } from '@core/app/common/NotificationItem/actions'
import { notificationItemProperties } from '@core/app/common/NotificationItem/selectors'

export const NotificationItem = () => {
  const properties = useSelector(notificationItemProperties)
  const hideNotification = useAction(hideNotificationAction)

  useEffect(() => {
    setTimeout(() => {
      if (properties.isVisible) {
        hideNotification()
      }
    }, properties.duration || 3000)
  }, [hideNotification, properties.duration, properties.isVisible])

  return properties.isVisible ? (
    <div className="notification-item-wrapper">
      <div className={`notification-item notification-item-${properties.type}`}>
        <NotificationIcons type={properties.type} />
        <div
          role="alert"
          data-testid="notification-item-title"
          className="notification-item-title ml-1"
        >
          {properties.title}
        </div>
      </div>
    </div>
  ) : (
    <div></div>
  )
}
