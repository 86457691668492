import { memo, useCallback, useState } from 'react'
import cx from 'clsx'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { LabelPaper } from '@core/components/form/LabelPaper'
import { PaperHeader, PaperContent } from '@core/components/paper'
import { Radio } from '@core/components/form/Radio'
import { GoogleMapComponent } from '@core/components/app/GoogleMapComponent'

type HealthServicesProps = {
  text: {
    pageHeader: string
    pageTitle: string
    note1: string
    note2: string
  }
}

const searchForItems = [
  {
    index: 0,
    label: 'GP Surgery',
    name: 'gpsurgery',
  },
  {
    index: 1,
    label: 'Hospital',
    name: 'hospital',
  },
  {
    index: 2,
    label: 'Pharmacy',
    name: 'pharmacy',
  },
  {
    index: 3,
    label: 'Dentist',
    name: 'dentist',
  },
]

export const HealthServices = memo(function HealthServices(
  props: HealthServicesProps,
) {
  const { text } = props
  const [selectedType, setSelectedType] = useState<string>('GP Surgery')

  const handleOnTypeClick = useCallback((type: string) => {
    setSelectedType(type)
  }, [])

  return (
    <div className="page health-services-page">
      <PageHeader showBg backBtn title={text.pageHeader} />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="content">
            <GoogleMapComponent selectedType={selectedType} />
            <div className="section">
              <div className="">
                <div className="mb-2">
                  <h3 className="text-weight-md">{text.pageTitle}</h3>
                </div>
                <div className="flex-row search-for-wrapper">
                  {searchForItems.map((el) => (
                    <LabelPaper
                      className="type-pick flex-1 action-cursor"
                      key={el.index}
                    >
                      <PaperContent className="health-service-paper-content">
                        <PaperHeader
                          className={cx(
                            'text-sm flex-row  space-between',
                            el.label === selectedType && 'selected',
                          )}
                        >
                          <Radio
                            name="type-radio"
                            className="mr-2"
                            defaultChecked={el.label === selectedType}
                            onChange={() => handleOnTypeClick(el.label)}
                            value={el.name}
                          />
                          {el.label}
                        </PaperHeader>
                      </PaperContent>
                    </LabelPaper>
                  ))}
                </div>
              </div>
            </div>

            <div className="section">
              <p className="mb-2 text-sm">{text.note1}</p>
              <p className="text-sm">{text.note2}</p>
            </div>
          </div>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
