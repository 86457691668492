import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormContext, useWatch } from 'react-hook-form'
import cx from 'clsx'

import { Feature } from '../features/features'
import { ServiceType, Patient } from '@core/shared/types/patient/PatientRecord'
import { GPDetailsInput } from './ServiceDetailsInput/GPDetailsInput'
import { PharmacyDetailsInput } from './ServiceDetailsInput/PharmacyDetailsInput'

import styles from './ServiceDetails.module.scss'
import { setTemporaryUserRecordRequestAction } from '@core/app/user/actions'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import { useAction } from '@core/shared/useAction'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { SelectedServiceCard } from './SelectedServiceCard/SelectedServiceCard'
import { ServiceHeader } from './ServiceHeader'
import { GPRegisterInput } from './ServiceDetailsInput/GPRegisterInput'
import {
  hasValidRequiredServiceFields,
  serviceIsNotEmpty,
} from '../../healthServiceDataUtils'
import { Button } from '../../Button/Button'
import { TertiaryButtonLink } from '../../Button/TertiaryButtonLink'
import { IconPin1 } from '../../icons/locationSearch/24/outline/IconPin1'

export enum ServiceValues {
  GP = 'gp',
  PHARMACY = 'nominatedPharmacy',
}

type Props = {
  serviceType: ServiceValues.GP | ServiceValues.PHARMACY
  existingRecord?: Patient | null
  shouldClearTempRecord?: React.MutableRefObject<boolean>
}

export const ServiceDetailOptions = (props: Props) => {
  const { serviceType, shouldClearTempRecord = null } = props

  const { getValues, setValue } = useFormContext()
  const history = useHistory()
  const isRegisteredWithGP = useWatch<unknown>({ name: 'isRegisteredWithGP' })
  const [hasSelectedManualOption, setHasSelectedManualOption] = useState(false)
  const [isEditing, setisEditing] = useState(false)
  const [isShowingCard, setIsShowingCard] = useState()
  const [isClearing, setIsClearing] = useState(false)

  const isGP = serviceType === ServiceValues.GP
  const isPharmacy = serviceType === ServiceValues.PHARMACY
  const selectServiceParam = isGP ? ServiceType.GPSURGERY : ServiceType.PHARMACY

  const temporaryUserRecord = useSelector(selectTemporaryUserRecord)
  const setTemporaryUserRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )

  const onHandleEnterDetailsManually = (): void => {
    setHasSelectedManualOption(true)
    setIsClearing(false)
  }

  const handleClear = (): void => {
    setIsClearing(true)
  }

  useEffect(() => {
    const fieldVal =
      serviceType === ServiceValues.GP ? 'gp.surgery' : 'nominatedPharmacy'
    if (isClearing) {
      setValue(
        fieldVal,
        {
          name: '',
          address: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            countryCode: '',
            postCode: '',
          },
          email: '',
          phoneNumber: '',
        },
        {
          shouldDirty: true,
        },
      )
    }
  }, [getValues, isClearing, isGP, serviceType, setValue])

  function onHandleEdit(): void {
    setisEditing(true)
    onHandleEnterDetailsManually()
  }

  function onHandleServicePageNavigation() {
    if (shouldClearTempRecord) {
      shouldClearTempRecord.current = false
    }

    if (isGP && !isRegisteredWithGP) return

    const currFormVals = getValues()
    const gPVals = currFormVals?.[ServiceValues.GP]
    const pharmaVals = currFormVals?.[ServiceValues.PHARMACY]

    if (hasValidRequiredServiceFields(gPVals, ServiceValues.GP)) {
      currFormVals[ServiceValues.GP] = { isSelected: true, ...gPVals }
    }

    if (hasValidRequiredServiceFields(pharmaVals, ServiceValues.PHARMACY)) {
      currFormVals[ServiceValues.PHARMACY] = {
        isSelected: true,
        ...pharmaVals,
      }
    }
    setTemporaryUserRecordRequest({
      userRecord: currFormVals,
      serviceType: selectServiceParam,
    })

    const path = ScreenRoutes.SELECT_SERVICE.replace(
      ':serviceType',
      selectServiceParam,
    )
    history.push(path)
  }

  const getServiceTempRecords = useCallback(
    (serviceType: string) => {
      return temporaryUserRecord?.userRecord?.[serviceType]
    },
    [temporaryUserRecord],
  )

  useEffect(() => {
    const temporaryRecord = temporaryUserRecord?.userRecord
    if (temporaryRecord) {
      const serviceTempRecord = getServiceTempRecords(serviceType)
      if (serviceIsNotEmpty(serviceTempRecord, serviceType)) {
        setValue(serviceType, serviceTempRecord, {
          shouldDirty: true,
        })
        serviceTempRecord && setHasSelectedManualOption(true)
      }
    }
  }, [
    temporaryUserRecord,
    setValue,
    serviceType,
    getServiceTempRecords,
    hasSelectedManualOption,
    isEditing,
  ])

  function showServiceFields() {
    if (isGP && isRegisteredWithGP) {
      return <GPDetailsInput />
    }
    if (isPharmacy) {
      return <PharmacyDetailsInput />
    }
  }

  const isDisplayingCard = useCallback(() => {
    if ((isGP && !isRegisteredWithGP) || isClearing) return false
    return (
      hasValidRequiredServiceFields(getValues()?.[serviceType], serviceType) &&
      !isEditing
    )
  }, [getValues, isClearing, isEditing, isGP, isRegisteredWithGP, serviceType])

  useEffect(() => {
    const isDisplaying = isDisplayingCard()
    setIsShowingCard(isDisplaying)
  }, [isDisplayingCard])

  const manualInputBtn = (
    <TertiaryButtonLink
      label="Enter details manually"
      onClick={() => onHandleEnterDetailsManually()}
      testid="choose-manual-input"
    />
  )

  const isShowingServiceFields = () => {
    if (!isClearing) {
      if (hasSelectedManualOption || isEditing) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const manualInputOption = (
    <div
      className={cx(
        styles.showOption,
        isGP && !isRegisteredWithGP && styles.hideOption,
      )}
    >
      <div
        className={cx(
          !isShowingServiceFields() ? styles.showOption : styles.hideOption,
        )}
      >
        {manualInputBtn}
      </div>
      <div
        className={cx(
          isShowingServiceFields() ? styles.showOption : styles.hideOption,
        )}
      >
        {showServiceFields()}
      </div>
    </div>
  )

  const selectServiceOnMapOption = (
    <Feature name="gpPharmacyMap">
      <div className="flex-row mb-1">
        <Button
          label="Choose on map"
          type="secondary"
          onClick={() => onHandleServicePageNavigation()}
          disabled={isGP && !isRegisteredWithGP}
          startIcon={<IconPin1 />}
          size="medium"
          ariaLabel="choose service on map"
        />
      </div>
    </Feature>
  )

  return (
    <div
      className={cx(!isShowingCard && 'mb-2', styles.services)}
      data-testid={`${serviceType}-section`}
    >
      <ServiceHeader serviceType={serviceType} />
      <div
        className={cx(styles.showOption, !isShowingCard && styles.hideOption)}
      >
        <SelectedServiceCard
          handleClear={handleClear}
          onHandleEdit={onHandleEdit}
          serviceType={serviceType}
        />
      </div>
      <div
        className={cx(styles.showOption, isShowingCard && styles.hideOption)}
      >
        {selectServiceOnMapOption}
        {manualInputOption}
      </div>
      {isGP && (
        <div
          data-testid="registered-checkbox"
          className={cx(styles.checkbox, isShowingCard && styles.hideCheckbox)}
        >
          <GPRegisterInput />
        </div>
      )}
    </div>
  )
}
