import { Action } from '@core/shared/types/Action'
import { User } from '@core/shared/types/user/User'
import { Patient } from '@core/shared/types/patient/PatientRecord'

export enum UserActionTypes {
  REGISTER = 'REGISTER',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAIL = 'REGISTER_FAIL',

  GET_ME = 'GET_ME',
  GET_ME_SUCCCESS = 'GET_ME_SUCCCESS',
  GET_ME_FAIL = 'GET_ME_FAIL',

  GET_CURRENT_USER = 'GET_CURRENT_USER',
  GET_CURRENT_USER_SUCCCESS = 'GET_CURRENT_USER_SUCCCESS',
  GET_CURRENT_USER_FAIL = 'GET_CURRENT_USER_FAIL',

  UPDATE_PROFILE = 'UPDATE_PROFILE',
  SAVE_NEW_PASSWORD = 'SAVE_NEW_PASSWORD',
  UPDATE_PASSCODE = 'UPDATE_PASSCODE',

  RESET_PASSWORD_START = 'RESET_PASSWORD_START',
  RESET_PASSWORD_START_SUCCESS = 'RESET_PASSWORD_START_SUCCESS',
  RESET_PASSWORD_START_FAIL = 'RESET_PASSWORD_START_FAIL',

  RESET_PASSWORD_COMPLETE = 'RESET_PASSWORD_COMPLETE',
  RESET_PASSWORD_COMPLETE_SUCCESS = 'RESET_PASSWORD_COMPLETE_SUCCESS',
  RESET_PASSWORD_COMPLETE_FAIL = 'RESET_PASSWORD_COMPLETE_FAIL',

  RESEND_EMAIL_CONFIRMATION = 'RESEND_EMAIL_CONFIRMATION',
  RESEND_EMAIL_CONFIRMATION_SUCCESS = 'RESEND_EMAIL_CONFIRMATION_SUCCESS',
  RESEND_EMAIL_CONFIRMATION_FAIL = 'RESEND_EMAIL_CONFIRMATION_FAIL',

  CURRENT_USER_AFTER_REGISTER_SUCCESS = 'CURRENT_USER_AFTER_REGISTER_SUCCESS',

  SET_TEMPORARY_USER_RECORD = 'SET_TEMPORARY_USER_RECORD',
}

export type GetMeAction = Action<typeof UserActionTypes.GET_ME>

export type GetCurrentUserAction = Action<
  typeof UserActionTypes.GET_CURRENT_USER,
  string
>

export type GetMeSuccessAction = Action<
  typeof UserActionTypes.GET_ME_SUCCCESS,
  User
>

export type GetMeFailAction = Action<typeof UserActionTypes.GET_ME_FAIL>

export type RegisterAction = Action<typeof UserActionTypes.REGISTER, any>

export type CurrentUserAfterRegisterSuccessAction = Action<
  typeof UserActionTypes.CURRENT_USER_AFTER_REGISTER_SUCCESS,
  any
>

export type UpadteProfileAction = Action<
  typeof UserActionTypes.UPDATE_PROFILE,
  any
>

export type SaveNewPasswordAction = Action<
  typeof UserActionTypes.SAVE_NEW_PASSWORD,
  { oldPassword: string; newPassword: string }
>

export type UdatePasscodeAction = Action<
  typeof UserActionTypes.UPDATE_PASSCODE,
  any
>

export type ResetPasswordStartAction = Action<
  typeof UserActionTypes.RESET_PASSWORD_START,
  { userName: string }
>

export type ResetPasswordCompleteAction = Action<
  typeof UserActionTypes.RESET_PASSWORD_COMPLETE,
  { token: string; tokenId: string; password: string }
>

export type ResendEmailConfirmationAction = Action<
  typeof UserActionTypes.RESEND_EMAIL_CONFIRMATION,
  {
    userId: string
  }
>

export type ResendEmailConfirmationSuccessAction = Action<
  typeof UserActionTypes.RESEND_EMAIL_CONFIRMATION_SUCCESS
>

export type ResendEmailConfirmationFailAction = Action<
  typeof UserActionTypes.RESEND_EMAIL_CONFIRMATION_FAIL
>

export type SetTemporaryUserRecordRequestAction = Action<
  typeof UserActionTypes.SET_TEMPORARY_USER_RECORD,
  {
    userRecord: any | null
    serviceType: string
  }
>
