import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const BackIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 9 15"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('back-icon', className)}
      fill="none"
      width="9"
      height="15"
      role="img"
    >
      <title>Back</title>
      <path
        d="M0.828072 8.40887L7.1909 14.7716C7.33806 14.9189 7.53452 15 7.74399 15C7.95346 15 8.14991 14.9189 8.29707 14.7716L8.76565 14.3031C9.07056 13.9979 9.07056 13.5017 8.76565 13.1969L3.42263 7.85392L8.77158 2.50497C8.91875 2.35769 9 2.16135 9 1.952C9 1.74241 8.91875 1.54607 8.77158 1.39868L8.303 0.930332C8.15572 0.783051 7.95939 0.701914 7.74992 0.701914C7.54045 0.701914 7.34399 0.783051 7.19683 0.930332L0.828072 7.29886C0.68056 7.4466 0.599538 7.64387 0.600003 7.85357C0.599538 8.06409 0.68056 8.26124 0.828072 8.40887Z"
        fill="#242D38"
      />
    </svg>
  )
}
