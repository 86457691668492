import { ArticlesIcon } from '../icons/ArticlesIcon'
import { ProfileIcon } from '../icons/ProfileIcon'
import { ForYouIcon } from '../icons/ForYouIcon'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const rootNav = {
  home: {
    Icon: ForYouIcon,
    routes: [
      `${ScreenRoutes.HOME}`,
      `${ScreenRoutes.EMERGENCY_DISCLAIMER}`,
      `${ScreenRoutes.FOR_YOU}`,
      `${ScreenRoutes.BOOKING_ELIGIBILITY}`,
      `${ScreenRoutes.BOOK_CONSULTATION}`,
      `${ScreenRoutes.BOOK_CONSULTATION_STEP_2}`,
      `${ScreenRoutes.BOOK_CONSULTATION_STEP_3}`,
      `${ScreenRoutes.CHOOSE_CONSULTATION}`,
      `${ScreenRoutes.DASHBOARD}`,
      `${ScreenRoutes.EDIT_GP_CONSULTATION}`,
      `${ScreenRoutes.VIEW_LAST_CONSULTATION}`,
      `${ScreenRoutes.CONSULTATION_BOOKED}`,
    ],
    title: 'Home',
    rootPath: ScreenRoutes.DASHBOARD,
  },
  articles: {
    Icon: ArticlesIcon,
    routes: [
      `${ScreenRoutes.ARTICLES}`,
      `${ScreenRoutes.HEALTH_WELLBEING}`,
      `${ScreenRoutes.MEDICAL_INFORMATION_RESULTS}`,
      `${ScreenRoutes.ARTICLE}`,
      `${ScreenRoutes.HEALTH_SERVICES}`,
    ],
    title: 'Articles',
    rootPath: ScreenRoutes.ARTICLES,
  },
  profile: {
    Icon: ProfileIcon,
    routes: [
      `${ScreenRoutes.ABOUT}`,
      `${ScreenRoutes.CHANGE_PASSWORD}`,
      `${ScreenRoutes.ADD_NEW_CONTACT}`,
      `${ScreenRoutes.EDIT_CONTACT}`,
      `${ScreenRoutes.DEPENDENTS}`,
      `${ScreenRoutes.EDIT_PROFILE}`,
      `${ScreenRoutes.PROFILE}`,
      `${ScreenRoutes.TERMS_AND_CONDS}`,
    ],
    title: 'Profile',
    rootPath: ScreenRoutes.PROFILE,
  },
}
