import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const IconArrowDown = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('arrow-down-icon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.5 9 12 16.5 4.5 9"
      />
    </svg>
  )
}
