import { Switch, Route } from 'react-router-dom'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { BookStep1Page } from './BookStep1Page'
import { BookStep2Page } from './BookStep2Page'
import { BookStep3Page } from './BookStep3Page'
import { useClearTemporaryRecord } from '@core/app/useClearTemporaryRecord'

export const BookConsultationPage = ({
  customTermsAndPrivacyCopy,
}: {
  customTermsAndPrivacyCopy?: {
    privacyLink?: { href?: string }
    termsLink?: { href?: string }
  }
}) => {
  const shouldClearTemporaryRecord = useClearTemporaryRecord()

  return (
    <Switch>
      <Route path={ScreenRoutes.BOOK_CONSULTATION_STEP_2}>
        <BookStep2Page
          shouldClearTemporaryRecord={shouldClearTemporaryRecord}
        />
      </Route>
      <Route path={ScreenRoutes.BOOK_CONSULTATION_STEP_3}>
        <BookStep3Page customTermsAndPrivacyCopy={customTermsAndPrivacyCopy} />
      </Route>
      <Route>
        <BookStep1Page />
      </Route>
    </Switch>
  )
}
