import React from 'react'

import { InlineLoader } from '@core/components/app/InlineLoader'
import { ErrorIcon, IconLock } from '@core/components/icons'
import { StripeForm } from '../../../payment/components/Stripe'

type Props = {
  preparingPayment: boolean
  IsPaymentEligibilityEnabled: boolean
  termsAndPrivacyComponent: React.ReactNode
  localError: string
  loading: boolean
  handleSubmit: () => void
  paymentIntent: string
  publishableKey: string
}

export const ConfirmBooking = (props: Props) => {
  const {
    preparingPayment,
    IsPaymentEligibilityEnabled,
    termsAndPrivacyComponent,
    localError,
    loading,
    paymentIntent,
    publishableKey,
    handleSubmit,
  } = props

  if (preparingPayment && IsPaymentEligibilityEnabled) {
    return (
      <div className="preparing-payment">
        <InlineLoader />
      </div>
    )
  }
  if (!IsPaymentEligibilityEnabled) {
    return (
      <>
        <div className="section">{termsAndPrivacyComponent}</div>
        <div className="section">
          {localError ? (
            <div className="validation-errors flex-center mt-2 mb-2">
              <ErrorIcon />
              <div className="tooltip warning ml-1">
                {localError || 'Some fields are not valid'}
              </div>
            </div>
          ) : null}
          <div className="btn-container">
            <button
              disabled={loading || Boolean(localError)}
              type="submit"
              onClick={handleSubmit}
              className="btn btn-primary w-full"
              data-testid="book-consultation-btn"
            >
              Book the consultation
            </button>
          </div>
        </div>
      </>
    )
  } else if (paymentIntent && publishableKey) {
    return (
      <>
        <div className="section">{termsAndPrivacyComponent}</div>
        <div className="section">
          {localError ? (
            <div className="validation-errors flex-center mt-2 mb-2">
              <ErrorIcon />
              <div className="tooltip warning ml-1">
                {localError || 'Some fields are not valid'}
              </div>
            </div>
          ) : null}
        </div>
        <div className="section">
          <hr />
          <h3 className="section-title mb-2 mt-2">Payment details</h3>
          <p>
            <IconLock /> This is a secure 128-bit SSL encrypted payment.
          </p>
          <p className="mt-1">
            We won't charge you until the consultation has been completed.
          </p>
          <StripeForm
            clientSecret={paymentIntent}
            publishableKey={publishableKey}
          />
        </div>
      </>
    )
  } else {
    return (
      <div className="validation-errors flex-center mt-2 mb-2 section">
        <ErrorIcon />
        <div className="tooltip warning ml-1">{localError}</div>
      </div>
    )
  }
}
