import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const ServicesPhoneIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 43"
      className={cx('services-phone-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#475a79" cx="21.5" cy="21.5" r="21.5"></circle>
          <path
            d="M21.1635 28.1401C20.383 28.1401 19.7371 28.7861 19.7371 29.5666C19.7371 30.3471 20.383 30.9931 21.1635 30.9931C21.9441 30.9931 22.59 30.3471 22.59 29.5666C22.59 28.7861 21.9441 28.1401 21.1635 28.1401ZM21.1635 30.4279C20.6791 30.4279 20.2754 30.0242 20.2754 29.5397C20.2754 29.0552 20.6791 28.6515 21.1635 28.6515C21.648 28.6515 22.0517 29.0552 22.0517 29.5397C22.0248 30.0511 21.648 30.4279 21.1635 30.4279Z"
            fill="white"
          />
          <path
            d="M25.9004 10H16.3996C15.646 10 15 10.646 15 11.3996V30.9127C15 31.6932 15.646 32.3392 16.3996 32.3392H25.8735C26.654 32.3392 27.2731 31.6932 27.2731 30.9396V11.3996C27.3 10.646 26.681 10 25.9004 10ZM26.4926 30.9396C26.4926 31.2626 26.2234 31.5317 25.9004 31.5317H16.3996C16.0766 31.5317 15.8074 31.2626 15.8074 30.9396V11.3996C15.8074 11.0766 16.0766 10.8074 16.3996 10.8074H25.8735C26.1965 10.8074 26.4656 11.0766 26.4656 11.3996V30.9396H26.4926Z"
            fill="white"
          />
          <path
            d="M22.6708 11.9644H19.6025V12.5026H22.6708V11.9644Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}
