import { useFormContext, useWatch } from 'react-hook-form'

import { Checkbox } from '../../../form/Checkbox/Checkbox'

export const GPRegisterInput = () => {
  const { register, setValue, getValues } = useFormContext()
  const isRegistered = useWatch<unknown>({ name: 'isRegisteredWithGP' })

  const onIsRegisteredHandler = (): void => {
    const isRegistered = getValues().isRegisteredWithGP
    setValue('isRegisteredWithGP', !isRegistered)
  }

  return (
    <>
      <Checkbox
        inputRef={register('isRegisteredWithGP')}
        onCheckHandler={onIsRegisteredHandler}
        isChecked={isRegistered}
        label="I am not registered with a GP"
        id="isRegisteredWithGP"
      />
    </>
  )
}
