import { useHistory } from 'react-router-dom'
import { SymptomAssessmentIcon } from '@core/components/icons/SymptomAssessmentIcon'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import styles from './SymptomAssessmentEntry.module.scss'
import clsx from 'clsx'
import { Card } from '@core/components/card/Card'
import { Button } from '@core/components/Button/Button'

export interface SymptomAssessmentEntryProps {
  disabled?: boolean
}

export const SymptomAssessmentEntry = ({
  disabled,
}: SymptomAssessmentEntryProps) => {
  const history = useHistory()
  const goToSymptomAssessment = () =>
    history.push(ScreenRoutes.SYMPTOMASSESSMENT)

  return (
    <section
      className={clsx('section', styles.symptomChecker, {
        [styles['symptomChecker--disabled']]: disabled,
      })}
      data-testid="symptom-checker-entry"
    >
      <h2 className="text-heading-m font-bold mb-1">Assess your symptoms</h2>
      <Card className={styles.symptomCard}>
        <div>
          <div className="card-header">
            <div className={clsx('card-header-icon', styles.icon)}>
              <SymptomAssessmentIcon />
            </div>
            <h3 className="text-heading-s font-bold">Symptom assessment</h3>
          </div>
          <div className="card-content">
            <p className="text-body-m">
              An online symptom assessment to find the care you need.
            </p>
          </div>
        </div>
        <div className={styles.btnContainer}>
          <Button
            disabled={disabled}
            label="Assess symptoms"
            onClick={goToSymptomAssessment}
            type="primary"
          />
        </div>
      </Card>
    </section>
  )
}
