import { Action } from '@core/shared/types/Action'

export enum AppActionTypes {
  APP_INIT = 'APP_INIT',
  APP_INIT_SUCCESS = 'APP_INIT_SUCCESS',
}

export type InitAppAction = Action<typeof AppActionTypes.APP_INIT>

export type InitAppSuccessAction = Action<
  typeof AppActionTypes.APP_INIT_SUCCESS
>
