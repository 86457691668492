import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'

type Props = {
  customServicesEligibilityPara?: string
}

export const EmergencyDisclaimer = (props: Props) => {
  const { customServicesEligibilityPara } = props
  const emergencySymptoms = [
    'Has difficulty or is not breathing.',
    'You believe someone is having a heart attack or stroke.',
    'Has severe chest pain - has severe abdominal pain.',
    'Has severe bleeding and it can’t be stopped.',
    'Has lost consciousness.',
    'Is in an acute confused state and/or is having fits which aren’t stopping.',
  ]
  const servicesAvailablePara =
    customServicesEligibilityPara ||
    'Only residents and/or nationals of the United Kingdom (UK) or Republic of Ireland are eligible to use our services. By using our services, you are confirming that you are a UK or Republic of Ireland resident and/or national.'
  return (
    <div className="page emergency-disclaimer-page">
      <PageHeader showBg backBtn title="Important information" />
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <div className="section">
            <div className="section-title mb-1">
              This is not an emergency service.
            </div>
            <div className="emergency-disclaimer-paragraph text-weight-md">
              You must not use the service for emergencies or urgent conditions
              as this may delay necessary treatment.
            </div>
            <div className="emergency-disclaimer-paragraph local-emergency-services-para">
              If you believe that you are in an emergency or life-threatening
              situation you should contact your local emergency services
              immediately, especially if someone:
            </div>
          </div>

          <div className="emergency-disclaimer-list-wrapper">
            <ul className="emergency-disclaimer-list">
              {emergencySymptoms.map((symptom: string, index: number) => (
                <li key={index}>- {symptom}</li>
              ))}
            </ul>
          </div>

          <div className="section bottom-text">
            <div className="emergency-disclaimer-paragraph">
              {servicesAvailablePara}
            </div>
            <div className="emergency-disclaimer-separate-line"></div>
            <div className="emergency-disclaimer-paragraph">
              The service is not a replacement for your own GP. You may still
              need to see your own GP or contact the emergency services if the
              clinician you speak with feels it is necessary.
            </div>
          </div>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
