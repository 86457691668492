import { produce } from 'immer'

import { User } from '@core/shared/types/user/User'
import { Patient } from '@core/shared/types/patient/PatientRecord'
import {
  GetMeAction,
  UserActionTypes,
  GetMeSuccessAction,
  GetMeFailAction,
  CurrentUserAfterRegisterSuccessAction,
  SetTemporaryUserRecordRequestAction,
} from './actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

export interface RUser {
  me?: User
  temporaryRecord: {
    userRecord: any | null
    serviceType: string
  }
}

export const userInitialState = (): RUser => ({
  me: undefined,
  temporaryRecord: {
    userRecord: null,
    serviceType: '',
  },
})

type UserActions =
  | GetMeAction
  | GetMeSuccessAction
  | GetMeFailAction
  | CurrentUserAfterRegisterSuccessAction
  | SetTemporaryUserRecordRequestAction
  | LogoutAction

const userReducer = produce(function (draft: RUser, action: UserActions) {
  switch (action.type) {
    case UserActionTypes.GET_ME_SUCCCESS: {
      return {
        ...draft,
        me: action.payload,
      }
    }
    case UserActionTypes.CURRENT_USER_AFTER_REGISTER_SUCCESS: {
      return {
        ...draft,
        me: action.payload,
      }
    }

    case UserActionTypes.SET_TEMPORARY_USER_RECORD: {
      draft.temporaryRecord.userRecord = action.payload.userRecord
      draft.temporaryRecord.serviceType = action.payload.serviceType
      break
    }

    case AuthActionTypes.LOGOUT:
      return userInitialState()
  }
}, userInitialState())

export default userReducer
