import { produce } from 'immer'
import deepmerge from 'deepmerge'
import {
  AppointmentDate,
  AppointmentSlot,
} from '@core/shared/types/appointment'

import {
  AppointmentActionTypes,
  SubmitAppointmentBookingStep2Action,
  SubmitAppointmentBookingStep1Action,
  GetAppointmentBookingDatesSuccessAction,
  GetAppointmentBookingSlotsSuccessAction,
  SelectBookingDate,
} from './actionTypes'

import {
  BookingActionTypes,
  SubmitBookingSuccessAction,
} from '../bookings/actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

export interface RAppointment {
  bookingSlots: AppointmentSlot[]
  bookingDates: AppointmentDate[]
  newBooking?: Record<string, any> //AppointmentBooking
  selectedBookingDate?: string
}

export const appointmentInitialState = (): RAppointment => ({
  bookingSlots: [],
  bookingDates: [],
})

export type AppointmentActions =
  | SubmitAppointmentBookingStep1Action
  | SubmitAppointmentBookingStep2Action
  | GetAppointmentBookingDatesSuccessAction
  | GetAppointmentBookingSlotsSuccessAction
  | SubmitBookingSuccessAction
  | LogoutAction
  | SelectBookingDate

const overwriteMerge = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destinationArray: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sourceArray: any[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
) => sourceArray

const appointmentReducer = produce(function (
  draft: RAppointment,
  action: AppointmentActions,
) {
  switch (action.type) {
    case AppointmentActionTypes.SUBMIT_BOOKING_STEP_1:
    case AppointmentActionTypes.SUBMIT_BOOKING_STEP_2: {
      return {
        ...draft,
        newBooking: Object.assign(
          {},
          deepmerge(draft.newBooking || {}, action.payload, {
            arrayMerge: overwriteMerge,
          }),
        ),
      }
    }
    case AppointmentActionTypes.SELECT_BOOKING_DATE: {
      return {
        ...draft,
        selectedBookingDate: action.payload.date,
      }
    }
    case AppointmentActionTypes.GET_BOOKING_DATES_SUCCESS: {
      return {
        ...draft,
        bookingDates: action.payload,
      }
    }
    case AppointmentActionTypes.GET_BOOKING_SLOTS_SUCCESS: {
      return {
        ...draft,
        bookingSlots: action.payload,
      }
    }
    case BookingActionTypes.SUBMIT_BOOKING_SUCCESS: {
      return appointmentInitialState()
    }
    case AuthActionTypes.LOGOUT:
      return appointmentInitialState()
  }
},
appointmentInitialState())

export default appointmentReducer
