import { produce } from 'immer'
// eslint-disable-next-line import/no-extraneous-dependencies
import { SymptomReportModel } from '@doctorlink/traversal-core'

import {
  AssessmentActionTypes,
  GetAssessmentAction,
  CreateAssessmentAction,
  DeleteAssessmentAction,
  SetPopulateReasonForBooking,
} from './actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

export interface RAssessment {
  conclusion: null | SymptomReportModel
  populateReasonForBooking: boolean
}

export const initialAssessment = (): RAssessment => ({
  conclusion: null,
  populateReasonForBooking: false,
})

type AssessmentActions =
  | GetAssessmentAction
  | CreateAssessmentAction
  | DeleteAssessmentAction
  | SetPopulateReasonForBooking
  | LogoutAction

const symptomsAssessmentReducer = produce(function (
  state = initialAssessment(),
  action: AssessmentActions,
) {
  switch (action.type) {
    case AssessmentActionTypes.CREATE_ASSESSMENT_RESULT: {
      return { ...state, ...action.payload }
    }
    case AssessmentActionTypes.DELETE_ASSESSMENT_RESULT: {
      return initialAssessment()
    }
    case AssessmentActionTypes.SET_POPULATE_REASONFORBOOKING: {
      return { ...state, ...action.payload }
    }

    case AuthActionTypes.LOGOUT:
      return initialAssessment()
  }
},
initialAssessment())

export default symptomsAssessmentReducer
