import axios from 'axios'

import { env } from '@core/shared/configuration'

const config = env()

const sapiClient = axios.create({
  baseURL: `${config.api.sapiHost}/v${config.api.apiVersion}/clients/${config.api.clientId}`,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
})

export default sapiClient
