import { useState, useEffect } from 'react'

import { CloseIcon } from '../../icons/CloseIcon'
import { NotificationIcons } from '../../icons/NotificationIcons'

export const AutoLogoutBanner = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const idleData = window.localStorage.getItem('idle')
    if (idleData) {
      setIsVisible(true)
      window.localStorage.removeItem('idle')
    }
  }, [isVisible])

  return isVisible ? (
    <div className="session-timeout">
      <div className="auto-logout-container">
        <div className="notification-item w-full">
          <div className="notification-title-container flex-row-center w-full">
            <NotificationIcons type="warning" />
            <p
              role="alert"
              data-testid="auto-logout-title"
              className="ml-2 text-smd"
            >
              You have been logged out due to inactivity. To continue using our
              services, please log in again.
            </p>
          </div>
          <button
            className="action-cursor"
            type="button"
            aria-label="Close"
            onClick={() => setIsVisible(false)}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  ) : null
}
