import { useHistory } from 'react-router-dom'
import { route } from '@core/shared/route'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { PhoneCallIcon } from '@core/components/icons/PhoneCallIcon'
import { AppointmentBookingIcon } from '@core/components/icons/AppointmentBookingIcon'
import {
  PageBodyItemsType,
  SpecialtiesItemsType,
} from '@core/components/specialitiesMap'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useClientVariables } from '@core/components/app/features/variables'
import { ServicesAccordion } from './components/ServicesAccordion'
import { CPSpecialties } from '@core/shared/types/bookings'
import { Service, useFeatures } from '@core/components/app/features/features'

interface Props {
  pageContent: SpecialtiesItemsType
  cpSpecialtiesId: string
}

export const WaysToBookOther = ({ pageContent, cpSpecialtiesId }: Props) => {
  const history = useHistory()

  const goToBookOnline = () =>
    history.push(
      route(ScreenRoutes.BOOK_CONSULTATION, {
        cpSpecialtiesId,
      }),
    )

  const { bookings } = useFeatures()
  const {
    clientVariables: { phoneNumber },
  } = useClientVariables()

  const serviceId = CPSpecialties[cpSpecialtiesId] as Service
  const bookingTypes = bookings.services.value[serviceId]?.bookingTypes
  const canBookOnline = bookingTypes?.online ?? false
  const canBookViaPhone = bookingTypes?.phone ?? true

  return (
    <div className="book-single-phone-consultation-page">
      <div
        className={`section book-single-phone-consultation-section ${pageContent?.specialtiesTypeName}`}
      >
        <div className="book-single-phone-consultation-header">
          {pageContent ? (
            <div className="icon-wrapper mb-1">
              <pageContent.HeaderIcon />
            </div>
          ) : null}
          <div className="book-single-phone-consultation-title-wrapper">
            <h1 className="book-single-phone-consultation-title mb-1">
              {pageContent?.headerTitle}
            </h1>
            <p className="book-single-phone-consultation-subtitle mb-2">
              {pageContent?.pageTitle}
            </p>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="card-row">
          {canBookOnline && (
            <div className="card action-card" onClick={goToBookOnline}>
              <div className="card-header">
                <div className="mr-1 flex-center">
                  <AppointmentBookingIcon />
                </div>
                <div className="card-header-text text-color-primary">
                  Book Online
                </div>
              </div>
              <div className="card-content">
                <div className="text-color-secondary text-smd text-weight-md">
                  Confirm your preferences and choose a consultation time online
                </div>
              </div>
              <div className="card-footer">
                <div className="footer-text  text-color-secondary text-sm"></div>
                <span className="footer-arrow">
                  <RightChevronIcon />
                </span>
              </div>
            </div>
          )}
          {canBookViaPhone && (
            <div className="card call-to-book-desktop-card">
              <a href={`tel:${phoneNumber}`} className="call-to-book-link">
                <div className="card-header">
                  <div className="mr-1 flex-center">
                    <PhoneCallIcon />
                  </div>
                  <div className="card-header-text">Call to book</div>
                </div>
                <div className="card-content call-to-book-phone-number text-weight-md">
                  <p>{phoneNumber}</p>
                </div>
                <div className="card-footer">
                  <div className="text-color-secondary text-smd text-weight-md">
                    Appointment booking, and appointments, are available: 09:00
                    - 17:00, Mon - Fri
                  </div>
                  <span className="footer-arrow">
                    <RightChevronIcon />
                  </span>
                </div>
              </a>
            </div>
          )}
        </div>
      </div>

      <div className="section note-section">
        {pageContent?.pageBody?.map((el: PageBodyItemsType, key: React.Key) => (
          <div className="note-wrapper flex-row mb-2" key={key}>
            {el.pageBodyIcon ? (
              <div className="note-icon pr-2">{<el.pageBodyIcon />}</div>
            ) : null}
            <div className="note-text-wrapper">
              <p className="note-text text-smd">{el.pageBodyText}</p>
            </div>
          </div>
        ))}
      </div>

      <ServicesAccordion pageContent={pageContent} />
    </div>
  )
}
