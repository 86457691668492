import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const IconPin1 = (props: IconProps) => {
  const { className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={cx('calendar-add-icon', className)}
      aria-hidden="true"
    >
      <path
        stroke="#242D38"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 12.75a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
      />
      <path
        stroke="#242D38"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.5 9.75c0 6.75-7.5 12-7.5 12s-7.5-5.25-7.5-12a7.5 7.5 0 0 1 15 0v0Z"
      />
    </svg>
  )
}
