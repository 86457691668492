import { memo, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useForm, FieldError, FormProvider, DeepMap } from 'react-hook-form'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { isValidPhoneNumberStrict } from '@core/shared/validation/phone'
import { Patient, ServiceType } from '@core/shared/types/patient/PatientRecord'
import {
  isValidEmailStrict,
  isValidEmail,
  useEmailValidation,
} from '../validation/email'
import { ListInput } from '../form/ListInput'
import { LabelPaper } from '../form/LabelPaper'
import { PaperContent, PaperHeader } from '../paper'
import { Radio } from '../form/Radio'
import { NativeSelect } from '../form/NativeSelect'
import { TextInput } from '../form/TextInput'
import { countryCodeOptions } from '@core/shared/data/countryCodes'
import { postCodePlaceholders } from '@core/shared/data/postCodePlaceholders'
import { required } from '@core/shared/validation/required'
import { ErrorIcon } from '../icons/ErrorIcon'
import { DateInput } from '../form/DateInput'
import { toIsoDateString } from '../date-picker/utils'
import {
  underAgeValidate,
  ageAfter16,
} from '@core/shared/validation/underAgeValidate'
import {
  getAdultMaxDobDate,
  getAdultMinDobDate,
  getDependantMaxDobDate,
  getDependantMinDobDate,
} from '@core/shared/date'
import { Feature } from './features/features'
import { useClientVariables } from './features/variables'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import {
  ServiceDetailOptions,
  ServiceValues,
} from '../app/HealthServiceDetails/ServiceDetailOptions'
import { GpServiceError } from '../app/HealthServiceDetails/ServiceDetailsError/GpServiceError'
import { PharmacyServiceError } from '../app/HealthServiceDetails/ServiceDetailsError/PharmacyServiceError'
import { useClearTemporaryRecord } from '@core/app/useClearTemporaryRecord'
import { formatGpData, formatPharmacyData } from '../healthServiceDataUtils'
import { useOnlyOffersCounselling } from './services'

interface ContactInformationProps {
  handleOnSubmit: (data: any) => void
  submitButtonText: string
  record?: Patient
  editProfile?: boolean
  emailRequired?: boolean
  error?: string
  dependantContact?: boolean
  userPolicyNumber?: string
  hasSectionDividers?: boolean
}
const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number. Must include country code and + symbol',
  email: 'Enter valid email address',
  required: 'Required field',
  dateOfBirth:
    'Dependants must be under 16. Dependants over 16 must create their own account.',
  dateOfBirthMain: 'You must be over 16.',
}
function getError<T>(
  errors: DeepMap<T, FieldError>,
  field: keyof T,
  isDependantRecord?: boolean,
) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }
  if (errorsMap[err.type]) {
    if (err.type === 'dateOfBirth') {
      return isDependantRecord
        ? errorsMap.dateOfBirth
        : errorsMap.dateOfBirthMain
    }
    return errorsMap[err.type]
  }
  return null
}
export const ContactInformation = memo<ContactInformationProps>(
  function ContactInformation(props) {
    const {
      handleOnSubmit,
      submitButtonText,
      record,
      emailRequired = true,
      error: errorProps,
      dependantContact = false,
      userPolicyNumber,
      hasSectionDividers = false,
    } = props
    const hasExistingGPRecord = record?.[ServiceValues.GP] ? true : false

    const temporaryPatientRecord = useSelector(selectTemporaryUserRecord)
    const shouldClearTemporaryRecord = useClearTemporaryRecord()
    const validateEmail = useEmailValidation()

    const defaultFormValues = useMemo(
      () =>
        temporaryPatientRecord?.userRecord
          ? {
              ...temporaryPatientRecord.userRecord,
              nominatedPharmacy:
                temporaryPatientRecord.serviceType === ServiceType.PHARMACY
                  ? null
                  : {
                      ...temporaryPatientRecord.userRecord.nominatedPharmacy,
                    },
              gp:
                temporaryPatientRecord.serviceType === ServiceType.GPSURGERY
                  ? null
                  : {
                      ...temporaryPatientRecord.userRecord.gp,
                    },
            }
          : {
              ...record,
              isRegisteredWithGP: hasExistingGPRecord,
              dateOfBirth: dayjs(record?.dateOfBirth).format('YYYY-MM-DD'),
            },
      [hasExistingGPRecord, record, temporaryPatientRecord],
    )

    const methods = useForm({
      defaultValues: defaultFormValues as any,
      mode: 'onChange',
    })
    const {
      register,
      handleSubmit,
      errors,
      reset,
      setValue,
      formState,
      getValues,
    } = methods
    useEffect(() => {
      register({ name: 'allergies' })
      register({ name: 'medications' })
      register({ name: 'medicalHistory' })
    }, [register])

    useEffect(() => {
      const isRegisteredWithGP = getValues()?.isRegisteredWithGP
      if (record && shouldClearTemporaryRecord) {
        reset({
          ...record,
          gp: getValues()?.gp,
          nominatedPharmacy: getValues()?.nominatedPharmacy,
          isRegisteredWithGP,
          dateOfBirth: dayjs(record?.dateOfBirth).format('YYYY-MM-DD'),
          address: {
            ...record.address,
          },
        })
      }
    }, [
      getValues,
      hasExistingGPRecord,
      record,
      reset,
      shouldClearTemporaryRecord,
      temporaryPatientRecord,
    ])
    const {
      clientVariables: {
        phoneInputPlaceholder,
        clientIdentifierLabel,
        countryList = countryCodeOptions,
        baseCountryCode = 'GB',
      },
    } = useClientVariables()

    const onlyOffersCounselling = useOnlyOffersCounselling()

    const depMinBirthDate = new Date()
    depMinBirthDate.setFullYear(depMinBirthDate.getFullYear() - 16)
    const hasError = {
      gender: Boolean(errors.gender),
    }

    const localOnSubmit = useCallback(
      (data: any) => {
        const newData = {
          ...data,
          dateOfBirth: dayjs(data?.dateOfBirth).format('YYYY-MM-DD'),
        }

        const gpFormData = newData?.gp
        if (gpFormData) {
          newData[ServiceValues.GP] = formatGpData(gpFormData)
        }
        if (!newData?.isRegisteredWithGP) {
          newData[ServiceValues.GP] = null
        }

        if (dependantContact) {
          newData.email = null
          newData.phoneNumber = 'NA'
          newData.address = {
            addressLine1: 'NA',
            addressLine2: 'NA',
            postCode: 'NA',
            city: 'NA',
            countryCode: 'NA',
          }
        }

        const pharmaFormData = newData?.nominatedPharmacy
        if (pharmaFormData) {
          newData[ServiceValues.PHARMACY] = formatPharmacyData(pharmaFormData)
        }
        delete newData?.isRegisteredWithGP
        handleOnSubmit(newData)
      },
      [dependantContact, handleOnSubmit],
    )

    return (
      <div>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(localOnSubmit)}
            className="contact-information"
          >
            {!dependantContact && (
              <>
                <Feature name="clientIdentifierField">
                  <div
                    className="section user-data-block"
                    data-testid="client-identifier-section"
                  >
                    <div
                      className="section-title mb-2 "
                      data-testid="client-identifier-label"
                    >
                      {clientIdentifierLabel}
                    </div>
                    <div className="company-number-view">
                      {(userPolicyNumber || '12345678')
                        .split('')
                        .map((symbol) => (
                          <span className="company-number-symbol">
                            {symbol}
                          </span>
                        ))}
                    </div>
                  </div>
                  {hasSectionDividers && (
                    <div className="section-divider"></div>
                  )}
                </Feature>
                <Feature name="clientIdentifierPin">
                  <div className="section user-data-block">
                    <div className="section-title mb-2">
                      {clientIdentifierLabel}
                    </div>
                    <div className="company-number-view">
                      {(userPolicyNumber || '12345678')
                        .split('')
                        .map((symbol) => (
                          <span className="company-number-symbol">
                            {symbol}
                          </span>
                        ))}
                    </div>
                  </div>
                  {hasSectionDividers && (
                    <div className="section-divider"></div>
                  )}
                </Feature>
              </>
            )}
            <div
              className="section user-data-block"
              data-testid="personal-details-section"
            >
              <h2 className="section-title mb-2">Personal details</h2>
              <div className="details-row">
                <TextInput
                  label="First name"
                  placeholder="First name"
                  className="w-full"
                  name="firstName"
                  error={getError(errors, 'firstName')}
                  inputRef={register({ validate: { required } })}
                  required
                />
                <TextInput
                  label="Last name"
                  placeholder="Last name"
                  className="w-full"
                  name="lastName"
                  error={getError(errors, 'lastName')}
                  inputRef={register({ validate: { required } })}
                  required
                />
              </div>
              <div className="details-row">
                <div
                  role="radiogroup"
                  aria-labelledby="contact-gender-pick-option"
                  className="form-control mb-2 gender-form-control"
                  data-testid="gender-control"
                  aria-required="true"
                >
                  <label
                    id="contact-gender-pick-option"
                    className="flex-row-center"
                  >
                    Sex assigned at birth
                    <ErrorIcon
                      className={clsx(
                        'error-icon-sm-size',
                        !hasError.gender && 'hidden',
                      )}
                    />
                  </label>
                  <div className="flex-row wrap">
                    <LabelPaper
                      className={clsx(
                        'radio-container flex-1',
                        hasError.gender && 'has-error',
                      )}
                    >
                      <PaperContent className="flex-1">
                        <PaperHeader className="text-weight-md text-md flex-row  space-between">
                          Male
                          <Radio
                            defaultChecked={record?.gender === 'Male'}
                            inputRef={register({ required: true })}
                            name="gender"
                            value={'Male'}
                          />
                        </PaperHeader>
                      </PaperContent>
                    </LabelPaper>
                    <LabelPaper
                      className={clsx(
                        'radio-container flex-1 ml-1',
                        hasError.gender && 'has-error',
                      )}
                    >
                      <PaperContent className="flex-1">
                        <PaperHeader className="text-weight-md text-md flex-row  space-between">
                          Female
                          <Radio
                            defaultChecked={record?.gender === 'Female'}
                            inputRef={register({ required: true })}
                            name="gender"
                            value={'Female'}
                          />
                        </PaperHeader>
                      </PaperContent>
                    </LabelPaper>
                  </div>
                  {hasError.gender ? (
                    <div role="alert" className="error-message">
                      Required field
                    </div>
                  ) : null}
                </div>
                <DateInput
                  label="Date of birth"
                  name="dateOfBirth"
                  className="w-full"
                  error={getError(errors, 'dateOfBirth', dependantContact)}
                  required
                  inputRef={register({
                    validate: {
                      dateOfBirth: dependantContact
                        ? underAgeValidate
                        : ageAfter16,
                    },
                  })}
                  value={defaultFormValues.dateOfBirth}
                  max={
                    dependantContact
                      ? toIsoDateString(getDependantMaxDobDate())
                      : toIsoDateString(getAdultMaxDobDate())
                  }
                  min={
                    dependantContact
                      ? toIsoDateString(getDependantMinDobDate())
                      : toIsoDateString(getAdultMinDobDate())
                  }
                />
              </div>
            </div>
            {hasSectionDividers && <div className="section-divider"></div>}

            {!dependantContact && (
              <div
                className="section user-data-block"
                data-testid="contact-details"
              >
                <h2 className="section-title mb-1">Contact details</h2>
                <div className="details-row">
                  <TextInput
                    label="Phone number (please include the country code and + symbol)"
                    placeholder={phoneInputPlaceholder}
                    className="w-full"
                    name="phoneNumber"
                    error={getError(errors, 'phoneNumber')}
                    inputRef={register({
                      validate: {
                        phone: isValidPhoneNumberStrict,
                      },
                    })}
                    required
                  />
                  <TextInput
                    label="Email address"
                    placeholder="E-mail address"
                    className="w-full mt-1"
                    name="email"
                    error={getError(errors, 'email')}
                    inputRef={register({
                      validate: {
                        email: emailRequired
                          ? isValidEmailStrict
                          : isValidEmail,
                        filter: (v) =>
                          validateEmail(v) ||
                          'Email address does not match requirement. Please use your company email address',
                      },
                    })}
                    required
                  />
                </div>
                <TextInput
                  label="Address"
                  placeholder="First line of address"
                  className="w-full"
                  name="address.addressLine1"
                  error={getError(errors.address || {}, 'addressLine1')}
                  inputRef={register({ validate: { required } })}
                  required
                />
                <TextInput
                  label=""
                  placeholder="Second line of address (optional)"
                  className="w-full"
                  name="address.addressLine2"
                  inputRef={register}
                />
                <div className="details-row">
                  <div style={{ display: 'flex' }} className="city-post-line">
                    <div style={{ display: 'flex', flex: 1 }}>
                      <TextInput
                        label=""
                        placeholder="Town or city"
                        className="w-full"
                        name="address.city"
                        error={getError(errors.address || {}, 'city')}
                        inputRef={register({ validate: { required } })}
                        required
                      />
                    </div>
                    <div style={{ width: '30%', marginLeft: '20px' }}>
                      <TextInput
                        label=""
                        placeholder={postCodePlaceholders[baseCountryCode]}
                        className="postcode-input"
                        name="address.postCode"
                        error={getError(errors.address || {}, 'postCode')}
                        inputRef={register({ validate: { required } })}
                        required
                      />
                    </div>
                  </div>
                  <NativeSelect
                    className="w-full"
                    label=""
                    options={countryList}
                    name="address.countryCode"
                    selectRef={register({
                      validate: {
                        required,
                      },
                    })}
                    placeholder="Country"
                    aria-label="Country"
                    error={getError(errors.address || {}, 'countryCode')}
                  />
                </div>
              </div>
            )}
            {hasSectionDividers && <div className="section-divider"></div>}
            {!onlyOffersCounselling ? (
              <div className="section service-details">
                <div className="mb-2">
                  <ServiceDetailOptions
                    serviceType={ServiceValues.GP}
                    existingRecord={record}
                    shouldClearTempRecord={shouldClearTemporaryRecord}
                  />
                </div>
                <div>
                  <ServiceDetailOptions
                    serviceType={ServiceValues.PHARMACY}
                    existingRecord={record}
                    shouldClearTempRecord={shouldClearTemporaryRecord}
                  />
                </div>
              </div>
            ) : null}
            {hasSectionDividers && <div className="section-divider"></div>}
            {!onlyOffersCounselling ? (
              <div
                className="section user-data-block"
                data-testid="my-health-section"
              >
                <h3 className="section-title mb-2">
                  <span>
                    My health{' '}
                    <span className="text-body font-light italic">
                      (Optional)
                    </span>
                  </span>
                </h3>
                <ListInput
                  defaultValue={defaultFormValues?.allergies}
                  label="Allergies"
                  placeholder="Enter an allergy and press enter to add it below"
                  className="mb-2 contact-information-list-input"
                  inputWrapperClassName="w-full"
                  name="allergies"
                  onChange={(values) => {
                    setValue('allergies', values, { shouldDirty: true })
                  }}
                />
                <ListInput
                  defaultValue={defaultFormValues?.medications}
                  label="Current medications"
                  placeholder="Enter a medication and press enter to add it below"
                  className="mb-2 contact-information-list-input"
                  inputWrapperClassName="w-full"
                  name="medications"
                  onChange={(values) => {
                    setValue('medications', values, { shouldDirty: true })
                  }}
                />
                <ListInput
                  defaultValue={defaultFormValues?.medicalHistory}
                  label="Pre-existing conditions"
                  placeholder="Enter a condition and press enter to add it below"
                  className="mb-2 contact-information-list-input"
                  inputWrapperClassName="w-full"
                  name="medicalHistory"
                  onChange={(values) => {
                    setValue('medicalHistory', values, { shouldDirty: true })
                  }}
                />
              </div>
            ) : null}
            {(!formState.isValid && formState.isSubmitted) || errorProps ? (
              <div className="section">
                <div className="validation-errors flex-center">
                  <ErrorIcon />
                  <div role="alert" className="tooltip warning ml-1">
                    {errorProps || 'Some fields are not valid'}
                  </div>
                </div>
              </div>
            ) : null}
            <GpServiceError />
            <PharmacyServiceError />
            <div className="section">
              <div className="btn-container">
                <button
                  data-testid="update-profile-button"
                  disabled={!formState.isDirty || formState.isSubmitting}
                  className="btn btn-primary w-full mb-2 mt-2"
                  type="submit"
                >
                  {formState.isSubmitting ? 'Please wait...' : submitButtonText}
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    )
  },
)
