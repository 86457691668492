import { ofType, Epic } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

import {
  ArticlesActionTypes,
  GetMedicalInformationRequestAction,
  GetLiveWellRequestAction,
  GetMedicalInformationByUrlRequestAction,
  GetMedicalInformationMoreRequestAction,
} from './actionTypes'
import {
  getMedicalInformationSuccessAction,
  getMedicalInformationFailAction,
  getLiveWellFailAction,
  getLiveWellSuccessAction,
  getMedicalInformationByUrlSuccessAction,
  getMedicalInformationByUrlFailAction,
  getMedicalInformationMoreFailAction,
  getMedicalInformationMoreSuccessAction,
} from './actions'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import {
  getMedicalInformation,
  getLiveWell,
  getMedicalInformationByUrl,
  getMoreMedicalInformation,
} from '@core/api/articles'

export const getMedicalInformationEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ArticlesActionTypes.GET_MEDICAL_INFORMATION),
    mergeMap(async (action: GetMedicalInformationRequestAction) => {
      try {
        const response = await getMedicalInformation(action.payload)

        notifySuccess(action)
        return getMedicalInformationSuccessAction(response.data)
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return getMedicalInformationFailAction()
      }
    }),
    concatActions(),
  )

export const getMedicalInformationByUrlEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL),
    mergeMap(async (action: GetMedicalInformationByUrlRequestAction) => {
      try {
        const response = await getMedicalInformationByUrl(action.payload)

        notifySuccess(action)
        return getMedicalInformationByUrlSuccessAction(response.data)
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return getMedicalInformationByUrlFailAction()
      }
    }),
    concatActions(),
  )

export const getMedicalInformationMoreEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE),
    mergeMap(async (action: GetMedicalInformationMoreRequestAction) => {
      try {
        const response = await getMoreMedicalInformation(action.payload)

        notifySuccess(action)
        return getMedicalInformationMoreSuccessAction({
          relatedLink: response.data.relatedLink,
          significantLink: response.data.significantLink,
        })
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return getMedicalInformationMoreFailAction()
      }
    }),
    concatActions(),
  )

export const getLiveWellEpic: Epic = (action$) =>
  action$.pipe(
    ofType(ArticlesActionTypes.GET_LIVE_WELL),
    mergeMap(async (action: GetLiveWellRequestAction) => {
      try {
        const response = await getLiveWell(action.payload)

        notifySuccess(action)
        return getLiveWellSuccessAction(response.data)
      } catch (e) {
        const reason = e.response?.data?.message || 'Request failed'
        notifyFail(action, reason)
        return getLiveWellFailAction()
      }
    }),
    concatActions(),
  )

export default [
  getMedicalInformationEpic,
  getLiveWellEpic,
  getMedicalInformationByUrlEpic,
  getMedicalInformationMoreEpic,
]
