import { createRef } from 'react'
import Slider from 'react-slick'
import { useSelector } from 'react-redux'
import { isEmailVerified } from '@core/app/patientRecord/selectors'
import { HsfComponent } from './components/HsfComponent'
import { GenericConsultation } from './components/GenericConsultation'
import { SpecialtiesItemsType } from '../../specialitiesMap'

export enum LayoutTypes {
  GpConsultation = 'GpConsultation',
  GPPlusTwoServices = 'GPPlusTwoServices',
  MultipleNoImage = 'MultipleNoImage',
  HSF = 'HSF',
}

type Props = {
  type: keyof typeof LayoutTypes
  headerText?: string
  enabledServices: Map<string, SpecialtiesItemsType>
  image?: any
  hasEligibility?: boolean
}

const sliderSettings = {
  speed: 500,
  arrows: false,
  centerPadding: '25px',
  infinite: false,
  slidesToShow: 1,
  rows: 2,
  slidesPerRow: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesPerRow: 1,
        rows: 1,
      },
    },
  ],
}

export const SpecialtiesSlider = (props: Props) => {
  const {
    type,
    headerText = 'Discuss your health',
    image,
    hasEligibility = false,
    enabledServices,
  } = props

  const slider = createRef<any>()
  const isUserEmailVerified = useSelector(isEmailVerified)

  const renderSwitch = (type: keyof typeof LayoutTypes) => {
    if (!enabledServices) {
      return null
    }

    switch (type) {
      case 'GpConsultation':
        return Array.from(enabledServices).map(([key, el]) => {
          return (
            <div className="specialties-slider action-cursor" key={key}>
              <GenericConsultation
                key={key}
                isUserEmailVerified={Boolean(isUserEmailVerified)}
                specialtiesItem={el}
                image={image}
                isGpConsultation={true}
                hasTranslation={true}
                hasEligibility={hasEligibility}
              />
            </div>
          )
        })
      case 'HSF':
        return (
          <div className="section-slider specialties-slider action-cursor">
            <div className="mobile-specialties-slider">
              <Slider ref={slider} {...sliderSettings}>
                {Array.from(enabledServices).map(([key, el]) => {
                  if (el.specialtiesType === '1') {
                    return (
                      <GenericConsultation
                        key={key}
                        isUserEmailVerified={Boolean(isUserEmailVerified)}
                        specialtiesItem={el}
                        image={image}
                        isGpConsultation={true}
                        hasTranslation={true}
                      />
                    )
                  } else {
                    return <HsfComponent key={key} specialtiesItem={el} />
                  }
                })}
              </Slider>
            </div>
            <div className="desktop-specialties-slider">
              {Array.from(enabledServices).map(([key, el]) => {
                if (el.specialtiesType === '1') {
                  return (
                    <GenericConsultation
                      key={key}
                      isUserEmailVerified={Boolean(isUserEmailVerified)}
                      specialtiesItem={el}
                      image={image}
                      isGpConsultation={true}
                      hasTranslation={true}
                    />
                  )
                } else {
                  return <HsfComponent key={key} specialtiesItem={el} />
                }
              })}
            </div>
          </div>
        )
      case 'GPPlusTwoServices':
        return (
          <div className="section-slider specialties-slider action-cursor">
            <div className="mobile-specialties-slider">
              <Slider ref={slider} {...sliderSettings}>
                {Array.from(enabledServices).map(([key, el]) => {
                  return (
                    <GenericConsultation
                      key={key}
                      specialtiesItem={el}
                      isUserEmailVerified={Boolean(isUserEmailVerified)}
                      isGpConsultation={
                        el.specialtiesType === '1' ? true : false
                      }
                      hasTranslation={el.specialtiesType === '1' ? true : false}
                    />
                  )
                })}
              </Slider>
            </div>
            <div className="desktop-specialties-slider">
              {Array.from(enabledServices).map(([key, el]) => {
                return (
                  <GenericConsultation
                    key={key}
                    specialtiesItem={el}
                    isUserEmailVerified={Boolean(isUserEmailVerified)}
                    isGpConsultation={el.specialtiesType === '1' ? true : false}
                    hasTranslation={el.specialtiesType === '1' ? true : false}
                  />
                )
              })}
            </div>
          </div>
        )
      case 'MultipleNoImage':
        return (
          <div className="section-slider specialties-slider action-cursor">
            <Slider ref={slider} {...sliderSettings}>
              {Array.from(enabledServices).map(([key, el]) => {
                return (
                  <GenericConsultation
                    key={key}
                    specialtiesItem={el}
                    isUserEmailVerified={Boolean(isUserEmailVerified)}
                    hasTranslation={el.specialtiesType === '1' ? true : false}
                  />
                )
              })}
            </Slider>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div className="section">
      <h2 className="text-heading-m font-bold">{headerText}</h2>
      {renderSwitch(type)}
    </div>
  )
}
