import React, { memo, useCallback, useEffect, useState } from 'react'
import cx from 'clsx'

import { ChevronLeftIcon } from '../icons/ChevronLeftIcon'
import { ErrorIcon } from '../icons/ErrorIcon'

export interface PinInputProps {
  inputRef?: any
  symbolNum?: number
  defaultValue?: string
  name: string
  label?: string
  error?: string | null
  required?: boolean
  description?: string | React.ReactNode
}

function wrapRef<T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  wrapper: (refNode: T) => void,
): React.Ref<T> {
  return (refNode: T) => {
    if (typeof ref === 'function') {
      ref(refNode)
    } else if (ref) {
      ref.current = refNode
    }
    wrapper(refNode)
  }
}

export const PinInput = memo<PinInputProps>(function PinInput(props) {
  const [value, setValue] = useState('')
  const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null)
  const [isFocused, setIsFocused] = useState(false)
  const {
    inputRef,
    symbolNum = 8,
    defaultValue,
    name,
    required,
    label,
    error,
    description,
  } = props
  const symb = Array.from(Array(symbolNum).keys())

  const focusInputNode = useCallback(() => {
    inputNode?.focus()
    setIsFocused(true)
  }, [inputNode])
  const blurInputNode = useCallback(() => {
    inputNode?.blur()
    setIsFocused(false)
  }, [inputNode])

  const currentSymbol =
    value.length === symbolNum ? value.length - 1 : value.length

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return (
    <div className="pin-input-root">
      <label className="pin-input-wrapper">
        <h3 className="pin-input-label section-title">
          {label} {Boolean(error) ? <ErrorIcon /> : null}
        </h3>
        {Boolean(description) && (
          <div className="pin-input-description">{description}</div>
        )}
        <input
          name={name}
          autoComplete="off"
          aria-required={required ? 'true' : 'false'}
          aria-label={label}
          aria-invalid={error ? 'true' : 'false'}
          inputMode="numeric"
          onFocus={() => setIsFocused(true)}
          onChange={(e) => {
            const val = e.target.value
            if (val.length > symbolNum) {
              e.target.value = value
              e.preventDefault()
              e.stopPropagation()
              return
            }
            setValue(val)
          }}
          className="pin-input-value-holder"
          ref={(ref) => {
            setInputNode(ref)
            if (typeof inputRef === 'function') {
              inputRef(ref)
            } else if (inputRef && inputRef.current) {
              inputRef.current = ref
            }
          }}
          type="text"
        />
        <div
          onBlur={blurInputNode}
          onClick={focusInputNode}
          className={cx('pin-input-view', isFocused && 'focused')}
        >
          {symb.map((num) => (
            <div
              key={`pin-input-num${num}`}
              className={cx(
                'pin-input-symbol',
                currentSymbol === num && 'current',
              )}
            >
              <span className="pin-input-symbol-value">{value[num] || ''}</span>
            </div>
          ))}
          <button
            type="button"
            onClick={() => {
              if (value.length) {
                const newValue = value.slice(0, -1)
                setValue(newValue)
                if (inputNode) {
                  inputNode.value = newValue
                }
                inputNode?.dispatchEvent(new Event('input'))
                inputNode?.focus()
              }
              focusInputNode()
            }}
            className="btn-link pin-input-btn"
          >
            {' '}
            <ChevronLeftIcon /> Delete
          </button>
        </div>
      </label>

      {error ? (
        <div role="alert" className="error-message">
          {error}
        </div>
      ) : null}
    </div>
  )
})
