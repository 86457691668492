import React, { memo, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import equal from 'fast-deep-equal'
import { datadogLogs } from '@datadog/browser-logs'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { Stepper } from './components/Stepper'
import {
  selectAppointmentBooking,
  selectCurrentBookingSlot,
} from '@core/app/appointment/selectors'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import {
  AppointmentSlot,
  AppointmentType,
} from '@core/shared/types/appointment'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { submitBookingAction } from '@core/app/bookings/actions'
import { selectAppUser } from '@core/app/user/selectors'
import { SideMenu } from '@core/components/app/SideMenu'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import {
  CPSpecialtiesEnum,
  SubmitBookingType,
} from '@core/shared/types/bookings'
import { formatAccessiblePhoneNumber } from '@core/shared/formatters/a11y'
import { RedirectOnPageRefresh } from '@core/components/app/booking/RedirectOnPageRefresh'
import { TermsAndPrivacy } from '@core/components/app/TermsAndPrivacy'
import { getPractitionerLabel } from '@core/components/specialityPractitionerLabels'
import { trackGoal } from '@core/shared/utils/analytics/useMatomo'
import {
  updateUserPatientRecordAction,
  updateDependentPatientRecordAction,
} from '@core/app/patientRecord/actions'
import {
  formatGpData,
  formatPharmacyData,
  serviceIsNotEmpty,
} from '@core/components/healthServiceDataUtils'
import { ServiceValues } from '@core/components/app/HealthServiceDetails/ServiceDetailOptions'
import { usePaymentEligibilityWrapper } from '@core/components/app/paymentEligibility/usePaymentEligibilityWrapper'
import { createAppointmentOrder } from '@core/api/bookings'
import { ConfirmBooking } from './components/ConfirmBooking'

const makeSubmitPayload = (
  bookingSlot: AppointmentSlot,
  booking: Record<string, any>,
) => {
  const submitData: SubmitBookingType = {
    files: booking.files || [],
    data: {
      type: 'bookings',
      attributes: {
        symptoms: booking.symptoms ? [booking.symptoms] : null,
        reasonForBooking: booking.reasonForBooking,
        contactDetails: {
          phoneNumber: booking.contactDetails.phoneNumber,
          address: booking.contactDetails.address,
        },
        consultationType: booking.consultationType,
      },
      relationships: {
        appointment: {
          data: {
            type: 'appointments',
            id: bookingSlot.id,
          },
        },
      },
    },
  }

  if (booking.patient.isDependant) {
    submitData.data.relationships.dependant = {
      data: {
        type: booking.patient.type,
        id: booking.patient.id,
      },
    }
  }

  return submitData
}

const appointmentTypeLabels: Record<string, string> = {
  [AppointmentType.VideoCall]: 'Video call',
  [AppointmentType.PhoneCall]: 'Phone call',
  [AppointmentType.Onsite]: 'Onsite',
}
interface BookStep3PageProps {
  customTermsAndPrivacyComponent?: React.ReactNode
  customTermsAndPrivacyCopy?: {
    privacyLink?: { href?: string }
    termsLink?: { href?: string }
  }
}

export const BookStep3Page = memo<BookStep3PageProps>(function BookStep3Page(
  props,
) {
  const { customTermsAndPrivacyComponent, customTermsAndPrivacyCopy } = props
  const termsAndPrivacyComponent = customTermsAndPrivacyComponent || (
    <TermsAndPrivacy {...customTermsAndPrivacyCopy} />
  )

  const [loading, setLoading] = useState(false)
  const [preparingPayment, setPreparingPayment] = useState(true)
  const [paymentIntent, setPaymentIntent] = useState('')
  const [publishableKey, setPublishableKey] = useState('')
  const [localError, setLocalError] = useState<string>('')

  const { IsPaymentEligibilityEnabled } = usePaymentEligibilityWrapper()

  const { pristine, booking } = useSelector(selectAppointmentBooking)
  const user = useSelector(selectAppUser)
  const bookingSlot = useSelector(selectCurrentBookingSlot)

  const submitBooking = useActionAsync(submitBookingAction)
  const showNotification = useAction(showNotificationAction)
  const updatePatientRecord = useActionAsync(updateUserPatientRecordAction)
  const updateDependentRecord = useActionAsync(
    updateDependentPatientRecordAction,
  )

  const handleUpdatePatientRecord = async (booking: Record<string, any>) => {
    const patientRecord = booking.patient?.attributes
    const pharmacyIsNotEmpty = serviceIsNotEmpty(
      booking?.nominatedPharmacy,
      ServiceValues.PHARMACY,
    )
    const gpData = booking?.isRegisteredWithGP
      ? formatGpData(booking?.gp)
      : null
    const pharmacyData =
      pharmacyIsNotEmpty && formatPharmacyData(booking?.nominatedPharmacy)
    const hasSameGP = gpData
      ? equal(gpData, formatGpData(patientRecord?.gp))
      : false
    const hasSamePharmacy = pharmacyData
      ? equal(
          pharmacyData,
          formatPharmacyData(patientRecord?.nominatedPharmacy),
        )
      : true
    if (!hasSameGP || !hasSamePharmacy) {
      const servicesData = {
        ...(!hasSameGP && { gp: gpData }),
        ...(!hasSamePharmacy && { nominatedPharmacy: pharmacyData }),
      }
      if (booking.patient?.isDependant) {
        await updateDependentRecord({
          data: servicesData,
          patientRecord: booking.patient,
        })
      } else {
        await updatePatientRecord({
          data: servicesData,
          patientRecord: booking.patient,
        })
      }
    }
  }

  const submit = async () => {
    if (!bookingSlot) {
      return
    }
    if (!user) {
      return
    }
    setLocalError('')
    const data = makeSubmitPayload(bookingSlot, booking)
    try {
      setLoading(true)
      if (booking?.patient) {
        await handleUpdatePatientRecord(booking)
      }
      // regular, no payments required. Route via SAPI
      await submitBooking({
        userId: user.id,
        data,
        IsPaymentEligibilityEnabled,
      })
      trackGoal(2) // GP booking
      showNotification({
        type: 'success',
        title: 'Consultation booked',
      })
      history.push(ScreenRoutes.DASHBOARD)
    } catch (e) {
      console.error(e)
      datadogLogs.logger.error(e as string)
      setLocalError(e as string)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (IsPaymentEligibilityEnabled) {
      setLocalError('')
      const loadOrder = async () => {
        if (!user || !bookingSlot) return
        try {
          const order = await createAppointmentOrder(user.id, bookingSlot.id)
          if (
            order.data.isPaymentRequired &&
            order.data.providers.psp.type === 'stripe'
          ) {
            setPaymentIntent(order.data.providers.psp.clientSecret)
            setPublishableKey(order.data.providers.psp.publishableKey)
            setPreparingPayment(false)
          } else {
            // deal with other types of payments e.g. vouchers
          }
        } catch (e) {
          console.error(e)
          datadogLogs.logger.error(e as string)
          setLocalError(
            'We were unable to process your booking. Please try again later.',
          )
          setPreparingPayment(false)
        }
      }

      loadOrder()
    }
  }, [IsPaymentEligibilityEnabled, bookingSlot, user])

  const history = useHistory()
  const { state } = useLocation<{ cpSpecialtiesId: string }>()
  const cpSpecialtiesId = state?.cpSpecialtiesId

  const isCounselling = cpSpecialtiesId === CPSpecialtiesEnum.COUNSELLOR

  if (pristine && !loading) {
    return <RedirectOnPageRefresh />
  }

  const practitionerLabel = getPractitionerLabel(cpSpecialtiesId)

  return (
    <div className="page book-consultation-page">
      <PageHeader showBg backBtn title="Book your consultation" withStepper>
        <Stepper step={3} steps={3} />
      </PageHeader>
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <div className="section mb-2">
            <table className="summary-table">
              <caption>
                <div className="mb-2">
                  <h2 className="section-subtitle mb-1 text-left">
                    Review details
                  </h2>
                  <p className="text-smd text-left">
                    Please confirm the details below are correct
                  </p>
                </div>

                {booking.consultationType !== AppointmentType.Onsite ? (
                  <>
                    <h3 className="text-smd pt-1 video-link-text-message">
                      {booking.consultationType === AppointmentType.VideoCall
                        ? 'Your video link will be sent to:'
                        : `The ${practitionerLabel} will call you on:`}
                    </h3>
                    <p
                      aria-label={
                        booking.consultationType === AppointmentType.VideoCall
                          ? user?.attributes?.email
                          : formatAccessiblePhoneNumber(
                              booking?.contactDetails?.phoneNumber,
                            )
                      }
                      className="section-subtitle mb-1 text-left"
                    >
                      {booking.consultationType === AppointmentType.VideoCall
                        ? user?.attributes?.email
                        : booking?.contactDetails?.phoneNumber}
                    </p>
                  </>
                ) : null}
              </caption>
              <tbody>
                <tr className="table-row">
                  <th scope="row">Patient:</th>
                  <td>
                    {`${booking?.patient?.attributes?.firstName} ${booking?.patient?.attributes?.lastName}`}
                  </td>
                </tr>
                {booking.consultationType === AppointmentType.VideoCall ? (
                  <tr className="table-row">
                    <th scope="row">Preferred contact number: </th>
                    <td
                      aria-label={formatAccessiblePhoneNumber(
                        booking?.contactDetails?.phoneNumber,
                      )}
                      className="cell-value"
                    >
                      {' '}
                      {booking?.contactDetails?.phoneNumber}
                    </td>
                  </tr>
                ) : null}
                <tr className="table-row">
                  <th scope="row">{practitionerLabel}:</th>
                  <td>
                    {
                      bookingSlot?.relationships?.clinicalPractitioner
                        ?.attributes?.firstName
                    }{' '}
                    {
                      bookingSlot?.relationships?.clinicalPractitioner
                        ?.attributes?.lastName
                    }
                  </td>
                </tr>
                <tr className="table-row" data-testid="consultation-type">
                  <th scope="row">Appointment type:</th>
                  <td>{appointmentTypeLabels[booking.consultationType]}</td>
                </tr>
                <tr className="table-row">
                  <th scope="row">Date and time:</th>
                  <td>
                    {dayjs(bookingSlot?.attributes.start).format(
                      'DD MMMM YYYY HH:mm',
                    )}
                  </td>
                </tr>
                {!isCounselling ? (
                  <>
                    <tr className="table-row">
                      <th scope="row">GP practice:</th>
                      <td>
                        {booking?.isRegisteredWithGP
                          ? booking?.gp?.surgery?.name
                          : 'Not registered'}
                      </td>
                    </tr>
                    {booking?.nominatedPharmacy && (
                      <tr className="table-row">
                        <th scope="row">Pharmacy:</th>
                        <td>
                          {booking?.nominatedPharmacy?.name || 'Not provided'}
                        </td>
                      </tr>
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>

          <ConfirmBooking
            preparingPayment={preparingPayment}
            IsPaymentEligibilityEnabled={IsPaymentEligibilityEnabled}
            termsAndPrivacyComponent={termsAndPrivacyComponent}
            localError={localError}
            loading={loading}
            handleSubmit={submit}
            paymentIntent={paymentIntent}
            publishableKey={publishableKey}
          />
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
