import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const PrescriptionIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 25"
      className={cx('prescription-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g stroke="#8A93A1" strokeWidth="1.25">
          <path d="M21.3727,10.8086 L19.9917,12.1896 C19.1437,11.7106 18.1647,11.4506 17.1207,11.4506 C15.5437,11.4506 14.1087,12.0586 13.0537,13.0486 C11.8577,14.1366 11.1187,15.7126 11.1187,17.4526 C11.1187,18.4966 11.3797,19.4756 11.8577,20.3236 L10.8027,21.3776 C8.4767,23.7046 4.7027,23.7046 2.3757,21.3776 C1.2117,20.2146 0.6247,18.6816 0.6247,17.1596 C0.6247,15.6366 1.2117,14.1036 2.3757,12.9396 L12.9447,2.3706 C15.2717,0.0436 19.0457,0.0436 21.3727,2.3706 C23.6997,4.6976 23.6997,8.4816 21.3727,10.8086 Z"></path>
          <line x1="13.0538" y1="13.0488" x2="7.6598" y2="7.6548"></line>
          <path d="M17.1207,11.4502 L17.1207,23.4552 C14.8487,23.4552 12.8697,22.1932 11.8577,20.3232 C11.3797,19.4752 11.1187,18.4962 11.1187,17.4522 C11.1187,15.7132 11.8577,14.1362 13.0537,13.0492 C14.1087,12.0592 15.5437,11.4502 17.1207,11.4502 Z"></path>
          <path d="M23.1232,17.4526 C23.1232,20.7686 20.4372,23.4546 17.1212,23.4546 L17.1212,11.4506 C18.1652,11.4506 19.1432,11.7106 19.9912,12.1896 C21.8622,13.2006 23.1232,15.1796 23.1232,17.4526 Z"></path>
        </g>
      </g>
    </svg>
  )
}
