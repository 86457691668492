import { ErrorIcon } from '../../../icons/ErrorIcon'
import { useServiceValidation } from '../useServiceValidation'
import { ServiceValues } from '../ServiceDetailOptions'

export const PharmacyServiceError = () => {
  const hasError = useServiceValidation(ServiceValues.PHARMACY)

  if (!hasError) {
    return null
  }

  return (
    <div className="section">
      <div className="validation-errors flex-row-center">
        <ErrorIcon />
        <div role="alert" className="tooltip warning ml-1">
          Please input pharmacy details or make sure all required fields of
          pharmacy details are filled in.
        </div>
      </div>
    </div>
  )
}
