import React from 'react'
import clsx from 'clsx'
import styles from './Button.module.scss'
import { useClientVariables } from '../app/features/variables'

export enum ButtonSize {
  large = 'large',
  medium = 'medium',
}

export enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonStyleEnum {
  rounded = 'rounded',
  square = 'square',
  fullRounded = 'fullRounded',
}

export type UseButtonStylesOptions = {
  size: keyof typeof ButtonSize
  type: keyof typeof ButtonVariant
  className?: string
  disabled?: boolean
  endIcon?: boolean
  startIcon?: boolean
}
export const useButtonStyles = ({
  className,
  disabled,
  size,
  type,
  startIcon,
  endIcon,
}: UseButtonStylesOptions) => {
  const { theme } = useClientVariables()

  const combinedStyles = []
  type === 'primary' && combinedStyles.push(styles.primary)
  type === 'secondary' && combinedStyles.push(styles.secondary)
  if (theme?.buttonStyle !== undefined) {
    combinedStyles.push(styles[theme.buttonStyle])
  } else {
    combinedStyles.push(styles.rounded)
  }
  startIcon && combinedStyles.push(styles.startIcon)
  endIcon && combinedStyles.push(styles.endIcon)
  combinedStyles.push(styles[size])

  if (disabled) {
    combinedStyles.push(styles['button--disabled'])
  }

  return clsx(styles.button, ...combinedStyles, className)
}

type Props = {
  label: string
  className?: string
  disabled?: boolean
  size?: keyof typeof ButtonSize
  onClick?: () => void
  // type: defines whether if it's a primary (full background) or secondary (hollow) button
  type: keyof typeof ButtonVariant
  testid?: string
  startIcon?: JSX.Element
  endIcon?: JSX.Element
  ariaLabel?: string
}

export const Button: React.FC<Props> = (props) => {
  const {
    label,
    className,
    disabled,
    size = 'large',
    onClick,
    type,
    testid,
    startIcon,
    endIcon,
    ariaLabel,
  } = props

  const buttonClassName = useButtonStyles({
    className,
    disabled,
    endIcon: Boolean(endIcon),
    size,
    startIcon: Boolean(startIcon),
    type,
  })

  return (
    <button
      type="button"
      className={buttonClassName}
      disabled={disabled}
      onClick={onClick}
      data-testid={testid}
      aria-label={ariaLabel}
    >
      {startIcon}
      {label}
      {endIcon}
    </button>
  )
}
