import { CPSpecialties, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { useFeatures } from './features/features'

/**
 * Hook which indicates whether the only service offered by this client is counselling.
 */
export const useOnlyOffersCounselling = () => {
  const { bookings } = useFeatures()

  const enabledServices = Object.entries(bookings.services.value)
    .filter(([, config]) => config.isEnabled === true)
    .map(([name]) => name)

  return (
    enabledServices.length === 1 &&
    enabledServices.at(0) === CPSpecialties[CPSpecialtiesEnum.COUNSELLOR]
  )
}
