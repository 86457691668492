import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { SideMenu } from '@core/components/app/SideMenu'
import { TermsAndConditionsIcon } from '@core/components/icons/TermsAndConditionsIcon'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

import { text } from '../../shared/data/termsAndConditions/termsAndConditions.json'
import { acceptInitialUserTerms } from '@core/api/user'
import { getCurrentUserAction } from '@core/app/user/actions'
import { getUserIdFromCookie } from '@core/app/auth/AuthService'
import { loginSuccess } from '@core/app/auth/actions'
import { BFFConfigType } from '@core/shared/configuration'

type Props = {
  customPageHeader?: string
  customContent?: Array<string>
  customNextRoute?: string
  customLinks?: {
    terms?: {
      label?: string
      href?: string
    }
    privacy?: {
      label?: string
      href?: string
    }
  }
}

export const TermsAndCondsTemplate = memo(function TermsAndCondsPage(
  props: Props,
) {
  const { customPageHeader, customNextRoute, customContent, customLinks } =
    props

  const history = useHistory()
  const location = useLocation()
  const { bff } = useFeatures()
  const {
    clientVariables: { clientIdentifierParam },
  } = useClientVariables()

  const isLoggedIn = useSelector(isLoggedInSelector)

  const pageHeaderTitle = customPageHeader || text.pageHeader
  const bodyContent = customContent || text.content
  const nextPageRoute = customNextRoute || ScreenRoutes.CREATE_ACCOUNT

  const links = [
    {
      label: customLinks?.terms?.label || text.links.terms,
      href:
        customLinks?.terms?.href ||
        'https://www.healthhero.com/terms-and-conditions/',
    },
    {
      label: customLinks?.privacy?.label || text.links.privacy,
      href:
        customLinks?.privacy?.href ||
        'https://www.healthhero.com/privacy-policy/',
    },
  ]

  const dispatch = useDispatch()

  const goToNextPage = useCallback(async () => {
    const userId = getUserIdFromCookie()

    if (bff === BFFConfigType.CuritySSO && userId) {
      await acceptInitialUserTerms(userId)
      dispatch(getCurrentUserAction(userId))
      dispatch(loginSuccess())
    }

    if (clientIdentifierParam) {
      history.push({
        pathname: nextPageRoute,
        search: location.search,
      })
    } else {
      history.push(nextPageRoute)
    }
  }, [
    bff,
    clientIdentifierParam,
    dispatch,
    history,
    nextPageRoute,
    location.search,
  ])

  return (
    <div className="page terms-and-conds-page">
      <PageHeader backBtn title={pageHeaderTitle} showBg />
      <div className={clsx('page-wrapper', !isLoggedIn && 'no-sidebar')}>
        {isLoggedIn ? <SideMenu /> : null}
        <div
          role="main"
          className={clsx('page-content', !isLoggedIn && 'mx-auto')}
        >
          <div className={clsx('terms px-6')}>
            <div className="mt-12 flex-center">
              <TermsAndConditionsIcon />
            </div>
            <div
              data-testid="tandc-content"
              className="mt-3 text-body-m text-color-primary"
            >
              {bodyContent.map((text, index) => (
                <p key={index} className="mt-2">
                  {text}
                </p>
              ))}
            </div>
            <div
              data-testid="tandc-links"
              className="mt-12 flex flex-column text-center"
            >
              {links.map((link, index) => (
                <a
                  key={link.href}
                  href={link.href}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={clsx('text-color-primary', {
                    'mb-2': index !== links.length - 1,
                  })}
                >
                  {link.label}
                  <RightChevronIcon className="ml-1" />
                </a>
              ))}
            </div>
            {!isLoggedIn && (
              <div className="text-center mt-12">
                <button
                  onClick={goToNextPage}
                  className="btn btn-primary w-full"
                >
                  Agree and continue
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoggedIn ? <PageFooter stickyBottom /> : null}
    </div>
  )
})
