import { memo, useState, useEffect, useRef } from 'react'
import cx from 'clsx'

export interface ListInputProps {
  error?: string | null
  label: string
  inputClassName?: string
  inputWrapperClassName?: string
  defaultValue?: any | any[]
  className?: string
  placeholder?: string
  name?: string
  onChange: (values: any[]) => void
  inputRef?: any
}
const removeItem = (arr: any[], value: any) =>
  arr.filter((item) => item !== value)

export const ListInput = memo<ListInputProps>(function ListInput({
  label,
  className,
  inputClassName,
  inputWrapperClassName,
  defaultValue: defaultValueProps,
  placeholder,
  onChange,
}) {
  const [currentValue, setCurrentValue] = useState('')
  const [values, setValues] = useState<any[]>([])
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    let defaultValue = []

    if (Array.isArray(defaultValueProps)) {
      defaultValue = defaultValueProps
    }

    if (
      typeof defaultValueProps === 'string' ||
      typeof defaultValueProps === 'number'
    ) {
      defaultValue = [defaultValueProps]
    }

    setValues(defaultValue)
  }, [defaultValueProps])

  return (
    <div className={cx('form-control', 'list-input', className)}>
      <label>{label}</label>
      <div className={cx('text-input-wrapper', inputWrapperClassName)}>
        <input
          value={currentValue}
          onInput={(e) => {
            setCurrentValue(e.currentTarget.value)
          }}
          aria-label={label}
          ref={ref}
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.keyCode === 13 && currentValue.trim().length !== 0) {
              const newValues = [...values, currentValue]
              setValues(newValues)
              setCurrentValue('')
              onChange(newValues)
              e.preventDefault()
            }
          }}
          className={cx('text-input', inputClassName)}
        />
        <button
          disabled={currentValue.length === 0}
          type="button"
          aria-label={`click to add to ${label} list`}
          onClick={() => {
            if (ref.current?.value) {
              const newValues = [...values, currentValue]
              setValues(newValues)
              setCurrentValue('')
              onChange(newValues)
            }
          }}
          className="btn btn-primary"
          data-testid="add-button"
        >
          Add
        </button>
      </div>
      <div role="list" className="list">
        {values.map((val) => (
          <div role="listitem" key={`list-input-${val}`} className="list-item">
            <div className="list-item-text"> {val} </div>
            <button
              type="button"
              className="btn list-item-remove"
              aria-label={`click to remove ${val} from list`}
              onClick={() => {
                const newVals = removeItem(values, val)
                setValues(newVals)
                onChange(newVals)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="list-item-remove-icon"
                aria-hidden={true}
              >
                <path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" />
              </svg>
            </button>
          </div>
        ))}
      </div>
    </div>
  )
})
