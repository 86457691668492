import { Action } from '@core/shared/types/Action'
import {
  Patient,
  PatientRecord,
} from '@core/shared/types/patient/PatientRecord'

export enum PatientRecordActionTypes {
  GET_USER_PATIENT_RECORD = 'GET_USER_PATIENT_RECORD',
  GET_USER_PATIENT_RECORD_SUCCESS = 'GET_USER_PATIENT_RECORD_SUCCESS',
  GET_USER_PATIENT_RECORD_FAIL = 'GET_USER_PATIENT_RECORD_FAIL',

  UPDATE_USER_PATIENT_RECORD = 'UPDATE_USER_PATIENT_RECORD',
  UPDATE_USER_PATIENT_RECORD_SUCCESS = 'UPDATE_USER_PATIENT_RECORD_SUCCESS',
  UPDATE_USER_PATIENT_RECORD_FAIL = 'UPDATE_USER_PATIENT_RECORD_FAIL',

  UPDATE_DEPENDENT_PATIENT_RECORD = 'UPDATE_DEPENDENT_PATIENT_RECORD',
  UPDATE_DEPENDENT_PATIENT_RECORD_SUCCESS = 'UPDATE_DEPENDENT_PATIENT_RECORD_SUCCESS',
  UPDATE_DEPENDENT_PATIENT_RECORD_FAIL = 'UPDATE_DEPENDENT_PATIENT_RECORD_FAIL',

  CREATE_DEPENDENT_PATIENT_RECORD = 'CREATE_DEPENDENT_PATIENT_RECORD',
  CREATE_DEPENDENT_PATIENT_RECORD_SUCCESS = 'CREATE_DEPENDENT_PATIENT_RECORD_SUCCESS',
  CREATE_DEPENDENT_PATIENT_RECORD_FAIL = 'CREATE_DEPENDENT_PATIENT_RECORD_FAIL',

  GET_DEPENDENT_PATIENT_RECORD = 'GET_DEPENDENT_PATIENT_RECORD',
  GET_DEPENDENT_PATIENT_RECORD_SUCCESS = 'GET_DEPENDENT_PATIENT_RECORD_SUCCESS',
  GET_DEPENDENT_PATIENT_RECORD_FAIL = 'GET_DEPENDENT_PATIENT_RECORD_FAIL',

  DELETE_DEPENDENT_PATIENT_RECORD = 'DELETE_DEPENDENT_PATIENT_RECORD',
  DELETE_DEPENDENT_PATIENT_RECORD_SUCCESS = 'DELETE_DEPENDENT_PATIENT_RECORD_SUCCESS',
  DELETE_DEPENDENT_PATIENT_RECORD_FAIL = 'DELETE_DEPENDENT_PATIENT_RECORD_FAIL',

  CURRENT_PATIENT_RECORD_AFTER_REGISTER_SUCCESS = 'CURRENT_PATIENT_RECORD_AFTER_REGISTER_SUCCESS',

  SET_TEMPORARY_PATIENT_RECORD = 'SET_TEMPORARY_PATIENT_RECORD',
}

// GetUserPatientRecord
export type GetUserPatientRecordAction = Action<
  typeof PatientRecordActionTypes.GET_USER_PATIENT_RECORD,
  { userId: string }
>
export type GetUserPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.GET_USER_PATIENT_RECORD_SUCCESS,
  PatientRecord
>

export type GetUserPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.GET_USER_PATIENT_RECORD_FAIL
>

// UpdateUserPatientRecord
export type UpdateUserPatientRecordAction = Action<
  typeof PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD,
  { patientRecord: PatientRecord; data: Partial<Patient> }
>

export type UpdateUserPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD_SUCCESS,
  PatientRecord
>

export type UpdateUserPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD_FAIL
>

// UpdateDependentPatientRecord
export type UpdateDependentPatientRecordAction = Action<
  typeof PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD,
  { patientRecord: PatientRecord; data: Partial<Patient> }
>

export type UpdateDependentPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  PatientRecord
>

export type UpdateDependentPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD_FAIL
>

// CreateDependentPatientRecord
export type CreateDependentPatientRecordAction = Action<
  typeof PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD,
  Patient
>

export type CreateDependentPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  PatientRecord
>

export type CreateDependentPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD_FAIL
>

// GetDependentPatientRecord
export type GetDependentPatientRecordAction = Action<
  typeof PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD
>

export type GetDependentPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD_SUCCESS,
  PatientRecord[]
>

export type GetDependentPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD_FAIL
>

// DeleteDependentPatientRecord
export type DeleteDependentPatientRecordAction = Action<
  typeof PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD,
  { dependentId: string }
>

export type DeleteDependentPatientRecordSuccessAction = Action<
  typeof PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD_SUCCESS,
  { dependentId: string }
>

export type DeleteDependentPatientRecordFailAction = Action<
  typeof PatientRecordActionTypes.DELETE_DEPENDENT_PATIENT_RECORD_FAIL,
  { dependentId: string }
>

export type CurrentPatientRecordAfterRegisterSuccessAction = Action<
  typeof PatientRecordActionTypes.CURRENT_PATIENT_RECORD_AFTER_REGISTER_SUCCESS,
  PatientRecord
>

export type SetTemporaryPatientRecordRequestAction = Action<
  typeof PatientRecordActionTypes.SET_TEMPORARY_PATIENT_RECORD,
  {
    patientRecord: Patient | null
    serviceType: string
  }
>
