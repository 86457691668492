export function getLandingSliderSettings(config: any, handleSlideChange: any) {
  return {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: config.speed || 3500,
    autoplaySpeed: config.speed || 3500,
    pauseOnHover: false,
    className: 'slick-slide-content',
    afterChange: (num: number) => handleSlideChange(num),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          autoplay: false,
          speed: 500,
        },
      },
    ],
  }
}
