import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const DownloadAttachmentIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('download-attachment-icon', className)}
      role="img"
    >
      <title>Download</title>
      <path
        d="M6.77192 0.186997V12.8352L1.67718 7.68615L0.648926 8.72522L6.985 15.1287C7.26933 15.416 7.72892 15.416 8.01325 15.1287L14.3507 8.72522L13.3224 7.68615L8.22625 12.8352V0.186264H6.77192V0.186997Z"
        fill="#F4616B"
      />
      <path d="M0 13.4737H3.33333L5 15.1579H0V13.4737Z" fill="#F4616B" />
      <path
        d="M11.6667 13.4737H15V15.1579H10L11.6667 13.4737Z"
        fill="#F4616B"
      />
    </svg>
  )
}
