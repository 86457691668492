import { memo, useCallback, useEffect, useRef } from 'react'

// KeyboardTrap prevents the user from tabbing outside of the popper
// It creates a tabindex loop so that "Tab" on the last element will focus the first element
// and "Shift Tab" on the first element will focus the last element

const focusableElementsSelector =
  '[tabindex], a, button, input, select, textarea'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const focusableFilter = (node: any) => !node.disabled && node.tabIndex !== -1

export const KeyboardTrap = memo(function KeyboardTrap(props) {
  const tabLoopRef = useRef<HTMLDivElement | null>(null)
  const getTabChildren = useCallback(
    () =>
      Array.prototype.slice
        .call(
          tabLoopRef.current?.querySelectorAll(focusableElementsSelector) || [],
          1,
          -1,
        )
        .filter(focusableFilter),
    [tabLoopRef],
  )

  const handleFocusStart = useCallback(() => {
    const tabChildren = getTabChildren()
    tabChildren &&
      tabChildren.length > 1 &&
      tabChildren[tabChildren.length - 1].focus()
  }, [getTabChildren])

  const handleFocusEnd = useCallback(() => {
    const tabChildren = getTabChildren()
    tabChildren && tabChildren.length > 1 && tabChildren[0].focus()
  }, [getTabChildren])

  useEffect(() => {
    handleFocusEnd()
  }, [handleFocusEnd])

  return (
    <div className="tab-loop" ref={tabLoopRef}>
      <div
        className="tab-loop__start"
        tabIndex={0}
        onFocus={handleFocusStart}
      />
      {props.children}
      <div className="tab-loop__end" tabIndex={0} onFocus={handleFocusEnd} />
    </div>
  )
})
