/* eslint-disable @typescript-eslint/no-explicit-any */
export const removeEmptyProperties = (obj: Record<string, any>) => {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === 'object') {
      //recursive for objects
      removeEmptyProperties(obj[key])
    } else if (obj[key] == null || obj[key] === '') {
      delete obj[key] //remove empty properties
    }

    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
      delete obj[key] //remove empty objects
  })
}

export const deepCopy = <T>(inObject: T): T => {
  let outObject, value, key

  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  // eslint-disable-next-line prefer-const
  outObject = Array.isArray(inObject) ? [] : {}

  for (key in inObject) {
    //@ts-ignore
    value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    //@ts-ignore
    outObject[key] = deepCopy(value)
  }
  //@ts-ignore
  return outObject
}

export const isEmptyObject = (obj: object) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false
  }
  return true
}

const alnum = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
export const randomString = (size: number): string => {
  let output = ''

  for (let i = 0; i < size; i++) {
    output += alnum.charAt(Math.floor(Math.random() * alnum.length))
  }

  return output
}
