import { createSelector } from 'reselect'

import { AppState } from '..'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { selectUserPatientRecord } from '../patientRecord/selectors'
import { GpGender } from '@core/shared/types/appointment'
import { PatientRecord } from '@core/shared/types/patient/PatientRecord'
import { deepCopy, isEmptyObject } from '@core/shared/lang'

dayjs.extend(utc)

const defaultBooking = {
  gpGender: GpGender.Either,
  contactDetails: {
    address: {},
  },
}

export const selectAppointmentBooking = (
  state: AppState,
): { pristine: boolean; booking: Record<string, any> } => {
  const pristine = !state.appointment.newBooking
  const booking = !state.appointment.newBooking
    ? defaultBooking
    : state.appointment.newBooking
  return {
    pristine,
    booking,
  }
}

export const selectBookingDates = (state: AppState) => {
  const map: Record<string, boolean> = {}
  return state.appointment.bookingDates.filter((bookDate) => {
    const date = dayjs(bookDate.attributes.start).utc().format('YYYY-MM-DD')

    if (!map[date]) {
      map[date] = true
      return true
    }
    return false
  })
}

export const selectUniqueBookingTimes = (state: AppState) => {
  // Description:
  //    we have a set of booking slots on ApppointmentSummary
  //    which grouped by booking slot duration
  //    so, "2020-12-12 12:00-12:15" and "2020-12-12 12:00-12:13" are different slots
  //    BUT!
  //    What we actually require is booking slots grouped by start datetime
  //    Thats what this selector does
  const map: Record<string, boolean> = {}
  return state.appointment.bookingDates.filter((bookDate) => {
    const date = bookDate.attributes.start

    if (!map[date]) {
      map[date] = true
      return true
    }
    return false
  })
}

export const selectAppointmentBookingSlots = (state: AppState) =>
  state.appointment.bookingSlots

export const selectCurrentBookingSlot = createSelector(
  selectAppointmentBooking,
  selectAppointmentBookingSlots,
  (newBooking, bookingSlots) => {
    const id = newBooking.booking.appointmentSlotId
    return bookingSlots.find((slot) => slot.id === id)
  },
)

const defaultDetails = {
  address: '',
  phoneNumber: '',
  email: '',
  dob: '',
}
export const getPatientDetails = (patient?: PatientRecord) => {
  if (!patient) {
    return defaultDetails
  }
  const { addressLine1, addressLine2, city, postCode } =
    patient.attributes.address
  const addressParts: string[] = []
  addressParts.push(addressLine1)
  if (addressLine2) {
    addressParts.push(addressLine2)
  }
  addressParts.push(city)
  addressParts.push(postCode)

  const { email, dateOfBirth, phoneNumber } = patient.attributes
  const dob = dayjs(dateOfBirth).format('DD/MM/YYYY')
  const address = addressParts.join(', ')

  return {
    address,
    dob,
    email,
    phoneNumber,
  }
}

export const selectUserDetails = createSelector(
  selectUserPatientRecord,
  getPatientDetails,
)

// Used on bookingStep1
export const selectBookingDatesValues = createSelector(
  selectBookingDates,
  (dates) =>
    dates.map((date) => ({
      value: date.attributes.start,
      label: dayjs(date.attributes.start).format('D[.] ddd '),
      iso: dayjs(date.attributes.start).format('YYYY-MM-DD'),
    })),
)

// Used on bookingStep1
const emptyArray: Array<{ value: string; label: string }> = []
// This holds the appointment times available when you select a day
export const selectBookingSlots = createSelector(
  selectUniqueBookingTimes,
  (state: AppState) => state.appointment.selectedBookingDate,
  (dates, selectedDate) => {
    if (!dates.length || !selectedDate) {
      return emptyArray
    }

    const slotDate = dayjs(selectedDate).format('YYYY-MM-DD')

    const bookingSlots = dates
      .filter(
        (slot) =>
          dayjs(slot.attributes.start).format('YYYY-MM-DD') === slotDate,
      )
      .sort((a, b) => {
        return (
          dayjs(a.attributes.start).unix() - dayjs(b.attributes.start).unix()
        )
      })
      .map((slot) => {
        return {
          // get first available appointment id from appointmentSummary
          value: (slot.attributes.appointmentIds[0] || '').toString(),
          label: dayjs(slot.attributes.start).format('HH:mm'),
        }
      })

    return bookingSlots
  },
)

// Used on bookingStep1
export const selectInitialBooking = createSelector(
  selectAppointmentBooking,
  selectUserPatientRecord,
  ({ booking }, patientRecord) => {
    const newBooking = deepCopy(booking)

    if (isEmptyObject(newBooking.contactDetails.address)) {
      newBooking.contactDetails.address = {
        ...patientRecord?.attributes.address,
      }
    }
    return newBooking
  },
)

export const selectSelectededDay = (state: AppState) =>
  state.appointment.selectedBookingDate
