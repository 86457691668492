import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import cx from 'clsx'

import { selectUserDetails } from '@core/app/appointment/selectors'
import { selectUserPatientRecord } from '@core/app/patientRecord/selectors'
import { useAction } from '@core/shared/useAction'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { logout as logoutAction } from '@core/app/auth/actions'
import { isExternalUrl } from '@core/shared/url'
import { ForYouIcon } from '../icons/ForYouIcon'
import { ArticlesIcon } from '../icons/ArticlesIcon'
import { RightChevronIcon } from '../../components/icons/RightChevron'
import { Feature, useFeatures } from './features/features'
import { useClientVariables } from './features/variables'
import { rootNav } from './navData'

export const useProfileNavItems = () => {
  const { about, dependants, hasStandardAuth } = useFeatures()
  const { clientData } = useClientVariables()

  const isAboutEnabled = about.isEnabled

  const items: Array<{ title: string; routePath: string }> = []

  if (dependants) {
    items.push({
      title: 'Dependants',
      routePath: ScreenRoutes.DEPENDENTS,
    })
  }

  if (hasStandardAuth) {
    items.push({
      title: 'Security',
      routePath: ScreenRoutes.CHANGE_PASSWORD,
    })
  }

  if (isAboutEnabled) {
    items.push({
      title: clientData?.navigation?.about?.title ?? 'About',
      routePath: ScreenRoutes.ABOUT,
    })
  }

  items.push({
    title: clientData?.navigation?.tandc?.title ?? 'Terms and conditions',
    routePath: ScreenRoutes.TERMS_AND_CONDS,
  })

  return items
}
export const SideMenu = memo(function SideMenu() {
  const userDetails = useSelector(selectUserDetails)
  const userRecord = useSelector(selectUserPatientRecord)
  const logout = useAction(logoutAction)

  const history = useHistory()
  const match = useRouteMatch()
  const { hasStandardAuth } = useFeatures()
  const { clientData } = useClientVariables()

  const homeTitle = clientData?.navigation?.home?.title ?? 'Home'

  const ActualForYouIcon = clientData?.navigation?.home?.Icon ?? ForYouIcon

  const ActualArticlesIcon =
    clientData?.navigation?.articles?.Icon ?? ArticlesIcon

  const profileNavItems = useProfileNavItems()

  const signOutItem = {
    title: clientData?.navigation?.signOut?.title ?? 'Sign out',
    routePath:
      clientData?.navigation?.signOut?.redirectUrl ?? ScreenRoutes.HOME,
    testid: 'sidebar-signout',
  }

  return (
    <div
      role="navigation"
      className="side-menu"
      data-testid="side-menu-navigation"
    >
      <div className="side-menu-inner-part">
        <div className="section">
          <div className="paper w-full">
            <div className="paper-content w-full">
              <div className="profile-menu-list-wrapper list-nav">
                <ul className="profile-menu-list">
                  <li className="profile-menu-list-item">
                    <Link
                      to={ScreenRoutes.FOR_YOU}
                      className="profile-menu-list-item-link"
                    >
                      <ActualForYouIcon
                        color={'#000'}
                        isActive={rootNav.home.routes.includes(match.path)}
                      />
                      <p className="paper-header-top text-body-l font-bold">
                        {' '}
                        {homeTitle}
                      </p>
                      <div>
                        <RightChevronIcon />
                      </div>
                    </Link>
                  </li>
                  <Feature name="articles">
                    <li className="profile-menu-list-item">
                      <Link
                        to={ScreenRoutes.ARTICLES}
                        className="profile-menu-list-item-link"
                      >
                        <ActualArticlesIcon
                          isActive={rootNav.articles.routes.includes(
                            match.path,
                          )}
                        />
                        <p className="text-body-l font-bold paper-header-top">
                          Articles
                        </p>
                        <div>
                          <RightChevronIcon />
                        </div>
                      </Link>
                    </li>
                  </Feature>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="paper w-full">
            <div className="paper-content w-full">
              <Feature name="hasStandardAuth">
                <Link
                  to={ScreenRoutes.EDIT_PROFILE}
                  className="edit-profile-nav-item"
                  aria-label="Edit profile"
                >
                  <div className="paper-header text-body-l font-bold flex-row space-between ">
                    <p>Profile</p>
                    <span className="profile-edit-text text-body-m font-bold">
                      Edit
                    </span>
                  </div>
                  <div className="profile-info-wrapper">
                    <ul className="user-details paper-text text-color-secondary text-sm mt-2 mb-1">
                      <li className="user-details-line text-weight-md text-color-primary">
                        {userRecord?.attributes.firstName}{' '}
                        {userRecord?.attributes.lastName}
                      </li>
                      <li className="user-details-line  text-clipping">
                        {userDetails.phoneNumber}
                      </li>
                      <li className="user-details-line text-clipping">
                        {userDetails.email}
                      </li>
                      <li className="user-details-line">
                        <address>{userDetails.address}</address>
                      </li>
                    </ul>
                  </div>
                </Link>
              </Feature>

              <div
                className={cx(
                  'profile-menu-list-wrapper',
                  hasStandardAuth && 'mt-2',
                )}
              >
                <ul className="profile-menu-list profile-nav second-profile-menu-list">
                  {profileNavItems.map((el, index) => (
                    <li className="profile-menu-list-item" key={index}>
                      <Link
                        to={el.routePath}
                        className="profile-menu-list-item-link"
                      >
                        <p className="text-body-l font-bold">{el.title}</p>
                        <div>
                          <RightChevronIcon />
                        </div>
                      </Link>
                    </li>
                  ))}
                  <li className="profile-menu-list-item">
                    {isExternalUrl(signOutItem.routePath) ? (
                      <a
                        href={signOutItem.routePath}
                        data-testid={signOutItem.testid}
                        className="profile-menu-list-item-link"
                      >
                        <p className="text-body-l font-bold">
                          {signOutItem.title}
                        </p>
                      </a>
                    ) : (
                      <button
                        type="button"
                        data-testid={signOutItem.testid}
                        className="profile-menu-list-item-link"
                        onClick={() => {
                          logout()
                          history.push(ScreenRoutes.HOME)
                        }}
                      >
                        <p className="text-body-l font-bold">
                          {signOutItem.title}
                        </p>
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
