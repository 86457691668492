import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const DeletePhotoIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 76 76"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('delete-photo-icon', className)}
      role="img"
    >
      <title>Delete</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#8A93A1" fillRule="nonzero">
          <path d="M72.8351429,12.6648571 L66.5,12.6648571 L66.5,12.6648571 L56.3051429,12.6648571 L55.2628571,8.50114286 C54.0197143,3.50685714 49.5248571,0 44.3785714,0 L31.6214286,0 C26.4751429,0 21.9802857,3.50685714 20.7371429,8.50114286 L19.6948571,12.6648571 L9.5,12.6648571 L9.5,12.6648571 C9.5,12.6648571 9.5,12.6648571 9.5,12.6648571 L3.16485714,12.6648571 C1.41685714,12.6648571 0,14.0871429 0,15.8351429 C0,17.5831429 1.41685714,19 3.16485714,19 L6.50342857,19 L9.17428571,67.0265714 C9.45114286,72.0588571 13.6202857,76 18.6634286,76 L57.3365714,76 C62.3797143,76 66.5488571,72.0588571 66.8257143,67.0265714 L69.4965714,19 L72.8351429,19 C74.5831429,19 76,17.5831429 76,15.8351429 C76,14.0871429 74.5831429,12.6648571 72.8351429,12.6648571 Z M26.8768571,10.0374286 C27.4251429,7.86057143 29.3794286,6.33514286 31.6214286,6.33514286 L44.3785714,6.33514286 C46.6205714,6.33514286 48.5748571,7.86057143 49.1231429,10.0374286 L49.78,12.6648571 L26.22,12.6648571 L26.8768571,10.0374286 Z M60.5068571,66.6737143 C60.4091429,68.3511429 59.0194286,69.6648571 57.3365714,69.6648571 L18.6634286,69.6648571 C16.9805714,69.6648571 15.5908571,68.3511429 15.4985714,66.6737143 L12.8494286,19 L22.1648571,19 L53.8351429,19 L63.1505714,19 L60.5068571,66.6737143 Z"></path>
          <path d="M24.4978578,27 C23.1182519,27 22,28.4168571 22,30.1648571 L22,61.8351429 C22,63.5831429 23.1182519,65 24.4978578,65 C25.8817481,65 27,63.5831429 27,61.8351429 L27,30.1648571 C27,28.4168571 25.8817481,27 24.4978578,27 Z"></path>
          <path d="M37.4978578,27 C36.1182519,27 35,28.4168571 35,30.1648571 L35,61.8351429 C35,63.5831429 36.1182519,65 37.4978578,65 C38.8774636,65 40,63.5831429 40,61.8351429 L40,30.1648571 C40,28.4168571 38.8774636,27 37.4978578,27 Z"></path>
          <path d="M51.4978578,27 C50.1182519,27 49,28.4168571 49,30.1648571 L49,61.8351429 C49,63.5831429 50.1182519,65 51.4978578,65 C52.8774636,65 54,63.5831429 54,61.8351429 L54,30.1648571 C54,28.4168571 52.8774636,27 51.4978578,27 Z"></path>
        </g>
      </g>
    </svg>
  )
}
