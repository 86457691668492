import { CPSpecialtiesEnum, CPSpecialties } from '@core/shared/types/bookings'
import { GpConsultationIcon } from './icons/GpConsultationIcon'
import { EmotionalWellbeingIcon } from './icons/EmotionalWellbeing'
import { SecondMedicalOpinionIcon } from './icons/SecondMedicalOpinion'
import { AchesAndPainsIcon } from './icons/AchesAndPainsIcon'
import { LegalSupportIcon } from './icons/LegalSupportIcon'
import { VideoCallIcon } from './icons/VideoCallIcon'
import { PhoneCallIcon } from './icons/PhoneCallIcon'
import { ServicesWayIcon } from './icons/ServicesWayIcon'
import { ServicesPhoneIcon } from './icons/ServicesPhoneIcon'
import { BookingType } from './app/features/Context'

export interface BodyItemsType {
  title: string
  Icon: (props: { className?: string }) => JSX.Element
  type: BookingType
}

export interface PageBodyItemsType {
  pageBodyText: string
  pageBodyIcon: null | ((props: { className?: string }) => JSX.Element)
}

export interface SpecialtiesItemsType {
  specialtiesType: string
  specialtiesTypeName: string
  headerTitle: string
  pageTitle?: string
  pageBody?: PageBodyItemsType[]
  pageFooter?: {
    pageFooterTitle: string
    pageFooterText: string
  }[]
  friendlyName: string
  description: string
  bodyIcons: BodyItemsType[]
  HeaderIcon: (props: { className?: string }) => JSX.Element
  /** Default duration of a consultation for this specialty, in minutes. */
  defaultDuration?: number
}

export const specialtiesItems = new Map<string, SpecialtiesItemsType>([
  [
    'GeneralPractitioner',
    {
      specialtiesType: CPSpecialtiesEnum.GENERAL_PRACTITIONER,
      specialtiesTypeName:
        CPSpecialties[CPSpecialtiesEnum.GENERAL_PRACTITIONER],
      HeaderIcon: GpConsultationIcon,
      headerTitle: 'GP consultations',
      friendlyName: 'GP',
      description: 'Book a video or phone consultation with a GP.',
      bodyIcons: [
        {
          Icon: VideoCallIcon,
          title: 'Online',
          type: 'online',
        },
        {
          Icon: PhoneCallIcon,
          title: 'Phone call',
          type: 'phone',
        },
      ],
      defaultDuration: 12,
    },
  ],
  [
    'Counsellor',
    {
      specialtiesType: CPSpecialtiesEnum.COUNSELLOR,
      specialtiesTypeName: CPSpecialties[CPSpecialtiesEnum.COUNSELLOR],
      HeaderIcon: EmotionalWellbeingIcon,
      headerTitle: 'Counselling',
      friendlyName: 'Counsellor',
      description:
        'Our counsellors are here to teach you how to maintain a healthy emotional balance.',
      bodyIcons: [
        {
          Icon: VideoCallIcon,
          title: 'Online',
          type: 'online',
        },
      ],
      pageTitle:
        'Book a session with an onsite counsellor to help with your emotional wellbeing.',
      pageBody: [
        {
          pageBodyIcon: ServicesWayIcon,
          pageBodyText:
            'We arrange a 60 minute onsite consultation with an experienced counsellor at a convenient time.',
        },
        {
          pageBodyIcon: ServicesPhoneIcon,
          pageBodyText:
            'The counsellor will listen to your concerns and provide helpful advice, coping strategies and sign-posting to resources that support you in maintaining good emotional wellbeing.',
        },
      ],
      pageFooter: [],
      defaultDuration: 60,
    },
  ],
  [
    'Physiotherapist',
    {
      specialtiesType: CPSpecialtiesEnum.PHYSIOTHERAPIST,
      specialtiesTypeName: CPSpecialties[CPSpecialtiesEnum.PHYSIOTHERAPIST],
      HeaderIcon: AchesAndPainsIcon,
      headerTitle: 'Aches & Pains',
      friendlyName: 'Physiotherapist',
      description:
        'An Aches & Pains adviser will provide professional advice on the effective management of aches and pains.',
      bodyIcons: [
        {
          Icon: VideoCallIcon,
          title: 'Online',
          type: 'online',
        },
        {
          Icon: PhoneCallIcon,
          title: 'Phone call',
          type: 'phone',
        },
      ],
      pageTitle:
        'Designed to help alleviate and prevent suffering from general aches and pains.',
      pageBody: [
        {
          pageBodyIcon: ServicesWayIcon,
          pageBodyText:
            'We arrange a 30 minute phone consultation with an experienced adviser at a convenient time.',
        },
        {
          pageBodyIcon: ServicesPhoneIcon,
          pageBodyText:
            'Assessments last 30 minutes, allowing our adviser to understand the specific nature of the problem and to supply appropriate advice and where necessary, sign-posting to useful resources.',
        },
      ],
      pageFooter: [
        {
          pageFooterTitle: 'How is muscle or bone pain treated remotely?',
          pageFooterText:
            '<p>Many general aches and pains can be addressed via guided ‘self-help’ from an experienced adviser. Where it’s beneficial, the adviser will share remedial and lifestyle information, exercises and guided videos to support you in feeling better, faster. This service is not designed to treat more significant conditions or provide ongoing treatment, however, where necessary, our advisers will signpost the most appropriate course of action for the individual.</p>',
        },
        {
          pageFooterTitle: 'Why is preventing aches & pains important?',
          pageFooterText:
            '<p>Modern working life has led to many of us leading increasingly sedentary lifestyles, spending too much time sitting at desks and working at computer screens. Over time, this inactivity can lead to postural problems, muscular imbalance and general discomfort, such as back and neck pain. Our advisers, who may include Physiotherapists and Sports Therapists, can provide early intervention and education to help you make proactive changes and reduce the risk of minor problems becoming more serious.</p>',
        },
        {
          pageFooterTitle: 'What experience do the advisers have?',
          pageFooterText:
            '<p>At the specified time, you’ll receive a call from one of our highly experienced multidisciplinary team. They have a variety of skills, experience and qualifications, are all registered with the appropriate professional bodies, undertake regular CPD, internal training and have routine supervision sessions. In addition, each of our advisers has a minimum of two years’ experience in performing their specialism remotely.</p>',
        },
      ],
    },
  ],
  [
    'SecondOpinion',
    {
      specialtiesType: CPSpecialtiesEnum.SECOND_OPINION,
      specialtiesTypeName: CPSpecialties[CPSpecialtiesEnum.SECOND_OPINION],
      HeaderIcon: SecondMedicalOpinionIcon,
      headerTitle: 'Second Medical Opinion',
      friendlyName: 'Second Medical Opinion',
      description:
        'Access to a network of consultations to review your diagnosis and treatment plan.',
      bodyIcons: [
        {
          Icon: VideoCallIcon,
          title: 'Online',
          type: 'online',
        },
        {
          Icon: PhoneCallIcon,
          title: 'Phone call',
          type: 'phone',
        },
      ],
      pageTitle:
        'Access to a network of consultants to review your diagnosis and treatment plan.',
      pageBody: [
        {
          pageBodyIcon: null,
          pageBodyText:
            'If you have been diagnosed with a condition but would like a second opinion on the diagnosis or treatment, we can organise face-to-face or video consultations with a specialist consultant.',
        },
        {
          pageBodyIcon: null,
          pageBodyText:
            'We work with an extensive network of clinical specialists across the UK, which means experts, particularly in cancer care, cardiology and orthopaedics, are available to provide patients with more clarity regarding treatment options and diagnosis.',
        },
      ],
      pageFooter: [
        {
          pageFooterTitle: 'How is the consultation delivered?',
          pageFooterText:
            '<p>A second opinion can be delivered in a variety of ways (where appropriate), including:</p><ul><li>Face-to-face consultation</li><li>Telephone consultation</li><li>Secure video-link consultation</li><li>"Desktop" review of medical information</li><li>Multi-disciplinary team review</li></ul><p>This will give patients the opportunity to ask questions of the specialist, such as:</p><ul><li>What is your expert opinion on my diagnosis?</li><li>Can I discuss proposed treatment?</li><li>Are there any alternative treatments?</li><li>Is there more information available about my condition?</li></ul>',
        },
        {
          pageFooterTitle: 'What is the process?',
          pageFooterText:
            '<p>The first step of the SMO process is a remote (phone/video) consultation with a HealthHero GP. This is to obtain an understanding of the patient’s condition and areas of concern, and to assess suitability for a SMO with an appropriate specialist. It may be that the advice and guidance provided by the GP is sufficient to reassure the patient and that a SMO with a specialist is not indicated. If a SMO is appropriate then the GP will capture the required information from the patient to initiate the SMO process.</p><p>The SMO will be delivered by an approved medical doctor who holds, or has held, the position of consultant within the NHS and is on the specialist register of the UK General Medical Council.</p>',
        },
        {
          pageFooterTitle: 'What are the criteria to access the SMO service?',
          pageFooterText:
            '<p>For a SMO to be undertaken, patients will need to meet three key requirements:</p><ul><li>All the investigations requested by the patient’s treating consultant have been completed and the results are available and accessible to both the patient and treating doctor</li><li>The patient’s treating consultant has made a firm diagnosis, or concluded that he/she is unable to explain the cause of the condition and has exhausted all relevant and available diagnostic tests</li><li>The patient can gain access to the relevant medical records from the treating consultant, which explains the results of all the tests and the diagnosis.</li></ul>',
        },
        {
          pageFooterTitle:
            '* Eligible conditions for specialist Second Medical Opinion',
          pageFooterText:
            '<ul><li>Alzheimer’s disease</li><li>Aortic surgery</li><li>Aplastic anaemia</li><li>Bacterial meningitis</li><li>Benign brain tumour</li><li>Blindness</li><li>Cancer</li><li>Coma</li><li>Coronary artery- bypass surgery</li><li>Deafness</li><li>Heart attack</li><li>Heart valve replacement</li><li>Kidney failure</li><li>Loss of independent existence</li><li>Loss of limbs</li><li>Loss of speech</li><li>Major organ transplant</li><li>Major organ failure on waiting list</li><li>Motor neuron disease</li><li>Multiple sclerosis</li><li>Occupational HIV infection</li><li>Paralysis</li><li>Parkinson’s disease</li><li>Severe burns</li><li>Stroke (Cerebrovascular accident)</li></ul>',
        },
      ],
    },
  ],
  [
    'LegalServices',
    {
      specialtiesType: CPSpecialtiesEnum.LEGAL_SUPPORT,
      specialtiesTypeName: CPSpecialties[CPSpecialtiesEnum.LEGAL_SUPPORT],
      HeaderIcon: LegalSupportIcon,
      headerTitle: 'Legal Support',
      friendlyName: 'Legal Support',
      description:
        'Speak to a specialist for information on a range of personal law matters, including consumer, property and family law.',
      bodyIcons: [
        {
          Icon: PhoneCallIcon,
          title: 'Phone call',
          type: 'phone',
        },
      ],
    },
  ],
])
