export const loadReCaptcha = (siteKey: string) => {
  if (window.grecaptcha) {
    return
  }

  const script = document.createElement('script')

  script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`

  document.body.appendChild(script)
}

//Generic message return by handler on any token validation related failure
const GENERIC_RECAPTCHA_ERROR_MESSAGE = 'Invalid recaptcha response code.'
const OVERRIDE_RECAPTCHA_ERROR_MESSAGE =
  'We’re experiencing an issue with your registration. Please try again later, or use a different browser/ device to retry.'
export const rewriteRecaptchaErrorMessage = (
  errorCodes: Record<string, string>[],
) => {
  return errorCodes.map((e) =>
    e.message === GENERIC_RECAPTCHA_ERROR_MESSAGE
      ? { message: OVERRIDE_RECAPTCHA_ERROR_MESSAGE }
      : e,
  )
}
