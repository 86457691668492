import { memo } from 'react'
import cx from 'clsx'
import setMonth from 'date-fns/setMonth'

import { DatepickerNav } from '../useDatepickerNavigation'
import { getMonthShortInLocale } from '../utils'

import { DatePickerView } from '../DatePicker'

export interface MonthViewProps {
  selectedDate: Date
  onSelect: (date: Date) => void
  chooseDayAriaLabelPrefix?: string
  disabledDayAriaLabelPrefix?: string
  min?: Date
  max?: Date
  inline?: boolean
  locale: string | Locale

  fixedHeight?: boolean
  peekNextMonth?: boolean
  nav: DatepickerNav
  yearItemNumber?: number
  currentView: DatePickerView
  setCurrentView: (view: DatePickerView) => void
}

const dayClassNames = {
  static: 'datepicker-month-view__month',
  selected: 'datepicker-month-view__month--selected',
  disabled: 'datepicker-month-view__month--disabled',
}

function getClassNames({
  selectedDate,
  month,
}: {
  selectedDate: Date
  month: number
  min?: Date
  max?: Date
}) {
  return cx(
    dayClassNames.static,
    month === selectedDate.getMonth() && dayClassNames.selected,
    // isMonth(year, { min, max }) && dayClassNames.disabled,
  )
}
export const MonthView = memo<MonthViewProps>(function MonthView(props) {
  const { selectedDate, onSelect, min, max, locale } = props

  const monthsThreeColumns = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [9, 10, 11],
  ]

  const handleOnSelect = (month: number) => {
    const newDate = setMonth(selectedDate, month)
    onSelect(newDate)
  }
  return (
    <div className="datepicker-view__month--container">
      <div className="datepicker-view datepicker-view__month">
        {monthsThreeColumns.map((monthRow, idx) => (
          <div key={idx} className="datepicker-view__month--row">
            {monthRow.map((month) => (
              <button
                className={getClassNames({ min, max, selectedDate, month })}
                key={month}
                // disabled={min && max && isYearDisabled(year, { min, max })}
                onClick={() => handleOnSelect(month)}
                type="button"
              >
                {getMonthShortInLocale(month, locale)}
              </button>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
})
