import { produce } from 'immer'

import {
  ArticlesActionTypes,
  GetLiveWellSuccessAction,
  GetLiveWellRequestAction,
  GetMedicalInformationRequestAction,
  GetMedicalInformationSuccessAction,
  GetMedicalInformationByUrlSuccessAction,
  GetMedicalInformationMoreSuccessAction,
} from './actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

export interface RArticles {
  loadedArticle: null | any
  medicalInformation: null | any
}

export const initialArticles = (): RArticles => ({
  loadedArticle: null,
  medicalInformation: null,
})

type ArticlesActions =
  | GetLiveWellSuccessAction
  | GetLiveWellRequestAction
  | GetMedicalInformationRequestAction
  | GetMedicalInformationSuccessAction
  | GetMedicalInformationByUrlSuccessAction
  | GetMedicalInformationMoreSuccessAction
  | LogoutAction

const articlesReducer = produce(function (
  draft: RArticles,
  action: ArticlesActions,
) {
  switch (action.type) {
    case ArticlesActionTypes.GET_LIVE_WELL_SUCCESS: {
      return {
        ...draft,
        loadedArticle: action.payload,
      }
    }

    case ArticlesActionTypes.GET_MEDICAL_INFORMATION_SUCCESS: {
      return {
        ...draft,
        medicalInformation: action.payload,
      }
    }

    case ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL_SUCCESS: {
      return {
        ...draft,
        loadedArticle: action.payload,
      }
    }

    case ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE_SUCCESS: {
      return {
        ...draft,
        medicalInformation: {
          ...draft.medicalInformation,
          relatedLink: action.payload.relatedLink,
          significantLink: [
            ...draft.medicalInformation.significantLink,
            ...action.payload.significantLink,
          ],
        },
      }
    }

    case AuthActionTypes.LOGOUT:
      return initialArticles()
  }
},
initialArticles())

export default articlesReducer
