import React, { memo, useRef, useCallback } from 'react'

import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'

import { BookingMappedOutputsType } from '@core/shared/types/bookings'
import { DownloadAttachmentIcon } from '@core/components/icons/DownloadAttachmentIcon'

interface Props {
  attachment: BookingMappedOutputsType
  downloadBookingOutput: (attachment: BookingMappedOutputsType) => void
}

export const OutputItem = memo(function OutputItem({
  attachment,
  downloadBookingOutput,
}: Props) {
  const titleRef = useRef<any>(null)

  const handleOnDownloadClick = useCallback(() => {
    downloadBookingOutput(attachment)
  }, [attachment, downloadBookingOutput])

  const handleOnTooltipClick = useCallback((event: any) => {
    if (isMobile) {
      event.stopPropagation()

      if (titleRef.current) {
        ReactTooltip.show(titleRef.current)
      }
    }
  }, [])

  return (
    <li
      className="past-consultation-attachment-wrapper"
      key={attachment.id}
      onClick={handleOnDownloadClick}
    >
      <div className="past-consultation-attach-wrapper">
        <div className="mr-1 flex-center">
          <DownloadAttachmentIcon />
        </div>
        <span
          className="list-item-title text-sm text-clipping flex-center"
          onClick={handleOnTooltipClick}
        >
          <span
            ref={titleRef}
            data-tip
            data-for={attachment.id}
            className="list-item-title text-sm text-clipping"
          >
            {attachment.attributes.attributes.description}
          </span>
          <ReactTooltip
            id={attachment.id}
            place="bottom"
            effect="solid"
            multiline
            className="custom-tooltip-initialize"
          >
            <div className="text-sm book-tooltip-text">
              {attachment.attributes.attributes.description}
            </div>
          </ReactTooltip>
        </span>
      </div>
    </li>
  )
})
