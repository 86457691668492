import { FormEvent, FunctionComponent, useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'

import { Banner } from '@core/components/app/Banners/banner/Banner'
import { selectAppointmentBooking } from '@core/app/appointment/selectors'
import {
  clearBookingFromSessionStorage,
  saveBookingInSessionStorage,
} from '@core/shared/utils/bookingSessionStorage'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { env } from '@core/shared/configuration'

export const CheckoutForm: FunctionComponent = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { clientURL } = env()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { booking } = useSelector(selectAppointmentBooking)

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    )

    if (!stripe || !clientSecret) return

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent === undefined) return

      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!')
          break
        case 'processing':
          setMessage('Your payment is processing.')
          break
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.')
          break
        default:
          setMessage('Something went wrong.')
          break
      }
    })
  }, [stripe])

  const handleSubmit = async (e: FormEvent) => {
    clearBookingFromSessionStorage()

    e.preventDefault()

    if (!stripe || !elements || !booking) {
      return
    }

    setIsLoading(true)

    // store booking details so we can complete the booking after payment
    saveBookingInSessionStorage(booking)

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          return_url: clientURL + ScreenRoutes.PAYMENT_CONFIRMED,
        },
      })
      .then((result) => {
        if (result.error) {
          const error = result.error
          if (
            error.type === 'card_error' ||
            error.type === 'validation_error'
          ) {
            error.message && setMessage(error.message)
          } else {
            setMessage('An unexpected error occurred.')
          }
        }
      })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    setIsLoading(false)
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="mt-2 mb-2">
      <PaymentElement id="payment-element" />
      {message && (
        <Banner type="error" description={message} className="mt-2" />
      )}

      <button
        disabled={isLoading || !stripe || !elements}
        id="submit"
        className="btn btn-primary mt-2"
      >
        <span id="button-text">
          {isLoading ? 'Processing...' : 'Confirm and book'}
        </span>
      </button>
    </form>
  )
}
