import { memo } from 'react'

interface InlineLoaderProps {
  size?: number
  color?: string
}

export const InlineLoader = memo<InlineLoaderProps>(function InlineLoader(
  props,
) {
  const { size: sizeProps, color: colorProps } = props
  const radiousSize = sizeProps || 5
  const color = colorProps || '#000'

  const bigSize = `${radiousSize};${radiousSize * 0.6};${radiousSize}`
  const smallSize = `${radiousSize * 0.6};${radiousSize};${radiousSize * 0.6}`

  const viewBox = `0 0 ${radiousSize * 7} ${radiousSize * 2}`

  return (
    <svg
      width={radiousSize * 7}
      height={radiousSize * 2}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
      role="img"
    >
      <title>loading</title>
      <circle cx={radiousSize} cy={radiousSize} r={radiousSize}>
        <animate
          attributeName="r"
          from={radiousSize}
          to={radiousSize}
          begin="0s"
          dur="0.8s"
          values={bigSize}
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle
        cx={radiousSize * 3.5}
        cy={radiousSize}
        r={radiousSize * 0.6}
        fillOpacity="0.3"
      >
        <animate
          attributeName="r"
          from={radiousSize * 0.6}
          to={radiousSize * 0.6}
          begin="0s"
          dur="0.8s"
          values={smallSize}
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="0.5"
          to="0.5"
          begin="0s"
          dur="0.8s"
          values=".5;1;.5"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx={radiousSize * 6} cy={radiousSize} r={radiousSize}>
        <animate
          attributeName="r"
          from={radiousSize}
          to={radiousSize}
          begin="0s"
          dur="0.8s"
          values={bigSize}
          calcMode="linear"
          repeatCount="indefinite"
        />
        <animate
          attributeName="fill-opacity"
          from="1"
          to="1"
          begin="0s"
          dur="0.8s"
          values="1;.5;1"
          calcMode="linear"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  )
})
