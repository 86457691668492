import {
  specialtiesItems,
  SpecialtiesItemsType,
} from '../../../specialitiesMap'
import { CPSpecialties, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { consolidateCopy } from '@core/shared/utils/consolidateCopy'
import { useFeatures } from '../../features/features'
import { useClientVariables } from '../../features/variables'

const desiredServiceOrder = [
  CPSpecialties[CPSpecialtiesEnum.GENERAL_PRACTITIONER],
  CPSpecialties[CPSpecialtiesEnum.COUNSELLOR],
  CPSpecialties[CPSpecialtiesEnum.PHYSIOTHERAPIST],
  CPSpecialties[CPSpecialtiesEnum.SECOND_OPINION],
  CPSpecialties[CPSpecialtiesEnum.LEGAL_SUPPORT],
]

/**
 * Generates the copy for a client based on their configuration.
 */
export const useGenerateClientCopy = () => {
  const { bookings } = useFeatures()
  const {
    dashboardVariables: { customSpecialties },
  } = useClientVariables()

  const servicesConfiguration = bookings.services.value

  const activeServiceEntries = Object.entries(servicesConfiguration)
    .filter(([, config]) => config.isEnabled === true)
    .sort((a, b) => {
      const indexA = desiredServiceOrder.indexOf(a[0])
      const indexB = desiredServiceOrder.indexOf(b[0])

      if (indexA > indexB) {
        return 1
      } else if (indexA < indexB) {
        return -1
      } else {
        return 0
      }
    })

  const originalContent = new Map<string, SpecialtiesItemsType>()

  activeServiceEntries.forEach(([name, config]) => {
    const item = specialtiesItems.get(name)
    if (!item) {
      throw new Error(`No specialties item exists for the service: ${name}`)
    }

    const bodyIcons = item.bodyIcons.filter(
      (icon) => config.bookingTypes[icon.type],
    )

    originalContent.set(name, {
      ...item,
      bodyIcons,
    })
  })

  if (customSpecialties) {
    const newCopy = new Map<string, SpecialtiesItemsType>()
    Array.from(originalContent).map(([key, value]) => {
      const specialty = customSpecialties.get(key)
      if (!specialty) {
        return value
      }

      return newCopy.set(key, consolidateCopy(value, specialty))
    })
    return newCopy
  }

  return originalContent
}
