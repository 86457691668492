import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { env } from './configuration'

const config = env()

export function initMonitoring() {
  datadogRum.init({
    applicationId: config.dataDog.applicationId ?? '',
    clientToken: config.dataDog.clientToken ?? '',
    site: 'datadoghq.eu',
    service: 'pwa',
    env: process.env.REACT_APP_APP_ENV ?? 'dev',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
    allowedTracingOrigins: [/https:\/\/.*\.azurewebsites\.net/],
  })

  // datadogRum.addRumGlobalContext('pwaClient', 'NextGen')

  datadogLogs.init({
    clientToken: config.dataDog.clientToken ?? '',
    site: 'datadoghq.eu',
    service: 'pwa',
    env: process.env.REACT_APP_APP_ENV ?? 'dev',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })

  if (process.env.REACT_APP_APP_ENV === 'production') {
    datadogRum.startSessionReplayRecording()
  }
}
