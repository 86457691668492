import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { ContactInformation } from '@core/components/app/ContactInformation'
import {
  updateUserPatientRecordAction,
  setTemporaryPatientRecordRequestAction,
} from '@core/app/patientRecord/actions'
import { selectUserPatientRecord } from '@core/app/patientRecord/selectors'
import { selectAppUser } from '@core/app/user/selectors'
import { Patient } from '@core/shared/types/patient/PatientRecord'
import { removeEmptyProperties } from '@core/shared/lang'
import mapPatientData from '@core/shared/mapPatientData'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { SideMenu } from '@core/components/app/SideMenu'
import { NotificationItem } from '@core/components/app/NotificationItem'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

interface Props {
  hasSectionDividers?: boolean
}

export const EditProfilePage = (props: Props) => {
  const { hasSectionDividers = false } = props
  const history = useHistory()
  const updateProfile = useActionAsync(updateUserPatientRecordAction)
  const showNotification = useAction(showNotificationAction)
  const setTemporaryPatientRecordRequest = useAction(
    setTemporaryPatientRecordRequestAction,
  )

  const user = useSelector(selectAppUser)
  const patientRecord = useSelector(selectUserPatientRecord)
  const [submitError, setSubmitError] = useState<string>('')

  const location = useLocation<{
    suppressSuccessNotification: boolean
    cpSpecialtiesId: string
  }>()

  const handleOnSubmit = async (data: Patient) => {
    try {
      setSubmitError('')
      if (patientRecord) {
        removeEmptyProperties(data)
        mapPatientData(patientRecord.attributes, data)

        await updateProfile({ data, patientRecord })
        setTemporaryPatientRecordRequest({
          patientRecord: null,
          serviceType: '',
        })
        if (!location.state?.suppressSuccessNotification) {
          showNotification({
            type: 'success',
            title: 'Profile updated',
          })
        }

        const path = location.state?.cpSpecialtiesId
          ? ScreenRoutes.BOOK_CONSULTATION.replace(
              ':cpSpecialtiesId',
              location.state?.cpSpecialtiesId,
            )
          : ScreenRoutes.EDIT_PROFILE

        window.scrollTo(0, 0)
        history.push(path)
      }
    } catch (error) {
      console.error(error)

      setSubmitError(error as any)
    }
  }

  return (
    <div className="page edit-profile-page">
      <PageHeader showBg backBtn title="Edit my profile" />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <NotificationItem />
          <ContactInformation
            record={patientRecord?.attributes}
            handleOnSubmit={handleOnSubmit}
            submitButtonText="Save"
            editProfile={true}
            error={submitError}
            userPolicyNumber={user?.attributes?.clientIdentifier}
            hasSectionDividers={hasSectionDividers}
          />
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
