import dayjs from 'dayjs'
import styles from './AddToCalendar.module.scss'
import { CPSpecialties } from '@core/shared/types/bookings'
import { IconCalendarAdd } from '../../icons/communications/24/outline/IconCalendarAdd'
import { specialtiesItems } from '../../specialitiesMap'
import { Service } from '../features/features'
import { useClientVariables } from '../features/variables'

const DATE_FORMAT = 'YYYYMMDD[T]HHmmss'

export type AddToCalendarLinkProps = {
  /** Duration of the event in minutes. */
  duration: number
  /** The start date and time of the event. */
  startTime: string | number | Date
  /** The subject line or title of the event. */
  subject: string
  /** Additional details of the event. */
  details?: string
}

export const AddToCalendarLink = ({
  details,
  duration,
  startTime,
  subject,
}: AddToCalendarLinkProps) => {
  const startDateTime = dayjs(startTime).format(DATE_FORMAT)
  const endDateTime = dayjs(startTime)
    .add(duration, 'minute')
    .format(DATE_FORMAT)

  const params = new URLSearchParams({
    action: 'TEMPLATE',
    dates: `${startDateTime}/${endDateTime}`,
    output: 'xml',
    sf: 'true',
    text: subject,
  })

  if (details) {
    params.append('details', details)
  }

  const href = `https://www.google.com/calendar/render?${params.toString()}`

  return (
    <span className={styles.addToCalendar} data-testid="add-to-calendar">
      <a href={href} target="_blank" rel="noreferrer" title="Add to calendar">
        <IconCalendarAdd />
        Add to calendar{' '}
      </a>
      <span className="sr-only">external link opens in a new tab</span>
    </span>
  )
}

const DEFAULT_CONSULTATION_DURATION = 12 // 12 minutes

const resolveSpecialityName = (specialty: string) => {
  return (
    specialty in CPSpecialties ? CPSpecialties[specialty] : specialty
  ) as Service
}

const getConsultationDuration = (specialty: string) => {
  const name = resolveSpecialityName(specialty)
  const item = specialtiesItems.get(name)
  return item?.defaultDuration ?? DEFAULT_CONSULTATION_DURATION
}

export type AddConsultationToCalendarLinkProps = {
  /** The name of the client that the event is for. */
  clientName: string
  /** The URL of the website of the client. */
  clientURL: string
  /** The start time of the consultation. */
  startTime: string | number | Date
  /**
   * The specialty of the consultation. This can be either the `CPSpecialtiesEnum` value,
   * or the name of the specialty e.g. 'GeneralPractitioner'.
   */
  specialty: string
}

export const AddConsultationToCalendarLink = ({
  clientName,
  clientURL,
  startTime,
  specialty,
}: AddConsultationToCalendarLinkProps) => {
  const { clientVariables } = useClientVariables()

  const specialtyName = resolveSpecialityName(specialty)
  const {
    details = `To view your consultation details please visit: ${clientURL}`,
    subject = `${clientName} - GP Consultation`,
  } = clientVariables.bookings?.[specialtyName]?.addToCalendar ?? {}

  const duration = getConsultationDuration(specialty)

  return (
    <AddToCalendarLink
      details={details}
      duration={duration}
      startTime={startTime}
      subject={subject}
    />
  )
}
