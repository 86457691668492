import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const LegalSupportIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('legal-support-icon', className)}
      aria-hidden="true"
    >
      <path
        d="M19.23 9.995h-.217L16.883 3.6c0-.011-.012-.02-.015-.032a.623.623 0 0 0-.041-.077.738.738 0 0 0-.325-.324.747.747 0 0 0-.353-.093h-3.982a2.305 2.305 0 0 0-1.424-1.406.726.726 0 0 0 .027-.132v-.77a.769.769 0 0 0-1.538 0v.77a.726.726 0 0 0 .027.132 2.3 2.3 0 0 0-1.42 1.406H3.846a.745.745 0 0 0-.348.093.722.722 0 0 0-.325.324.903.903 0 0 0-.04.077c0 .012-.012.02-.016.031L.986 9.994H.769a.769.769 0 0 0-.769.768v.77a3.846 3.846 0 0 0 4.608 3.83 3.807 3.807 0 0 0 1.978-1.073 3.853 3.853 0 0 0 1.105-2.757v-.77a.77.77 0 0 0-.768-.768h-.217L4.914 4.61h2.923a2.31 2.31 0 0 0 1.392 1.4v10.145H6.924a2.318 2.318 0 0 0-1.63.676 2.304 2.304 0 0 0-.675 1.63.774.774 0 0 0-.545.225A.769.769 0 0 0 4.62 20h10.764a.77.77 0 1 0 0-1.538 2.304 2.304 0 0 0-2.306-2.306h-2.306V6.011a2.3 2.3 0 0 0 1.397-1.395h2.92l-1.796 5.378h-.215a.769.769 0 0 0-.769.768v.77a3.836 3.836 0 0 0 1.106 2.757 3.852 3.852 0 0 0 3.503 1.073A3.854 3.854 0 0 0 20 11.533v-.77a.769.769 0 0 0-.77-.768Zm-16.623 0 1.239-3.721 1.241 3.72h-2.48Zm12.306 0 1.24-3.721 1.24 3.72h-2.48Z"
        fill="#fff"
      />
    </svg>
  )
}
