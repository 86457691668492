import { memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { isEmailVerified } from '@core/app/patientRecord/selectors'
import { RightChevronIcon } from '../../icons/RightChevron'
import { ErrorIcon } from '../../icons/ErrorIcon'
import { CircularLoader } from '../../app/CircularLoader'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { resendEmailConfirmationAction } from '@core/app/user/actions'
import { selectAppUser } from '@core/app/user/selectors'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import styles from './ResendEmailConfirmationBanner.module.scss'

export const ResendEmailConfirmationBanner = memo(
  function ResendEmailConfirmationBanner() {
    const resendEmailConfirmation = useActionAsync(
      resendEmailConfirmationAction,
    )
    const showNotification = useAction(showNotificationAction)

    const isUserEmailVerified = useSelector(isEmailVerified)
    const appUser = useSelector(selectAppUser)

    const [isLoading, setLoading] = useState<boolean>(false)

    const handleClick = useCallback(async () => {
      try {
        if (!appUser?.id) {
          return
        }

        setLoading(true)

        await resendEmailConfirmation({
          userId: appUser?.id,
        })
        setLoading(false)
        showNotification({
          type: 'success',
          title: 'Email re-sent',
        })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }, [appUser, resendEmailConfirmation, showNotification])

    return (
      <>
        {isUserEmailVerified === false ? (
          <div className="section">
            <div
              className={styles.resendEmail}
              onClick={handleClick}
              data-testid="resend-email-banner"
            >
              <div className="flex-center">
                <div className="mr-1">
                  <ErrorIcon />
                </div>
                <div>
                  <div className="flex-row column-text">
                    <p className="text-weight-lg">
                      Please confirm your email address before booking.
                    </p>
                    <p className="mt-1 text-smd">
                      If you have verified your email but still see this
                      message, please refresh this page.
                    </p>
                    <p className="text-smd">
                      Email confirmation link expired or didn't get a
                      confirmation email?{' '}
                      <span className={styles.resendLink}>Resend it</span>.
                    </p>
                  </div>
                </div>
              </div>
              <RightChevronIcon fill="#fff" />
            </div>
          </div>
        ) : null}
        {isLoading ? <CircularLoader /> : null}
      </>
    )
  },
)
