import clsx from 'clsx'
import { useFeatures } from '../features/features'
import { ErrorIcon } from '../../icons/ErrorIcon'
import { ServiceValues } from './ServiceDetailOptions'
import { useServiceValidation } from './useServiceValidation'

type Props = {
  serviceType: ServiceValues.GP | ServiceValues.PHARMACY
}

export function ServiceHeader(props: Props) {
  const { serviceType } = props
  const hasError = useServiceValidation(serviceType)

  const { gpPharmacyMap, isPharmacyRequired } = useFeatures()

  const services = {
    gp: {
      title: 'GP details',
      content:
        'Your GP details allow us to send consultation notes to your local surgery to keep your medical records up-to-date.',
      isRequired: true,
    },
    nominatedPharmacy: {
      title: 'Pharmacy details',
      content:
        'By nominating a pharmacy, you can have prescriptions issued to you faster.',
      isRequired: isPharmacyRequired ?? false,
    },
  }

  const service = services[serviceType]
  const isPharmacyOptional =
    serviceType === ServiceValues.PHARMACY && !isPharmacyRequired
  const recommendedPharmacyText = isPharmacyOptional
    ? 'We highly recommend you to nominate a pharmacy if you would like to collect your prescription in Ireland.'
    : null

  return (
    <>
      <div
        className="flex-row-center mb-2"
        data-testid={`${serviceType}-header`}
      >
        <h3 className="section-title">
          <span>
            {service.title}
            {!service.isRequired ? (
              <>
                {' '}
                <span className="text-body text-body--italic text-body--light">
                  (Optional)
                </span>
              </>
            ) : null}
          </span>
        </h3>
        <div className="flex-row">
          {hasError && (
            <>
              <ErrorIcon className="error-icon" />
              <p className="text-md text-color-warning">Required</p>
            </>
          )}
        </div>
      </div>
      <p
        className={clsx('text-color-primary', gpPharmacyMap ? 'mb-2' : 'mb-1')}
      >
        {service.content} {recommendedPharmacyText}
      </p>
    </>
  )
}
