import addDays from 'date-fns/addDays'
import addYears from 'date-fns/addYears'

export const getDependantMinDobDate = () => {
  const now = new Date()

  return addYears(addDays(now, 1), -16)
}

export const getDependantMaxDobDate = () => {
  return new Date()
}

export const getAdultMaxDobDate = () => {
  const now = new Date()

  return addYears(now, -16)
}

export const getAdultMinDobDate = () => {
  const now = new Date()

  return addYears(now, -150)
}
