import { AppState } from '..'

export const selectAssessmentResult = (state: AppState) =>
  state.symptomsAssessment.conclusion

export const selectReasonResult = (state: AppState) =>
  state.symptomsAssessment.conclusion?.reasonConclusions

export const selectAdditionalText = (state: AppState) =>
  state.symptomsAssessment.conclusion?.reasonConclusions[0]?.data?.Properties
    ?.AdditionalText

export const selectBookingRecommended = (state: AppState) => {
  const bookingRecommendationStatuses = ['Self-care', 'ANA', 'Maybe', 'Yes']
  const result =
    state.symptomsAssessment.conclusion?.reasonConclusions[0]?.data?.Properties
      ?.VirtualConsultation
  return bookingRecommendationStatuses.includes(result)
}

export const selectPopulateReasonForBooking = (state: AppState) =>
  state.symptomsAssessment.populateReasonForBooking
