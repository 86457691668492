import { createSelector } from 'reselect'

import { AppState } from '../'
import { getAge } from '@core/shared/validation/underAgeValidate'

export const selectUserPatientRecord = (state: AppState) => {
  const userRecord = state.patientRecord.me
  return userRecord
}

export const selectDependentsRecords = (state: AppState) =>
  state.patientRecord.dependents

export const selectMainPatientPhoneNumber = (state: AppState) =>
  state.patientRecord.me?.attributes.phoneNumber

export const selectMainPatientAddress = (state: AppState) =>
  state.patientRecord.me?.attributes.address

export const selectDependentsRecordsList = createSelector(
  selectDependentsRecords,
  (records) => Object.values(records),
)

export const makeSelectDependentById = () => {
  return createSelector(
    selectDependentsRecords,
    (_: AppState, dependentId: string) => dependentId,
    (records, dependentId) => records[dependentId],
  )
}

export const filledPatientRecord = (state: AppState) =>
  !state.patientRecord.me?.attributes?.allergies?.length &&
  !state.patientRecord.me?.attributes?.gp?.gpName &&
  !state.patientRecord.me?.attributes?.gp?.surgery?.name &&
  !state.patientRecord.me?.attributes?.gp?.surgery?.address?.addressLine1 &&
  !state.patientRecord.me?.attributes?.gp?.surgery?.address?.city &&
  !state.patientRecord.me?.attributes?.gp?.surgery?.address?.countryCode &&
  !state.patientRecord.me?.attributes?.gp?.surgery?.address?.postCode
    ? false
    : true

export const isEmailVerified = (state: AppState) =>
  state.patientRecord.me?.attributes?.emailIsVerified

export const selectTemporaryPatientRecord = (state: AppState) =>
  state.patientRecord.temporaryRecord

export const selectUserPatientAge = (state: AppState) => {
  const userAge = state.patientRecord.me?.attributes.dateOfBirth
  if (userAge) {
    return getAge(userAge)
  }
}

export const selectUserPatientGender = (state: AppState) =>
  state.patientRecord.me?.attributes.gender
