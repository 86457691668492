import { memo, ReactNode } from 'react'
import cx from 'clsx'

export interface HorizontalScrollProps {
  className?: string
  children?: ReactNode
  dynamic?: boolean
}

export const HorizontalScroll = memo<HorizontalScrollProps>(
  function HorizontalScroll(props) {
    return (
      <div
        role="region"
        aria-live={props.dynamic ? 'polite' : 'off'}
        className={cx('horizontal-scroll', props.className)}
      >
        {props.children}
      </div>
    )
  },
)
