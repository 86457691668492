const parseNHSUrl = (link: string) => {
  if (!link) return
  if (link.includes('https://api.nhs.uk')) {
    return link.replace('https://api.nhs.uk', 'https://nhs.uk')
  } else if (!link.includes('https://')) {
    return `https://www.nhs.uk${link}`
  } else {
    return link
  }
}

export default parseNHSUrl
