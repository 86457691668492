import clsx from 'clsx'
import { useClientVariables } from '@core/components/app/features/variables'
import { SliderProps } from './Slider'
import styles from './Slider.module.scss'

export const SliderPagination = (props: SliderProps) => {
  const { handleSlideChange, slider, slideNum } = props

  const {
    landingVariables: { landingSlider },
  } = useClientVariables()

  const numSlides = landingSlider?.pagination || [0, 1]

  if (numSlides.length === 0) {
    return null
  }

  return (
    <div className={styles.pagerDots} data-testid="slider-pagination">
      {numSlides.map((idx) => (
        <div
          onClick={() => {
            handleSlideChange(idx)
            slider.current.slickGoTo(idx)
          }}
          className={clsx(idx === slideNum && styles.active, styles.pagerDot)}
          key={idx}
        >
          <div className={styles.indicator} />
        </div>
      ))}
    </div>
  )
}
