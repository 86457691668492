import { AppointmentType } from '@core/shared/types/appointment'
import { CPSpecialties, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { AppState } from '../'

export const upcomingBookings = (state: AppState) =>
  state.bookings.upcomingBookings

export const completedBookings = (state: AppState) =>
  state.bookings.completedBookings

export const selectedUpcomingBooking = (state: AppState) => {
  const selectedId = state.bookings.selectedUpcomingBooking.id

  const selectedBooking = state.bookings.upcomingBookings.find(
    (el) => el.id === selectedId,
  )
  return selectedBooking
}

export const selectedUpcomingBookingById = (state: AppState, id: string) =>
  state.bookings.upcomingBookings.find((el) => el.id === id)

export const selectedCompletedBooking = (state: AppState) => {
  const selectedId = state.bookings.selectedCompletedBooking.id

  const selectedBooking = state.bookings.completedBookings.find(
    (el) => el.id === selectedId,
  )
  return selectedBooking
}

export const selectedUpcomingBookingAttachments = (state: AppState) =>
  state.bookings.selectedUpcomingBooking.attachments

export const selectedCompletedBookingAttachments = (state: AppState) =>
  state.bookings.selectedCompletedBooking.attachments

export const editStaticValuesToDisplay = (state: AppState) => {
  const selectedBooking = state.bookings.upcomingBookings.find(
    (el) => el.id === state.bookings.selectedUpcomingBooking.id,
  )

  const patientAttributes =
    selectedBooking?.relationships?.patientRecord?.attributes ?? {}
  const clinicalPractitioner =
    selectedBooking?.relationships?.clinicalPractitioner ?? {}
  const isOnsiteConsultation =
    selectedBooking?.attributes?.consultationType === AppointmentType.Onsite

  const specialties = clinicalPractitioner?.specialties ?? []
  const isCounselling =
    Array.isArray(specialties) &&
    specialties[0] === CPSpecialties[CPSpecialtiesEnum.COUNSELLOR]

  const values = [
    {
      title: 'Patient',
      value: [patientAttributes.firstName, patientAttributes.lastName]
        .filter(Boolean)
        .join(' '),
    },
    {
      title: 'HealthHero GP',
      value: [clinicalPractitioner.firstName, clinicalPractitioner.lastName]
        .filter(Boolean)
        .join(' '),
    },
  ]

  if (!isCounselling) {
    values.push(
      {
        title: 'Registered GP practice',
        value: patientAttributes.gp?.surgery?.name || 'Not registered',
      },
      {
        title: 'Nominated pharmacy',
        value: patientAttributes.nominatedPharmacy?.name || 'Not provided',
      },
    )
  }

  if (!isOnsiteConsultation) {
    values.splice(1, 0, {
      title: 'Preferred contact number',
      value: selectedBooking?.attributes?.contactDetails?.phoneNumber ?? '',
    })
  }

  return values.filter((val) => Boolean(val.value))
}

export const selectedCompletedBookingOutputs = (state: AppState) => {
  const selectedId = state.bookings.selectedCompletedBooking.id

  const selectedBooking = state.bookings.completedBookings.find(
    (el) => el.id === selectedId,
  )
  const outputs = selectedBooking?.relationships.consultation.outputs

  return outputs
}
