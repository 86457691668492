import React, {
  memo,
  useCallback,
  useState,
  cloneElement,
  useEffect,
} from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import defaultLocale from 'date-fns/locale/en-GB'

import { DateView } from './views/DateView'
import { TabLoop } from './TabLoop'
import { useDatepickerNavigation } from './useDatepickerNavigation'
import { YearView } from './views/YearView'
import { MonthView } from './views/MonthView'
import {
  formatDate,
  getDefaultLocale,
  getLocaleObject,
  isDayDisabled,
  registerLocale,
  setDefaultLocale,
} from './utils'

registerLocale('gb', defaultLocale)

setDefaultLocale('gb')

export type DatePickerView = 'year' | 'month' | 'day'

export type DatePickerDisplayMode = 'modal' | 'popover'

export type DatePickerProps = {
  value?: Date
  min?: Date
  max?: Date
  view?: DatePickerView
  onChange: (date: Date) => void
  locale?: string | Locale
  inputElement: React.ReactElement
  inputElementRef?: string
  onRequestOpen: () => void
  onRequestClose: () => void
  isOpen?: boolean
  closeOnClickOutside?: boolean
  displayMode?: DatePickerDisplayMode
  disableInputFocus?: boolean
}

const _emptyObj = {}

function wrapRef<T>(
  ref:
    | React.MutableRefObject<T | null>
    | ((instance: T | null) => void)
    | null
    | undefined,
  wrapper: (refNode: T) => void,
): React.Ref<T> {
  return (refNode: T) => {
    if (typeof ref === 'function') {
      ref(refNode)
    } else if (ref) {
      ref.current = refNode
    }
    wrapper(refNode)
  }
}

const RenderModeWrapper = memo<{
  displayMode?: DatePickerDisplayMode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any
}>(function RenderModeWrapper(props) {
  const isInModal = props.displayMode === 'modal'
  // @ts-ignore
  const element: React.ReactElement = isInModal
    ? ((
        <div style={{ top: window.pageYOffset }} className="datepicker-overlay">
          {props.children}
        </div>
      ) as React.ReactElement)
    : props.children || null
  return isInModal
    ? createPortal(element, document.body)
    : props.children || null
})
// const makePickerInput = (input: ReactElement)
const viewMap = {
  year: YearView,
  day: DateView,
  month: MonthView,
}
export const DatePicker = memo<DatePickerProps>(function DatePicker(props) {
  const {
    value = new Date(),
    locale = getDefaultLocale(),
    min,
    max,
    isOpen,
    onRequestClose,
    onChange,
    closeOnClickOutside = true,
    disableInputFocus,
  } = props
  const [selectedDate, setSelectedDate] = useState(value)

  const [currentView, setCurrentView] = useState<DatePickerView>(
    props.view || 'day',
  )

  useEffect(() => {
    if (value?.getDate()) {
      setSelectedDate(value)
      nav.navigateToDate(value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null)
  const [datepickerNode, setDatepickerNode] = useState<HTMLDivElement | null>(
    null,
  )

  const nav = useDatepickerNavigation(selectedDate, { min, max })
  const handleOnSelect = useCallback(
    (date: Date) => {
      setSelectedDate(date)
      onChange(date)

      nav.navigateToDate(date)
      if (currentView !== 'day') {
        setCurrentView('day')
      } else {
        onRequestClose()

        if (!disableInputFocus) {
          inputNode?.focus()
        }
      }
    },
    [onChange, nav, currentView, onRequestClose, disableInputFocus, inputNode],
  )

  const setYearView = () => {
    if (currentView !== 'year') {
      setCurrentView('year')
    }
  }

  const setDayView = () => {
    if (currentView !== 'day') {
      setCurrentView('day')
    }
    nav.navigateToDate(selectedDate)
  }

  const inp = props.inputElement

  const inputRefName = props.inputElementRef || 'ref'
  const prevRef = inp.props[inputRefName]

  const newInp = cloneElement(props.inputElement, {
    ...inp.props,
    [inputRefName]: wrapRef<HTMLInputElement>(prevRef, (ref) => {
      setInputNode(ref)
      if (typeof prevRef === 'function') {
        prevRef(ref)
      } else if (prevRef && prevRef.current) {
        prevRef.current = ref
      }
    }),
  })

  useEffect(() => {
    if (!closeOnClickOutside) {
      return
    }

    const onDocClick = (e: MouseEvent) => {
      let el = e.target
      // @ts-ignore
      while (el.parentNode) {
        //@ts-ignore
        if (datepickerNode && el.parentNode === datepickerNode) {
          break
        }
        //@ts-ignore
        el = el.parentNode
      }
      if (el === window.document) {
        onRequestClose()
      }
    }

    document.addEventListener('click', onDocClick)

    return () => document.removeEventListener('click', onDocClick)
  }, [datepickerNode, onRequestClose, closeOnClickOutside, inputNode])

  const popper = usePopper(inputNode, datepickerNode, {
    placement: 'bottom-end',
  })

  const isModal = props.displayMode === 'modal'
  const wrapperStyles = {
    styles: isModal ? _emptyObj : { ...popper.styles.popper, zIndex: 200 },
    attributes: isModal ? _emptyObj : popper.attributes.popper,
  }

  const View = viewMap[currentView]

  return (
    <div className="datepicker-root">
      {newInp}
      {isOpen ? (
        <RenderModeWrapper displayMode={props.displayMode}>
          <div
            style={wrapperStyles.styles}
            {...wrapperStyles.attributes}
            ref={(ref) => setDatepickerNode(ref)}
            className="datepicker-container"
          >
            <TabLoop>
              <div className="datepicker-header-container">
                <div className="current-date-year">
                  <button
                    onClick={setYearView}
                    className="current-date-year-button"
                    type="button"
                  >
                    {selectedDate.getFullYear()}{' '}
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <line
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2px"
                        x1="16"
                        x2="7"
                        y1="20.5"
                        y2="11.5"
                      />
                      <line
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2px"
                        x1="25"
                        x2="16"
                        y1="11.5"
                        y2="20.5"
                      />
                    </svg>
                  </button>
                </div>
                <div className="current-date-date-button-wrapper">
                  <button
                    type="button"
                    onClick={setDayView}
                    className="current-date-date-button"
                  >
                    {formatDate(selectedDate, 'EEE, MMM dd', locale)}
                  </button>
                </div>
              </div>
              <div className="datepicker-view-container">
                <View
                  currentView={currentView}
                  setCurrentView={setCurrentView}
                  nav={nav}
                  selectedDate={selectedDate}
                  date={nav.current}
                  locale={locale}
                  min={min}
                  max={max}
                  onSelect={handleOnSelect}
                />
              </div>
              <div className="datepicker-toolbar">
                <button
                  onClick={() => {
                    onRequestClose()
                    inputNode?.focus()
                  }}
                  type="button"
                  className="btn btn-link"
                >
                  Cancel
                </button>
                <button
                  disabled={isDayDisabled(selectedDate, { min, max })}
                  onClick={() => {
                    onChange(selectedDate)

                    inputNode?.dispatchEvent(new Event('input'))
                    inputNode?.focus()
                    onRequestClose()
                  }}
                  type="button"
                  className="btn btn-link"
                >
                  Ok
                </button>
              </div>
            </TabLoop>
          </div>
        </RenderModeWrapper>
      ) : null}
    </div>
  )
})
