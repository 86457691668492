import { memo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { createDependentPatientRecordAction } from '@core/app/patientRecord/actions'
import { setTemporaryUserRecordRequestAction } from '@core/app/user/actions'
import { ContactInformation } from '@core/components/app/ContactInformation'
import { removeEmptyProperties } from '@core/shared/lang'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { SideMenu } from '@core/components/app/SideMenu'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const AddNewContactPage = memo(function AddNewContactPage() {
  const location = useLocation<{
    suppressSuccessNotification: boolean
    cpSpecialtiesId: string
  }>()

  const addNewContact = useActionAsync(createDependentPatientRecordAction)
  const showNotification = useAction(showNotificationAction)
  const setTemporaryPatientRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )

  const history = useHistory()
  const [submitError, setSubmitError] = useState('')

  const handleOnSubmit = async (data: any) => {
    try {
      setSubmitError('')
      removeEmptyProperties(data)
      await addNewContact(data)
      setTemporaryPatientRecordRequest({
        userRecord: null,
        serviceType: '',
      })
      showNotification({
        type: 'success',
        title: 'Dependant created',
      })

      const path = location.state?.suppressSuccessNotification
        ? ScreenRoutes.BOOK_CONSULTATION.replace(
            ':cpSpecialtiesId',
            location.state?.cpSpecialtiesId,
          )
        : ScreenRoutes.DEPENDENTS

      history.push(path)
    } catch (error) {
      console.error(error)
      setSubmitError(error as any)
    }
  }

  return (
    <div className="page add-update-contact-page">
      <PageHeader showBg backBtn title="New dependant" />
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <ContactInformation
            handleOnSubmit={handleOnSubmit}
            submitButtonText="Add dependant"
            emailRequired={false}
            error={submitError}
            dependantContact
          />
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
