import axios from 'axios'
import qs from 'query-string'
import client from '@core/api/client'
import paymentEligibilityWrapper, {
  paymentEligibilityCheck,
} from '@core/api/paymentEligibilityWrapper'
import { BFFConfigType, env } from '@core/shared/configuration'
import Cookies from 'js-cookie'

const config = env()
const bffSSOConfig = config.auth.bff === BFFConfigType.curitySSO
const bffImplicitConfig = config.auth.bff === BFFConfigType.ImplictIDS4

export type OauthTokenType = 'Bearer'

export interface AuthResponse {
  access_token: string
  refresh_token: string
}

// tslint:disable-next-line:variable-name
let _refreshToken: string

// tslint:disable-next-line:variable-name
let _accessToken: string

export const setRefreshToken = (refreshToken: string) => {
  _refreshToken = refreshToken
}

export const setAccessToken = (accessToken: string) => {
  _accessToken = accessToken

  client.defaults.headers.common['Authorization'] = `Bearer ${_accessToken}`
  paymentEligibilityCheck.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${_accessToken}`
  paymentEligibilityWrapper.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${_accessToken}`
}

const redirectToSsoLogin = () => {
  window.location.href = `${config.auth.authHost}/Authentication/Login`
}

export const refreshAccessToken = () => {
  const refreshToken = window.localStorage.getItem('refreshToken')

  const params = {
    grant_type: 'refresh_token',
    client_id: config.auth.clientId,
    client_secret: config.auth.clientSecret,
    refresh_token: refreshToken,
  }

  if (client.defaults.headers.common['Authorization']) {
    delete client.defaults.headers.common['Authorization']
  }

  return client.post(
    `${config.api.apiHost}/v${config.api.apiVersion}/clients/${config.api.clientId}/oauth2/token`,
    qs.stringify(params),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  )
}

export const getUserIdFromCookie = () => {
  try {
    const userInfoCookie = Cookies.get('UserInfo')
    return userInfoCookie ? JSON.parse(userInfoCookie).userId : null
  } catch (e) {
    if (config.auth.bff === BFFConfigType.curitySSO) {
      redirectToSsoLogin()
    }
  }
}

const getSSOAuthVerification = async (): Promise<{
  data: { active: boolean }
}> => {
  return client.get(`${config.auth.authHost}/Authentication/Verify`)
}

export const checkUnauthenticatedSSOUser = async () => {
  try {
    const { data } = await getSSOAuthVerification()
    const { active: isAuthenticated } = data

    if (!isAuthenticated) {
      redirectToSsoLogin()
    }
  } catch (e) {
    redirectToSsoLogin()
  }
}

let isRefreshing = false
let refreshSubscribers: Array<(error: any, token: string) => void> = []

const revokeListeners: Array<() => void> = []
const tokenRevoke = () => {
  revokeListeners.forEach((fn) => fn())
}

const tokenRefreshedListeners: Array<(refreshData: AuthResponse) => void> = []

const tokenRefreshed = (authData: AuthResponse) =>
  tokenRefreshedListeners.forEach((fn) => fn(authData))

const subscribeTokenRefresh = (cb: (error: any, token: string) => void) => {
  refreshSubscribers.push(cb)
}

const onRefreshed = (error: any, token: string) => {
  refreshSubscribers.map((cb) => cb(error, token))
}

export const onTokenRevoke = (listener: () => void) => {
  revokeListeners.push(listener)
}

export const onTokenRefreshed = (
  listener: (authData: AuthResponse) => void,
) => {
  tokenRefreshedListeners.push(listener)
}

client.interceptors.response.use(undefined, (err: any) => {
  const {
    config,
    response: { status },
  } = err
  const originalRequest = config

  if (status !== 401) {
    return Promise.reject(err)
  }

  if (
    bffImplicitConfig &&
    status === 401 &&
    originalRequest.url.includes('changePassword')
  ) {
    return Promise.reject(err)
  }

  if (bffSSOConfig) {
    redirectToSsoLogin()
  }

  if (!_refreshToken) {
    tokenRevoke()
    return Promise.reject(err)
  }

  if (!isRefreshing) {
    isRefreshing = true
    refreshAccessToken()
      .then((response) => {
        const { data } = response
        isRefreshing = false
        onRefreshed(undefined, data.access_token)
        setAccessToken(data.access_token)
        setRefreshToken(data.refresh_token)
        tokenRefreshed(data)
        refreshSubscribers = []
      })
      .catch((e: any) => {
        isRefreshing = false
        onRefreshed(e, '')
        tokenRevoke()
      })
  }
  const requestSubscribers = new Promise((resolve, reject) => {
    subscribeTokenRefresh((error, token) => {
      if (error) {
        reject(error)
      } else {
        originalRequest.headers.Authorization = `Bearer ${token}`
        resolve(axios(originalRequest))
      }
    })
  })
  return requestSubscribers
})

export default {
  refreshAccessToken,
  onTokenRevoke,
  onTokenRefreshed,
}
