import { memo } from 'react'
import { ChevronLeftIcon } from '../../../icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../../icons/ChevronRightIcon'
import { DatepickerNav } from '../../useDatepickerNavigation'

export interface YearViewNavigationProps {
  nav: DatepickerNav
  locale: string | Locale
  pageYearFirst: number
  pageYearLast: number
}

export const YearViewNavigation = memo<YearViewNavigationProps>(
  function YearViewNavigation(props) {
    const { nav, pageYearFirst, pageYearLast } = props
    const prevStep = nav.current.getFullYear() - pageYearFirst + 1
    const nextStep = pageYearLast - nav.current.getFullYear() + 1

    const title = `${pageYearFirst} to ${pageYearLast}`
    return (
      <div className="year-view-header__navigation--wrapper">
        <button
          type="button"
          disabled={!nav.canNavigateToPrevYear(Math.round(prevStep))}
          onClick={() => nav.navigateToPrevYear(prevStep)}
          className="year-view-header__navigation--button"
          aria-label="Previous years"
        >
          <ChevronLeftIcon />
        </button>
        <div
          aria-label={`showing ${title} years`}
          className="year-view-header__navigation--date"
        >
          {title}
        </div>
        <button
          type="button"
          onClick={() => nav.navigateToNextYear(nextStep)}
          disabled={!nav.canNavigateToNextYear(nextStep)}
          className="year-view-header__navigation--button"
          aria-label="next years"
        >
          <ChevronRightIcon />
        </button>
      </div>
    )
  },
)
