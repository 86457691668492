import { ConsultationOutcomes, BookingType } from '@core/shared/types/bookings'
import { getPractitionerLabel } from './specialityPractitionerLabels'

const getConsultationTitle = ({
  booking,
  bookingType,
  showDoctorName,
}: {
  booking: BookingType | undefined
  bookingType: string
  showDoctorName?: boolean
}) => {
  if (!booking) {
    return
  }

  const practitionerLabel = getPractitionerLabel(
    booking?.relationships?.clinicalPractitioner?.specialties?.[0],
  )

  if (booking.attributes.status === 'Cancelled') {
    return `Your ${bookingType} with a ${practitionerLabel} ${
      showDoctorName
        ? `${booking?.relationships?.clinicalPractitioner?.firstName} ${booking?.relationships?.clinicalPractitioner?.lastName}`
        : ''
    } was cancelled`
  } else {
    switch (booking?.relationships?.consultation?.outcome?.attributes?.name) {
      case ConsultationOutcomes.CompletedSatisfactory:
      case ConsultationOutcomes.CompletedUnsatisfactory:
        return `You had a ${bookingType} with a ${practitionerLabel} ${
          showDoctorName
            ? `${booking?.relationships?.clinicalPractitioner?.firstName} ${booking?.relationships?.clinicalPractitioner?.lastName}`
            : ''
        }`

      case ConsultationOutcomes.NoShow:
        return `You were not able to join your ${bookingType} with a ${practitionerLabel} ${
          showDoctorName
            ? `${booking?.relationships?.clinicalPractitioner?.firstName} ${booking?.relationships?.clinicalPractitioner?.lastName}`
            : ''
        }`

      case ConsultationOutcomes.IncompleteMedicalEmergency:
      case ConsultationOutcomes.IncompleteOther:
      case ConsultationOutcomes.IncompletePatientInappropriate:
      case ConsultationOutcomes.IncompletePatientNotIdentified:
        return `This consultation could not take place`

      default:
        return `You had a ${bookingType} with a ${practitionerLabel} ${
          showDoctorName
            ? `${booking?.relationships?.clinicalPractitioner?.firstName} ${booking?.relationships?.clinicalPractitioner?.lastName}`
            : ''
        }`
    }
  }
}

export default getConsultationTitle
