import React, { memo } from 'react'
import cx from 'clsx'
import { ErrorIcon } from '../icons/ErrorIcon'
import { LoupeIcon } from '../icons/LoupeIcon'

export interface TextInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | null
  label: string
  inputRef?: any
  inputClassName?: string
  rightIcon?: React.ReactElement | null
  withoutLabel?: boolean
  withoutErrorText?: boolean
  buttonDisabled?: boolean
  onSubmitButton?: (props: any) => void
  buttonText?: string
}

export const TextInput = memo<TextInputProps>(function TextInput(props) {
  const {
    label,
    error,
    inputRef,
    className,
    inputClassName,
    required,
    rightIcon = null,
    withoutLabel = false,
    withoutErrorText = false,
    onSubmitButton,
    buttonDisabled,
    buttonText,
    ...inputProps
  } = props

  return (
    <div className={cx('form-control text-left', withoutLabel && 'no-label')}>
      {withoutLabel ? null : <label>{label}</label>}
      <div
        className={cx('text-input-wrapper', className, !!error && 'has-error')}
      >
        <input
          ref={inputRef}
          aria-required={required ? 'true' : 'false'}
          aria-label={label}
          aria-invalid={error ? 'true' : 'false'}
          {...inputProps}
          className={cx('text-input', inputClassName, error ? 'error' : null)}
        />
        {rightIcon}
        {error ? <ErrorIcon /> : null}
        {onSubmitButton ? (
          <button
            disabled={buttonDisabled}
            type="button"
            onClick={onSubmitButton}
            className="btn btn-primary text-sm custom-input-button"
          >
            {' '}
            {buttonText ? buttonText : <LoupeIcon />}{' '}
          </button>
        ) : null}
      </div>

      {error && !withoutErrorText ? (
        <div role="alert" className="error-message ">
          {error}
        </div>
      ) : null}
    </div>
  )
})
