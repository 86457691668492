// Generic across clients
interface BaseAppConfigSection {
  nhs: {
    primaryKey: string
    secondaryKey: string
    apiHost: string
  }
  googleMaps: {
    apiHost: string
    apiKey: string
  }
  dataDog: {
    applicationId: string
    clientToken: string
  }
  consult: {
    consultHost: string
  }
}

// Flag representing the configuration type
// of the backend-for-front-end regarding this client.
export enum BFFConfigType {
  //Does not use bff
  Disabled = 'disabled',
  //bff enabled, all requests proxying through and using IDS4 implicit grant
  ImplictIDS4 = 'implictIDS4',
  //bff enabled, all requests proxying through, leveraging Curity for single Sign on flow (OIDC)
  CuritySSO = 'curitySSO',
}

const traversalClientID = '7ea777ec8d3d43b3b3200f8b70f9651a'

export interface AppConfigSection extends BaseAppConfigSection {
  api: {
    clientId: string
    apiHost: string
    apiHostname: string
    sapiHost: string
    apiVersion: string
    paymentsEligibilityHost: string
    paymentsEligibilityVersion: string
    registrationEndpoint: string
    traversalEngineApi: string
  }
  auth: {
    clientId: string
    clientSecret: string
    authHost: string
    bff?: BFFConfigType
  }
  ga?: {
    code?: string
  }
  recaptcha: {
    siteKey: string
  }
  clientURL: string
}

export type AppEnv = 'development' | 'uat' | 'production'
export type AppConfig = Record<AppEnv, AppConfigSection>

let _config: AppConfig

let _configured = false

export const setConfig = (config: AppConfig) => {
  if (!_configured) {
    _config = config
    _configured = true
  }
}

export const env = (env?: AppEnv) => {
  if (!_configured) {
    throw new Error('you must call  setConfig(configuration) before usage')
  }
  const _env = (env || process.env.REACT_APP_APP_ENV) as AppEnv
  return _config[_env] || _config.development
}

export const baseAppConfigDevelopment: AppConfigSection = {
  api: {
    clientId: '1',
    apiHost: 'https://ms-dev-msapi.azurewebsites.net/api',
    apiHostname: 'https://ms-dev-msapi.azurewebsites.net',
    sapiHost: 'https://ms-dev-msapi.azurewebsites.net/api',
    apiVersion: '1',
    paymentsEligibilityHost: 'https://ms-dev-pew-cmp-app.azurewebsites.net/api',
    paymentsEligibilityVersion: '1',
    registrationEndpoint:
      'https://ms-dev-msapi-registration-app.azurewebsites.net/api',
    traversalEngineApi: `https://platform-traversal-engine.doctorlink.com/api/v2/${traversalClientID}/`,
  },
  auth: {
    clientId: 'MSAPI',
    clientSecret: 'MSAPISecret',
    authHost: 'https://ms-dev-identity-sts.azurewebsites.net',
    bff: BFFConfigType.Disabled,
  },
  consult: {
    consultHost: 'https://ms-dev-consultation-portal.azurewebsites.net',
  },
  nhs: {
    primaryKey: 'f333e9644b6e4ba19a12e540ee61116b',
    secondaryKey: '6d0543b143b744f99f79b21e2b89e2c3',
    apiHost: 'https://api.nhs.uk',
  },
  googleMaps: {
    apiHost: 'https://maps.googleapis.com/maps/api/place',
    apiKey: 'AIzaSyAxHyqAwMjOvmWMKVJmq6Dx6vvH49KYQgY',
  },
  recaptcha: {
    siteKey: 'REPLACE_ME',
  },
  dataDog: {
    applicationId: '30d3ef06-a885-4e53-9418-2f76deea208e',
    clientToken: 'pubc2ca150b8fb64d1c80e722d9c8e43ed2',
  },
  clientURL: 'http://localhost:3000',
}

export const baseAppConfigUAT: AppConfigSection = {
  api: {
    clientId: 'REPLACE_ME',
    apiHost: 'https://ms-uat-msapi.azurewebsites.net/api',
    apiHostname: 'https://ms-uat-msapi.azurewebsites.net',
    sapiHost: 'https://ms-uat-msapi.azurewebsites.net/api',
    apiVersion: '1',
    paymentsEligibilityHost: 'https://ms-dev-pew-cmp-app.azurewebsites.net/api',
    paymentsEligibilityVersion: '1',
    registrationEndpoint:
      'https://ms-uat-msapi-registration-app.azurewebsites.net/api',
    traversalEngineApi: `https://platform-traversal-engine.doctorlink.com/api/v2/${traversalClientID}/`,
  },
  auth: {
    clientId: 'REPLACE_ME',
    clientSecret: 'REPLACE_ME',
    authHost: 'https://ms-uat-identity-sts.azurewebsites.net',
    bff: BFFConfigType.Disabled,
  },
  consult: {
    consultHost: 'https://ms-uat-consultation-portal.azurewebsites.net',
  },
  nhs: {
    primaryKey: 'f333e9644b6e4ba19a12e540ee61116b',
    secondaryKey: '6d0543b143b744f99f79b21e2b89e2c3',
    apiHost: 'https://api.nhs.uk',
  },
  googleMaps: {
    apiHost: 'https://maps.googleapis.com/maps/api/place',
    apiKey: 'AIzaSyAxHyqAwMjOvmWMKVJmq6Dx6vvH49KYQgY',
  },
  dataDog: {
    applicationId: '30d3ef06-a885-4e53-9418-2f76deea208e',
    clientToken: 'pubc2ca150b8fb64d1c80e722d9c8e43ed2',
  },
  recaptcha: {
    siteKey: 'REPLACE_ME',
  },
  clientURL: 'REPLACE_ME',
}

const baseUrl = 'https://msapi.medicalsolutions.co.uk'
const apiUrl = new URL('/api', baseUrl).toString()

export const baseAppConfigProduction: AppConfigSection = {
  api: {
    clientId: 'REPLACE_ME',
    apiHost: apiUrl,
    apiHostname: baseUrl,
    sapiHost: apiUrl,
    apiVersion: '1',
    paymentsEligibilityHost: 'https://something/api',
    paymentsEligibilityVersion: '1',
    registrationEndpoint:
      'https://ms-live-msapi-registration-app.azurewebsites.net/api',
    traversalEngineApi: `https://platform-traversal-engine.doctorlink.com/api/v2/${traversalClientID}/`,
  },
  auth: {
    clientId: 'REPLACE_ME',
    clientSecret: 'REPLACE_ME',
    authHost: apiUrl,
    bff: BFFConfigType.Disabled,
  },
  consult: {
    consultHost: 'https://ms-live-consultation-portal.azurewebsites.net',
  },
  nhs: {
    primaryKey: 'd46c617502fd48bfb929c2735bc1a8e1',
    secondaryKey: 'd2576f3d1194447c8885d83c0523f4bb',
    apiHost: 'https://api.nhs.uk',
  },
  googleMaps: {
    apiHost: 'https://maps.googleapis.com/maps/api/place',
    apiKey: 'AIzaSyAxHyqAwMjOvmWMKVJmq6Dx6vvH49KYQgY',
  },
  dataDog: {
    applicationId: '30d3ef06-a885-4e53-9418-2f76deea208e',
    clientToken: 'pubc2ca150b8fb64d1c80e722d9c8e43ed2',
  },
  recaptcha: {
    siteKey: 'REPLACE_ME',
  },
  clientURL: 'REPLACE_ME',
}
