import { JsonApiEntity } from '../JsonApi'
import { Address } from '../shared/Address'

export interface PatientNominatedPharmacy {
  name: string
  address?: Address
  phoneNumber?: string
  email?: string
  isSelected?: boolean
}

export interface PatientGpSurgery {
  gpName?: string
  isSelected?: boolean
  surgery?: {
    name?: string
    address?: Address
    phoneNumber?: string
    email?: string
  }
}

export interface Patient {
  patientId?: string | number
  firstName: string
  lastName: string
  dateOfBirth: string
  phoneNumber: string
  email?: string
  gender?: string
  policyNumber?: string
  address: Address
  allergies: string[]
  medicalHistory: string[]
  medications: string[]
  emailIsVerified: boolean
  gp?: PatientGpSurgery
  terms?: {
    id: string
    termsType: string
    accepted: boolean
    acceptedAt: string
    acceptedBy: string
  }
  nominatedPharmacy?: PatientNominatedPharmacy | null
}

export type PatientRecord = JsonApiEntity<Patient>

export enum ServiceType {
  GPSURGERY = 'gpsurgery',
  PHARMACY = 'pharmacy',
}
