import {
  AuthActionTypes,
  LoginAction,
  LoginSuccessAction,
  LoginFailAction,
  LogoutAction,
  LogoutSuccessAction,
  LoginTokenRetrievedAction,
  SsoAuthenticationSuccess,
} from './actionTypes'

export interface RAuth {
  loggedIn: boolean
  loading: boolean
  token: string
  refreshToken: string
}

type AuthActions =
  | LoginAction
  | LoginSuccessAction
  | LoginFailAction
  | LogoutAction
  | LogoutSuccessAction
  | LoginTokenRetrievedAction
  | SsoAuthenticationSuccess

export const initialAuth = (): RAuth => ({
  loggedIn: false,
  loading: false,
  token: '',
  refreshToken: '',
})

const authReducer = (state = initialAuth(), action: AuthActions): RAuth => {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return { ...state, loading: true }
    case AuthActionTypes.LOGIN_TOKEN_RETRIEVED:
      return { ...state, ...action.payload }
    case AuthActionTypes.LOGIN_FAIL:
      return { ...state, loading: false }
    case AuthActionTypes.LOGIN_SUCCESS:
    case AuthActionTypes.SSO_AUTHENTICATION_SUCCESS:
      return { ...state, loading: false, loggedIn: true }
    case AuthActionTypes.LOGOUT:
      return initialAuth()
    default:
      return state
  }
}

export default authReducer
