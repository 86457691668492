import React from 'react'
import { BodyItemsType, SpecialtiesItemsType } from '../../../specialitiesMap'
import { useClientVariables } from '../../features/variables'
import styles from './HsfComponent.module.scss'

interface Props {
  specialtiesItem: SpecialtiesItemsType
}

export const HsfComponent = ({ specialtiesItem }: Props) => {
  const { HeaderIcon, headerTitle, description, bodyIcons } = specialtiesItem

  const {
    clientVariables: { phoneNumber },
  } = useClientVariables()

  return (
    <a href={`tel: ${phoneNumber}`} className={styles.hsfConsultation}>
      <div className={styles.wrapper}>
        <header>
          <div className={styles.serviceIcon}>
            <HeaderIcon />
          </div>
          <h3>{headerTitle}</h3>
        </header>
        <div className={styles.content}>
          <p>{description}</p>
        </div>
        <div className={styles.icons}>
          {bodyIcons?.map((item: BodyItemsType, key: React.Key) => (
            <>
              <item.Icon className="card-header-icon" key={key} />
              {item.title}
            </>
          ))}
        </div>
      </div>
    </a>
  )
}
