const mapPatientData = (
  currentData: Record<string, any>,
  newData: Record<string, any>,
) => {
  if (currentData?.address?.addressLine2 && !newData?.address?.addressLine2) {
    newData.address.addressLine2 = null
  }

  if (currentData?.allergies?.length && !newData?.allergies) {
    newData.allergies = []
  }

  if (currentData?.medicalHistory?.length && !newData?.medicalHistory) {
    newData.medicalHistory = []
  }

  if (currentData?.medications?.length && !newData?.medications) {
    newData.medications = []
  }

  if (currentData?.email && !newData?.email) {
    newData.email = null
  }

  if (currentData?.gp && !newData?.gp) {
    newData.gp = null
  } else {
    if (currentData?.gp?.gpName && !newData?.gp?.gpName) {
      newData.gp.gpName = null
    }

    if (currentData?.gp?.surgery && !newData?.gp?.surgery) {
      newData.gp.surgery = null
    } else {
      if (currentData?.gp?.surgery?.email && !newData?.gp?.surgery?.email) {
        newData.gp.surgery.email = null
      }

      if (currentData?.gp?.surgery?.name && !newData?.gp?.surgery?.name) {
        newData.gp.surgery.name = null
      }

      if (
        currentData?.gp?.surgery?.phoneNumber &&
        !newData?.gp?.surgery?.phoneNumber
      ) {
        newData.gp.surgery.phoneNumber = null
      }

      if (currentData?.gp?.surgery?.address && !newData?.gp?.surgery?.address) {
        newData.gp.surgery.address = null
      } else {
        if (
          currentData?.gp?.surgery?.address?.addressLine1 &&
          !newData?.gp?.surgery?.address?.addressLine1
        ) {
          newData.gp.surgery.address.addressLine1 = null
        }

        if (
          currentData?.gp?.surgery?.address?.addressLine2 &&
          !newData?.gp?.surgery?.address?.addressLine2
        ) {
          newData.gp.surgery.address.addressLine2 = null
        }

        if (
          currentData?.gp?.surgery?.address?.city &&
          !newData?.gp?.surgery?.address?.city
        ) {
          newData.gp.surgery.address.city = null
        }

        if (
          currentData?.gp?.surgery?.address?.countryCode &&
          !newData?.gp?.surgery?.address?.countryCode
        ) {
          newData.gp.surgery.address.countryCode = null
        }

        if (
          currentData?.gp?.surgery?.address?.postCode &&
          !newData?.gp?.surgery?.address?.postCode
        ) {
          newData.gp.surgery.address.postCode = null
        }
      }
    }
  }

  if (currentData?.nominatedPharmacy && !newData?.nominatedPharmacy) {
    newData.nominatedPharmacy = null
  } else {
    if (
      currentData?.nominatedPharmacy?.name &&
      !newData?.nominatedPharmacy?.name
    ) {
      newData.nominatedPharmacy.name = null
    }

    if (
      currentData?.nominatedPharmacy?.phoneNumber &&
      !newData?.nominatedPharmacy?.phoneNumber
    ) {
      newData.nominatedPharmacy.phoneNumber = null
    }

    if (
      currentData?.nominatedPharmacy?.email &&
      !newData?.nominatedPharmacy?.email
    ) {
      newData.nominatedPharmacy.email = null
    }

    if (
      currentData?.nominatedPharmacy?.address &&
      !newData?.nominatedPharmacy?.address
    ) {
      newData.nominatedPharmacy.email = null
    } else {
      if (
        currentData?.nominatedPharmacy?.address?.addressLine1 &&
        !newData?.nominatedPharmacy?.address?.addressLine1
      ) {
        newData.nominatedPharmacy.address.addressLine1 = null
      }

      if (
        currentData?.nominatedPharmacy?.address?.addressLine2 &&
        !newData?.nominatedPharmacy?.address?.addressLine2
      ) {
        newData.nominatedPharmacy.address.addressLine2 = null
      }

      if (
        currentData?.nominatedPharmacy?.address?.city &&
        !newData?.nominatedPharmacy?.address?.city
      ) {
        newData.nominatedPharmacy.address.city = null
      }

      if (
        currentData?.nominatedPharmacy?.address?.countryCode &&
        !newData?.nominatedPharmacy?.address?.countryCode
      ) {
        newData.nominatedPharmacy.address.countryCode = null
      }

      if (
        currentData?.nominatedPharmacy?.address?.postCode &&
        !newData?.nominatedPharmacy?.address?.postCode
      ) {
        newData.nominatedPharmacy.address.postCode = null
      }
    }
  }
}

export default mapPatientData
