import { memo, useCallback, useEffect, useState, useRef } from 'react'
import cx from 'clsx'

import { useAction } from '@core/shared/useAction'
import { NotificationItem } from '../../components/app/NotificationItem'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { CircularLoader } from '../../components/app/CircularLoader'
import { LabelPaper } from '../../components/form/LabelPaper'
import { TextInput } from '../../components/form/TextInput'
import { PaperHeader, PaperContent } from '../../components/paper'
import { Radio } from '../../components/form/Radio'
import { useClientVariables } from './features/variables'

import { env } from '@core/shared/configuration'

const config = env()

let googleService: google.maps.places.PlacesService | null = null
let googleMap: google.maps.Map | null = null
let googleMarkers: google.maps.Marker[] = []

interface Props {
  selectedType?: string
  handleServices?: (items: google.maps.places.PlaceResult[]) => void
  detailsIds?: string[]
  handleDetails?: (props: any) => void
  scrollToElem?: (placeId: string) => void
}

export const GoogleMapComponent = memo(function GoogleMapComponent({
  selectedType = 'GP Surgery',
  handleServices,
  detailsIds,
  handleDetails,
  scrollToElem,
}: Props) {
  const googleMapRef = useRef<any>()

  const {
    clientVariables: {
      mapsPostcodePlaceholderValue = 'Location',
      baseCountryCode = 'UK',
    },
  } = useClientVariables()

  let defaultLocation

  if (baseCountryCode === 'IE') {
    defaultLocation = {
      lat: 53.346365,
      lng: -6.268279,
    }
  } else {
    defaultLocation = {
      lat: 51.509865,
      lng: -0.118092,
    }
  }

  const [isLoading, setLoading] = useState<boolean>(false)
  const [useLocation, setUseLocation] = useState<boolean>(true)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [currentLocation, setCurrentLocation] = useState<{
    lat: number
    lng: number
  }>(defaultLocation)

  const showNotification = useAction(showNotificationAction)

  const createGoogleMap = useCallback(
    (coordinates: { lat: number; lng: number }) => {
      googleMap = new window.google.maps.Map(googleMapRef.current, {
        zoom: 15,
        center: {
          lat: coordinates.lat,
          lng: coordinates.lng,
        },
        disableDefaultUI: true,
        minZoom: 14,
        maxZoom: 20,
      })

      googleService = new window.google.maps.places.PlacesService(googleMap)
    },
    [],
  )

  const addInfoWindow = useCallback(
    (
      marker: google.maps.Marker,
      content: string,
      place: google.maps.places.PlaceResult,
    ) => {
      const infoWindow = new window.google.maps.InfoWindow({
        content: content,
      })

      google.maps.event.addListener(marker, 'click', function () {
        infoWindow.open(googleMap as google.maps.Map, marker)
        if (scrollToElem) {
          scrollToElem(place?.place_id || '')
        }
      })
    },
    [scrollToElem],
  )

  const createMarker = useCallback(
    (place: google.maps.places.PlaceResult) => {
      const marker = new window.google.maps.Marker({
        map: googleMap as google.maps.Map,
        position: (place.geometry as google.maps.places.PlaceGeometry).location,
      })

      googleMarkers.push(marker)

      const contentString = `<div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div class="text-weight-md">${place.name || ''}</div>
      <div>${place.vicinity || ''}</div>
    </div>`

      addInfoWindow(marker, contentString, place)
    },
    [addInfoWindow],
  )

  const getMarkers = useCallback(
    (props?: { lat: number; lng: number; type?: string }) => {
      setLoading(true)

      if (props) {
        const currentPos = new google.maps.LatLng(props.lat, props.lng)

        const searchRadius = googleMap ? googleMap?.getZoom() * 100 : 1000

        const request = {
          // fields: ['name', 'geometry', 'formatted_address', 'opening_hours'],
          fields: 'name,geometry,formatted_address,opening_hours',
          keyword: props.type || selectedType,
          radius: searchRadius,
          location: currentPos,
          language: 'en-GB',
        }

        googleService?.nearbySearch(
          request,
          function (
            results: google.maps.places.PlaceResult[],
            status: google.maps.places.PlacesServiceStatus,
          ) {
            if (results?.length) {
              googleMap?.setCenter(
                (results[0].geometry as google.maps.places.PlaceGeometry)
                  .location,
              )
            } else if (props.lat && props.lng) {
              googleMap?.setCenter({
                lat: props.lat,
                lng: props.lng,
              })
            }

            googleMarkers.length &&
              googleMarkers.forEach((el) => el.setMap(null))
            googleMarkers = []

            if (status === google.maps.places.PlacesServiceStatus.OK) {
              results.forEach((element: google.maps.places.PlaceResult) => {
                createMarker(element)
              })
            }
            if (handleServices) {
              handleServices(results)
            }
            setLoading(false)
          },
        )
      } else {
        const request = {
          query: searchQuery,
          // potential options https://developers.google.com/maps/documentation/places/web-service/supported_types?hl=en
        }

        googleService?.textSearch(
          request,
          function (
            results: google.maps.places.PlaceResult[],
            status: google.maps.places.PlacesServiceStatus,
          ) {
            if (results?.length) {
              setCurrentLocation({
                lng: (
                  results[0].geometry as google.maps.places.PlaceGeometry
                ).location.lng(),
                lat: (
                  results[0].geometry as google.maps.places.PlaceGeometry
                ).location.lat(),
              })

              googleMap?.setCenter(
                (results[0].geometry as google.maps.places.PlaceGeometry)
                  .location,
              )
            }

            if (status === google.maps.places.PlacesServiceStatus.OK) {
              getMarkers({
                lng: (
                  results[0].geometry as google.maps.places.PlaceGeometry
                ).location.lng(),
                lat: (
                  results[0].geometry as google.maps.places.PlaceGeometry
                ).location.lat(),
              })
            } else if (
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              handleServices && handleServices(results)
              setLoading(false)
            } else {
              setLoading(false)
            }
          },
        )
      }
    },
    [createMarker, handleServices, searchQuery, selectedType],
  )

  const startMap = useCallback(
    ({ lat, lng }: { lat: number; lng: number }) => {
      if (!googleMap) {
        createGoogleMap({ lat, lng })
      }

      setCurrentLocation({ lat, lng })

      getMarkers({ lat, lng })
    },
    [createGoogleMap, getMarkers],
  )

  const getPlaceDetails = useCallback(
    async (placeIds: string[]) => {
      try {
        setLoading(true)

        await Promise.all(
          placeIds.map(async (placeId: string) => {
            const request = {
              fields: [
                'name',
                'geometry',
                'formatted_address',
                'opening_hours',
                'address_component',
                'place_id',
                'website',
                'url',
                'international_phone_number',
              ],
              language: 'en-GB',
              placeId,
            }

            await googleService?.getDetails(
              request,
              function (
                result: google.maps.places.PlaceResult,
                status: google.maps.places.PlacesServiceStatus,
              ) {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                  if (handleDetails) {
                    handleDetails(result)
                  }

                  return result
                }

                return ''
              },
            )
          }),
        )

        setLoading(false)
      } catch (error) {
        console.log('error', error)
        setLoading(false)
      }
    },
    [handleDetails],
  )

  const initialize = useCallback(() => {
    setLoading(true)
    navigator.geolocation.getCurrentPosition(
      (info: any) => {
        setLoading(false)
        startMap({
          lat: info.coords.latitude,
          lng: info.coords.longitude,
        })
      },
      (error: any) => {
        setLoading(false)

        setUseLocation(false)

        startMap({
          lat: currentLocation.lat,
          lng: currentLocation.lng,
        })

        showNotification({
          type: 'warning',
          title: error?.message || 'Something went wrong',
          // duration: 5000,
        })
      },
      { timeout: 20000 },
    )
  }, [currentLocation.lat, currentLocation.lng, showNotification, startMap])

  const onSearchQueryChange = useCallback(
    (event) => {
      if (useLocation) {
        setUseLocation(false)
      }

      setSearchQuery(event.target.value)
    },
    [useLocation],
  )

  const onUseLocationHandle = useCallback(() => {
    if (useLocation) {
      return
    }

    initialize()

    setUseLocation(true)
    setSearchQuery('')
  }, [initialize, useLocation])

  useEffect(() => {
    if (googleMap && selectedType && !searchQuery) {
      getMarkers({
        lat: currentLocation.lat,
        lng: currentLocation.lng,
        type: selectedType,
      })
    }
  }, [currentLocation, getMarkers, selectedType, searchQuery])

  useEffect(() => {
    if (detailsIds?.length) {
      getPlaceDetails(detailsIds)
    }
  }, [detailsIds, getPlaceDetails])

  useEffect(() => {
    if (!window.google) {
      const googleMapsScript = document.createElement('script')
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?v=3&key=${config.googleMaps.apiKey}&libraries=places`
      googleMapsScript.async = true
      googleMapsScript.defer = true
      googleMapsScript.onload = initialize
      document.head.appendChild(googleMapsScript)
    }
  }, [initialize])

  useEffect(() => {
    if (window.google) {
      initialize()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      googleMap = null
      googleService = null
      googleMarkers = []
    }
  }, [])

  return (
    <div>
      <div>
        <div className="map-wrapper" id="map" ref={googleMapRef} />
        <NotificationItem />
        <div className="section">
          <h2 className="text-weight-md mb-2" data-testid="search-header">
            Choose how to search
          </h2>
          <p className="text-smd mb-2">
            Search by your current location, establishment name, service or
            enter a postcode to narrow the results.
          </p>
          <div className="flex-row single-line-input-wrapper">
            <LabelPaper className="location-pick flex-1 mr-1 action-cursor">
              <PaperContent className="health-service-paper-content">
                <PaperHeader
                  className={cx(
                    'text-sm flex-row  space-between',
                    useLocation && 'selected',
                  )}
                >
                  <Radio
                    name="useLocation"
                    className="mr-2"
                    checked={useLocation}
                    value="ad"
                    onClick={onUseLocationHandle}
                  />
                  Use your location
                </PaperHeader>
              </PaperContent>
            </LabelPaper>
            <TextInput
              label=""
              placeholder={mapsPostcodePlaceholderValue}
              className="w-full"
              name="location"
              withoutLabel
              value={searchQuery}
              onChange={onSearchQueryChange}
              onKeyDown={(e: any) => {
                if (e.keyCode === 13) {
                  getMarkers()
                }
              }}
              onSubmitButton={() => getMarkers()}
              buttonText="Search"
            />
          </div>
        </div>
      </div>

      {isLoading ? <CircularLoader /> : null}
    </div>
  )
})
