import cx from 'clsx'
import styles from './InfoIcon.module.scss'

enum variantEnum {
  regular = 'regular',
  regularWhite = 'regular-white',
  positive = 'positive',
  alert = 'alert',
}

interface Props {
  type: keyof typeof variantEnum
  fillBg?: boolean
  clickable?: boolean
}

export const InfoIcon = (props: Props) => {
  const { type, fillBg = false, clickable = false } = props
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(
        styles.infoIcon,
        styles[type],
        fillBg && styles.fillBg,
        clickable && styles.clickable,
      )}
      aria-hidden="true"
    >
      {fillBg ? (
        <>
          <path
            className="outside"
            clipRule="evenodd"
            d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Z"
          />
          <path
            className="inner"
            d="M10.143 13.571a1.143 1.143 0 1 1-2.286 0V7.436a1.143 1.143 0 1 1 2.286 0v6.135ZM7.857 4.443c0-.606.512-1.098 1.143-1.098s1.143.492 1.143 1.098c0 .607-.512 1.098-1.143 1.098S7.857 5.05 7.857 4.443Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <path
            className="outside"
            clipRule="evenodd"
            d="M9 17A8 8 0 1 0 9 1a8 8 0 0 0 0 16Z"
            strokeWidth="2"
          />
          <path
            className="inner"
            d="M10.143 13.571a1.143 1.143 0 1 1-2.286 0V7.436a1.143 1.143 0 1 1 2.286 0v6.135ZM7.857 4.443c0-.606.512-1.098 1.143-1.098s1.143.492 1.143 1.098c0 .607-.512 1.098-1.143 1.098S7.857 5.05 7.857 4.443Z"
          />
        </>
      )}
    </svg>
  )
}
