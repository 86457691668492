import { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { selectMedicalInformation } from '@core/app/articles/selectors'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { TextInput } from '@core/components/form/TextInput'
import {
  getMedicalInformationRequestAction,
  getMedicalInformationByUrlRequestAction,
  getMedicalInformationMoreRequestAction,
} from '@core/app/articles/actions'
import { useActionAsync } from '@core/shared/useAction'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

type SearchProps = {
  text: {
    pageHeader: string
    pageTitle: string
    searchPlaceholder: string
    noResults: string
    noResultsNote: string
    resultLink: string
    seeMore: string
  }
}

export const MedicalInformationResultsPage = memo(
  function MedicalInformationResultsPage(props: SearchProps) {
    const { text } = props
    const [isLoading, setLoading] = useState<boolean>(false)
    const history = useHistory()
    const medicalInformation = useSelector(selectMedicalInformation)
    const getMedicalInformationRequest = useActionAsync(
      getMedicalInformationRequestAction,
    )
    const getMedicalInformationByUrlRequest = useActionAsync(
      getMedicalInformationByUrlRequestAction,
    )
    const getMedicalInformationMoreRequest = useActionAsync(
      getMedicalInformationMoreRequestAction,
    )

    const { register, handleSubmit, formState } = useForm<{
      defaultValues: {
        search: ''
      }
    }>()

    const onSubmit = useCallback(
      async (data: any) => {
        try {
          setLoading(true)
          await getMedicalInformationRequest({
            search: data.search,
          })
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      },
      [getMedicalInformationRequest],
    )

    const fetchByUrl = useCallback(
      async (data: any) => {
        try {
          setLoading(true)
          await getMedicalInformationByUrlRequest({
            url: data,
          })
          setLoading(false)
          history.push(ScreenRoutes.ARTICLE)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      },
      [getMedicalInformationByUrlRequest, history],
    )

    const fetchMoreItems = useCallback(
      async (data: any) => {
        try {
          setLoading(true)
          await getMedicalInformationMoreRequest({
            url: data,
          })
          setLoading(false)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      },
      [getMedicalInformationMoreRequest],
    )

    return (
      <div className="page medical-information-page">
        <PageHeader showBg backBtn title={text.pageHeader} />
        <div className="page-wrapper">
          <SideMenu />
          <div className="page-content" role="main">
            <div className="content">
              <div className="section">
                {!medicalInformation?.significantLink?.length ? (
                  <div className="">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="medical-information-search">
                        <h3 className="medical-information-search-title mb-2">
                          {text.pageTitle}
                        </h3>

                        <div className="medical-information-search-input-wrapper flex-row">
                          <TextInput
                            label=""
                            placeholder={text.searchPlaceholder}
                            className="w-full"
                            name="search"
                            inputRef={register}
                            withoutLabel
                          />
                          <button
                            disabled={formState.isSubmitting}
                            type="submit"
                            className="btn"
                          >
                            <RightChevronIcon />
                          </button>
                        </div>
                        <div>
                          <h4 className="text-weight-lg mb-2">
                            {text.noResults}
                          </h4>
                          <p className="text-sm">{text.noResultsNote}</p>
                        </div>
                      </div>
                    </form>
                  </div>
                ) : null}
                {medicalInformation?.significantLink?.map(
                  (item: any, index: number) => (
                    <div
                      key={index}
                      className="medical-item mb-2 action-cursor"
                      onClick={() => fetchByUrl(item.url)}
                    >
                      <h3 className="medical-title mb-1">{item.name}</h3>
                      <p className="medical-description text-weight-normal">
                        {item.description}
                      </p>
                      <div
                        className="medical-navigate text-smd action-cursor"
                        role="button"
                      >
                        {text.resultLink}
                        <RightChevronIcon className="ml-1" />
                      </div>
                      <div className="separator"></div>
                    </div>
                  ),
                )}
                {medicalInformation?.relatedLink?.find(
                  (el: any) => el.name === 'Next Page',
                ) ? (
                  <div className="btn-container">
                    <button
                      onClick={() =>
                        fetchMoreItems(
                          medicalInformation?.relatedLink?.find(
                            (el: any) => el.name === 'Next Page',
                          ).url,
                        )
                      }
                      className="btn btn-primary"
                    >
                      {text.seeMore}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? <CircularLoader /> : null}
        <PageFooter stickyBottom />
      </div>
    )
  },
)
