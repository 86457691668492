import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useAction, useActionAsync } from '@core/shared/useAction'
import {
  createBookingJoiningTokenAction,
  selectUpcomingBooking as selectUpcomingBookingAction,
} from '@core/app/bookings/actions'
import { Button } from '../../../Button/Button'
import { BookingType } from '@core/shared/types/bookings'
import styles from '../UpcomingBookings.module.scss'
import { CancelModal } from './CancelModal'

type Props = {
  shouldStartCallShown: boolean
  isVideoConsultation: boolean
  practitionerTitle: string
  booking: BookingType
}

export const UpcomingBookingActions = (props: Props) => {
  const {
    shouldStartCallShown,
    isVideoConsultation,
    practitionerTitle,
    booking,
  } = props

  const selectUpcomingBooking = useAction(selectUpcomingBookingAction)

  const history = useHistory()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleEditConsultation = () => {
    selectUpcomingBooking({
      bookingId: booking.id,
    })
    history.push(ScreenRoutes.EDIT_GP_CONSULTATION)
  }

  const createBookingJoiningToken = useActionAsync(
    createBookingJoiningTokenAction,
  )

  const fetchCreateBookingJoiningToken = async () => {
    try {
      await createBookingJoiningToken({
        consultationId: booking.relationships.consultation.data.id,
        userId: booking.relationships.user.data.id,
        clientId: booking.relationships.user.attributes.clientId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  const toggleDeleteContactModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  if (!shouldStartCallShown) {
    return (
      <>
        <Button
          label="Edit"
          className="w-full mb-1"
          type="primary"
          onClick={handleEditConsultation}
          testid="edit-button"
        />
        <Button
          label="Cancel"
          className="w-full"
          type="secondary"
          onClick={toggleDeleteContactModal}
          testid="cancel-button"
        />
        {showDeleteModal && (
          <CancelModal
            title="Cancel consultation?"
            modalContent={`Are you sure you want to cancel your consultation with a GP?`}
            buttonSubmitText="Yes, cancel"
            buttonCanceledText="No, take me back"
            toggleModal={toggleDeleteContactModal}
            bookingId={booking.id}
          />
        )}
      </>
    )
  }

  if (shouldStartCallShown && isVideoConsultation) {
    return (
      <Button
        label="Join"
        type="primary"
        className="w-full"
        onClick={fetchCreateBookingJoiningToken}
        testid="join-button"
      />
    )
  }

  if (shouldStartCallShown && !isVideoConsultation) {
    return (
      <p className={styles.phoneCall}>
        The {practitionerTitle} will call you shortly
      </p>
    )
  }

  return null
}
