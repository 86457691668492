import { memo } from 'react'
import cx from 'clsx'

export interface StepperProps {
  step: number
  steps: number
}
export const Stepper = memo<StepperProps>(function Stepper(props) {
  const { step, steps } = props
  const items = [...Array(steps)].map((_, idx) => idx + 1)

  return (
    <div className="stepper">
      {items.map((item) => (
        <div
          key={`stepper-${item}`}
          className={cx('step', step === item && 'current')}
        >
          <div className={cx('step-number', step === item && 'primary-bg')}>
            {item}
          </div>
        </div>
      ))}
    </div>
  )
})
