import { ofType, Epic } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { ResponseCodes } from '../responseCodes'
import { getMessageByCode } from '../responseMessages'
import {
  UserActionTypes,
  RegisterAction,
  UpadteProfileAction,
  SaveNewPasswordAction,
  UdatePasscodeAction,
  GetMeAction,
  ResetPasswordStartAction,
  ResetPasswordCompleteAction,
  ResendEmailConfirmationAction,
  GetCurrentUserAction,
} from './actionTypes'
import {
  register,
  getCurrentUser,
  changePassword,
  resetPasswordStart,
  resetPasswordComplete,
  resendEmailConfirmation,
  getUserById,
} from '@core/api/user'
import {
  notifySuccess,
  notifyFail,
  emptyAction,
  concatActions,
} from '@core/shared/epicHelpers'
import {
  getMeSuccessAction,
  getMeFailAction,
  currentUserAfterRegisterSuccessAction,
} from './actions'
import { currentPatientRecordAfterRegisterSuccessAction } from '../patientRecord/actions'

const mapResponseErrors = (response: any, defaultError: string) => {
  if (response?.errors && response.errors.length) {
    return response.errors
      .filter((err: any) => !!err.detail)
      .map((err: any) => {
        if (err.code === ResponseCodes['400_client_validation_failed']) {
          return {
            message: getMessageByCode(
              ResponseCodes['400_client_validation_failed'],
            ),
          }
        } else {
          return {
            message: err.detail,
          }
        }
      })
  }
  return [
    {
      message: defaultError,
    },
  ]
}

export const registerEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.REGISTER),
    mergeMap(async (action: RegisterAction) => {
      try {
        const response = await register(action.payload)
        notifySuccess(action)

        return [
          currentUserAfterRegisterSuccessAction(
            response.data.find((el: any) => el?.data?.type === 'users')?.data ||
              {},
          ),
          currentPatientRecordAfterRegisterSuccessAction(
            response.data.find((el: any) => el?.data?.type === 'patients')
              ?.data || {},
          ),
        ]
      } catch (e) {
        const reason = mapResponseErrors(
          e.response?.data,
          'Registration failed',
        )

        notifyFail(action, reason)
        return []
      }
    }),
    concatActions(),
  )

export const updateProfileEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.UPDATE_PROFILE),
    mergeMap(async (action: UpadteProfileAction) => {
      try {
      } catch (e) {
        const reason = e.response?.data?.message || 'Updating failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export const saveNewPasswordEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.SAVE_NEW_PASSWORD),
    mergeMap(async (action: SaveNewPasswordAction) => {
      try {
        const { oldPassword, newPassword } = action.payload

        await changePassword({ currentPassword: oldPassword, newPassword })

        notifySuccess(action)
      } catch (e) {
        const reason = e.response?.data?.message || 'Updating password failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export const updatePasscodeEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.UPDATE_PASSCODE),
    mergeMap(async (action: UdatePasscodeAction) => {
      try {
      } catch (e) {
        const reason = e.response?.data?.message || 'Updating password failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export const getMeEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.GET_ME),
    mergeMap(async (action: GetMeAction) => {
      try {
        const resp = await getCurrentUser()
        notifySuccess(action)
        return getMeSuccessAction(resp.data.data)
      } catch (e) {
        return getMeFailAction()
      }
    }),
    concatActions(),
  )

// For any clients using BFF - we cannot use above epic as the call to currentUser requires an access token
export const getCurrentUserEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.GET_CURRENT_USER),
    mergeMap(async (action: GetCurrentUserAction) => {
      try {
        const resp = await getUserById(`${action.payload}`)
        notifySuccess(action)
        return [getMeSuccessAction(resp.data.data)]
      } catch (e) {
        return getMeFailAction()
      }
    }),
    concatActions(),
  )

export const resetPasswordStartEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.RESET_PASSWORD_START),
    mergeMap(async (action: ResetPasswordStartAction) => {
      try {
        const { userName } = action.payload

        await resetPasswordStart({ userName })

        notifySuccess(action)
      } catch (e) {
        const reason = e.response?.data?.message || 'Reset password failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export const resetPasswordCompleteEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.RESET_PASSWORD_COMPLETE),
    mergeMap(async (action: ResetPasswordCompleteAction) => {
      try {
        const { token, tokenId, password } = action.payload

        await resetPasswordComplete({ token, tokenId, password })

        notifySuccess(action)
      } catch (e) {
        const reason = e.response?.data?.message || 'Reset password failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export const resendEmailConfirmationEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.RESEND_EMAIL_CONFIRMATION),
    mergeMap(async (action: ResendEmailConfirmationAction) => {
      try {
        await resendEmailConfirmation(action.payload)

        notifySuccess(action)
      } catch (e) {
        const reason = e.response?.data?.message || 'Resend confirmation failed'
        notifyFail(action, reason)
      } finally {
        return
      }
    }),
    emptyAction(),
  )

export default [
  registerEpic,
  updateProfileEpic,
  saveNewPasswordEpic,
  updatePasscodeEpic,
  getMeEpic,
  resetPasswordStartEpic,
  resetPasswordCompleteEpic,
  resendEmailConfirmationEpic,
  getCurrentUserEpic,
]
