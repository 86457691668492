import React, { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useHistory } from 'react-router-dom'
import { FieldError, useForm } from 'react-hook-form'
import ReactTooltip from 'react-tooltip'
import { FilePickerList } from '@core/components/form/FilePickerList'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { TextAreaInput } from '@core/components/form/TextAreaInput'
import { DeletePhotoIcon } from '@core/components/icons/DeletePhotoIcon'
import { DownloadAttachmentIcon } from '@core/components/icons/DownloadAttachmentIcon'
import { TextInput } from '@core/components/form/TextInput'
import { NativeSelect } from '@core/components/form/NativeSelect'
import { countryCodeOptions } from '@core/shared/data/countryCodes'
import { SideMenu } from '@core/components/app/SideMenu'
import {
  selectedUpcomingBooking,
  selectedUpcomingBookingAttachments,
  editStaticValuesToDisplay,
} from '@core/app/bookings/selectors'
import { selectUserPatientRecord } from '@core/app/patientRecord/selectors'
import { selectAppUser } from '@core/app/user/selectors'
import { useAction, useActionAsync } from '@core/shared/useAction'
import {
  selectUpcomingBooking as selectUpcomingBookingAction,
  getBookingAttachments as getBookingAttachmentsAction,
  updateBooking as updateBookingAction,
  deleteBookingAttachment as deleteBookingAttachmentAction,
  downloadBookingAttachmentRequestAction,
} from '@core/app/bookings/actions'
import {
  BookingAttachmentType,
  CPSpecialties,
  CPSpecialtiesEnum,
} from '@core/shared/types/bookings'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { required } from '@core/shared/validation/required'
import { Address } from '@core/shared/types/shared/Address'

import { CancelModal } from '@core/components/app/UpcomingBookings/components/CancelModal'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { acceptedFileFormats } from '@core/shared/acceptedFileFormats'
import { ErrorIcon } from '@core/components/icons/ErrorIcon'
import { AddConsultationToCalendarLink } from '@core/components/app/addToCalendar'
import { env } from '@core/shared/configuration'
import { AppointmentType } from '@core/shared/types/appointment'

type FormValues = {
  symptoms: string
  reasonForBooking: string
  files: File[]
  contactDetails: Address
}

const errorsMap: Record<string, string> = {
  required: 'Required field',
}

function getError<T>(errors: any, field: string) {
  const err = errors[field] as FieldError

  if (!err) {
    return null
  }

  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }

  return null
}

const getConsultationDateStringFormatted = (startDateTimeString: string) => {
  const isConsultationToday = dayjs(startDateTimeString).isSame(
    dayjs().format(),
    'day',
  )
  const isConsultationTomorrow = dayjs(startDateTimeString).isSame(
    dayjs().add(1, 'day').format(),
    'day',
  )

  const todaysDate = `Today at ${dayjs(startDateTimeString).format('HH:mm')}`
  const tomorrowsDate = `Tomorrow at ${dayjs(startDateTimeString).format(
    'HH:mm',
  )}`
  const futureDate = `${dayjs(startDateTimeString).format(
    'DD MMMM YYYY',
  )} at ${dayjs(startDateTimeString).format('HH:mm')}`

  let consultationDate

  if (isConsultationToday) {
    consultationDate = todaysDate
  } else if (isConsultationTomorrow) {
    consultationDate = tomorrowsDate
  } else {
    consultationDate = futureDate
  }
  return consultationDate
}

export const EditGpConsultationPage: React.FC = () => {
  const history = useHistory()
  const [attachmentsToDisplay, setAttachmentsToDisplay] = useState<
    BookingAttachmentType[]
  >([])
  const [attachmentsToDelete, setAttachmentsToDelete] = useState<
    BookingAttachmentType[]
  >([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [editAddress, setEditAddress] = useState<boolean>(false)
  const [localError, setLocalError] = useState<string>('')

  const selectedBooking = useSelector(selectedUpcomingBooking)

  const staticValuesToDisplay = useSelector(editStaticValuesToDisplay)

  const showNotification = useAction(showNotificationAction)
  if (!selectedBooking) {
    history.push(ScreenRoutes.DASHBOARD)
  }

  const { handleSubmit, register, errors, setValue, getValues, reset } =
    useForm({
      defaultValues: {
        symptoms: selectedBooking?.attributes?.symptoms?.join(', '),
        reasonForBooking: selectedBooking?.attributes?.reasonForBooking,
        contactDetails: selectedBooking?.attributes?.contactDetails.address,
        files: [],
      } as FormValues,
    })

  const selectedBookingAttachments = useSelector(
    selectedUpcomingBookingAttachments,
  )
  const user = useSelector(selectAppUser)
  const getBookingAttachments = useActionAsync(getBookingAttachmentsAction)
  const updateBooking = useActionAsync(updateBookingAction)
  const deleteBookingAttachment = useActionAsync(deleteBookingAttachmentAction)
  const downloadBookingAttachmentRequest = useActionAsync(
    downloadBookingAttachmentRequestAction,
  )
  const selectUpcomingBooking = useAction(selectUpcomingBookingAction)

  const startTime =
    selectedBooking?.relationships?.appointment?.attributes?.start

  const consultationType = selectedBooking?.attributes?.consultationType
  const isVideoConsultation = consultationType === AppointmentType.VideoCall
  const isOnsiteConsultation = consultationType === AppointmentType.Onsite

  const consultationTypeText = isVideoConsultation ? 'video call' : 'phone call'

  const patientRecord = useSelector(selectUserPatientRecord)
  const videoLinkEmailAddress = patientRecord?.attributes?.email

  useEffect(() => {
    return () => {
      selectUpcomingBooking({
        bookingId: null,
        attachments: [],
      })
    }
  }, [selectUpcomingBooking])

  useEffect(() => {
    const fetchBookingAttachments = async () => {
      try {
        if (user && selectedBooking) {
          await getBookingAttachments({
            userId: user?.id,
            bookingId: selectedBooking?.id,
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchBookingAttachments()
  }, [getBookingAttachments, selectedBooking, user])

  useEffect(() => {
    setAttachmentsToDisplay(selectedBookingAttachments)
  }, [selectedBookingAttachments])

  const onSubmit = async (data: any) => {
    setLocalError('')
    try {
      if (selectedBooking && user) {
        if (attachmentsToDelete.length) {
          await Promise.all(
            attachmentsToDelete.map((el) => {
              if (el.id) {
                fetchDeleteBookingAttachment(el.id)
              }
            }),
          )
        }
        await updateBooking({
          userId: user?.id,
          bookingId: selectedBooking?.id,
          symptoms: [data.symptoms || ''],
          reasonForBooking: data.reasonForBooking,
          contactDetails: {
            phoneNumber:
              selectedBooking?.attributes?.contactDetails.phoneNumber,
            address: {
              ...selectedBooking?.attributes?.contactDetails.address,
              ...data.contactDetails,
            },
          },
          files: data.files,
        })
        showNotification({
          type: 'success',
          title: 'Consultation updated',
        })
        history.push(ScreenRoutes.DASHBOARD)
      }
    } catch (error) {
      console.error(error)
      setLocalError(error as any)
    }
  }

  const fetchDeleteBookingAttachment = async (attachmentId: string) => {
    try {
      if (user && selectedBooking) {
        await deleteBookingAttachment({
          userId: user?.id,
          bookingId: selectedBooking?.id,
          attachmentId,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteAttachment = useCallback(
    (attachment: BookingAttachmentType, attachmentIndex: number) => {
      const newAttachments = attachmentsToDisplay.filter(
        (_, index: number) => index !== attachmentIndex,
      )
      setAttachmentsToDisplay(newAttachments)
      if (!attachment.id) {
        const values: any = getValues()
        const currentFileValueIndex =
          attachmentIndex -
          ((selectedBookingAttachments?.length || 0) -
            (attachmentsToDelete?.length || 0))
        setValue(
          'files',
          values.files.filter(
            (el: File, index: number) =>
              (currentFileValueIndex > 0 ? currentFileValueIndex : 0) !== index,
          ),
        )
      } else {
        setAttachmentsToDelete([...attachmentsToDelete, attachment])
      }
    },
    [
      attachmentsToDelete,
      attachmentsToDisplay,
      getValues,
      selectedBookingAttachments,
      setValue,
    ],
  )

  const toggleDeleteContactModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const downloadBookingAttachment = useCallback(
    async (attachment: BookingAttachmentType) => {
      await downloadBookingAttachmentRequest({
        userId: selectedBooking?.relationships.user.data.id,
        fileName: attachment.attributes.description,
        fileId: attachment.relationships.file.id,
        mimeType: attachment?.relationships?.file?.attributes?.mimeType,
      })
    },
    [downloadBookingAttachmentRequest, selectedBooking],
  )

  useEffect(() => {
    register({ name: 'files' })
  }, [register])

  const address = selectedBooking?.attributes?.contactDetails?.address

  const addressStr = `${address?.addressLine1}, ${
    address?.addressLine2 || ''
  }, ${address?.city}, ${address?.postCode}`

  const hasError = {
    symptoms: Boolean(errors.symptoms),
    reasonForBooking: Boolean(errors.reasonForBooking),
  }

  const { bookings } = useFeatures()
  const {
    clientVariables: { phoneNumber, clientName },
  } = useClientVariables()

  const { clientURL } = env()

  const canUploadSupportingDocuments =
    !isOnsiteConsultation && bookings.uploadSupportingDocuments.isEnabled

  const cpSpecialties =
    selectedBooking?.relationships?.clinicalPractitioner?.specialties
  const specialtyName = cpSpecialties?.[0]

  const isCounselling =
    Array.isArray(cpSpecialties) &&
    cpSpecialties.includes(CPSpecialties[CPSpecialtiesEnum.COUNSELLOR])

  return (
    <div className="page edit-gp-consultation-page">
      <PageHeader showBg backBtn title="Edit GP Consultation" />

      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="section">
            <h2
              data-testid="edit-gp-consultation-page-title"
              className="edit-gp-consultation-page-title mb-2 section-title"
            >
              {`You have booked a ${consultationTypeText} with a GP`}
            </h2>

            <div className="scheduled-time-wrapper">
              <p className="mb-1">
                {`Your ${consultationTypeText} will start:`}
              </p>
              <p className="text-xlg text-weight-md mb-2">
                {getConsultationDateStringFormatted(startTime)}
                <AddConsultationToCalendarLink
                  clientName={clientName}
                  clientURL={clientURL}
                  startTime={startTime}
                  specialty={specialtyName}
                />
              </p>
            </div>

            <div className="consultation-info">
              {staticValuesToDisplay.map((item) => (
                <div
                  className="consultation-info-item-wrapper"
                  key={item?.title}
                >
                  <div className="consultation-info-item-title">
                    {item?.title}
                  </div>
                  <div className="consultation-info-item-data">
                    {item?.value}
                  </div>
                </div>
              ))}
            </div>

            {isVideoConsultation ? (
              <div
                className="consultation-link-notice"
                data-testid="consultation-link-notice"
              >
                <p>
                  Your video link will be sent to{' '}
                  <strong>{videoLinkEmailAddress}</strong> 15 mins before the
                  consultation time.
                </p>
              </div>
            ) : null}

            <div className="consultation-symptoms">
              <form onSubmit={handleSubmit(onSubmit)}>
                <h3 className="edit-gp-consultation-page-subtitle mb-1 text-md text-weight-lg">
                  Reason for booking
                </h3>
                <div className="details-row">
                  <TextAreaInput
                    label="Reason for booking"
                    className="w-full"
                    name="reasonForBooking"
                    error={getError(errors, 'reasonForBooking')}
                    inputRef={register({ validate: { required } })}
                    required
                    placeholder="Please give a brief explanation of the reason for the consultation."
                  />
                  {!isCounselling ? (
                    <Feature name="hasSymptomsField">
                      <TextAreaInput
                        label="What are your symptoms?"
                        className="w-full"
                        name="symptoms"
                        error={hasError.symptoms}
                        inputRef={register({})}
                        placeholder="Please list any symptoms you are experiencing including details of any medication you are taking."
                      />
                    </Feature>
                  ) : null}
                </div>

                {!isOnsiteConsultation ? (
                  <>
                    <h3 className="edit-gp-consultation-page-subtitle mb-1 mt-3 text-md text-weight-lg">
                      Will you be at this address during the consultation? If
                      not, please edit.
                    </h3>
                    {editAddress ? (
                      <EditAddressElements
                        errors={errors}
                        register={register}
                      />
                    ) : (
                      <div className="card mb-2">
                        <div
                          data-testid="edit-gp-consultation-page-current-location-edit"
                          className="edit-gp-consultation-page-current-location"
                        >
                          <span className="edit-gp-consultation-page-current-location-address">
                            {addressStr}
                          </span>
                          <button
                            className="btn-link btn-link-primary action-cursor edit-gp-consultation-page-current-location-edit-button"
                            onClick={() => {
                              const values: any = getValues()
                              if (!values.contactDetails) {
                                values.contactDetails =
                                  selectedBooking?.attributes?.contactDetails
                                    ?.address || {}
                              }

                              reset(values)
                              setEditAddress(true)
                            }}
                            aria-label="Change your address during the consultation"
                          >
                            Edit.
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                ) : null}

                {canUploadSupportingDocuments ? (
                  <>
                    <h4 className="section-title mt-3 text-md text-weight-lg">
                      Supporting documents
                    </h4>
                    <div className="consultation-attachment-upload flex-center flex-row space-between">
                      <FilePickerList
                        onChange={(files) => setValue('files', files)}
                        label="Add supporting documents, this could be an image, test results, x-rays etc."
                        btnText="Add file"
                        acceptFormat={acceptedFileFormats}
                      />
                    </div>
                  </>
                ) : null}

                <div className="consultation-attachment-uploaded">
                  <ul className="consultation-attachment-uploaded-list">
                    {attachmentsToDisplay?.map(
                      (attachment: BookingAttachmentType, index: number) => (
                        <li
                          data-testid="consultation-attachment-uploaded-list-item"
                          className="consultation-attachment-uploaded-list-item"
                          key={index}
                        >
                          <span
                            data-tip
                            data-for={attachment.id}
                            className="list-item-title text-sm text-weight-md"
                          >
                            {attachment.attributes.description}
                          </span>
                          <ReactTooltip
                            id={attachment.id}
                            place="bottom"
                            effect="solid"
                            multiline
                          >
                            <div className="text-sm book-tooltip-text">
                              {attachment.attributes.description}
                            </div>
                          </ReactTooltip>
                          <div className="attachment-action-buttons-wrapper flex-row">
                            {attachment.id ? (
                              <div
                                className="list-item-delete-button action-cursor"
                                onClick={() =>
                                  downloadBookingAttachment(attachment)
                                }
                                role="button"
                                tabIndex={0}
                                aria-label={`Download file ${attachment.attributes.description}`}
                              >
                                <DownloadAttachmentIcon />
                              </div>
                            ) : null}
                            <div
                              className="list-item-delete-button ml-1 action-cursor"
                              onClick={() =>
                                deleteAttachment(attachment, index)
                              }
                              role="button"
                              tabIndex={0}
                              aria-label={`Delete file ${attachment.attributes.description}`}
                            >
                              <DeletePhotoIcon />
                            </div>
                          </div>
                        </li>
                      ),
                    )}
                  </ul>
                </div>

                <div className="contact-cancel-container mt-3">
                  <div className="consultation-need-to-speak">
                    <h4 className="edit-gp-consultation-page-subtitle mb-2 text-md text-weight-lg">
                      Need to speak to someone?
                    </h4>
                    <p className="consultation-need-to-speak-subtitle text-sm mb-1">
                      Contact our Customer Service Team on:
                    </p>
                    <p className="consultation-need-to-speak-phone-number text-sm text-weight-md text-color-primary-pink">
                      <a
                        href={
                          // Phone number field is sometimes used to store an email
                          phoneNumber.includes('@')
                            ? `mailto:${phoneNumber}`
                            : `tel:${phoneNumber}`
                        }
                      >
                        {phoneNumber}
                      </a>
                    </p>
                  </div>
                  <div className="consultation-cancel">
                    <h4 className="edit-gp-consultation-page-subtitle mb-2 text-md text-weight-lg">
                      Wish to cancel?
                    </h4>
                    <div className="consultation-cancel-wrapper flex-center flex-row space-between">
                      <p className="consultation-cancel-subtitle text-sm">
                        If your situation has changed, and you no longer need
                        the consultation. You can cancel it here.
                      </p>
                      <button
                        className="consultation-cancel-button action-button action-cursor"
                        onClick={(e) => {
                          e.preventDefault()

                          toggleDeleteContactModal()
                        }}
                        aria-label="Cancel the consultation"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>

                <div className="consultation-save-wrapper">
                  {localError ? (
                    <div className="validation-errors flex-center mt-2 mb-2">
                      <ErrorIcon />
                      <div className="tooltip warning ml-1">
                        {localError ||
                          'Something went wrong, please try again.'}
                      </div>
                    </div>
                  ) : null}
                  <div className="btn-container">
                    <button
                      type="submit"
                      className="consultation-save-button btn btn-primary w-full mb-2 mt-2"
                    >
                      Confirm changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {showDeleteModal && selectedBooking && (
            <CancelModal
              title="Cancel consultation?"
              modalContent={`Are you sure you want to cancel your ${consultationTypeText} with a GP?`}
              buttonSubmitText="Yes, cancel"
              buttonCanceledText="No, take me back"
              toggleModal={toggleDeleteContactModal}
              bookingId={selectedBooking.id}
            />
          )}
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}

interface EditAddressProps {
  errors: any
  register: any
}
const EditAddressElements: React.FC<EditAddressProps> = (props) => {
  const { errors, register } = props
  return (
    <>
      <TextInput
        label=""
        placeholder="First line of address"
        className="w-full mb-1"
        name="contactDetails.addressLine1"
        inputRef={register({ validate: { required } })}
        required
        error={getError(errors.contactDetails || {}, 'addressLine1')}
      />
      <TextInput
        label=""
        placeholder="Second line of address"
        className="w-full mb-1"
        name="contactDetails.addressLine2"
        inputRef={register}
      />
      <div className="details-row">
        <div style={{ display: 'flex' }} className="city-post-line">
          <div style={{ display: 'flex', flex: 1 }}>
            <TextInput
              label=""
              placeholder="Town or city"
              className="w-full mb-2"
              name="contactDetails.city"
              inputRef={register({ validate: { required } })}
              error={getError(errors.contactDetails || {}, 'city')}
              required
            />
          </div>
          <div style={{ width: '25%', marginLeft: '20px' }}>
            <TextInput
              label=""
              placeholder="Postcode"
              className="postcode-input w-full"
              name="contactDetails.postCode"
              inputRef={register({ validate: { required } })}
              error={getError(errors.contactDetails || {}, 'postCode')}
              required
            />
          </div>
        </div>
        <div className="mb-2">
          <NativeSelect
            label=""
            placeholder="Country"
            selectRef={register({ required: true })}
            aria-label="Country"
            name="contactDetails.countryCode"
            options={countryCodeOptions}
            error={getError(errors.contactDetails || {}, 'countryCode')}
          />
        </div>
      </div>
    </>
  )
}
