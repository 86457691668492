import { memo } from 'react'

interface Article {
  item: any
}

export const ArticleSubResourcesList = memo(function ArticleSubResourcesList({
  item,
}: Article) {
  const subResourceList = item.mainEntityOfPage?.flatMap(
    (entity: any) => entity?.mainEntityOfPage,
  )
  return (
    <div className="sub-resource-list-container">
      <h3 className="text-weight-md">More in {item.about.name} </h3>
      <ul>
        {subResourceList?.map((item: any) => (
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.nhs.uk${item.url}`}
            >
              {item.headline}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
})
