import { Link, useHistory } from 'react-router-dom'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useSelector } from 'react-redux'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { selectUserDetails } from '@core/app/appointment/selectors'
import { selectUserPatientRecord } from '@core/app/patientRecord/selectors'
import { useAction } from '@core/shared/useAction'
import { logout as logoutAction } from '@core/app/auth/actions'
import { NotificationItem } from '@core/components/app/NotificationItem'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { useProfileNavItems, SideMenu } from '@core/components/app/SideMenu'
import { useClientVariables } from '@core/components/app/features/variables'
import { isExternalUrl } from '@core/shared/url'

export const ProfilePage = () => {
  const userDetails = useSelector(selectUserDetails)
  const userRecord = useSelector(selectUserPatientRecord)
  const logout = useAction(logoutAction)
  const history = useHistory()

  const { clientData } = useClientVariables()
  const profileNavItems = useProfileNavItems()

  const signOutItem = {
    title: clientData?.navigation?.signOut?.title ?? 'Sign out',
    routePath:
      clientData?.navigation?.signOut?.redirectUrl ?? ScreenRoutes.HOME,
    testid: 'sidebar-signout',
  }

  return (
    <div className="page profile-page">
      <PageHeader showBg backBtn title="Profile" />
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <NotificationItem />
          <div className="section">
            <div className="paper">
              <div className="paper-content w-full">
                <Link
                  to={ScreenRoutes.EDIT_PROFILE}
                  className="edit-profile-nav-item"
                >
                  <div>
                    <div className="paper-header text-weight-md text-md">
                      {userRecord?.attributes.firstName}{' '}
                      {userRecord?.attributes.lastName}
                    </div>
                    <div className="profile-info-wrapper">
                      <div className="paper-text profile-info-personal">
                        <div>{userDetails.phoneNumber}</div>
                        <div>{userDetails.email}</div>
                        <div>{userDetails.address}</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-color-primary">
                    <RightChevronIcon />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="section profile-menu-section">
            <div className="paper">
              <div className="profile-menu-list-wrapper">
                <ul className="profile-menu-list">
                  {profileNavItems.map((el, index) => (
                    <li className="profile-menu-list-item" key={index}>
                      <Link
                        to={el.routePath}
                        className="profile-menu-list-item-link"
                      >
                        <div className="paper-header text-weight-md text-md">
                          {el.title}
                        </div>
                        <div>
                          <RightChevronIcon />
                        </div>
                      </Link>
                    </li>
                  ))}
                  <li className="profile-menu-list-item">
                    {isExternalUrl(signOutItem.routePath) ? (
                      <a
                        href={signOutItem.routePath}
                        data-testid={signOutItem.testid}
                        className="profile-menu-list-item-link"
                      >
                        <p className="paper-header text-weight-md text-md">
                          {signOutItem.title}
                        </p>
                      </a>
                    ) : (
                      <button
                        type="button"
                        data-testid={signOutItem.testid}
                        className="w-full profile-menu-list-item-link"
                        onClick={() => {
                          logout()
                          history.push(ScreenRoutes.HOME)
                        }}
                      >
                        <p className="paper-header text-weight-md text-md">
                          {signOutItem.title}
                        </p>
                      </button>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
