import { useEffect, useRef } from 'react'

// Necessary to access NHS API
// https://developer.api.nhs.uk/about/terms
const trackingPixelSrc =
  'https://statse.webtrendslive.com/dcs2221tai1ckz5huxw0mfq86_1m2w/njs.gif?dcsuri=&wt.cg_n=Syndication&wt.cg_s=&synduserid=&syndreviewdate='
const trackingPixelNHS = document.createElement('img')
trackingPixelNHS.id = 'trackingPixelNHS'
trackingPixelNHS.style.width = '1px'
trackingPixelNHS.style.height = '1px'
trackingPixelNHS.src = trackingPixelSrc

export const useTrackingPixelNHS = <T extends HTMLElement>() => {
  const trackingPixelRef = useRef<T | null>(null)

  useEffect(() => {
    const parent = trackingPixelRef?.current
    if (
      parent != null &&
      parent.querySelector('#' + trackingPixelNHS.id) === null
    ) {
      parent.appendChild(trackingPixelNHS)
    }
  }, [trackingPixelRef.current])

  return { trackingPixelRef }
}
