import { useEffect, useMemo } from 'react'

export function useMutationObserver(
  targetEl: Node | null,
  config: MutationObserverInit,
  onElMutation: MutationCallback,
) {
  const observer = useMemo(
    () =>
      new MutationObserver((mutationList, observer) => {
        return onElMutation(mutationList, observer)
      }),
    [onElMutation],
  )

  useEffect(() => {
    if (targetEl) {
      observer.observe(targetEl, config)
      return () => {
        if (observer) observer.disconnect()
      }
    }
  }, [targetEl, config, observer])
}
