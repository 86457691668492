import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const AchesAndPainsIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 43"
      className={cx('aches-and-pains-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#475a79" cx="21.5" cy="21.5" r="21.5"></circle>
          <path
            d="M13.333,22.309 C14.621,22.309 15.666,21.267 15.666,19.981 C15.666,18.694 14.621,17.652 13.333,17.652 C12.044,17.652 11,18.694 11,19.981 C11,21.267 12.044,22.309 13.333,22.309 Z"
            stroke="#FFFFFF"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M30.911,28.162 L26.911,17.517 C26.716,16.998 26.219,16.653 25.663,16.653 L18.998,16.653 L18.998,11.331 C18.998,10.596 18.401,10 17.665,10 C16.929,10 16.332,10.596 16.332,11.331 L16.332,28.629 C16.332,29.364 16.929,29.96 17.665,29.96 C18.401,29.96 18.998,29.364 18.998,28.629 L18.998,21.976 L23.018,21.976 L21.063,28.233 C20.901,28.693 21.003,29.203 21.329,29.565 C21.656,29.927 22.154,30.082 22.628,29.97 C23.103,29.857 23.478,29.495 23.606,29.026 L25.782,22.079 L28.418,29.097 C28.677,29.786 29.445,30.134 30.135,29.875 C30.824,29.617 31.173,28.85 30.914,28.162 L30.911,28.162 Z"
            stroke="#FFFFFF"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </g>
      </g>
    </svg>
  )
}
