import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const IconCalendarAdd = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('calendar-add-icon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      aria-hidden="true"
    >
      <path
        stroke="#242D38"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 14.25V5.038c0-.375-.346-.68-.773-.68H3.773c-.427 0-.773.305-.773.68v13.584c0 .376.346.68.773.68H13.5M16.136 3v2.717M6.864 3v2.717M3 8.434h17"
      />
      <path
        stroke="#242D38"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M17 21a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      />
      <path
        stroke="#242D38"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.25 17h3.5M17 15.25v3.5"
      />
    </svg>
  )
}
