export const formatAccessiblePhoneNumber = (phoneNumber?: string) => {
  //Description:
  //    format phone number for screen readers.
  //    “(703) 555-1212”. A screen reader will read this number as
  //    “seven hundred three (pause) five hundred fifty-five minus one thousand two hundred twelve.”
  //    or even worse.
  //    so, get  +123456789 and make it => + 1 2 3 4 5 6 7 8 9
  //
  if (!phoneNumber) {
    return 'No Phone Number'
  }
  return phoneNumber.replace(/\s/g, '').split('').join(' ')
}
