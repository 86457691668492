import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const ChevronLeftIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('chevron-icon chevron-left-icon', className)}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
    >
      <path
        className="fg"
        fill="#242D38"
        d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
      />
      <path fill="none" d="M0 0h24v24H0V0z" />
    </svg>
  )
}
