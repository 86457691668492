import { ofType, Epic } from 'redux-observable'
import { concat, forkJoin, of, race, EMPTY } from 'rxjs'
import { mapTo, mergeMap, take } from 'rxjs/operators'

import { AppActionTypes, InitAppAction } from './actionTypes'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import { initAppSuccessAction } from './actions'

import {
  getUserIdFromCookie,
  refreshAccessToken,
} from '../../app/auth/AuthService'
import {
  loginSuccess,
  loginTokenRetrieved,
  loginFail,
  logout,
} from '../auth/actions'
import { getMeAction } from '../user/actions'
import { UserActionTypes, GetMeSuccessAction } from '../user/actionTypes'
import { getUserPatientRecordAction } from '../patientRecord/actions'
import { AuthActionTypes, LoginSuccessAction } from '../auth/actionTypes'
import { PatientRecordActionTypes } from '../patientRecord/actionTypes'
import { showNotificationAction } from '../common/NotificationItem/actions'
import { logoutCookies } from '@core/api/user'
import { BFFConfigType, env } from '@core/shared/configuration'

const config = env()
export const initEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AppActionTypes.APP_INIT),
    mergeMap(async (action: InitAppAction) => {
      const accessToken = window.localStorage.getItem('accessToken') as string
      const refreshToken = window.localStorage.getItem('refreshToken') as string
      const userId = getUserIdFromCookie()
      const tokenStored = !!accessToken && refreshToken

      if (config.auth.bff === BFFConfigType.ImplictIDS4 && userId) {
        return loginSuccess()
      }

      if (!tokenStored) {
        // no prev session  stored
        notifySuccess(action)
        return initAppSuccessAction()
      }

      // we have prev session stored, try refresh tokens
      try {
        // FIXME: refresh endpoint doesn't work, return token refresh when it fill  be fixed
        const response = await refreshAccessToken()

        notifySuccess(action)
        return [
          loginTokenRetrieved({
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
          }),
          loginSuccess(),
        ]
      } catch (e) {
        window.localStorage.removeItem('accesssToken')
        logoutCookies()

        notifyFail(action)
        return [loginFail(), initAppSuccessAction()]
      }
    }),
    concatActions(),
  )

// to not couple modules on each other, some cross-cutting logic will go here
export const getPatientRecordOnLoadEpic: Epic = (action$) =>
  action$.pipe(
    ofType(UserActionTypes.GET_ME_SUCCCESS),
    mergeMap((action: GetMeSuccessAction) => {
      const userId = action.payload.id
      return of(getUserPatientRecordAction({ userId }))
    }),
  )

export const onLoginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    mergeMap((_: LoginSuccessAction) =>
      config.auth.bff === BFFConfigType.Disabled ? of(getMeAction()) : EMPTY,
    ),
  )

export const completeInitOnLoginEpic: Epic = (action$) =>
  forkJoin([
    action$.ofType(AuthActionTypes.LOGIN_SUCCESS).pipe(take(1)),
    action$.ofType(UserActionTypes.GET_ME_SUCCCESS).pipe(take(1)),
    action$
      .ofType(PatientRecordActionTypes.GET_USER_PATIENT_RECORD_SUCCESS)
      .pipe(take(1)),
  ]).pipe(mapTo(initAppSuccessAction()))

export const completeInitOnLoginFailEpic: Epic = (action$) =>
  forkJoin([
    action$.ofType(AuthActionTypes.LOGIN_SUCCESS).pipe(take(1)),
    race(
      action$.ofType(UserActionTypes.GET_ME_FAIL).pipe(take(1)),
      action$
        .ofType(PatientRecordActionTypes.GET_USER_PATIENT_RECORD_FAIL)
        .pipe(take(1)),
    ),
  ]).pipe(
    mergeMap(() => {
      return concat(
        of(initAppSuccessAction()),
        of(loginFail()),
        of(logout()),
        of(
          showNotificationAction({
            type: 'error',
            title: 'Failed to retrieve user data. Please try to log in again.',
            duration: 5000,
          }),
        ),
      )
    }),
  )

export default [
  initEpic,
  getPatientRecordOnLoadEpic,
  onLoginEpic,
  completeInitOnLoginEpic,
  completeInitOnLoginFailEpic,
]
