import { FieldError, DeepMap } from 'react-hook-form'

import { Address } from '../types/appointment'
import { postCodePlaceholders } from '../data/postCodePlaceholders'

enum CountryCodes {
  ROI = 'IE',
  UK = 'GB',
}

// Can set the postcode to unrequired if country code = Ireland or always unrequired if ROI client
export function isPostCodeRequired(
  selectedCountryCodeForSection: string | undefined,
  baseCountryCode: string,
) {
  const isROICodeSelected = selectedCountryCodeForSection === CountryCodes.ROI
  const unselectedCodeOnROIClient =
    !selectedCountryCodeForSection && baseCountryCode === CountryCodes.ROI
  if (isROICodeSelected || unselectedCodeOnROIClient) return false
  return true
}

export function getPostCodePlaceholder(selectedCountryCode: string) {
  if (selectedCountryCode === CountryCodes.ROI) {
    return postCodePlaceholders[selectedCountryCode]
  } else {
    return postCodePlaceholders[CountryCodes.UK]
  }
}

// Generic, move to different file
export const objectIsNotEmpty = (object?: object) => {
  if (object) {
    return Object.values(object).some((addrPart) => Boolean(addrPart))
  }

  return false
}

export const addressIsNotEmpty = (address?: Address) => {
  if (address && typeof address === 'object') {
    return Object.values(address).some((addrPart) => Boolean(addrPart))
  }

  return false
}

// Currently need for the new DOB component on register
export function setTemporaryRecordDOB(dob: string) {
  // Input is formatted: year-month-day
  if (!dob) return
  const keys = ['year', 'month', 'day']
  const dateValues = dob.split('-')
  const temporaryDOB = dateValues.reduce(
    (obj, currVal, index) => ({
      ...obj,
      [keys[index]]: +currVal,
    }),
    {},
  )
  return temporaryDOB
}

export function getError<T>(
  errors: DeepMap<T, FieldError>,
  field: keyof T,
  errorsMap: Record<string, string>,
) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}
