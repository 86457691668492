import React from 'react'
import cx from 'clsx'

interface IconProps {
  className?: string
  fill?: string
}

export const RightChevronIcon = (props: IconProps) => {
  const { className, fill } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8 12"
      className={cx('right-chevron-icon', className)}
      aria-hidden="true"
    >
      <path
        d="M6.858 5.532L1.518 0.192C1.395 0.068 1.23 0 1.054 0C0.878 0 0.713 0.068 0.59 0.192L0.197 0.585C-0.059 0.841 -0.059 1.257 0.197 1.513L4.681 5.998L0.192 10.487C0.068 10.61 0 10.775 0 10.951C0 11.127 0.068 11.292 0.192 11.415L0.585 11.808C0.709 11.932 0.873 12 1.049 12C1.225 12 1.39 11.932 1.513 11.808L6.858 6.463C6.982 6.339 7.05 6.174 7.05 5.998C7.05 5.821 6.982 5.656 6.858 5.532Z"
        fill={fill || '#242D38'}
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="8"
        height="12"
      >
        <path
          d="M6.858 5.532L1.518 0.192C1.395 0.068 1.23 0 1.054 0C0.878 0 0.713 0.068 0.59 0.192L0.197 0.585C-0.059 0.841 -0.059 1.257 0.197 1.513L4.681 5.998L0.192 10.487C0.068 10.61 0 10.775 0 10.951C0 11.127 0.068 11.292 0.192 11.415L0.585 11.808C0.709 11.932 0.873 12 1.049 12C1.225 12 1.39 11.932 1.513 11.808L6.858 6.463C6.982 6.339 7.05 6.174 7.05 5.998C7.05 5.821 6.982 5.656 6.858 5.532Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="-46" y="-43" width="8" height="12" fill="#242D38" />
      </g>
    </svg>
  )
}
