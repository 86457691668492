import React from 'react'
import cx from 'clsx'

interface IconProps {
  isActive?: boolean
  className?: string
  color?: string
}

export const ForYouIcon = (props: IconProps) => {
  const { isActive, className, color = '#242D38' } = props
  return isActive ? (
    <svg
      viewBox="0 0 28 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('for-you-icon', 'active', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g className="fg" fill={color} fillRule="nonzero">
          <path d="M11.8419591,16.96 L8.82425914,9.42 L7.74465914,12.66 L1.60545914,12.66 C3.25065914,15.22 6.10435914,17.66 9.70665914,20.72 C10.7368591,21.6 11.8327591,22.54 12.9842591,23.54 L13.5049591,24 L14.0259591,23.54 C15.1772591,22.54 16.2731591,21.6 17.3032591,20.72 C20.9055591,17.66 23.7592591,15.22 25.4045591,12.66 C19.5675752,12.0988405 15.7641085,12.0988405 13.9941591,12.66"></path>
          <path d="M19.8913591,0 C16.6503591,0.01 14.5869591,2.22 13.5050591,4.25 C12.4285591,2.23 10.3738591,0 7.17295914,0 C7.15435914,0 7.13665914,0 7.11895914,0 C3.35295914,0.03 0.201359136,3.24 0.00975913577,7.31 C-0.0541408642,8.67 0.197859136,9.9 0.703959136,11.08 L6.60415914,11.08 L8.68985914,4.82 L12.0028591,13.1 L13.0158591,11.08 L26.3059591,11.08 C26.8122591,9.9 27.0642591,8.67 27.0002591,7.31 C26.8087591,3.24 23.6571591,0.03 19.8913591,0 Z"></path>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 29 27"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('for-you-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(1.000000, 1.000000)"
          fillRule="nonzero"
          stroke={color}
          strokeWidth="1.5"
          className="fg"
        >
          <path d="M11.8419591,17.96 L8.82425914,10.42 L7.74465914,13.66 L1.60545914,13.66 C3.25065914,16.22 6.10435914,18.66 9.70665914,21.72 C10.7368591,22.6 11.8327591,23.54 12.9842591,24.54 L13.5049591,25 L14.0259591,24.54 C15.1772591,23.54 16.2731591,22.6 17.3032591,21.72 C20.9055591,18.66 23.7592591,16.22 25.4045591,13.66 L13.9941591,13.66 L11.8419591,17.96 Z"></path>
          <path d="M19.8913591,0 C16.6503591,0.01 14.5869591,2.22 13.5050591,4.25 C12.4285591,2.23 10.3738591,0 7.17295914,0 C7.15435914,0 7.13665914,0 7.11895914,0 C3.35295914,0.03 0.201359136,3.24 0.00975913577,7.31 C-0.0541408642,8.67 0.197859136,9.9 0.703959136,11.08 L6.60415914,11.08 L8.68985914,4.82 L12.0028591,13.1 L13.0158591,11.08 L26.3059591,11.08 C26.8122591,9.9 27.0642591,8.67 27.0002591,7.31 C26.8087591,3.24 23.6571591,0.03 19.8913591,0 Z"></path>
        </g>
      </g>
    </svg>
  )
}
