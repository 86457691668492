import React from 'react'

let idCounter = 0
function generateGradientId() {
  return `icon-consultations-gradient-${++idCounter}`
}

type SVGProps = React.SVGProps<SVGSVGElement>
export type IconConsultationsProps = SVGProps

export const IconConsultations = (props: IconConsultationsProps) => {
  const fillGradientId = generateGradientId()
  const strokeGradientId = generateGradientId()

  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="24"
        cy="24"
        r="17"
        fill={`url(#${fillGradientId})`}
        stroke={`url(#${strokeGradientId})`}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.3658 17.2202C25.5474 17.2202 20.8305 21.9371 20.8305 27.7555C20.8305 33.5739 25.5474 38.2908 31.3658 38.2908C32.9154 38.2908 34.3873 37.956 35.7125 37.355L40.9948 38.1535C41.1866 38.1824 41.3656 38.1123 41.4863 37.9605C41.607 37.8088 41.6353 37.6186 41.564 37.4384L40.0774 33.6808C41.2279 31.9925 41.9011 29.9524 41.9011 27.7552C41.9013 21.9368 37.1844 17.2202 31.3658 17.2202Z"
        fill="#D1D4D8"
        stroke="#D1D4D8"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5353 12C29.3537 12 34.0706 16.7169 34.0706 22.5353C34.0706 28.3537 29.3537 33.0706 23.5353 33.0706C21.9857 33.0706 20.5138 32.7358 19.1886 32.1348L13.9063 32.9333C13.7145 32.9622 13.5355 32.892 13.4148 32.7403C13.2941 32.5886 13.2659 32.3984 13.3372 32.2182L14.8238 28.4606C13.6732 26.7723 13 24.7322 13 22.535C12.9998 16.7166 17.7167 12 23.5353 12Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.378 17.5329C24.9303 17.5329 25.378 17.9806 25.378 18.5329V20.6929L27.538 20.6929C28.0903 20.6929 28.538 21.1407 28.538 21.6929V23.3784C28.538 23.9307 28.0903 24.3784 27.538 24.3784H25.378V26.5385C25.378 27.0908 24.9303 27.5385 24.378 27.5385L22.6925 27.5386C22.1402 27.5386 21.6925 27.0909 21.6925 26.5386V24.3786H19.5325C18.9802 24.3786 18.5325 23.9309 18.5325 23.3786V21.6927C18.5325 21.1405 18.9802 20.6927 19.5325 20.6927L21.6925 20.6927V18.5328C21.6925 17.9805 22.1402 17.5327 22.6926 17.5328L24.378 17.5329Z"
        fill="#DC5760"
      />
      <defs>
        <linearGradient
          id={fillGradientId}
          x1="7.44"
          y1="6.765"
          x2="35.25"
          y2="36.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4616B" />
          <stop offset="1" stopColor="#475A79" />
        </linearGradient>
        <linearGradient
          id={strokeGradientId}
          x1="7.44"
          y1="6.765"
          x2="35.25"
          y2="36.42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4616B" />
          <stop offset="1" stopColor="#475A79" />
        </linearGradient>
      </defs>
    </svg>
  )
}
