import {
  NotificationItemTypes,
  ShowNotificationItemAction,
  HideNotificationItemAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const showNotificationAction = createAction<ShowNotificationItemAction>(
  NotificationItemTypes.SHOW_NOTIFICATION,
)

export const hideNotificationAction = createAction<HideNotificationItemAction>(
  NotificationItemTypes.HIDE_NOTIFICATION,
)
