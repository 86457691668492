import { useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { completedBookings } from '@core/app/bookings/selectors'
import { SpecialtiesItemsType } from '../../specialitiesMap'
import { PastConsultationListItem } from './PastConsultationListItem'
import styles from './PastConsultations.module.scss'

export interface PastConsultationListProps {
  servicesAvailable: Map<string, SpecialtiesItemsType>
}

export const PastConsultationsList = (props: PastConsultationListProps) => {
  const { servicesAvailable } = props
  const pastConsultations = useSelector(completedBookings)
  const [showMore, setShowMore] = useState(false)

  const itemsToRender = useMemo(
    () => (showMore ? pastConsultations : pastConsultations.slice(0, 3)),
    [pastConsultations, showMore],
  )

  return itemsToRender.length ? (
    <div className="section" data-testid="past-consultations">
      <h3 className="text-heading-m font-bold mb-1">Past consultations</h3>
      {itemsToRender.map((item) => (
        <PastConsultationListItem
          servicesAvailable={servicesAvailable}
          pastConsultation={item}
          key={item.id}
        />
      ))}

      {!showMore && pastConsultations?.length > 3 ? (
        <div className={clsx('btn-container', styles.seeMore)}>
          <button onClick={() => setShowMore(true)} className="btn btn-primary">
            See more
          </button>
        </div>
      ) : null}
    </div>
  ) : null
}
