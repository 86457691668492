import React, { useState, useCallback, createRef } from 'react'
import clsx from 'clsx'
import { Link, useHistory, useLocation } from 'react-router-dom'

import HeHeLogo from '@core/styles/images/HeHeLogoCircle.svg'
import HeHeTextLogo from '@core/styles/images/HealthHeroLogo.svg'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { NotificationItem } from '@core/components/app/NotificationItem'
import { AutoLogoutBanner } from '@core/components/app/Banners/AutoLogoutBanner'
import { useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { ButtonLink } from '@core/components/Button/ButtonLink'
import { IconConsultations } from '@core/components/icons/health/48/filled'
import { SliderComponent } from './components/Slider'
import { SliderPagination } from './components/SliderPagination'
import styles from './Landing.module.scss'
import { CPSpecialtiesEnum } from '@core/shared/types/bookings'

type AnonymousBookingLandingContainerProps = Pick<
  AnonymousBookingLandingProps,
  'backgroundImage'
> & {
  children: React.ReactNode
}
const AnonymousBookingLandingContainer = ({
  backgroundImage,
  children,
}: AnonymousBookingLandingContainerProps) => {
  const {
    landingVariables: { backgroundStyles },
  } = useClientVariables()

  const pageBackgroundStyles = {
    ...backgroundStyles,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
    borderRadius: 0,
  }

  const className = styles.anonymousLanding

  return (
    <>
      {/** Mobile landing container -- has a background image. */}
      <main
        className={clsx(className, styles.mobile)}
        style={pageBackgroundStyles}
      >
        {children}
      </main>
      {/** Tablet+ landing container -- does not have a background image. */}
      <main className={clsx(className, styles.tablet)}>{children}</main>
    </>
  )
}

type AnonymousBookingLandingProps = LandingProps
const AnonymousBookingLanding = ({
  backgroundImage,
  clientLogo = HeHeTextLogo,
}: AnonymousBookingLandingProps) => {
  const {
    landingVariables: {
      logoAlt,
      logoWidth,
      missionStatement = 'Here to support your health, all day, every day.',
    },
  } = useClientVariables()

  return (
    <AnonymousBookingLandingContainer backgroundImage={backgroundImage}>
      <header className={clsx('text-body-l', styles.header)}>
        <div className={styles.headerContent}>
          <img
            src={clientLogo}
            alt={logoAlt}
            height="64"
            width={logoWidth}
            className={styles.clientLogo}
          />
          <p>{missionStatement}</p>
        </div>
      </header>
      <div className={styles.anonymousContent}>
        <div>
          <h1 className="text-heading-m mb-2">Discuss your health</h1>
          <p className="text-body-m font-normal mb-2">
            Book an online video call in minutes. Our consultation availability
            is from 08:00 - 22:00.
          </p>
          <section className={clsx('p-2', styles.card)}>
            <header className={styles.cardHeader}>
              <IconConsultations />
              <div className="flex-column">
                <h2 className="text-heading-s">GP Consultations</h2>
                <p className="text-body-m">Book a GP video consultation</p>
              </div>
            </header>
            <div className={styles.cardActionContainer}>
              <ButtonLink
                to={ScreenRoutes.BOOK_CONSULTATION.replace(
                  ':cpSpecialtiesId',
                  CPSpecialtiesEnum.GENERAL_PRACTITIONER,
                )}
                size="medium"
              >
                Book consultation
              </ButtonLink>
            </div>
          </section>
        </div>
        <div className={styles.anonymousActionsContainer}>
          <ButtonLink to={ScreenRoutes.CREATE_ACCOUNT} size="medium">
            Create account
          </ButtonLink>
          <p className="text-body-m font-bold">
            <span className={styles.loginActionText}>
              Already have an account?{' '}
            </span>
            <Link to={ScreenRoutes.LOGIN} className="text-link-m font-bold">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </AnonymousBookingLandingContainer>
  )
}

type DefaultLandingProps = LandingProps

const DefaultLanding = (props: DefaultLandingProps) => {
  const { clientLogo = HeHeLogo, backgroundImage } = props
  const {
    clientVariables: { clientIdentifierParam },
    landingVariables: {
      mainHeader = 'Welcome to GP24',
      logoAlt = 'HealthHero',
      logoWidth,
      missionStatement = 'Here to support your health, all day, every day.',
      partnershipText = null,
      customBlock = null,
      backgroundStyles,
      landingSlider,
    },
  } = useClientVariables()

  const slider = createRef<any>()
  const [slideNum, setSlideNum] = useState(0)
  function handleSlideChange(slideNum: number) {
    setSlideNum(slideNum)
  }
  const sliderProps = {
    slideNum,
    slider,
    handleSlideChange,
  }

  const history = useHistory()
  const location = useLocation()
  const termsRoute = ScreenRoutes.TERMS_AND_CONDS
  const goToTerms = useCallback(() => {
    if (clientIdentifierParam) {
      history.push({
        pathname: termsRoute,
        search: location.search,
      })
    } else {
      history.push(termsRoute)
    }
  }, [clientIdentifierParam, history, location.search, termsRoute])

  const pageBackgroundStyles = {
    ...backgroundStyles,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
  }

  return (
    <main className={clsx('landing', styles.landing)}>
      <div
        className={clsx(styles.content, 'flex-column')}
        style={pageBackgroundStyles}
      >
        <AutoLogoutBanner />
        <NotificationItem />
        <div className={styles.container} data-testid="landing-container">
          <div className={clsx(styles.logoContainer, 'mt-8 mb-8 flex-row')}>
            <img
              src={clientLogo}
              alt={logoAlt}
              data-testid="landing-logo"
              width={logoWidth && logoWidth}
            />
          </div>
          <h1
            className={clsx(
              styles.mainHeader,
              'text-heading-s text-color-white text-center mx-auto',
              partnershipText ? 'mb-0' : 'mb-2',
            )}
          >
            {mainHeader}
          </h1>
          {partnershipText && (
            <p
              className="text-body-m text-center text-color-white mb-2 mx-auto"
              data-testid="landing-partnership"
            >
              {partnershipText}
            </p>
          )}
          <p
            className={clsx(
              styles.mission,
              'text-body-l text-color-white text-center mx-auto',
            )}
            data-testid="landing-statement"
          >
            {missionStatement}
          </p>
          {customBlock && customBlock}
        </div>
        {landingSlider ? <SliderComponent {...sliderProps} /> : null}
        {landingSlider && (
          <div className="pt-6 pb-8">
            <SliderPagination {...sliderProps} />
          </div>
        )}
      </div>
      <div
        className={clsx(styles.btnContainer, 'flex flex-column mx-auto')}
        data-testid="landing-button-container"
      >
        <button
          className="btn btn-primary w-full"
          onClick={goToTerms}
          type="button"
          data-testid="landing-register-button"
        >
          Create account
        </button>
        <p className="text-body font-bold text-center mt-3">
          Already have an account?{' '}
          <Link to={ScreenRoutes.LOGIN} className="text-link-m font-bold">
            Log in
          </Link>
        </p>
      </div>
    </main>
  )
}

export type LandingProps = {
  clientLogo?: string
  backgroundImage?: string
}

export const Landing = (props: LandingProps) => {
  const { bookings } = useFeatures()
  const canBookAnonymously = bookings.anonymousBooking.isEnabled

  return canBookAnonymously ? (
    <AnonymousBookingLanding {...props} />
  ) : (
    <DefaultLanding {...props} />
  )
}
