export * from './AppointmentBookingSlotStatus'

export * from './AppointmentDate'

export * from './AppointmentSlot'

export * from './AppointmentFilter'

export enum AppointmentType {
  PhoneCall = 'Phone',
  VideoCall = 'Video',
  Onsite = 'Onsite',
}

export enum GpGender {
  Male = 'Male',
  Female = 'Female',
  Either = 'Either',
}

export interface Address {
  addressLine1: string
  addressLine2: string
  postCode: string
  city: string
  county: string
  countryCode: string
  description: string
}

export interface AppointmentBooking {
  gpGender: string
  consultationType: string
  phoneNumber: string
  address: Address
  symptoms: string
}

export enum ConsultationStatusEnum {
  Created = 'Created',
  Pending = 'Pending',
  ClinicalPractitionerWaiting = 'ClinicalPractitionerWaiting',
  PatientWaiting = 'PatientWaiting',
  InProgress = 'InProgress',
  Reconnecting = 'Reconnecting',
  SessionClosed = 'SessionClosed',
  PendingConsultationCompletion = 'PendingConsultationCompletion',
  PendingFollowUpActions = 'PendingFollowUpActions',
  CompletedWithoutFollowUpActions = 'CompletedWithoutFollowUpActions',
  CompletedWithFollowUpActions = 'CompletedWithFollowUpActions',
  PendingManualIntervention = 'PendingManualIntervention',
  BookingCancelled = 'BookingCancelled',
}
