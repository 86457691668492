import React from 'react'
import { Dashboard } from '@core/pages/Home/Dashboard'
import logoSrc from '../../assets/images/brand-logo.svg'
import background from '../../assets/images/mobile-background-dashboard.jpg'

export const HomePage = () => (
  <Dashboard
    logoLargeUrl={logoSrc}
    logoSmallUrl={logoSrc}
    backgroundImage={background}
  />
)
