import { useRef } from 'react'
import {
  useCalendarCell,
  mergeProps,
  useFocusRing,
  AriaCalendarCellProps,
} from 'react-aria'
import { CalendarState } from 'react-stately'
import clsx from 'clsx'

import * as styles from './Calendar.module.scss'

type Props = AriaCalendarCellProps & {
  state: CalendarState
}

export const CalendarCell: React.FC<Props> = ({ state, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)

  const {
    cellProps,
    buttonProps,
    formattedDate,
    isSelected,
    isDisabled,
    isOutsideVisibleRange,
    isUnavailable,
  } = useCalendarCell(props, state, ref)

  const { focusProps, isFocusVisible } = useFocusRing()

  return (
    <td {...cellProps}>
      <div
        ref={ref}
        hidden={isOutsideVisibleRange}
        {...mergeProps(buttonProps, focusProps)}
        className={clsx(styles.cell, {
          [styles.selected]: isSelected,
          [styles.focusRing]: isFocusVisible,
          [styles.unavailable]: isUnavailable,
          [styles.disabled]: isDisabled,
        })}
      >
        {formattedDate}
      </div>
    </td>
  )
}
