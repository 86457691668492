import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const InternetIcon = ({ className }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={cx('internet-icon', className)}
      fill="none"
      aria-hidden="true"
    >
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        stroke="#8A93A1"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M11.2808 2.14233C11.027 2.90387 10.2908 4.50309 9.37696 4.80771C8.23466 5.18847 6.33082 5.95001 7.09236 6.71154C7.85389 7.47308 10.5192 5.56924 11.6615 7.09231C12.8038 8.61538 11.2807 8.99615 12.0422 10.1385C12.6515 11.0523 13.5653 11.2808 13.9461 11.2808"
        stroke="#8A93A1"
      />
      <path
        d="M2.90343 12.8039C3.03035 12.677 3.36035 12.4231 3.66497 12.4231C4.04573 12.4231 4.42667 11.6616 4.0459 10.9001C3.66513 10.1385 4.42667 10.1385 6.71127 9.37699C8.53896 8.76776 8.74203 10.3924 8.61511 11.2808C8.74198 11.7885 9.14802 12.8801 9.75725 13.1847C10.3665 13.4893 10.7726 14.0731 10.8996 14.327M2.90343 8.61545C3.79189 8.74238 5.49265 8.38699 5.18804 7.47315C4.80727 6.33085 5.5688 5.56931 4.04573 5.56931C2.52266 5.56931 1.38037 5.18855 1.38037 5.95008C1.38037 6.71162 1.38037 7.09238 1.76113 7.47315C2.06574 7.77777 2.39574 8.36161 2.90343 8.61545Z"
        stroke="#8A93A1"
      />
    </svg>
  )
}
