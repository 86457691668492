import clsx from 'clsx'
import styles from './AppFooter.module.scss'

export const AppFooter = () => {
  return (
    <footer className={clsx(styles.appFooter, 'app-footer')}>
      <div className={styles.wrapper}>
        <p className="text-small-print">
          Copyright &copy; {new Date().getFullYear()} HealthHero Solutions Ltd.
          All rights reserved.
        </p>
        {Boolean(process.env.REACT_APP_VERSION) ? (
          <p className="text-small-print">{process.env.REACT_APP_VERSION}</p>
        ) : null}
      </div>
    </footer>
  )
}
