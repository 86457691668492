import React from 'react'
import { Redirect } from 'react-router-dom'
import { defu } from 'defu'
import { BFFConfigType } from '@core/shared/configuration'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

type DeepPartial<T> = T extends Array<any>
  ? T
  : T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>
    }
  : T

/** Supported services that can be offered to users. */
export type Service =
  | 'GeneralPractitioner'
  | 'Counsellor'
  | 'Physiotherapist'
  | 'SecondOpinion'
  | 'LegalServices'

/** Supported booking types. */
export type BookingType = 'online' | 'phone'

/** Supported consultation types. */
export type ConsultationType = 'phone' | 'video' | 'onsite'

export type FeatureFlags = {
  /**
   * Allows users to create, edit, and book appointments for dependants.
   */
  dependants: boolean
  /**
   * Allows users to view articles, health & wellbeing, and medical information results pages.
   */
  articles: boolean
  /**
   * Allows users to find services near them, within the articles section.
   */
  servicesNearYou: boolean
  /**
   * Allows users to use the "Message a GP" consultation type.
   */
  messageGp: boolean
  /**
   * Allows users to select the location of a service from a map.
   */
  gpPharmacyMap: boolean
  /**
   * Determines if the client identifier field is shown on the user profile.
   * The value is only editable when creating an account.
   * When enabled, the field will be an input that accepts a pin.
   */
  clientIdentifierPin: boolean
  /**
   * Determines if the client identifier field is shown on the user profile.
   * The value is only editable when creating an account.
   * When enabled, the field will be a free-text input.
   */
  clientIdentifierField: boolean
  /**
   * Determines if the user must enter their email address a second time
   * in order to confirm it when creating an account.
   */
  confirmEmailAddress: boolean
  /**
   * Determines if the client identifier field is sent to SAPI with the request to create an account.
   */
  clientIdentifierToSAPI: boolean
  /**
   * Determines if the "symptoms" field is shown during booking and viewing past consultations.
   */
  hasSymptomsField: boolean
  /**
   * Determines if the eligibility check page is shown as part of account creation and the booking process.
   */
  hasEligibility: boolean
  /**
   * Allows the user to use the symptom assessment service.
   */
  symptomAssessment: boolean
  /**
   * Determines if the user is required to supply details of their nominated pharmacy.
   *
   * **Note:** Must be enabled for ROI clients.
   */
  isPharmacyRequired: boolean
  /**
   * Determines if the user is required to supply details of their nominated GP.
   */
  isGPRequired: boolean
  /**
   * Determines if the application should use "standard" (token-based, password grant type) authentication.
   */
  hasStandardAuth: boolean
  /**
   * Determines if the user can see the consultation booking cards on the dashboard.
   */
  hasStandardBookingEntry: boolean
  /** Allows to filter what email domains can be used */
  filterEmail: {
    /** @default false */
    isEnabled: boolean
    /** array of allowed email domains, including @ */
    filterList: string[]
  }
  /** Feature flags related to the about feature. */
  about: {
    /** @default true */
    isEnabled: boolean
  }
  /**
   * Determines the type of BFF authentication that the application should use, if any.
   */
  bff: BFFConfigType
  /** Feature flags related to the bookings feature. */
  bookings: {
    /** Allows users to complete a portion of the booking flow whilst unauthenticated. */
    anonymousBooking: {
      /** @default false */
      isEnabled: boolean
    }
    /** Allows users to choose their preferred gender of GP, if any, when booking. */
    canChoosePreferredGPGender: {
      /** @default true */
      isEnabled: boolean
    }
    /** Determines the services that are available to the user. */
    services: {
      /**
       * Whether any services are allowed to be enabled.
       * @default true
       */
      isEnabled: boolean
      value: {
        [s in Service]: {
          /**
           * Determines if this service is enabled.
           * @default false
           */
          isEnabled: boolean
          /** The types of booking allowed for this service. */
          bookingTypes: {
            [b in BookingType]: boolean
          }
          /** The types of consultation allowed for this service. */
          consultationTypes: {
            [c in ConsultationType]: boolean
          }
        }
      }
    }
    /** Allows users to upload supporting documents to their bookings. */
    uploadSupportingDocuments: {
      /** @default true */
      isEnabled: boolean
    }
    /** Displays appointment dates using Adobe's useCalendar */
    appointmentsCalendar: {
      /** @default false */
      isEnabled: boolean
    }
  }
  /** Feature flags related to past consultations. */
  pastConsultations: {
    /** Allows the user to view the consultation notes and documents section. */
    notesAndDocuments: {
      /** @default true */
      isEnabled: boolean
    }
  }
}

/**
 * Feature flag configuration that clients are permitted to set.
 * Values set here will override feature flag defaults.
 */
export type FeatureFlagOptions = DeepPartial<FeatureFlags>

const featureFlagDefaults: FeatureFlags = {
  articles: false,
  bff: BFFConfigType.Disabled,
  about: {
    isEnabled: true,
  },
  bookings: {
    anonymousBooking: {
      isEnabled: false,
    },
    appointmentsCalendar: {
      isEnabled: false,
    },
    canChoosePreferredGPGender: {
      isEnabled: true,
    },
    services: {
      isEnabled: true,
      value: {
        Counsellor: {
          isEnabled: false,
          bookingTypes: {
            online: false,
            phone: true,
          },
          consultationTypes: {
            onsite: false,
            phone: true,
            video: false,
          },
        },
        GeneralPractitioner: {
          isEnabled: false,
          bookingTypes: {
            online: true,
            phone: true,
          },
          consultationTypes: {
            onsite: false,
            phone: true,
            video: true,
          },
        },
        LegalServices: {
          isEnabled: false,
          bookingTypes: {
            online: false,
            phone: true,
          },
          consultationTypes: {
            onsite: false,
            phone: true,
            video: true,
          },
        },
        Physiotherapist: {
          isEnabled: false,
          bookingTypes: {
            online: false,
            phone: true,
          },
          consultationTypes: {
            onsite: false,
            phone: true,
            video: true,
          },
        },
        SecondOpinion: {
          isEnabled: false,
          bookingTypes: {
            online: false,
            phone: true,
          },
          consultationTypes: {
            onsite: false,
            phone: true,
            video: true,
          },
        },
      },
    },
    uploadSupportingDocuments: {
      isEnabled: true,
    },
  },
  clientIdentifierField: false,
  clientIdentifierPin: false,
  clientIdentifierToSAPI: false,
  confirmEmailAddress: false,
  dependants: false,
  filterEmail: {
    isEnabled: false,
    filterList: [],
  },
  gpPharmacyMap: false,
  hasEligibility: false,
  hasStandardAuth: false,
  hasStandardBookingEntry: false,
  hasSymptomsField: false,
  isGPRequired: true,
  isPharmacyRequired: true,
  messageGp: false,
  pastConsultations: {
    notesAndDocuments: {
      isEnabled: true,
    },
  },
  servicesNearYou: true,
  symptomAssessment: false,
}

const FeaturesContext = React.createContext<FeatureFlags | null>(null)

export type FeaturesProviderProps = {
  children: React.ReactNode
  features: FeatureFlagOptions
}
export const FeaturesProvider = ({
  children,
  features,
}: FeaturesProviderProps) => {
  const value = defu(features, featureFlagDefaults)

  return (
    <FeaturesContext.Provider value={value}>
      {children}
    </FeaturesContext.Provider>
  )
}

export const useFeatures = () => {
  const context = React.useContext(FeaturesContext)
  if (context === null) {
    throw new Error(
      'useFeatures must be called from within a <FeaturesContextProvider> tag.',
    )
  }
  return context
}

export interface FeatureProps {
  children: React.ReactNode
  name: keyof FeatureFlags
}
export const Feature = ({ name, children }: FeatureProps) => {
  const featureFlags = useFeatures()
  if (typeof featureFlags[name] !== 'undefined') {
    return featureFlags[name] ? <>{children}</> : null
  } else {
    return <>{children}</>
  }
}

export type RequireFeaturesProps = {
  children: JSX.Element
  feature: keyof FeatureFlags | (keyof FeatureFlags)[]
  redirectTo?: string
}
export const RequireFeature = ({
  children,
  feature,
  redirectTo = ScreenRoutes.HOME,
}: RequireFeaturesProps) => {
  const featureFlags = useFeatures()
  const features = Array.isArray(feature) ? feature : [feature]
  const canUseAllFeatures = features.every((feat) => featureFlags[feat])

  return canUseAllFeatures ? children : <Redirect to={redirectTo} />
}
