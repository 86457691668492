import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { filledPatientRecord } from '@core/app/patientRecord/selectors'
import { upcomingBookings } from '@core/app/bookings/selectors'
import { RightChevronIcon } from '../../icons/RightChevron'
import { ErrorIcon } from '../../icons/ErrorIcon'
import styles from './FillinProfileBanner.module.scss'
import clsx from 'clsx'

export const FillInProfileBanner = () => {
  const history = useHistory()

  const isProfileFilledIn = useSelector(filledPatientRecord)
  const bookings = useSelector(upcomingBookings)

  const bookingsExist = useMemo(() => Boolean(bookings.length), [bookings])

  const handleClick = useCallback(() => {
    history.push(ScreenRoutes.EDIT_PROFILE)
  }, [history])

  return bookingsExist ? (
    <div
      className={clsx(
        styles.fillProfileWrapper,
        'flex-row space-between action-cursor general-banner-wrapper mb-2',
      )}
      onClick={handleClick}
      data-testid="fill-profile-banner"
    >
      <div className="flex-center">
        <div className="fill-profile-icon mr-1">
          <ErrorIcon className="fill-profile-banner-icon general-banner-icon" />
        </div>
        <div className="fill-profile-content text-sm">
          {isProfileFilledIn ? (
            <p className="text-weight-lg">
              Prior to your consultation please check your GP details and
              allergies are up to date
            </p>
          ) : (
            <div className="flex-row column-text">
              <p className="text-weight-lg">
                Please fill in your allergies and GP details
              </p>
              <p>Prior to your consultation</p>
            </div>
          )}
        </div>
      </div>
      <div className="fill-profile-arrow general-banner-arrow">
        <RightChevronIcon />
      </div>
    </div>
  ) : null
}
