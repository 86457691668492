export enum ScreenRoutes {
  HOME = '/',
  TERMS_AND_CONDS = '/terms-and-conditions',
  ELIGIBILITY = '/confirm-eligibility/',
  CREATE_ACCOUNT = '/create-account',
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/p/resetPassword',
  DASHBOARD = '/dashboard',
  BOOKING_ELIGIBILITY = '/confirm-eligibility/:cpSpecialtiesId',
  CHOOSE_CONSULTATION = '/ways-to-book/:cpSpecialtiesId',
  BOOK_CONSULTATION = '/book-consultation/:cpSpecialtiesId',
  BOOK_CONSULTATION_STEP_2 = '/book-consultation/step-2',
  BOOK_CONSULTATION_STEP_3 = '/book-consultation/step-3',
  ARTICLES = '/articles',
  PROFILE = '/profile',
  EDIT_PROFILE = '/edit-profile',
  SELECT_SERVICE = '/select-service/:serviceType',
  FOR_YOU = '/for-you',
  DEPENDENTS = '/dependants',
  EDIT_CONTACT = '/edit-contact/:id',
  ADD_NEW_CONTACT = '/add-new-contact',
  CHANGE_PASSWORD = '/security/change-password',
  ABOUT = '/about',
  EMERGENCY_DISCLAIMER = '/emergency-disclaimer',
  MESSAGE_DR = '/message-gp',
  EDIT_GP_CONSULTATION = '/edit-gp-consultation',
  VIEW_LAST_CONSULTATION = '/view-last-consultation/:bookingId',
  CONSULTATION_BOOKED = '/book-consultation/booked',
  EMAIL = '/e',
  VERIFY_EMAIL = '/e/verify',
  ACCOUNT_CREATED = '/account-created',
  JOIN_CONSULTATION = '/join/:consultationId',
  HEALTH_WELLBEING = '/health-wellbeing',
  MEDICAL_INFORMATION_RESULTS = '/medical-information-results',
  ARTICLE = '/article',
  HEALTH_SERVICES = '/health-services',
  SYMPTOMASSESSMENT = '/symptom-assessment',
  PAYMENT_CONFIRMED = '/payment-confirmed',
  BOOKING_COMPLETED = '/booking-completed/:bookingId',
}
