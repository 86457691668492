import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const IconArrowUp = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('arrow-up-icon', className)}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 15 12 7.5l7.5 7.5"
      />
    </svg>
  )
}
