import { Link } from 'react-router-dom'
import { ButtonSize, ButtonVariant, useButtonStyles } from './Button'

type LinkProps = React.ComponentPropsWithoutRef<typeof Link>
export type ButtonLinkProps = Omit<LinkProps, 'className'> & {
  size?: keyof typeof ButtonSize
  type?: keyof typeof ButtonVariant
}

export const ButtonLink = ({
  size = 'large',
  type = 'primary',
  ...props
}: ButtonLinkProps) => {
  const className = useButtonStyles({
    size,
    type,
  })

  return <Link {...props} className={className} />
}
