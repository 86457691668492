import { useFormContext, useWatch } from 'react-hook-form'

import { useFeatures } from '../features/features'
import { ServiceValues } from './ServiceDetailOptions'

export function useServiceValidation(serviceType: ServiceValues) {
  const { isPharmacyRequired, isGPRequired } = useFeatures()
  const { errors, formState } = useFormContext()
  const isRegisteredWithGP = useWatch<unknown>({ name: 'isRegisteredWithGP' })

  const isRequired =
    serviceType === ServiceValues.GP ? isGPRequired : isPharmacyRequired

  const hasFieldError = {
    gp: Boolean(errors.gp?.surgery),
    nominatedPharmacy: Boolean(errors.nominatedPharmacy),
  }
  if (!isRequired && !hasFieldError[serviceType]) {
    return null
  }

  if (!isRegisteredWithGP && serviceType === ServiceValues.GP) {
    return false
  }

  if (formState.isSubmitted) {
    return hasFieldError[serviceType]
  }
}
