import { useState } from 'react'
import { FieldError, useForm, DeepMap } from 'react-hook-form'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { TextInput } from '@core/components/form/TextInput'
import { PasswordInput } from '@core/components/form/PasswordInput'
import { PageHeader } from '@core/components/app/PageHeader'
import { useActionAsync } from '@core/shared/useAction'
import { login as loginAction } from '@core/app/auth/actions'
import { isValidEmailStrict } from '@core/components/validation/email'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

const errorsMap: Record<string, string> = {
  email: 'Enter valid email address',
  required: 'Required field',
}

function getError<T>(errors: DeepMap<T, FieldError>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const Login = () => {
  const { register, handleSubmit, errors, formState } = useForm<{
    userName: string
    password: string
  }>()
  const [error, setError] = useState('')
  const login = useActionAsync(loginAction)

  const history = useHistory()
  const location = useLocation()

  const onSubmit = async (data: { userName: string; password: string }) => {
    if (error) {
      setError('')
    }
    try {
      await login(data)
      const url =
        new URLSearchParams(location.search).get('ret') ||
        ScreenRoutes.DASHBOARD
      history.push(url)
    } catch (e) {
      setError((e as string) || 'Something went wrong. Please contact support')
    }
  }

  return (
    <div className="page login-page">
      <PageHeader showBg backBtn title="Login" />
      <main className="page-wrapper no-sidebar">
        <form onSubmit={handleSubmit(onSubmit)}>
          {Boolean(error) && (
            <div role="alert" className="error-text text-center mb-1 mt-1">
              {error}
            </div>
          )}
          <TextInput
            label=""
            placeholder="E-mail address"
            className="w-full"
            name="userName"
            error={getError(errors, 'userName')}
            inputRef={register({
              validate: {
                email: isValidEmailStrict,
              },
            })}
          />
          <PasswordInput
            label=""
            placeholder="Password"
            inputRef={register({ required: true })}
            name="password"
            className="mt-1"
          />
          <div className="forgot-password">
            <Link
              to={ScreenRoutes.FORGOT_PASSWORD}
              data-testid="forgot-password-link"
              className="text-link-s mb-5 mt-1 text-right display-block"
            >
              Forgot password?
            </Link>
          </div>
          <div className="btn-container">
            <button
              disabled={formState.isSubmitting}
              className="btn btn-primary w-full"
              type="submit"
              aria-busy={formState.isSubmitting ? 'true' : 'false'}
              aria-live="polite"
            >
              {formState.isSubmitting ? 'Logging in...' : 'Log in'}
            </button>
          </div>
        </form>
      </main>
    </div>
  )
}
