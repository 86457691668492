import React, { memo } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import { isExternalUrl } from '@core/shared/url'
import { rootNav as baseNav } from './navData'
import { useFeatures } from './features/features'
import { useClientVariables } from './features/variables'

type AnchorAttributes = React.AnchorHTMLAttributes<HTMLAnchorElement>
type FooterNavItemProps = AnchorAttributes & { href: string; isActive: boolean }

const FooterNavItem = ({
  children,
  isActive,
  href,
  ...props
}: FooterNavItemProps) => {
  const className = clsx(`page-footer-nav-item`, props.className, {
    active: isActive,
  })

  return isExternalUrl(href) ? (
    <a {...props} href={href} className={className}>
      {children}
    </a>
  ) : (
    <Link {...props} to={href} className={className}>
      {children as any}
    </Link>
  )
}

const useNavItems = () => {
  const featureFlags = useFeatures()
  const { clientData } = useClientVariables()

  const isArticlesEnabled = featureFlags.articles
  const mobileNavConfig = clientData?.navigation?.mobile

  const leftItem = mobileNavConfig?.left ?? baseNav.home
  const centerItem =
    mobileNavConfig?.center ?? (isArticlesEnabled ? baseNav.articles : null)
  const rightItem = mobileNavConfig?.right ?? baseNav.profile

  const items = [
    {
      Icon: leftItem.Icon,
      title: leftItem.title,
      href: 'href' in leftItem ? leftItem.href : leftItem.rootPath,
      routes: 'routes' in leftItem ? leftItem.routes : [],
    },
  ]

  if (centerItem) {
    items.push({
      Icon: centerItem.Icon,
      title: centerItem.title,
      href: 'href' in centerItem ? centerItem.href : centerItem.rootPath,
      routes: 'routes' in centerItem ? centerItem.routes : [],
    })
  }

  items.push({
    Icon: rightItem.Icon,
    title: rightItem.title,
    href: 'href' in rightItem ? rightItem.href : rightItem.rootPath,
    routes: 'routes' in rightItem ? rightItem.routes : [],
  })

  return items
}

interface PageFooterProps {
  stickyBottom?: boolean
}

export const PageFooter = memo<PageFooterProps>(function PageFooter(props) {
  const { stickyBottom } = props

  const match = useRouteMatch()
  const navItems = useNavItems()

  return (
    <div
      role="navigation"
      className={clsx(stickyBottom && 'bottom-sticky-footer-wrapper')}
    >
      <div className="page-footer">
        <div className="page-footer-content">
          {navItems.map((item) => {
            const isActive = item.routes?.includes(match.path) ?? false

            return (
              <FooterNavItem
                key={item.href}
                href={item.href}
                isActive={isActive}
              >
                <div className="pager-footer-content-item">
                  <div className="pager-footer-content-icon">
                    <item.Icon isActive={isActive} />
                  </div>
                  <div className="pager-footer-content-title">{item.title}</div>
                </div>
              </FooterNavItem>
            )
          })}
        </div>
      </div>
    </div>
  )
})
