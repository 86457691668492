import {
  AuthActionTypes,
  LoginAction,
  LoginTokenRetrievedAction,
  LoginSuccessAction,
  LoginFailAction,
  LogoutAction,
  LogoutSuccessAction,
  SsoAuthenticationSuccess,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const login = createAction<LoginAction>(AuthActionTypes.LOGIN)

export interface AuthTokenParams {
  token: string
  refreshToken: string
}

export const loginSuccess = createAction<LoginSuccessAction>(
  AuthActionTypes.LOGIN_SUCCESS,
)

export const loginTokenRetrieved = createAction<LoginTokenRetrievedAction>(
  AuthActionTypes.LOGIN_TOKEN_RETRIEVED,
)

export const loginFail = createAction<LoginFailAction>(
  AuthActionTypes.LOGIN_FAIL,
)

export const logout = createAction<LogoutAction>(AuthActionTypes.LOGOUT)

export const logoutSuccess = createAction<LogoutSuccessAction>(
  AuthActionTypes.LOGOUT_SUCCESS,
)

export const ssoAuthenticationSuccess = createAction<SsoAuthenticationSuccess>(
  AuthActionTypes.SSO_AUTHENTICATION_SUCCESS,
)
