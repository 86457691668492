import { SpecialtiesItemsType } from '@core/components/specialitiesMap'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  pageContent: SpecialtiesItemsType
}

export const ServicesAccordion = ({ pageContent }: Props) => {
  return (
    <div className="section accordion-wrapper">
      {pageContent?.pageFooter?.map((el: any, index: number) => (
        <details className="accordion-item" key={index}>
          <summary>{el.pageFooterTitle}</summary>
          <div>{ReactHtmlParser(el.pageFooterText)}</div>
        </details>
      ))}
    </div>
  )
}
