import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const ServicesWayIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 43 43"
      className={cx('services-way-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#475a79" cx="21.5" cy="21.5" r="21.5"></circle>
          <path
            d="M30.5929 22.5253H23.2659V20.1976H26.1425C26.2239 20.1976 26.3053 20.1699 26.3867 20.1145L30.8372 16.7892C30.9457 16.706 31 16.5952 31 16.4566C31 16.3181 30.9457 16.2072 30.8372 16.1241L26.4138 12.7711C26.3324 12.7157 26.251 12.688 26.1696 12.688H23.2659V10.4157C23.2659 10.194 23.076 10 22.8589 10H19.1411C18.924 10 18.7341 10.194 18.7341 10.4157V12.688H11.4071C11.19 12.688 11 12.8819 11 13.1036V19.7819C11 20.0036 11.19 20.1976 11.4071 20.1976H18.7341V22.5253H15.8575C15.7761 22.5253 15.6947 22.553 15.6133 22.6084L11.1628 25.9337C11.0543 26.0169 11 26.1554 11 26.2663C11 26.3771 11.0543 26.5157 11.1628 26.5988L15.6133 29.9518C15.6947 30.0072 15.7761 30.0349 15.8575 30.0349H18.7341V32.5843C18.7341 32.806 18.924 33 19.1411 33H22.8589C23.076 33 23.2659 32.806 23.2659 32.5843V30.0349H30.5929C30.81 30.0349 31 29.841 31 29.6193V22.941C31 22.7193 30.8372 22.5253 30.5929 22.5253ZM19.5482 10.8313H22.4518V12.688H19.5482V10.8313ZM11.8141 13.5193H19.1411H22.8589H26.0339L29.9145 16.4566L26.0339 19.3663H22.8589H19.1411H11.8141V13.5193ZM19.5482 20.1976H22.4518V22.5253H19.5482V20.1976ZM22.4518 32.1687H19.5482V30.0349H22.4518V32.1687ZM30.1859 29.2036H22.8589H19.1411H15.9661L12.0855 26.2663L15.9661 23.3566H19.1411H22.8589H30.1859V29.2036Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}
