import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const CloseIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      className={cx('close-icon', className)}
      role="img"
    >
      <title>Close</title>
      <path
        d="M9.76217 7.9995L15.7444 2.017C15.9089 1.8524 15.9997 1.6327 16 1.3984C16 1.164 15.9092 0.944 15.7444 0.7796L15.2202 0.2555C15.0553 0.0904 14.8356 0 14.6011 0C14.367 0 14.1473 0.0904 13.9824 0.2555L8.00027 6.2375L2.01778 0.2555C1.85328 0.0904 1.63338 0 1.39898 0C1.16488 0 0.944975 0.0904 0.780475 0.2555L0.255975 0.7796C-0.085325 1.1209 -0.085325 1.6761 0.255975 2.017L6.23828 7.9995L0.255975 13.9817C0.091275 14.1466 0.000675 14.3663 0.000675 14.6006C0.000675 14.8349 0.091275 15.0546 0.255975 15.2194L0.780375 15.7435C0.944875 15.9084 1.16488 15.999 1.39887 15.999C1.63328 15.999 1.85307 15.9084 2.01767 15.7435L8.00008 9.7613L13.9823 15.7435C14.1471 15.9084 14.3668 15.999 14.601 15.999H14.6012C14.8355 15.999 15.0552 15.9084 15.22 15.7435L15.7443 15.2194C15.9088 15.0547 15.9996 14.8349 15.9996 14.6006C15.9996 14.3663 15.9088 14.1466 15.7443 13.9818L9.76217 7.9995Z"
        fill="#242D38"
      />
    </svg>
  )
}
