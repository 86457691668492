import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const VideoCallIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('video-call-icon', className)}
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <svg aria-hidden="true" />
      <g clipPath="url(#clip0_405_1024)">
        <path
          d="M7.39439 5.70703H14.2515C14.7062 5.70703 15.1422 5.90535 15.4637 6.25836C15.7852 6.61137 15.9658 7.09015 15.9658 7.58938V13.2364C15.9658 13.3613 15.9207 13.4809 15.8403 13.5692C15.7599 13.6575 15.6509 13.707 15.5372 13.707H8.68011C8.22545 13.707 7.78941 13.5087 7.46792 13.1557C7.14643 12.8027 6.96582 12.3239 6.96582 11.8247V6.17762C6.96582 6.05281 7.01097 5.93312 7.09135 5.84486C7.17172 5.75661 7.28073 5.70703 7.39439 5.70703V5.70703Z"
          stroke="#8A93A1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9658 8.70703L18.9658 6.70703V12.707L15.9658 10.707"
          stroke="#8A93A1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.46582 18.7072L6.54369 22.4666L5.46582 18.7072H5.05889C2.82689 18.7072 1.0267 16.8805 1.05932 14.6487L1.20119 4.94155C1.23314 2.75543 3.01442 1 5.20076 1H20.673C22.8821 1 24.673 2.79086 24.673 5V14.4948C24.673 16.6722 22.9314 18.4497 20.7544 18.4939L10.2782 18.7071L9.46582 18.7072Z"
          stroke="#8A93A1"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_405_1024">
          <rect width="26" height="24" fill="#8A93A1" />
        </clipPath>
      </defs>
    </svg>
  )
}
