import { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { AllergyReminderIcon } from '@core/components/icons/AllergyReminderIcon'
import { SideMenu } from '@core/components/app/SideMenu'
import { PrescriptionIcon } from '@core/components/icons/PrescriptionIcon'
import { AppointmentBookingIcon } from '@core/components/icons/AppointmentBookingIcon'
import { PhoneCallIcon } from '@core/components/icons/PhoneCallIcon'

import { route } from '@core/shared/route'
import { CPSpecialties, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { selectUserPatientRecord } from '@core/app/patientRecord/selectors'
import { RightChevronIcon } from '@core/components/icons/RightChevron'

import { WaysToBookOther } from './WaysToBookOther'
import {
  specialtiesItems,
  SpecialtiesItemsType,
} from '@core/components/specialitiesMap'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Service, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { GPConsultationCopy } from '@core/shared/types/waysToBook/GPConsultation'
import { consolidateCopy } from '@core/shared/utils/consolidateCopy'

const useBaselineCopy = (): GPConsultationCopy => {
  const { bookings } = useFeatures()

  const hasCallToBook =
    bookings?.services?.value?.GeneralPractitioner?.bookingTypes?.phone ?? true

  const pageIntro = [
    'Consultations between 08:00 - 22:00 can be booked at any time using the app.',
  ]

  if (hasCallToBook) {
    pageIntro[0] +=
      ' To book a consultation outside of these hours, please phone our 24-hour customer service team.'
    pageIntro.push(
      'If you require the translation service, please call to make your booking.',
    )
  }

  return {
    pageHeader: 'Ways to book',
    pageIntro,
    bookOnline: {
      header: 'Book online',
      content:
        'Book a video or phone consultation between 08:00 - 22:00 online in minutes',
      footer: 'Available 08:00 - 22:00',
    },
    callToBook: {
      header: 'Call to book',
      content:
        'Speak to a member of our 24-hour customer service team to book your appointment',
      footer: 'Available 24 hours',
    },
    prescriptions: {
      header: 'Prescriptions and specialist referrals',
      content:
        'Prescription medication and open referral letters are subject to a consultation with the GP.',
      footer: 'Find out more',
    },
  }
}

type OverrideCopy = Partial<GPConsultationCopy>
type OverrideSpecialties = Partial<SpecialtiesItemsType>

interface Props {
  text?: OverrideCopy
  gpSpecialties?: OverrideSpecialties
  achesPainsSpecialties?: OverrideSpecialties
  counsellingSpecialties?: OverrideSpecialties
  secondOpinionSpecialties?: OverrideSpecialties
  prescriptionsHref?: string
}

export const WaysToBook = ({
  text,
  gpSpecialties,
  achesPainsSpecialties,
  counsellingSpecialties,
  secondOpinionSpecialties,
  prescriptionsHref = 'https://www.healthhero.com/terms-and-conditions/#private-prescription-service',
}: Props) => {
  const history = useHistory()
  const baselineCopy = useBaselineCopy()
  const copy = text ? consolidateCopy(baselineCopy, text) : baselineCopy
  const { cpSpecialtiesId } = useParams<{ cpSpecialtiesId: string }>()

  const [pageContent, setPageContent] = useState<
    SpecialtiesItemsType | undefined
  >()
  const { bookings } = useFeatures()
  const {
    clientVariables: { phoneNumber },
  } = useClientVariables()

  const currentServiceId = CPSpecialties[cpSpecialtiesId] as Service
  const currentService = bookings.services.value[currentServiceId]
  const hasCallToBookOption = currentService?.bookingTypes.phone

  useEffect(() => {
    const originalContent = specialtiesItems.get(currentServiceId)

    function displayPageContent<SpecialtiesItemsType>(
      updatedCopy: SpecialtiesItemsType,
    ) {
      if (originalContent === undefined) {
        throw new Error(
          `No specialties item could be found for service: ${currentServiceId}`,
        )
      }

      return updatedCopy
        ? consolidateCopy(originalContent, updatedCopy)
        : originalContent
    }

    const isServiceAvailable = currentService?.isEnabled === true

    if (!isServiceAvailable || !originalContent) {
      history.push(ScreenRoutes.DASHBOARD)
    } else {
      let content
      if (
        achesPainsSpecialties &&
        cpSpecialtiesId === CPSpecialtiesEnum.PHYSIOTHERAPIST
      ) {
        content = displayPageContent(achesPainsSpecialties)
      } else if (
        counsellingSpecialties &&
        cpSpecialtiesId === CPSpecialtiesEnum.COUNSELLOR
      ) {
        content = displayPageContent(counsellingSpecialties)
      } else if (
        secondOpinionSpecialties &&
        cpSpecialtiesId === CPSpecialtiesEnum.SECOND_OPINION
      ) {
        content = displayPageContent(secondOpinionSpecialties)
      } else {
        content = displayPageContent(gpSpecialties)
      }

      setPageContent(content)
    }
  }, [
    cpSpecialtiesId,
    currentService,
    currentServiceId,
    gpSpecialties,
    counsellingSpecialties,
    achesPainsSpecialties,
    secondOpinionSpecialties,
    history,
  ])

  const patientRecord = useSelector(selectUserPatientRecord)

  const goToBookOnline = useCallback(() => {
    if (!patientRecord?.attributes.emailIsVerified) {
      return
    }
    history.push(
      route(ScreenRoutes.BOOK_CONSULTATION, {
        cpSpecialtiesId,
      }),
    )
  }, [cpSpecialtiesId, history, patientRecord])

  const goToPrescriptions = () => window.open(prescriptionsHref)

  return (
    <div className="page ways-to-book-page">
      {pageContent !== undefined && (
        <PageHeader showBg backBtn title={pageContent.headerTitle} />
      )}
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          {CPSpecialtiesEnum.GENERAL_PRACTITIONER === cpSpecialtiesId ? (
            <>
              <div className="section">
                <div
                  className="allergy-reminder-wrapper"
                  test-id="allergy-wrapper"
                >
                  <Link
                    className="allergy-reminder-link"
                    to={ScreenRoutes.EMERGENCY_DISCLAIMER}
                  >
                    <div className="paper w-full">
                      <div className="card-header card-header-text w-full">
                        <div className="allergy-reminder-heeader-wrapper w-full">
                          <div style={{ marginRight: '16px' }}>
                            <AllergyReminderIcon />
                          </div>
                          <p className="allergy-reminder-title text-weight-md w-full text-color-primary-secondary">
                            Please do not use this service for emergencies
                            <span className="allergy-reminder-subtitle text-color-primary">
                              More information
                              <span className="text-weight-md text-md">
                                <RightChevronIcon />
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="section">
                <h2 className="section-title">{copy.pageHeader}</h2>
                <div className="text-smd mt-2 mb-2 text-weight-md text-color-primary">
                  {copy.pageIntro.map((paragraph, key) => (
                    <p className="mb-1" key={key}>
                      {paragraph}
                    </p>
                  ))}
                </div>
                <div className="card-row">
                  <button
                    className="card action-card"
                    test-id="book-online"
                    onClick={goToBookOnline}
                  >
                    <div className="card-header">
                      <div className="mr-1 flex-center">
                        <AppointmentBookingIcon />
                      </div>
                      <h3 className="card-header-text text-color-primary">
                        {copy.bookOnline.header}
                      </h3>
                    </div>
                    <div
                      className="card-content"
                      test-id="book-online--content"
                    >
                      {patientRecord?.attributes.emailIsVerified ? (
                        <p className="text-color-secondary text-smd text-weight-md">
                          {copy.bookOnline.content}
                        </p>
                      ) : (
                        <p className="text-color-warning text-smd text-weight-md">
                          You must confirm your email in order to start using
                          booking services
                        </p>
                      )}
                    </div>
                    <div className="card-footer" test-id="book-online--footer">
                      <p className="footer-text text-color-secondary text-sm">
                        {copy.bookOnline.footer}
                      </p>
                      <span className="footer-arrow">
                        <RightChevronIcon />
                      </span>
                    </div>
                  </button>

                  {hasCallToBookOption && (
                    <>
                      <div
                        className="card call-to-book-mobile-card"
                        test-id="call-book-sm"
                      >
                        <a
                          href={`tel:${phoneNumber}`}
                          className="call-to-book-link"
                        >
                          <div className="card-header">
                            <div className="mr-1 flex-center">
                              <PhoneCallIcon />
                            </div>
                            <h3 className="card-header-text text-color-primary">
                              {copy.callToBook.header}
                            </h3>
                          </div>
                          <div
                            className="card-content"
                            test-id="call-book-sm--content"
                          >
                            <div className="call-to-book-phone-number text-weight-md">
                              <p>{phoneNumber}</p>
                            </div>
                            <p className="text-color-secondary text-smd text-weight-md mt-1">
                              {copy.callToBook.content}
                            </p>
                          </div>
                          <div
                            className="card-footer"
                            test-id="call-book-sm--footer"
                          >
                            <p className="text-color-secondary text-sm text-weight-normal">
                              {copy.callToBook.footer}
                            </p>
                            <span className="footer-arrow">
                              <RightChevronIcon />
                            </span>
                          </div>
                        </a>
                      </div>
                      <div
                        className="card call-to-book-desktop-card"
                        test-id="call-book-lg"
                      >
                        <div className="card-header">
                          <div className="mr-1 flex-center">
                            <PhoneCallIcon />
                          </div>
                          <h3 className="card-header-text">
                            {copy.callToBook.header}
                          </h3>
                        </div>
                        <div
                          className="card-content call-to-book-phone-number text-weight-md"
                          test-id="call-book-lg--content"
                        >
                          <p>{phoneNumber}</p>
                        </div>
                        <div
                          className="card-footer"
                          test-id="call-book-lg--footer"
                        >
                          <p className="text-color-secondary text-smd text-weight-md">
                            {copy.callToBook.content}
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="section">
                <div className="cards-bottom-wrapper">
                  <button
                    className="card prescription-card action-card"
                    data-testid="prescriptions"
                    onClick={goToPrescriptions}
                  >
                    <div className="card-header">
                      <div className="mr-1 flex-center">
                        <PrescriptionIcon />
                      </div>

                      <h3 className="card-header-text text-color-primary">
                        {copy.prescriptions.header}
                      </h3>
                    </div>
                    <div
                      className="card-content"
                      test-id="prescriptions--content"
                    >
                      <p className="text-color-secondary text-smd text-weight-md">
                        {copy.prescriptions.content}
                      </p>
                    </div>
                    <div
                      className="card-footer"
                      test-id="prescriptions--footer"
                    >
                      <p className="footer-text text-color-secondary text-sm">
                        {copy.prescriptions.footer}
                      </p>
                      <span className="footer-arrow">
                        <RightChevronIcon />
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </>
          ) : (
            pageContent && (
              <WaysToBookOther
                pageContent={pageContent}
                cpSpecialtiesId={cpSpecialtiesId}
              />
            )
          )}
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
}
