import { memo, useState, useEffect, useCallback, useMemo } from 'react'
import dayjs from 'dayjs'

import { BookingType, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { AppointmentType } from '@core/shared/types/appointment'
import { SpecialtiesItemsType } from '../../../specialitiesMap'
import { GpConsultationIcon } from '../../../icons/GpConsultationIcon'
import { AddConsultationToCalendarLink } from '../../addToCalendar'
import { useClientVariables } from '../../features/variables'
import styles from '../UpcomingBookings.module.scss'
import { UpcomingBookingActions } from './UpcomingConsultationActions'
import { PhoneCallIcon, VideoCallIcon } from '../../../icons'
import clsx from 'clsx'
import { env } from '@core/shared/configuration'

interface BookingItemProps {
  booking: BookingType
  specialtiesItems: Map<string, SpecialtiesItemsType>
  practitionerTitle?: string
}

export const BookingItem = memo<BookingItemProps>(function BookingItem({
  booking,
  specialtiesItems,
  practitionerTitle = 'GP',
}) {
  const {
    clientVariables: { clientName },
  } = useClientVariables()
  const { clientURL } = env()

  const patientName = `${booking?.relationships?.patientRecord?.attributes?.firstName} ${booking?.relationships?.patientRecord?.attributes?.lastName}`

  const consultationType =
    booking?.relationships?.clinicalPractitioner?.specialties[0]
  const consultationSpecialties = specialtiesItems.get(consultationType)

  const isVideoConsultation = useMemo(
    () => booking.attributes.consultationType === AppointmentType.VideoCall,
    [booking.attributes.consultationType],
  )

  const startTime = booking.relationships?.appointment?.attributes?.start

  const getRemainingTimeToConsultation = useCallback(
    (startTime: string) => dayjs(startTime).diff(dayjs(), 'second'),
    [],
  )

  const remainingTime = getRemainingTimeToConsultation(startTime)

  const doctorJoined =
    !!booking.relationships?.consultation?.attributes?.isPatientAllowedToJoin

  const [shouldStartCallShown, setShouldStartCallShown] = useState(
    !!booking.relationships?.consultation?.attributes?.isPatientAllowedToJoin,
  )

  const ConsultationTitle = () => {
    if (!consultationSpecialties) {
      return <h3>GP Consultation</h3>
    }

    if (
      consultationSpecialties?.specialtiesType !==
      CPSpecialtiesEnum.GENERAL_PRACTITIONER
    ) {
      return <h3>{consultationSpecialties.headerTitle}</h3>
    }

    if (isVideoConsultation) {
      return <h3>GP Video Consultation</h3>
    } else {
      return <h3>GP Phone Consultation</h3>
    }
  }

  const ConsultationIcon = () => {
    if (!consultationSpecialties) {
      return <GpConsultationIcon />
    }

    if (isVideoConsultation) {
      return <VideoCallIcon className="white" />
    }

    if (!isVideoConsultation) {
      return <PhoneCallIcon className="white" />
    }

    return <GpConsultationIcon />
  }

  useEffect(() => {
    if (remainingTime <= 60) {
      setShouldStartCallShown(true)
    } else {
      setShouldStartCallShown(doctorJoined)
    }
  }, [remainingTime, doctorJoined])

  return (
    <div
      data-testid="upcoming-booking-up-wrapper"
      className={clsx(styles.upcomingBookingWrapper, 'upcoming-booking')}
    >
      <div className={styles.upcomingBookingIcon}>
        <div>
          <ConsultationIcon />
        </div>
      </div>
      <div className={styles.upcomingBookingInfo}>
        <ConsultationTitle />

        <p className={styles.patientName}>For {patientName}</p>

        {!shouldStartCallShown && (
          <div className={styles.meta}>
            <div className={styles.metaDate}>
              <p data-testid="upcoming-booking-date">
                {dayjs(startTime).format('DD MMM YYYY')}
              </p>
              <p data-testid="upcoming-booking-time">
                {dayjs(startTime).format('HH:mm')}
              </p>
            </div>
            <div className={styles.metaAddToCalendar}>
              <AddConsultationToCalendarLink
                clientName={clientName}
                clientURL={clientURL}
                specialty={consultationType}
                startTime={startTime}
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.upcomingBookingActions}>
        <UpcomingBookingActions
          practitionerTitle={practitionerTitle}
          shouldStartCallShown={shouldStartCallShown}
          isVideoConsultation={isVideoConsultation}
          booking={booking}
        />
      </div>
    </div>
  )
})
