import { produce } from 'immer'

import {
  PatientRecord,
  Patient,
} from '@core/shared/types/patient/PatientRecord'
import {
  PatientRecordActionTypes,
  UpdateUserPatientRecordSuccessAction,
  CreateDependentPatientRecordSuccessAction,
  UpdateDependentPatientRecordSuccessAction,
  GetUserPatientRecordSuccessAction,
  GetDependentPatientRecordSuccessAction,
  CurrentPatientRecordAfterRegisterSuccessAction,
  SetTemporaryPatientRecordRequestAction,
} from './actionTypes'

import { LogoutAction, AuthActionTypes } from '../auth/actionTypes'

export interface RPatientRecord {
  me?: PatientRecord
  dependents: Record<string, PatientRecord>
  temporaryRecord: {
    patientRecord: Patient | null
    serviceType: string
  }
}

export const initialPatientRecord = (): RPatientRecord => ({
  me: undefined,
  dependents: {},
  temporaryRecord: {
    patientRecord: null,
    serviceType: '',
  },
})

type PatientRecordActions =
  | UpdateUserPatientRecordSuccessAction
  | CreateDependentPatientRecordSuccessAction
  | UpdateDependentPatientRecordSuccessAction
  | GetUserPatientRecordSuccessAction
  | GetDependentPatientRecordSuccessAction
  | CurrentPatientRecordAfterRegisterSuccessAction
  | SetTemporaryPatientRecordRequestAction
  | LogoutAction

const patientRecordReducer = produce(function (
  draft: RPatientRecord,
  action: PatientRecordActions,
) {
  switch (action.type) {
    case PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD_SUCCESS:
    case PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD_SUCCESS: {
      const record = action.payload
      draft.dependents[record.id] = record
      break
    }

    case PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD_SUCCESS: {
      draft.dependents = {}
      action.payload.forEach((record) => (draft.dependents[record.id] = record))
      break
    }

    case PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD_SUCCESS: {
      draft.me = action.payload
      break
    }

    case PatientRecordActionTypes.GET_USER_PATIENT_RECORD_SUCCESS: {
      draft.me = action.payload
      break
    }

    case PatientRecordActionTypes.CURRENT_PATIENT_RECORD_AFTER_REGISTER_SUCCESS: {
      draft.me = action.payload
      break
    }

    case PatientRecordActionTypes.SET_TEMPORARY_PATIENT_RECORD: {
      draft.temporaryRecord.patientRecord = action.payload.patientRecord
      draft.temporaryRecord.serviceType = action.payload.serviceType
      break
    }

    case AuthActionTypes.LOGOUT:
      return initialPatientRecord()
  }
},
initialPatientRecord())

export default patientRecordReducer
