import React, { memo, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import { isMobile, isIOS } from 'react-device-detect'

import { DatePicker, DatePickerDisplayMode } from '../date-picker/DatePicker'
import { CalendarIcon } from '../icons/CalendarIcon'
import { TextInput } from './TextInput'
import { toIsoDateString } from '../date-picker/utils'

export interface DateInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | null
  label: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: any
  displayMode?: DatePickerDisplayMode
  withoutErrorText?: boolean
}

export const DateInput = memo<DateInputProps>(function DateInput(props) {
  const defaultDisplayMode = isMobile ? 'modal' : 'popover'
  const {
    error,
    inputRef,
    label,
    disabled,
    name,
    value,
    required,
    displayMode = defaultDisplayMode,
    min,
    max,
    withoutErrorText,
  } = props
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [setIsOpen])
  const close = useCallback(() => setIsOpen(false), [setIsOpen])

  const [inputNode, setInputNode] = useState<HTMLInputElement | null>(null)

  const setInputValue = useCallback(
    (value: string) => {
      if (inputNode) {
        inputNode.value = value ?? ''
      }
      inputNode?.dispatchEvent(new Event('input'))
    },
    [inputNode],
  )
  // const [inputValue, setInputValue] = useState(value || '')
  const [inputDateValue, setInputDateValue] = useState(
    value ? new Date(value as string) : new Date(max || Date.now()),
  )

  const maxDate = max ? new Date(max) : undefined
  const minDate = min ? new Date(min) : undefined

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      // setInputValue(e.target.value)
      setInputDateValue(new Date(e.target.value))
    },
    [setInputValue],
  )

  useEffect(() => {
    // update value in state when value prop changes
    setInputValue(value as string)
    setInputDateValue(new Date(value as string))
  }, [value, setInputValue])

  return (
    <DatePicker
      inputElement={
        <TextInput
          error={error}
          inputRef={(refNode: HTMLInputElement) => {
            if (typeof inputRef === 'function') {
              inputRef(refNode)
            } else if (inputRef) {
              inputRef.current = refNode
            }
            setInputNode(refNode)
          }}
          label={label}
          required={required}
          className={clsx('w-full', isMobile && 'disabled')}
          name={name}
          max={'9999-12-12'}
          onFocus={(e) => {
            if (isMobile) {
              e.preventDefault()
              e.stopPropagation()
              e.target.blur()

              if (isIOS) {
                window.scrollTo(0, 0)
              }
            }
          }}
          type={isIOS ? 'text' : 'date'}
          disabled={disabled}
          onChange={handleInputChange}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          rightIcon={
            <button
              disabled={disabled}
              className="input-btn"
              type="button"
              aria-label="press to open date picker"
              onClick={open}
            >
              {' '}
              <CalendarIcon />
            </button>
          }
          withoutErrorText={withoutErrorText}
        />
      }
      inputElementRef="inputRef"
      onChange={(val) => {
        setInputValue(toIsoDateString(val))
        setInputDateValue(val)
      }}
      onRequestClose={close}
      onRequestOpen={open}
      isOpen={isOpen}
      displayMode={displayMode}
      value={inputDateValue}
      min={minDate}
      max={maxDate}
    />
  )
})
