import { memo, ReactNode } from 'react'
import cx from 'clsx'

export interface PaperContentProps {
  className?: string
  children?: ReactNode
}

export const PaperContent = memo<PaperContentProps>(function PaperContent(
  props,
) {
  const { className, children } = props
  return (
    <div data-testid="paper-content" className={cx('paper-content', className)}>
      {children}
    </div>
  )
})
