import { Action } from '@core/shared/types/Action'

export enum NotificationItemTypes {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
}

export type ShowNotificationItemAction = Action<
  typeof NotificationItemTypes.SHOW_NOTIFICATION,
  {
    title: string
    type: 'success' | 'info' | 'error' | 'warning'
    duration?: number
  }
>

export type HideNotificationItemAction = Action<
  typeof NotificationItemTypes.HIDE_NOTIFICATION
>
