import { memo } from 'react'
import cx from 'clsx'
import setYear from 'date-fns/setYear'

import { DatepickerNav } from '../useDatepickerNavigation'
import { getYearsPeriod, isYearDisabled } from '../utils'
import { YearViewNavigation } from './YearView/YearViewNavigation'
import { DatePickerView } from '../DatePicker'

export interface YearViewProps {
  selectedDate: Date
  onSelect: (date: Date) => void
  chooseDayAriaLabelPrefix?: string
  disabledDayAriaLabelPrefix?: string
  min?: Date
  max?: Date
  inline?: boolean
  locale: string | Locale

  fixedHeight?: boolean
  peekNextMonth?: boolean
  nav: DatepickerNav
  yearItemNumber?: number
  currentView: DatePickerView
  setCurrentView: (view: DatePickerView) => void
}

const dayClassNames = {
  static: 'datepicker-year-view__year',
  selected: 'datepicker-year-view__year--selected',
  disabled: 'ddatepicker-year-view__year--disabled',
}

function getClassNames({
  min,
  max,
  selectedDate,
  year,
}: {
  selectedDate: Date
  year: number
  min?: Date
  max?: Date
}) {
  return cx(
    dayClassNames.static,
    year === selectedDate.getFullYear() && dayClassNames.selected,
    isYearDisabled(year, { min, max }) && dayClassNames.disabled,
  )
}
export const YearView = memo<YearViewProps>(function YearView(props) {
  const { nav, yearItemNumber = 21, selectedDate, onSelect, min, max } = props
  const years = getYearsPeriod(nav.current, yearItemNumber)

  const handleOnSelect = (year: number) => {
    const newDate = setYear(selectedDate, year)
    onSelect(newDate)
  }

  return (
    <div className="datepicker-view__year--container">
      <div className="datepicker__header">
        <YearViewNavigation
          pageYearFirst={years[0]}
          pageYearLast={years[yearItemNumber - 1]}
          locale={props.locale}
          nav={props.nav}
        />
      </div>
      <div className="datepicker-view datepicker-view__year">
        {years.map((year) => (
          <button
            className={getClassNames({ min, max, selectedDate, year })}
            key={year}
            disabled={min && max && isYearDisabled(year, { min, max })}
            onClick={() => handleOnSelect(year)}
            type="button"
          >
            {year}
          </button>
        ))}
      </div>
    </div>
  )
})
