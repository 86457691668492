import { env } from './configuration'

const makeJoinLink = (
  userId: string,
  token: string,
  consultationId: string,
) => {
  const {
    consult: { consultHost },
    api: { clientId },
  } = env()

  return `${consultHost}/?consultationId=${consultationId}&clientId=${clientId}&userId=${userId}&token=${token}`
}

export default makeJoinLink
