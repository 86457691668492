// util to clear the session storage from homepage
// and post booking. The booking item contains personal
// information and thus needs to be cleared
export const clearBookingFromSessionStorage = () =>
  window.sessionStorage.removeItem('booking')

// saves booking info in sessionStorage so we can
// regenerate the booking after the Stripe redirect
export const saveBookingInSessionStorage = (booking: Record<string, any>) =>
  window.sessionStorage.setItem('booking', JSON.stringify(booking))

export const readBookingFromSessionStorage = () => {
  const data = window.sessionStorage.getItem('booking')
  if (!data) return
  const booking = JSON.parse(data)
  return booking
}
