import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const AttachmentIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('attachment-icon', className)}
      fill="none"
      role="img"
    >
      <title>Attachment</title>
      <path
        d="M3 11.8751L11.1509 3.70261C13.4151 1.43246 17.0377 1.43246 19.3019 3.70261C21.566 5.97276 21.566 9.605 19.3019 11.8751L10.4264 20.7741C9.43019 21.8638 7.89057 22.227 6.35094 21.8638C5.98868 21.773 5.71698 21.6822 5.35472 21.5006C2.81887 20.0477 2.54717 16.7787 4.44906 14.7809L11.3321 7.87968C12.5094 6.69921 14.4113 6.79001 15.4075 8.06129C16.3132 9.15097 16.1321 10.7855 15.1358 11.7843L11.1509 15.7798"
        stroke="#8A93A1"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
