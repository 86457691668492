import { memo, useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Element } from 'react-scroll'

import { useAction } from '@core/shared/useAction'
import { mapPatientDataWithService } from '@core/shared/mapPatientDataWithService'
import { LocationIcon } from '../icons/LocationIcon'
import { PhoneCallIcon } from '../icons/PhoneCallIcon'
import { InternetIcon } from '../icons/InternetIcon'
import {
  PatientNominatedPharmacy,
  PatientGpSurgery,
  Patient,
  ServiceType,
} from '@core/shared/types/patient/PatientRecord'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import { setTemporaryUserRecordRequestAction } from '@core/app/user/actions'

interface Props {
  item: google.maps.places.PlaceResult
  isPharmacy?: boolean
  handleSelectedDetails: (id: string) => void
  placeDetail?: google.maps.places.PlaceResult
  goBack: () => void
}

export const ServiceCard = memo(function ServiceCard({
  item,
  isPharmacy,
  handleSelectedDetails,
  placeDetail,
  goBack,
}: Props) {
  const temporaryUserRecord = useSelector(selectTemporaryUserRecord)
  const setTemporaryUserRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )

  const [showDetails, setShowDetails] = useState<boolean>(false)
  const [shouldAssignData, setShouldAssignData] = useState<boolean>(false)

  const toggleDetails = useCallback(() => {
    if (item?.place_id) {
      handleSelectedDetails(item.place_id)
    }

    setShowDetails(!showDetails)
  }, [handleSelectedDetails, item, showDetails])

  const setData = useCallback(() => {
    setShouldAssignData(true)

    if (!placeDetail && item?.place_id) {
      handleSelectedDetails(item.place_id)
      return
    }
    setShouldAssignData(false)

    if (placeDetail) {
      const mappedData = mapPatientDataWithService({
        placeDetail,
        isPharmacy,
      })

      const newPatient = {
        ...temporaryUserRecord.userRecord,
      } as Patient

      if (isPharmacy) {
        newPatient.nominatedPharmacy = mappedData as PatientNominatedPharmacy
        newPatient.nominatedPharmacy['isSelected'] = true
      } else {
        newPatient.gp = mappedData as PatientGpSurgery
        newPatient.gp['isSelected'] = true
      }

      setTemporaryUserRecordRequest({
        userRecord: newPatient,
        serviceType: isPharmacy ? ServiceType.PHARMACY : ServiceType.GPSURGERY,
      })

      goBack()
    }
  }, [
    placeDetail,
    item,
    isPharmacy,
    temporaryUserRecord,
    handleSelectedDetails,
    setTemporaryUserRecordRequest,
    goBack,
  ])

  useEffect(() => {
    if (placeDetail && shouldAssignData) {
      setData()
      setShouldAssignData(false)
    }
  }, [placeDetail, shouldAssignData, setData])

  return (
    <div className="service-card-wrapper">
      <Element name={item?.place_id || ''} />
      <div className="service-card-name">
        <h3>{item.name}</h3>
      </div>
      <div className="service-card-address">
        <LocationIcon className="mr-1" />
        <p>{item.vicinity}</p>
      </div>
      <div className="service-card-buttons">
        <button onClick={toggleDetails} className="btn mr-1">
          View Details
        </button>
        <button onClick={setData} className="btn btn-primary">
          {isPharmacy ? 'Nominate Pharmacy' : 'Choose GP surgery'}
        </button>
      </div>
      {showDetails ? (
        <div className="service-card-details-wrapper">
          <div className="detail-row mb-1">
            <PhoneCallIcon className="custom-phone-icon" />
            <p>{placeDetail?.international_phone_number || 'N/A'}</p>
          </div>
          <div className="detail-row mb-1">
            <InternetIcon />
            {placeDetail?.website ? (
              <a
                href={placeDetail?.website}
                rel="noopener noreferrer"
                target="_blank"
                className="text-clipping"
              >
                {placeDetail?.website}
              </a>
            ) : (
              'N/A'
            )}
          </div>
          <div className="detail-row mb-2">
            <InternetIcon />
            {placeDetail?.url ? (
              <a
                href={placeDetail?.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                Check on Google
              </a>
            ) : (
              'N/A'
            )}
          </div>
          <div>
            {placeDetail?.opening_hours?.weekday_text?.map((el, index) => (
              <p key={index} className="text-sm text-color-secondary mb-1">
                {el}
              </p>
            )) || null}
          </div>
        </div>
      ) : null}
    </div>
  )
})
