import { memo, useState } from 'react'
import { useSelector } from 'react-redux'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { updateDependentPatientRecordAction } from '@core/app/patientRecord/actions'
import { ContactInformation } from '@core/components/app/ContactInformation'
import { selectDependentsRecords } from '@core/app/patientRecord/selectors'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { removeEmptyProperties } from '@core/shared/lang'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Patient } from '@core/shared/types/patient/PatientRecord'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { SideMenu } from '@core/components/app/SideMenu'
import mapPatientData from '@core/shared/mapPatientData'
import { setTemporaryUserRecordRequestAction } from '@core/app/user/actions'

export const EditContactPage = memo(function EditContactPage() {
  const editContact = useActionAsync(updateDependentPatientRecordAction)
  const dependents = useSelector(selectDependentsRecords)
  const showNotification = useAction(showNotificationAction)
  const setTemporaryPatientRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )
  const history = useHistory()
  const [submitError, setSubmitError] = useState('')

  const { params } = useRouteMatch<{ id: string }>()
  const record = dependents[params.id]

  const goToMyContacts = () => history.push(ScreenRoutes.DEPENDENTS)

  const handleOnSubmit = async (data: Patient) => {
    try {
      setSubmitError('')

      removeEmptyProperties(data)
      mapPatientData(record.attributes, data)

      await editContact({ data, patientRecord: record })
      setTemporaryPatientRecordRequest({
        userRecord: null,
        serviceType: '',
      })
      showNotification({
        type: 'success',
        title: 'Dependant updated',
      })
      goToMyContacts()
    } catch (error) {
      console.error(error)
      setSubmitError(error as any)
    }
  }

  return (
    <div className="page add-update-contact-page">
      <PageHeader showBg backBtn title="Edit dependant" />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <ContactInformation
            handleOnSubmit={handleOnSubmit}
            submitButtonText="Save"
            record={record?.attributes}
            emailRequired={false}
            error={submitError}
            dependantContact
          />
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
