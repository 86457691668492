import { ofType, Epic } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'
import { empty, EMPTY } from 'rxjs'

import {
  AuthActionTypes,
  LoginAction,
  LoginTokenRetrievedAction,
  LogoutAction,
} from './actionTypes'
import { login, logoutCookies } from '@core/api/user'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import { loginTokenRetrieved, loginSuccess, loginFail, logout } from './actions'
import {
  setAccessToken,
  setRefreshToken,
  getUserIdFromCookie,
} from './AuthService'
import { BFFConfigType, env } from '@core/shared/configuration'
import { getCurrentUserAction } from '../user/actions'

const config = env()

export const loginEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AuthActionTypes.LOGIN),
    mergeMap(async (action: LoginAction) => {
      try {
        const response = await login(action.payload)
        notifySuccess(action)
        if (config.auth.bff === BFFConfigType.ImplictIDS4) {
          const userId = getUserIdFromCookie()
          return [getCurrentUserAction(`${userId}`), loginSuccess()]
        } else {
          return [
            loginTokenRetrieved({
              token: response.data.access_token,
              refreshToken: response.data.refresh_token,
            }),
            loginSuccess(),
          ]
        }
      } catch (e) {
        const reason = e.response?.data?.message || 'Login failed'
        notifyFail(action, reason)
        return loginFail()
      }
    }),
    concatActions(),
  )

export const onLoginTokenRetrievedEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AuthActionTypes.LOGIN_TOKEN_RETRIEVED),
    mergeMap((action: LoginTokenRetrievedAction) => {
      const { token, refreshToken } = action.payload
      window.localStorage.setItem('accessToken', token)
      window.localStorage.setItem('refreshToken', refreshToken)
      setAccessToken(token)
      setRefreshToken(refreshToken)
      return EMPTY
    }),
  )

export const logoutEpic: Epic = (action$) =>
  action$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    mergeMap((action: LogoutAction) => {
      const userId = getUserIdFromCookie()
      window.localStorage.removeItem('accessToken')
      if (config.auth.bff === BFFConfigType.ImplictIDS4 && userId) {
        logoutCookies()
      }
      logout()
      return empty()
    }),
  )

export default [loginEpic, onLoginTokenRetrievedEpic, logoutEpic]
