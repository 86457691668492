import React, { memo, useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FieldError, useForm } from 'react-hook-form'
import cx from 'clsx'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { LabelPaper } from '@core/components/form/LabelPaper'
import { PaperHeader, PaperContent } from '@core/components/paper'
import { Radio } from '@core/components/form/Radio'
import { HorizontalScroll } from '@core/components/HorizontalScroll'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useAction, useActionAsync } from '@core/shared/useAction'
import {
  submitAppointmentBookingStep1Action,
  getBookingDatesAction,
  getBookingSlotsAction,
  holdAppointmentAction,
  chooseBookingDateAction,
} from '@core/app/appointment/actions'
import {
  selectUserDetails,
  selectBookingDatesValues,
  selectBookingSlots,
  selectInitialBooking,
  selectSelectededDay,
} from '@core/app/appointment/selectors'
import { GpGender, AppointmentType } from '@core/shared/types/appointment'
import { TextInput } from '@core/components/form/TextInput'
import { AllergyReminderIcon } from '@core/components/icons/AllergyReminderIcon'
import { VideoCallIcon } from '@core/components/icons/VideoCallIcon'
import { PhoneCallIcon } from '@core/components/icons/PhoneCallIcon'
import { ErrorIcon } from '@core/components/icons/ErrorIcon'
import { getDependentPatientRecordAction } from '@core/app/patientRecord/actions'
import { PatientRecord } from '@core/shared/types/patient/PatientRecord'
import {
  selectMainPatientAddress,
  selectMainPatientPhoneNumber,
  selectUserPatientRecord,
} from '@core/app/patientRecord/selectors'
import { SideMenu } from '@core/components/app/SideMenu'
import { InlineLoader } from '@core/components/app/InlineLoader'
import { CPSpecialties, CPSpecialtiesEnum } from '@core/shared/types/bookings'
import { useClientVariables } from '@core/components/app/features/variables'
import { isValidPhoneNumberStrict } from '@core/shared/validation/phone'
import { getPractitionerLabel } from '@core/components/specialityPractitionerLabels'
import { Stepper } from './components/Stepper'
import { PatientPicker } from './components/PatientPicker'
import { selectAppUser } from '@core/app/user/selectors'
import { Calendar } from '@core/components/calendar'
import { DateValue } from '@internationalized/date'
import dayjs from 'dayjs'
import { Service, useFeatures } from '@core/components/app/features/features'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number. Must include country code and + symbol',
}

function getError<T>(errors: any, field: string) {
  const err = errors[field] as FieldError

  if (!err) {
    return null
  }

  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

const desiredConsultationTypeOrder = [
  AppointmentType.VideoCall,
  AppointmentType.PhoneCall,
  AppointmentType.Onsite,
]

interface BookStep1PageProps {
  outsideAppointmentDescription?: string | React.ReactNode
  localTimeZoneDescription?: string | React.ReactNode
}

export const BookStep1Page = memo<BookStep1PageProps>(function BookStep1Page(
  props,
) {
  const { outsideAppointmentDescription, localTimeZoneDescription } = props

  const booking = useSelector(selectInitialBooking)

  const { cpSpecialtiesId } = useParams<{ cpSpecialtiesId: string }>()
  const practitionerLabel = getPractitionerLabel(cpSpecialtiesId)
  const isCounsellingBooking = cpSpecialtiesId === CPSpecialtiesEnum.COUNSELLOR

  const bookingDates = useSelector(selectBookingDatesValues)
  const bookingSlots = useSelector(selectBookingSlots)
  const selectedDay = useSelector(selectSelectededDay)
  const patientRecord = useSelector(selectUserPatientRecord)
  const userPhoneNumber = useSelector(selectMainPatientPhoneNumber)
  const userDetails = useSelector(selectUserDetails)
  const mainPatientAddress = useSelector(selectMainPatientAddress)
  const user = useSelector(selectAppUser)

  const [bookingDatesLoading, setBookingDatesLoading] = useState(false)
  const [patient, setPatient] = useState<PatientRecord>()
  const [localError, setLocalError] = useState<string>('')

  const getBookingDates = useActionAsync(getBookingDatesAction)
  const getBookingSlots = useActionAsync(getBookingSlotsAction)
  const holdAppointment = useActionAsync(holdAppointmentAction)
  const getRecords = useActionAsync(getDependentPatientRecordAction)
  const chooseBookingDate = useAction(chooseBookingDateAction)

  const { bookings } = useFeatures()

  const fetchBookingDates = async () => {
    setBookingDatesLoading(true)
    await getBookingDates({})
    setBookingDatesLoading(false)
  }

  useEffect(() => {
    fetchBookingDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userDetails && user) {
      getRecords()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails])

  const {
    clientVariables: { phoneInputPlaceholder },
  } = useClientVariables()

  const canChoosePreferredGPGender =
    bookings?.canChoosePreferredGPGender?.isEnabled ?? true

  const service = CPSpecialties[cpSpecialtiesId] as Service
  const consultationTypeConfig = bookings?.services?.value?.[service]
    ?.consultationTypes ?? {
    phone: true,
    video: true,
  }
  const configuredConsultationTypes = consultationTypeConfig
    ? Object.entries(consultationTypeConfig).reduce(
        (arr, [type, enabled]) => (enabled ? [...arr, type] : arr),
        [] as Array<string>,
      )
    : []
  const hasMultipleConsultationTypeOptions =
    configuredConsultationTypes.length > 1

  const allConsultationTypes = [
    {
      id: 'phone',
      icon: <PhoneCallIcon />,
      label: 'Phone call',
      type: AppointmentType.PhoneCall,
      description: `Speak to the ${practitionerLabel} over the phone`,
    },
    {
      id: 'video',
      icon: <VideoCallIcon />,
      label: 'Video call',
      type: AppointmentType.VideoCall,
      description: `See the ${practitionerLabel} face to face via video call`,
    },
    {
      id: 'onsite',
      icon: null, // TODO: TBD
      label: 'On-site',
      type: AppointmentType.Onsite,
      description: null, // TODO: TBD
    },
  ]

  const availableConsultationTypes = allConsultationTypes
    .filter((x) => configuredConsultationTypes.includes(x.id))
    .sort((a, b) => {
      const indexA = desiredConsultationTypeOrder.indexOf(a.type)
      const indexB = desiredConsultationTypeOrder.indexOf(b.type)

      if (indexA > indexB) {
        return 1
      } else if (indexA < indexB) {
        return -1
      } else {
        return 0
      }
    })

  const usesAppointmentsCalendar =
    bookings?.appointmentsCalendar?.isEnabled === true

  const differentPhoneNumber =
    !!booking?.contactDetails?.phoneNumber &&
    userPhoneNumber !== booking?.contactDetails?.phoneNumber

  const [enterPhoneNumber, setEnterPhoneNumber] = useState(differentPhoneNumber)

  const phoneNumber = booking.phoneNumber || userPhoneNumber

  const history = useHistory()
  const goToStep2 = () =>
    history.push(ScreenRoutes.BOOK_CONSULTATION_STEP_2, { cpSpecialtiesId })

  const {
    register,
    unregister,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState,
    errors,
  } = useForm({
    defaultValues: booking,
    reValidateMode: 'onChange',
  })

  const getFilters = useCallback(() => {
    const values = getValues()

    return {
      gpGender: values.gpGender,
      consultationType: values.consultationType,
      cpSpecialties: CPSpecialties[cpSpecialtiesId],
    }
  }, [cpSpecialtiesId, getValues])

  const onDateUnmount = () => unregister('appointmentDate')
  const onTimeUnmount = () => unregister('appointmentSlotId')

  const fields = watch([
    'appointmentSlotId',
    'appointmentDate',
    'consultationType',
  ])

  const hasError = {
    consultationType: Boolean(errors.consultationType),
    appointmentDate: Boolean(errors.appointmentDate),
    appointmentSlotId: Boolean(errors.appointmentSlotId),
    patient: Boolean(errors.patient),
  }

  const submitStep1 = useAction(submitAppointmentBookingStep1Action)

  const onSubmit = async (data: Record<string, any>) => {
    if (!data.contactDetails) {
      data.contactDetails = {}
    }
    const isDependant = patient?.id !== patientRecord?.id

    data.patient = {
      ...patient,
      isDependant: isDependant,
    }
    data.contactDetails.address = isDependant
      ? mainPatientAddress
      : patient?.attributes.address
    if (!data.contactDetails.phoneNumber) {
      data.contactDetails.phoneNumber = userPhoneNumber
    }

    // If there is only a single option for consultation type, we'll set that as the value.
    data.consultationType = hasMultipleConsultationTypeOptions
      ? data.consultationType
      : availableConsultationTypes[0].type

    try {
      await holdAppointment({
        appointmentId: data.appointmentSlotId,
      })

      submitStep1(data)
      goToStep2()
    } catch (err) {
      // Clear the slot ID as we were unable to hold it.
      // The user is free to try the same slot again if they wish.
      setValue('appointmentSlotId', '')
      setLocalError(
        'Unable to hold the appointment time. Please select a time and try again.',
      )
    }
  }

  const chooseCalendarDay = (date: DateValue) => {
    setValue('appointmentSlotId', '')
    chooseBookingDate({
      date: date.toString(),
    })
  }

  const isDateUnavailable = (date: DateValue) => {
    return !bookingDates.find((d) => {
      const date1 = d.iso
      const date2 = date.toString()
      return date1 === date2
    })
  }

  return (
    <div className="page book-consultation-page">
      <PageHeader showBg backBtn title="Book your consultation" withStepper>
        <Stepper step={1} steps={3} />
      </PageHeader>
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="section" data-testid="select-consultation-user">
              <h3 className="section-title mb-1">
                Who is the consultation for?
              </h3>
              <PatientPicker
                handleChange={(patient) => setPatient(patient)}
                register={register}
              />
            </div>

            {hasMultipleConsultationTypeOptions ? (
              <div className="section" data-testid="choose-consultation-type">
                <h3
                  id="book-consultation-type-title"
                  className="section-title mb-2"
                >
                  Choose your consultation type
                  {hasError.consultationType && <ErrorIcon />}
                </h3>
                <div
                  role="radiogroup"
                  aria-labelledby="book-consultation-type-title"
                  className="flex-row"
                  aria-required="true"
                  data-testid="select-consultation-type"
                >
                  {availableConsultationTypes.map((consultationType, index) => {
                    const radioId = `consultation-type-${consultationType.id}`
                    const descriptionId = `book-${consultationType.id}-description`

                    return (
                      <LabelPaper
                        className={cx('display-flex action-cursor', {
                          'has-error': hasError.consultationType,
                          'ml-1': index > 0,
                        })}
                      >
                        <PaperContent>
                          <PaperHeader className="text-weight-md text-smd flex-row space-between">
                            <label
                              htmlFor={radioId}
                              className="flex-row radio-label action-cursor"
                            >
                              {consultationType.icon}
                              {consultationType.label}
                            </label>
                            <Radio
                              inputRef={register({ required: true })}
                              defaultChecked={
                                booking.consultationType ===
                                consultationType.type
                              }
                              name="consultationType"
                              id={radioId}
                              value={consultationType.type}
                              aria-describedby={descriptionId}
                              onChange={() => {
                                getBookingDates({
                                  filters: getFilters(),
                                })
                              }}
                            />
                          </PaperHeader>
                          <p
                            id={descriptionId}
                            className="paper-text text-color-secondary text-sm mt-1"
                          >
                            {consultationType.description}
                          </p>
                        </PaperContent>
                      </LabelPaper>
                    )
                  })}
                </div>
              </div>
            ) : null}

            {fields.consultationType === AppointmentType.VideoCall && (
              <div className="section" data-testid="contact-for-video">
                <p className="section-title mb-1">
                  The {practitionerLabel} may call you to prompt you to join the
                  chat on
                </p>
                {enterPhoneNumber ? (
                  <TextInput
                    className="w-full mb-1"
                    label="Phone number (please include country code and + symbol)"
                    placeholder={phoneInputPlaceholder}
                    aria-label="enter phone number"
                    required
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                    defaultValue={phoneNumber}
                    error={getError(errors.contactDetails || {}, 'phoneNumber')}
                    inputRef={register({
                      validate: {
                        phone: isValidPhoneNumberStrict,
                      },
                    })}
                    name="contactDetails.phoneNumber"
                  />
                ) : (
                  <div className="paper">
                    <div className="paper-content">
                      <div className="paper-header text-weight-md">
                        {phoneNumber}{' '}
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setEnterPhoneNumber(true)
                          }}
                          className="btn-link btn-link-primary action-cursor"
                          aria-label="edit phone number"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{ justifyContent: 'flex-start', alignItems: 'center' }}
                  className="flex-row mt-1"
                >
                  <div style={{ padding: '10px' }}>
                    <AllergyReminderIcon />
                  </div>

                  <p className="text-weight-md text-sm">
                    The link to your consultation will be sent to the email
                    address associated with your profile
                  </p>
                </div>
              </div>
            )}
            {fields.consultationType === AppointmentType.PhoneCall && (
              <div className="section" data-testid="contact-for-phone">
                <p className="section-title mb-1">
                  The {practitionerLabel} will call you on
                </p>
                {enterPhoneNumber ? (
                  <TextInput
                    label="Phone number (please include country code and + symbol)"
                    placeholder={phoneInputPlaceholder}
                    className="w-full mb-1"
                    aria-label="enter phone number"
                    required
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                      }
                    }}
                    defaultValue={phoneNumber}
                    error={getError(errors.contactDetails || {}, 'phoneNumber')}
                    inputRef={register({
                      validate: {
                        phone: isValidPhoneNumberStrict,
                      },
                    })}
                    name="contactDetails.phoneNumber"
                  />
                ) : (
                  <div className="paper">
                    <div className="paper-content">
                      <div className="paper-header text-weight-md">
                        {phoneNumber}{' '}
                        <button
                          onClick={(e) => {
                            e.preventDefault()
                            setEnterPhoneNumber(true)
                          }}
                          className="btn-link btn-link-primary action-cursor"
                          aria-label="edit phone number"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {canChoosePreferredGPGender ? (
              <div className="section" data-testid="select-gp-gender">
                <h3 className="section-title mb-1">
                  Preferred gender of {practitionerLabel}
                </h3>
                <div className="flex-row space-between wrap">
                  <LabelPaper className="gender-pick action-cursor">
                    <PaperContent>
                      <PaperHeader className="text-weight-md text-smd flex-row space-between">
                        Male
                        <Radio
                          inputRef={register}
                          name="gpGender"
                          aria-label="Male GP"
                          value={GpGender.Male}
                          onChange={() => {
                            getBookingDates({
                              filters: getFilters(),
                            }).catch((e) => console.error(e))
                          }}
                        />
                      </PaperHeader>
                    </PaperContent>
                  </LabelPaper>
                  <LabelPaper className="gender-pick action-cursor">
                    <PaperContent>
                      <PaperHeader className="text-weight-md text-smd flex-row space-between">
                        Female
                        <Radio
                          inputRef={register}
                          name="gpGender"
                          aria-label="Female GP"
                          value={GpGender.Female}
                          onChange={() => {
                            getBookingDates({
                              filters: getFilters(),
                            }).catch((e) => console.error(e))
                          }}
                        />
                      </PaperHeader>
                    </PaperContent>
                  </LabelPaper>
                  <LabelPaper className="gender-pick action-cursor">
                    <PaperContent>
                      <PaperHeader className="text-weight-md text-smd flex-row space-between">
                        Either
                        <Radio
                          inputRef={register}
                          name="gpGender"
                          aria-label="No GP gender preference"
                          value={GpGender.Either}
                          onChange={() => {
                            getBookingDates({
                              filters: getFilters(),
                            }).catch((e) => console.error(e))
                          }}
                        />
                      </PaperHeader>
                    </PaperContent>
                  </LabelPaper>
                </div>
              </div>
            ) : null}

            <div className="section" data-testid="get-date-time-options">
              <h3 id="choose-booking-date-title" className="section-title mb-1">
                Choose a date and time
                {hasError.appointmentDate || hasError.appointmentSlotId ? (
                  <ErrorIcon />
                ) : null}
              </h3>
              {!isCounsellingBooking ? (
                <p className="text-smd mb-2">
                  Available Monday - Sunday 08:00 - 22:00
                  {localTimeZoneDescription && ' UK time'}, excluding Christmas
                  Day. {''}
                  {localTimeZoneDescription && <>{localTimeZoneDescription}</>}
                </p>
              ) : null}
              {outsideAppointmentDescription && (
                <>{outsideAppointmentDescription}</>
              )}

              {bookingDatesLoading && (
                <div className="flex-center">
                  <InlineLoader />
                </div>
              )}

              {!bookingDates.length && !bookingDatesLoading && (
                <div className="notification-item notification-item-info">
                  <p>No appointments available</p>
                </div>
              )}

              {!bookingDatesLoading && bookingDates.length > 0 ? (
                <>
                  {usesAppointmentsCalendar ? (
                    <Calendar
                      aria-label="choose appointment day"
                      onChange={chooseCalendarDay}
                      isDateUnavailable={isDateUnavailable}
                      data-testid="appointment-calendar"
                    />
                  ) : (
                    <HorizontalScroll
                      className={cx(hasError.appointmentDate && 'has-error')}
                    >
                      <div
                        aria-labelledby="choose-booking-date-title"
                        role="radiogroup"
                        aria-required="true"
                        className="flex-row day-wrapper"
                        aria-invalid={
                          hasError.appointmentDate ? 'true' : 'false'
                        }
                      >
                        {bookingDates.map((day) => (
                          <label
                            key={day.value}
                            className="day-slot action-cursor"
                            data-testid="select-day"
                          >
                            <div className="day-slot-header">
                              <Radio
                                inputRef={register({ required: true })}
                                name="appointmentDate"
                                onUnmount={onDateUnmount}
                                defaultChecked={
                                  booking.appointmentDate === day.value
                                }
                                value={day.value}
                                onChange={() => {
                                  chooseBookingDate({
                                    date: day.value.split('T')[0],
                                  })
                                  setValue('appointmentSlotId', '')
                                }}
                              />
                            </div>
                            <div className="day-slot-label">{day.label}</div>
                          </label>
                        ))}
                      </div>
                    </HorizontalScroll>
                  )}
                  {usesAppointmentsCalendar && bookingSlots.length > 0 ? (
                    <h4 className="text-heading-xs mt-2">
                      {dayjs(selectedDay).format('dddd, D MMMM')}
                    </h4>
                  ) : null}
                  <HorizontalScroll dynamic>
                    {bookingSlots.length > 0 ? (
                      <div
                        style={{ marginTop: '20px', padding: '0 20px' }}
                        className="flex-row"
                        role="radiogroup"
                        aria-required="true"
                        aria-label="choose appointment time"
                        aria-invalid={
                          hasError.appointmentSlotId ? 'true' : 'false'
                        }
                        data-testid="select-time"
                      >
                        {bookingSlots.map((t) => (
                          <LabelPaper
                            key={t.value}
                            className="time-pick action-cursor"
                          >
                            <PaperContent>
                              <PaperHeader
                                className={cx(
                                  'text-weight-md text-md flex-row  space-between',
                                  getValues().appointmentSlotId === t.value &&
                                    'selected',
                                )}
                              >
                                {t.label}
                                <Radio
                                  inputRef={register({ required: true })}
                                  name="appointmentSlotId"
                                  onUnmount={onTimeUnmount}
                                  defaultChecked={
                                    booking.appointmentSlotId === t.value
                                  }
                                  onChange={() => {
                                    getBookingSlots({ id: t.value })
                                      .then(() => {
                                        setLocalError('')
                                      })
                                      .catch((e) => {
                                        console.error(e)
                                        setLocalError(e)
                                      })
                                  }}
                                  value={t.value}
                                />
                              </PaperHeader>
                            </PaperContent>
                          </LabelPaper>
                        ))}
                      </div>
                    ) : null}
                  </HorizontalScroll>
                </>
              ) : null}
            </div>

            {!formState.isValid && formState.isSubmitted && (
              <div className="section">
                <div className="validation-errors">
                  <ErrorIcon />
                  <div
                    role="alert"
                    className="tooltip warning ml-1 text-center"
                  >
                    {hasError.patient
                      ? 'Please choose a patient before proceeding'
                      : enterPhoneNumber
                      ? `Please fill in the required fields, choose a consultation
                    type and a date & time before proceeding`
                      : `Choose a consultation
                    type and a date & time before proceeding`}
                  </div>
                </div>
              </div>
            )}
            {localError ? (
              <div className="validation-errors flex-center mt-2 mb-2">
                <ErrorIcon />
                <div className="tooltip warning ml-1">
                  {localError || 'Something went wrong. Please try again.'}
                </div>
              </div>
            ) : null}
            <div className="section">
              <div className="btn-container">
                <button
                  disabled={bookingSlots.length === 0 || Boolean(localError)}
                  type="submit"
                  className="btn btn-primary w-full"
                  data-testid="step-one-next-btn"
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
