import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const ChevronRightIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      className={cx('chevron-icon chevron-right-icon', className)}
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
      role="presentation"
      fill="#242D38"
    >
      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
      <path fill="none" d="M0 0h24v24H0V0z"></path>
    </svg>
  )
}
