import React, { memo, useEffect } from 'react'
import cx from 'clsx'

export interface RadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  // error?: string | null
  // label: string
  inputRef?: any
  onUnmount?: () => void
  // className?: string
}

export const Radio = memo<RadioProps>(function Radio(props) {
  const { inputRef, className, onUnmount, ...rest } = props
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount()
      }
    }
  }, [])
  return (
    <div className={cx('radio-button', className)}>
      <input {...rest} ref={inputRef} type="radio" />

      <span className="radio-button-control"></span>
    </div>
  )
})
