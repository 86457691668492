import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { parseISO, formatISO, isValid } from 'date-fns'
import styles from './DateInputKnown.module.scss'
import { ErrorBanner } from '../ErrorBanner/ErrorBanner'

const isNumeric = (value: unknown) => !isNaN(Number(value))

type InitialDateObject = {
  day: string
  month: string
  year: string
}

export interface DateInputKnownProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  error?: string | null
  legend: string
  initialDate?: InitialDateObject
  inputRef?: any
  onUpdateValue: (
    name: string,
    value: any,
    config?:
      | Partial<{
          shouldValidate: boolean
          shouldDirty: boolean
        }>
      | undefined,
  ) => void
}

const validateDate = (year: string, month: string, day: string) => {
  const trailingZeroMonth = ('0' + month).slice(-2)
  const trailingZeroDay = ('0' + day).slice(-2)

  const newDate = parseISO(`${year}-${trailingZeroMonth}-${trailingZeroDay}`)

  if (isValid(newDate)) {
    return newDate
  } else {
    return false
  }
}

export const DateInputKnown: React.FC<DateInputKnownProps> = (props) => {
  const { legend, error, onUpdateValue, initialDate } = props

  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  useEffect(() => {
    if (initialDate) {
      setDay(initialDate.day)
      setMonth(initialDate.month)
      setYear(initialDate.year)
    }
  }, [initialDate])

  useEffect(() => {
    const newDate = validateDate(year, month, day)

    if (newDate) {
      onUpdateValue(
        'dateOfBirth',
        formatISO(newDate, { representation: 'date' }),
        {
          shouldValidate: true,
        },
      )
    } else {
      onUpdateValue('dateOfBirth', '')
    }
  }, [day, month, year, onUpdateValue])

  const handleChangeField = (
    newValue: string,
    setState: (value: string) => void,
  ) => {
    if (isNumeric(newValue)) {
      setState(newValue)
    }
  }

  return (
    <>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{legend}</legend>
        <div
          className={clsx(
            styles.dateInputKnownWrapper,
            error && styles.hasError,
          )}
        >
          <div>
            <label htmlFor="dob-day">Day</label>
            <input
              id="dob-day"
              name="dob-day"
              placeholder="DD"
              type="text"
              inputMode="numeric"
              maxLength={2}
              value={day}
              onChange={(e) => handleChangeField(e.target.value, setDay)}
              autoComplete="bday-day"
            />
          </div>
          <div>
            <label htmlFor="dob-month">Month</label>
            <input
              id="dob-month"
              name="dob-month"
              placeholder="MM"
              type="text"
              inputMode="numeric"
              maxLength={2}
              value={month}
              onChange={(e) => handleChangeField(e.target.value, setMonth)}
              autoComplete="bday-month"
            />
          </div>
          <div>
            <label htmlFor="dob-year">Year</label>
            <input
              id="dob-year"
              className={styles.inputYear}
              placeholder="YYYY"
              name="dob-year"
              type="text"
              inputMode="numeric"
              maxLength={4}
              value={year}
              onChange={(e) => handleChangeField(e.target.value, setYear)}
              autoComplete="bday-year"
            />
          </div>
          {error ? (
            <div className={styles.error}>
              <ErrorBanner error={error} />
            </div>
          ) : null}
        </div>
      </fieldset>
    </>
  )
}
