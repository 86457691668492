import React, { memo, useState, useCallback } from 'react'
import { useForm, FieldError, DeepMap } from 'react-hook-form'
import clsx from 'clsx'

import {
  NewPasswordTypes,
  newPasswordValidators,
} from '@core/shared/validation/newPassword'
import { PasswordInput } from '../form/PasswordInput'

interface NewPasswordComponentProps {
  onCancel: () => void
  onSubmit: (data: {
    oldPassword?: string
    newPassword: string
    reEnteredNewPassword: string
  }) => Promise<void>
  error?: string
  resetPassword?: boolean
}

interface RequirementPasswordRules {
  charactersLength?: boolean
  capitalLetter?: boolean
  number?: boolean
  specialSymbol?: boolean
  lowercaseLetter?: boolean
}

const errorsMap: Record<string, string> = {
  required: 'Required field',
  requiredPassword: 'Please enter password according to requirements',
  reEnteredNewPassword: "Doesn't match new password",
}

function getError<T>(errors: DeepMap<T, FieldError>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }

  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}

export const NewPasswordComponent = memo<NewPasswordComponentProps>(
  function NewPasswordComponent({ onCancel, onSubmit, error, resetPassword }) {
    const [requirementPasswordRules, setRequirementPasswordRules] =
      useState<RequirementPasswordRules>({
        charactersLength: false,
        capitalLetter: false,
        number: false,
        specialSymbol: false,
        lowercaseLetter: false,
      })

    const { register, handleSubmit, errors, formState, watch } = useForm<{
      oldPassword: string
      newPassword: string
      reEnteredNewPassword: string
    }>()

    const watchAllFields = watch()

    const handlePasswordValidator = useCallback((value: string) => {
      const validFields: string[] = []

      newPasswordValidators.forEach((rule: NewPasswordTypes) => {
        if (rule.test instanceof RegExp) {
          if (rule.test.test(value)) {
            validFields.push(rule.messageType)
          }
        } else if (typeof rule.test === 'function') {
          if (rule.test(value)) {
            validFields.push(rule.messageType)
          }
        }
      })

      return validFields
    }, [])

    const handleOnChangeInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === 'newPassword') {
          let checkedRequirement = {
            charactersLength: false,
            capitalLetter: false,
            number: false,
            specialSymbol: false,
          }

          const validatedFields = handlePasswordValidator(event.target.value)

          if (validatedFields && validatedFields.length) {
            validatedFields.forEach((el) => {
              checkedRequirement = {
                ...checkedRequirement,
                [`${el}`]: true,
              }
            })
          }
          setRequirementPasswordRules(checkedRequirement)
        }
      },
      [handlePasswordValidator],
    )

    const localOnSubmit = useCallback(
      async (data) => {
        await onSubmit(data)
      },
      [onSubmit],
    )

    const requirementItems = [
      {
        label: '10 characters',
        isSatisfied: requirementPasswordRules.charactersLength,
      },
      {
        label: '1 lowercase letter',
        isSatisfied: requirementPasswordRules.lowercaseLetter,
      },
      {
        label: '1 capital letter',
        isSatisfied: requirementPasswordRules.capitalLetter,
      },
      {
        label: '1 number',
        isSatisfied: requirementPasswordRules.number,
      },
      {
        label: '1 special symbol',
        isSatisfied: requirementPasswordRules.specialSymbol,
      },
    ]

    return (
      <div className="new-password-modal-content new-password-modal">
        <div className="flex flex-column align-center text-color-primary">
          <h2 className="text-heading-m mb-8">Enter a new password</h2>
        </div>
        <div className="new-password-form-wrapper action-field-container">
          <form
            className="new-password-form"
            onSubmit={handleSubmit(localOnSubmit)}
          >
            {Boolean(error) && (
              <div className="error-text text-center mb-1 mt-1">{error}</div>
            )}
            {!resetPassword ? (
              <PasswordInput
                label=""
                placeholder="Old password"
                name="oldPassword"
                error={getError(errors, 'oldPassword')}
                inputRef={register({ required: true })}
                onChange={handleOnChangeInput}
              />
            ) : null}
            <PasswordInput
              label=""
              placeholder="Enter new password"
              name="newPassword"
              error={getError(errors, 'newPassword')}
              inputRef={register({
                validate: {
                  requiredPassword: (val: string) => {
                    const notEmpty = val.trim().length !== 0
                    const meetRules = handlePasswordValidator(val).length === 5

                    return notEmpty && meetRules
                  },
                },
              })}
              onChange={handleOnChangeInput}
            />
            <PasswordInput
              label=""
              placeholder="Re-enter new password"
              name="reEnteredNewPassword"
              error={getError(errors, 'reEnteredNewPassword')}
              inputRef={register({
                required: true,
                validate: {
                  reEnteredNewPassword: (val: string) => {
                    return watchAllFields.newPassword === val
                  },
                },
              })}
              onChange={handleOnChangeInput}
            />
            <div
              data-testid="password-requirements"
              className="requirement-handle-value mt-3 mb-3"
            >
              <h3
                data-testid="password-requirements-title"
                className="text-body-m font-normal text-color-primary mb-2"
              >
                Passwords must include at least:
              </h3>
              <ul
                data-testid="password-requirements-list"
                className="requirement-handle-value-list"
              >
                {requirementItems.map((item) => (
                  <li
                    key={item.label}
                    className="requirement-handle-value-list-item"
                  >
                    <div className="requirement-item">
                      <div
                        className={clsx(
                          'requirement-icon',
                          item.isSatisfied && 'checked',
                        )}
                      ></div>
                      <div className="text-body-m requirement-icon-text">
                        {item.label}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="btn-container">
              <button
                disabled={
                  formState.isSubmitting ||
                  (!formState.isValid && formState.isSubmitted)
                }
                className="btn btn-primary w-full mb-2 mt-1"
                type="submit"
              >
                Save password
              </button>
              {!resetPassword ? (
                <button
                  disabled={formState.isSubmitting}
                  className="btn w-full mb-2 mt-1"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault()
                    onCancel()
                  }}
                >
                  Cancel
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    )
  },
)
