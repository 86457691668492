import React, { memo, useState, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import cx from 'clsx'
import ReactTooltip from 'react-tooltip'
import { useForm, FieldError, FormProvider, DeepMap } from 'react-hook-form'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useClientVariables } from '@core/components/app/features/variables'
import { postCodePlaceholders } from '@core/shared/data/postCodePlaceholders'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { Stepper } from './components/Stepper'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useAction } from '@core/shared/useAction'
import { submitAppointmentBookingStep2Action } from '@core/app/appointment/actions'
import { useSelector } from 'react-redux'
import { selectAppointmentBooking } from '@core/app/appointment/selectors'
import {
  selectBookingRecommended,
  selectPopulateReasonForBooking,
  selectReasonResult,
} from '@core/app/symptomsAssessment/selectors'
import { TextInput } from '@core/components/form/TextInput'
import { TextAreaInput } from '@core/components/form/TextAreaInput'
import { ErrorIcon } from '@core/components/icons/ErrorIcon'
import { FilePickerList } from '@core/components/form/FilePickerList'
import { NativeSelect } from '@core/components/form/NativeSelect'
import { SideMenu } from '@core/components/app/SideMenu'
import { required } from '@core/shared/validation/required'
import { RedirectOnPageRefresh } from '@core/components/app/booking/RedirectOnPageRefresh'
import { acceptedFileFormats } from '@core/shared/acceptedFileFormats'
import {
  ServiceDetailOptions,
  ServiceValues,
} from '@core/components/app/HealthServiceDetails/ServiceDetailOptions'
import {
  selectUserPatientRecord,
  selectDependentsRecords,
} from '@core/app/patientRecord/selectors'
import { setTemporaryUserRecordRequestAction } from '@core/app/user/actions'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import { ServiceType } from '@core/shared/types/patient/PatientRecord'
import { GpServiceError } from '@core/components/app/HealthServiceDetails/ServiceDetailsError/GpServiceError'
import { PharmacyServiceError } from '@core/components/app/HealthServiceDetails/ServiceDetailsError/PharmacyServiceError'
import { Address } from '@core/shared/types/shared/Address'
import { usePaymentEligibilityWrapper } from '@core/components/app/paymentEligibility/usePaymentEligibilityWrapper'
import { AppointmentType } from '@core/shared/types/appointment'
import { CPSpecialtiesEnum } from '@core/shared/types/bookings'

type Props = {
  shouldClearTemporaryRecord?: React.MutableRefObject<boolean>
}

const errorsMap: Record<string, string> = {
  required: 'Required field',
}
function getError<T>(errors: DeepMap<T, FieldError>, field: keyof T) {
  const err = errors[field] as FieldError
  if (!err) {
    return null
  }
  if (err.message) {
    return err.message
  }
  if (errorsMap[err.type]) {
    return errorsMap[err.type]
  }
  return null
}
export const BookStep2Page = memo(function BookStep2Page(props: Props) {
  const { shouldClearTemporaryRecord } = props
  const history = useHistory()
  const { state } = useLocation<{ cpSpecialtiesId: string }>()
  const cpSpecialtiesId = state?.cpSpecialtiesId
  const goToStep3 = () =>
    history.push(ScreenRoutes.BOOK_CONSULTATION_STEP_3, { cpSpecialtiesId })
  const [editAddress, setEditAddress] = useState(false)
  const { pristine, booking } = useSelector(selectAppointmentBooking)
  const reasonConclusions = useSelector(selectReasonResult)
  const populateReasons = useSelector(selectPopulateReasonForBooking)
  const userPatientRecord = useSelector(selectUserPatientRecord)
  const isAssessmentBooking = useSelector(selectBookingRecommended)
  const dependentRecord = useSelector(selectDependentsRecords)

  const isDependant = booking?.patient?.isDependant
  const patientRecord = isDependant
    ? dependentRecord[booking?.patient?.id]
    : userPatientRecord

  const { IsPaymentEligibilityEnabled } = usePaymentEligibilityWrapper()

  const hasExistingGPRecord = patientRecord?.attributes?.[ServiceValues.GP]
    ? true
    : false

  const reasonForBooking = reasonConclusions
    ?.map((reason) => reason.displayText)
    .flat()
  const temporaryUserRecord = useSelector(selectTemporaryUserRecord)
  const tempRecordVals = useMemo(
    () =>
      temporaryUserRecord?.userRecord
        ? {
            ...temporaryUserRecord.userRecord,
            nominatedPharmacy:
              temporaryUserRecord.serviceType === ServiceType.PHARMACY
                ? null
                : {
                    ...temporaryUserRecord.userRecord.nominatedPharmacy,
                  },
            gp:
              temporaryUserRecord.serviceType === ServiceType.GPSURGERY
                ? null
                : {
                    ...temporaryUserRecord.userRecord.gp,
                  },
          }
        : '',
    [temporaryUserRecord],
  )

  const setTemporaryUserRecordRequest = useAction(
    setTemporaryUserRecordRequestAction,
  )

  const defaultValues = {
    ...booking,
    ...(reasonForBooking &&
      isAssessmentBooking &&
      populateReasons && { reasonForBooking }),
    gp: booking?.patient?.attributes?.gp,
    nominatedPharmacy: booking?.patient?.attributes?.nominatedPharmacy,
    isRegisteredWithGP: hasExistingGPRecord,
    ...(temporaryUserRecord?.userRecord && { ...tempRecordVals }),
  }

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  })

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    reset,
    errors,
    formState,
  } = methods
  const { bookings } = useFeatures()
  const {
    clientVariables: { baseCountryCode, countryList = [] },
  } = useClientVariables()

  const isOnsiteConsultation =
    booking?.consultationType === AppointmentType.Onsite

  const canUploadSupportingDocuments =
    !isOnsiteConsultation && bookings.uploadSupportingDocuments.isEnabled

  const isCounselling = cpSpecialtiesId === CPSpecialtiesEnum.COUNSELLOR

  useEffect(() => {
    register({ name: 'files' })
  }, [register])
  const hasError = {
    reasonForBooking: Boolean(errors?.reasonForBooking),
  }
  const address = isDependant
    ? userPatientRecord?.attributes.address
    : booking.contactDetails.address
  const temporaryRecordAddress =
    temporaryUserRecord?.userRecord?.contactDetails?.address
  const addressPreview = temporaryRecordAddress
    ? getPreviewAddress(temporaryRecordAddress)
    : getPreviewAddress(address)

  const submitStep2 = useAction(submitAppointmentBookingStep2Action)

  const onSubmit = (data: Record<string, any>) => {
    setTemporaryUserRecordRequest({
      userRecord: data,
      serviceType: '',
    })
    submitStep2(data)
    goToStep3()
  }

  function getPreviewAddress(address: Address) {
    return `${
      address.addressLine1
    } ${address.addressLine2 || ''} ${address.city} ${address.postCode}`
  }

  if (pristine) {
    return <RedirectOnPageRefresh />
  }
  return (
    <div className="page book-consultation-page">
      <PageHeader showBg backBtn title="Book your consultation" withStepper>
        <Stepper step={2} steps={3} />
      </PageHeader>
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {!isOnsiteConsultation ? (
                <div className="section" data-testid="consultation-address">
                  <div className="section-title mb-1">
                    <h3 className="text-weight-lg">
                      Will you be at this address during the consultation? If
                      not, please edit.
                      <span
                        data-tip
                        data-for="infoTooltip"
                        className="tooltip-icon-wrapper"
                      >
                        <ErrorIcon fillBg />
                      </span>
                      <ReactTooltip
                        id="infoTooltip"
                        place="bottom"
                        effect="solid"
                        multiline={true}
                        className="custom-tooltip"
                      >
                        <span className="text-sm book-tooltip-text">
                          In the event of an emergency, we may <br /> need your
                          current location in order to <br /> alert emergency
                          services.
                        </span>
                      </ReactTooltip>
                    </h3>
                  </div>
                  {editAddress ? (
                    <div className="consultation-address">
                      <TextInput
                        label=""
                        placeholder="First line of address"
                        className="w-full"
                        name="contactDetails.address.addressLine1"
                        error={getError(
                          errors?.contactDetails?.address || {},
                          'addressLine1',
                        )}
                        required
                        inputRef={register({ validate: { required } })}
                      />
                      <TextInput
                        label=""
                        withoutLabel={true}
                        placeholder="Second line of address (optional)"
                        className="w-full"
                        name="contactDetails.address.addressLine2"
                        inputRef={register}
                      />
                      <div className="details-row">
                        <div
                          style={{ display: 'flex' }}
                          className="city-post-line"
                        >
                          <div className="flex-row">
                            <div style={{ display: 'flex', flex: 1 }}>
                              <TextInput
                                withoutLabel={true}
                                label=""
                                placeholder="Town or city"
                                className="w-full"
                                name="contactDetails.address.city"
                                error={getError(
                                  errors?.contactDetails?.address || {},
                                  'city',
                                )}
                                inputRef={register({ validate: { required } })}
                                required
                              />
                            </div>
                            <div style={{ width: '30%', marginLeft: '20px' }}>
                              <TextInput
                                withoutLabel={true}
                                label=""
                                placeholder={
                                  postCodePlaceholders[baseCountryCode]
                                }
                                className="postcode-input"
                                name="contactDetails.address.postCode"
                                error={getError(
                                  errors?.contactDetails?.address || {},
                                  'postCode',
                                )}
                                inputRef={register({ validate: { required } })}
                                required
                              />
                            </div>
                          </div>
                          <div className="flex-row">
                            <NativeSelect
                              withoutLabel={true}
                              label=""
                              placeholder="Country"
                              aria-label="Country"
                              error={getError(
                                errors?.contactDetails?.address || {},
                                'countryCode',
                              )}
                              selectRef={register({ required: true })}
                              name="contactDetails.address.countryCode"
                              options={countryList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="paper">
                      <div className="paper-content">
                        <div className="text-weight-md text-smd flex-row space-between">
                          {addressPreview}
                          <button
                            type="button"
                            onClick={() => {
                              const values: any = getValues()
                              if (!values.contactDetails) {
                                values.contactDetails = isDependant
                                  ? userPatientRecord?.attributes
                                  : booking?.contactDetails || {}
                              }
                              reset(values)
                              setEditAddress(true)
                            }}
                            className="btn action-link"
                            arial-label="edit address during consultation"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}
              <div className="section" data-testid="reason-for-booking">
                <h3 className="section-title mb-1">Reason for booking</h3>
                <div className="details-row">
                  <TextAreaInput
                    label="Reason for booking"
                    placeholder="Please give your reason for the consultation."
                    className={cx('w-full mb-2')}
                    error={hasError.reasonForBooking}
                    name="reasonForBooking"
                    inputRef={register({ validate: { required } })}
                    required
                  />
                  {!isCounselling ? (
                    <Feature name="hasSymptomsField">
                      <TextAreaInput
                        label="Symptoms"
                        placeholder="Please list any symptoms you are experiencing including details of any medication you are taking."
                        className={cx('w-full mb-2')}
                        name="symptoms"
                        inputRef={register}
                      />
                    </Feature>
                  ) : null}
                </div>
                {canUploadSupportingDocuments &&
                !IsPaymentEligibilityEnabled ? (
                  <div data-testid="file-picker">
                    <h3 className="section-title mb-1 mt-3">
                      Supporting documents
                    </h3>
                    <FilePickerList
                      onChange={(files) => setValue('files', files)}
                      defaultFiles={defaultValues?.files}
                      label="Add supporting documents, this could be an image, test results, x-rays etc."
                      btnText="Add file"
                      acceptFormat={acceptedFileFormats}
                    />
                  </div>
                ) : null}
              </div>

              {!isCounselling ? (
                <>
                  <div className="section">
                    <ServiceDetailOptions
                      serviceType={ServiceValues.GP}
                      existingRecord={patientRecord?.attributes}
                      shouldClearTempRecord={shouldClearTemporaryRecord}
                    />
                    <ServiceDetailOptions
                      serviceType={ServiceValues.PHARMACY}
                      existingRecord={patientRecord?.attributes}
                      shouldClearTempRecord={shouldClearTemporaryRecord}
                    />
                  </div>
                </>
              ) : null}

              <div className="section">
                <div className="btn-container">
                  <button
                    type="submit"
                    className="btn btn-primary w-full"
                    data-testid="step-two-next-btn"
                  >
                    Next
                  </button>
                </div>
              </div>
              {!formState.isValid && formState.isSubmitted && (
                <div className="section">
                  <div className="validation-errors">
                    <ErrorIcon />
                    <div role="alert" className="tooltip warning ml-1">
                      Please fill all required fields
                    </div>
                  </div>
                </div>
              )}
              <GpServiceError />
              <PharmacyServiceError />
            </form>
          </FormProvider>
        </div>
      </div>
      <PageFooter stickyBottom />
    </div>
  )
})
