import { Action } from '@core/shared/types/Action'
import { SymptomReportModel } from '@doctorlink/traversal-core'

export enum AssessmentActionTypes {
  CREATE_ASSESSMENT_RESULT = 'CREATE_ASSESSMENT_RESULT',
  GET_ASSESSMENT_RESULT = 'GET_ASSESSMENT_RESULT',
  DELETE_ASSESSMENT_RESULT = 'DELETE_ASSESSMENT_RESULT',
  SET_POPULATE_REASONFORBOOKING = 'SET_POPULATE_REASONFORBOOKING',
}

export type CreateAssessmentAction = Action<
  typeof AssessmentActionTypes.CREATE_ASSESSMENT_RESULT,
  {
    conclusion: SymptomReportModel
  }
>

export type GetAssessmentAction = Action<
  typeof AssessmentActionTypes.GET_ASSESSMENT_RESULT,
  {
    conclusion: SymptomReportModel
  }
>

export type DeleteAssessmentAction = Action<
  typeof AssessmentActionTypes.DELETE_ASSESSMENT_RESULT
>

export type SetPopulateReasonForBooking = Action<
  typeof AssessmentActionTypes.SET_POPULATE_REASONFORBOOKING,
  {
    populateReasonForBooking: boolean
  }
>
