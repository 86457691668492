import { Action } from '@core/shared/types/Action'

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  SSO_AUTHENTICATION_SUCCESS = 'SSO_AUTHENTICATION_SUCCESS',
  LOGOUT = 'LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGIN_TOKEN_RETRIEVED = 'TOKEN_RETRIEVED',
}

export interface LoginPayload {
  email: string
  password: string
}

export interface TokenPayload {
  token: string
  refreshToken: string
}

export type LoginAction = Action<
  typeof AuthActionTypes.LOGIN,
  {
    userName: string
    password: string
  }
>

export type LoginTokenRetrievedAction = Action<
  typeof AuthActionTypes.LOGIN_TOKEN_RETRIEVED,
  TokenPayload
>

export type LoginSuccessAction = Action<typeof AuthActionTypes.LOGIN_SUCCESS>

export type LoginFailAction = Action<typeof AuthActionTypes.LOGIN_FAIL>

export type LogoutAction = Action<typeof AuthActionTypes.LOGOUT>

export type LogoutSuccessAction = Action<typeof AuthActionTypes.LOGOUT_SUCCESS>

export type SsoAuthenticationSuccess = Action<
  typeof AuthActionTypes.SSO_AUTHENTICATION_SUCCESS
>
