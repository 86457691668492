import {
  BookingActionTypes,
  GetUpcomingBookingsAction,
  GetUpcomingBookingsActionSuccess,
  SelectUpcomingBookingAction,
  CancelBookingAction,
  GetBookingAttachmentsAction,
  GetBookingAttachmentsActionSuccess,
  CancelBookingActionSuccess,
  UpdateBookingAction,
  UpdateBookingActionSuccess,
  DeleteBookingAttachment,
  DeleteBookingAttachmentSuccess,
  SelectCompletedBookingAction,
  SubmitBookingAction,
  SubmitBookingFailAction,
  SubmitBookingSuccessAction,
  CreateBookingJoiningTokenAction,
  CreateBookingJoiningTokenSuccessAction,
  CreateBookingJoiningTokenFailAction,
  DownloadBookingAttachmentRequestAction,
  DownloadBookingAttachmentSuccessAction,
  GetBookingByIdRequestAction,
  GetBookingByIdSuccessAction,
  GetBookingByIdFailAction,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const getUpcomingBookings = createAction<GetUpcomingBookingsAction>(
  BookingActionTypes.GET_UPCOMING_BOOKINGS,
)

export const getUpcomingBookingsSuccess =
  createAction<GetUpcomingBookingsActionSuccess>(
    BookingActionTypes.GET_UPCOMING_BOOKINGS_SUCCESS,
  )

export const selectUpcomingBooking = createAction<SelectUpcomingBookingAction>(
  BookingActionTypes.SELECT_UPCOMING_BOOKING,
)

export const selectCompletedBooking =
  createAction<SelectCompletedBookingAction>(
    BookingActionTypes.SELECT_COMPLETED_BOOKING,
  )

export const cancelBooking = createAction<CancelBookingAction>(
  BookingActionTypes.CANCEL_BOOKING,
)

export const cancelBookingSuccess = createAction<CancelBookingActionSuccess>(
  BookingActionTypes.CANCEL_BOOKING_SUCCESS,
)

export const getBookingAttachments = createAction<GetBookingAttachmentsAction>(
  BookingActionTypes.GET_BOOKING_ATTACHMENTS,
)

export const getBookingAttachmentsSuccess =
  createAction<GetBookingAttachmentsActionSuccess>(
    BookingActionTypes.GET_BOOKING_ATTACHMENTS_SUCCESS,
  )

export const updateBooking = createAction<UpdateBookingAction>(
  BookingActionTypes.UPDATE_BOOKING,
)

export const updateBookingSuccess = createAction<UpdateBookingActionSuccess>(
  BookingActionTypes.UPDATE_BOOKING_SUCCESS,
)

export const deleteBookingAttachment = createAction<DeleteBookingAttachment>(
  BookingActionTypes.DELETE_BOOKING_ATTACHMENT,
)

export const deleteBookingAttachmentSuccess =
  createAction<DeleteBookingAttachmentSuccess>(
    BookingActionTypes.DELETE_BOOKING_ATTACHMENT_SUCCESS,
  )

export const submitBookingAction = createAction<SubmitBookingAction>(
  BookingActionTypes.SUBMIT_BOOKING,
)

export const submitBookingSuccessAction =
  createAction<SubmitBookingSuccessAction>(
    BookingActionTypes.SUBMIT_BOOKING_SUCCESS,
  )

export const submitBookingFailAction = createAction<SubmitBookingFailAction>(
  BookingActionTypes.SUBMIT_BOOKING_FAIL,
)

export const createBookingJoiningTokenAction =
  createAction<CreateBookingJoiningTokenAction>(
    BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN,
  )

export const createBookingJoiningTokenSuccessAction =
  createAction<CreateBookingJoiningTokenSuccessAction>(
    BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN_SUCCESS,
  )

export const createBookingJoiningTokenFailAction =
  createAction<CreateBookingJoiningTokenFailAction>(
    BookingActionTypes.CREATE_BOOKING_JOINING_TOKEN_FAIL,
  )

export const downloadBookingAttachmentRequestAction =
  createAction<DownloadBookingAttachmentRequestAction>(
    BookingActionTypes.DOWNLOAD_BOOKING_ATTACHMENT,
  )

export const downloadBookingAttachmentSuccessAction =
  createAction<DownloadBookingAttachmentSuccessAction>(
    BookingActionTypes.DOWNLOAD_BOOKING_ATTACHMENT_SUCCESS,
  )

export const getBookingByIdRequestAction =
  createAction<GetBookingByIdRequestAction>(
    BookingActionTypes.GET_BOOKING_BY_ID,
  )

export const getBookingByIdSuccessAction =
  createAction<GetBookingByIdSuccessAction>(
    BookingActionTypes.GET_BOOKING_BY_ID_SUCCESS,
  )

export const getBookingByIdFailAction = createAction<GetBookingByIdFailAction>(
  BookingActionTypes.GET_BOOKING_BY_ID_FAIL,
)
