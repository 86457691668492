import dayjs from 'dayjs'
import { PatientRecord } from './types/patient/PatientRecord'

export const getPatienName = (patient?: PatientRecord) => {
  if (!patient) {
    return ''
  }

  const { firstName, lastName } = patient.attributes

  return `${firstName} ${lastName}`
}

export const getPatientAddressString = (patient?: PatientRecord) => {
  if (!patient) {
    return ''
  }
  const { addressLine1, addressLine2, city, postCode } =
    patient.attributes.address
  const addressParts: string[] = []
  addressParts.push(addressLine1)
  if (addressLine2) {
    addressParts.push(addressLine2)
  }
  addressParts.push(city)
  addressParts.push(postCode)

  const { dateOfBirth } = patient.attributes
  const dob = dayjs(dateOfBirth).format('DD/MM/YYYY')
  const address = addressParts.join(', ')

  return `${address}. DOB: ${dob}.`
}

export const getPatientContactString = (patient?: PatientRecord) => {
  if (!patient) {
    return ''
  }

  const { email, phoneNumber } = patient.attributes

  return `${phoneNumber}. ${email}`
}
