import { ResponseCodes } from './responseCodes'

export type ResponseMessagesByCode = {
  [key in ResponseCodes]?: string
}

let responseMessages: ResponseMessagesByCode = {
  '400_client_validation_failed': 'Invalid company number',
  '403_not_on_hold':
    'Appointment hold time has expired, please choose the consultation time again.',
  '403_on_hold_by_other_user':
    'This appointment time is reserved by another user. Please choose a different consultation time',
  '403_appointment_hold_not_completed':
    'Unable to hold appointment time. Please try again.',
}

export function getMessageByCode(code: ResponseCodes) {
  return responseMessages[code]
}

export function hasMessageByCode(code: ResponseCodes) {
  return responseMessages.hasOwnProperty(code)
}

export function setMessages(messages: Partial<ResponseMessagesByCode>) {
  responseMessages = Object.assign(responseMessages, messages)
}

export function mapResponseErrors(
  response: any,
): Array<{ message: string; code: string }> {
  if (response?.errors && response.errors.length) {
    return response.errors.map((err: any) => {
      const message = getMessageByCode(err.code)
      return {
        message: message ?? err.detail,
        code: err.code,
      }
    })
  }
  return []
}

/**
 * Set of error codes from backend. 
 * Not all meaningful for us in context of error codes mapping at the moment
 *  public class ExceptionErrorCodes
    {
        public const string UNCATEGORISED_ERROR = "000_uncategorised";
        public const string UNCATEGORISED_CLIENT_ERROR = "000_uncategorised_client";
        public const string UNCATEGORISED_SERVER_ERROR = "000_uncategorised_server";

        public const string BAD_REQUEST = "400";
        public const string BAD_REQUEST_VALIDATION = "400_validation";
        public const string BAD_REQUEST_INVALID_FILTER = "400_invalid_filter";
        public const string BAD_REQUEST_INVALID_CLIENT_CODE = "400_invalid_client_code";
        public const string BAD_REQUEST_EMAIL_NOT_VERIFIED = "400_email_not_verified";
        public const string BAD_REQUEST_INVALID_CREDENTIALS = "400_invalid_username_or_password";
        public const string BAD_REQUEST_APPOINTMENT_NOT_ON_HOLD = "400_appointment_not_on_hold";
        public const string BAD_REQUEST_INVALID_CLIENT_IDENTIFIER = "400_invalid_client_identifier";
        public const string BAD_REQUEST_CLIENT_VALIDATION_FAILED = "400_client_validation_failed";
        public const string BAD_REQUEST_MFA_REQUIRED = "400_mfa_required";
        public const string BAD_REQUEST_INVALID_REQUEST_FILTER = "400_invalid_request_filter";
        public const string BAD_REQUEST_INVALID_APPOINTMENT_SWAP = "400_invalid_appointment_swap";
        public const string BAD_REQUEST_PMS_NOT_SUPPORTED = "400_pms_not_supported";
        public const string BAD_REQUEST_INVALID_MESSAGE_DOCTOR_CONVERSION = "400_invalid_message_doctor_conversion";

        public const string FORBIDDEN = "403";
        public const string FORBIDDEN_NOT_ON_HOLD = "403_not_on_hold";
        public const string FORBIDDEN_ON_HOLD_BY_OTHER_USER = "403_on_hold_by_other_user";
        public const string FORBIDDEN_INVALID_APPOINTMENT_STATUS = "403_invalid_appointment_status";
        public const string FORBIDDEN_APPOINTMENT_HOLD_NOT_COMPLETED = "403_appointment_hold_not_completed";
        
        
        public const string NOT_FOUND = "404";
        public const string NOT_FOUND_APPOINTMENT = "404_appointment";
        public const string NOT_FOUND_APPOINTMENT_HOLD = "404_appointment_hold";
        public const string NOT_FOUND_BOOKING = "404_booking";
        public const string NOT_FOUND_BOOKING_COMMENT = "404_bookingComment";
        public const string NOT_FOUND_USER = "404_user";
        public const string NOT_FOUND_CLINICAL_PRACTITIONER = "404_clinical_practitioner";
        public const string NOT_FOUND_CONSULTATION = "404_consultation";
        public const string NOT_FOUND_PATIENT_RECORD = "404_patient_record";
        public const string NOT_FOUND_DEPENDANT = "404_dependant";
        public const string NOT_FOUND_REST_ENDPOINT = "404_rest_endpoint";
        public const string NOT_FOUND_MATCHING_APPOINTMENTS = "404_no_matching_appointments";
        public const string NOT_FOUND_CLIENT_APPOINTMENT_FILTER = "404_client_appointment_filter";
        public const string NOT_FOUND_SHIFT = "404_shift";
        public const string NOT_FOUND_PAYMENT_INTENT = "404_payment_intent";

        public const string CONFLICT = "409";
        public const string CONFLICT_APPOINTMENT_OVERLAP = "409_appointment_overlap";

        public const string PRECONDITION_FAILED = "412";
        public const string PRECONDITION_FAILED_OPTIMISTIC_LOCK = "412_optimistic_lock";
        public const string PRECONDITION_INVALID_STATE = "412_invalid_state";
        public const string PRECONDITION_MISSING_PATIENT_RECORD = "412_patient_record_required";

        public const string TOO_MANY_REQUESTS = "429";

        public const string INTERNAL_SERVER_ERROR = "500";
        public const string INTERNAL_SERVER_ERROR_ADASTRA_ADD_CASE = "500_clinical_management_add_case";
        public const string INTERNAL_SERVER_ERROR_ADASTRA_UPDATE_CASE = "500_clinical_management_update_case";
        public const string INTERNAL_SERVER_ERROR_PMS_COMMUNICATION_FAILED = "500_pms_communication_failed";
    }
 */
