export const getUrl = (path: any, params: Record<string, any> = {}): string => {
  const paramsToPairs = Object.keys(params).map((key) => [key, params[key]])

  return paramsToPairs.reduce((p, params) => {
    const regex = new RegExp(`:${params[0]}\\??`)

    return p.replace(regex, params[1])
  }, path)
}

export const route = (route: any, params?: Record<string, any>) =>
  getUrl(route, params)
