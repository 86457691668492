import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const AppointmentBookingIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 21"
      className={cx('appointment-booking-icon', className)}
      aria-hidden="true"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke="#8A93A1" strokeWidth="1.25">
          <g>
            <path d="M19.9729,8.8735 C20.0469,9.3565 20.0849,9.8515 20.0849,10.3545 C20.0849,15.7285 15.7289,20.0855 10.3559,20.0855 C4.9819,20.0855 0.6249,15.7285 0.6249,10.3545 C0.6249,4.9815 4.9819,0.6245 10.3559,0.6245 C11.6989,0.6245 12.9789,0.8975 14.1429,1.3895"></path>
            <polygon points="10.0578 15.5293 4.3338 9.8053 7.1618 6.9773 10.0578 9.8733 17.9788 1.9523 20.8068 4.7803"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
