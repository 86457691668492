import { ErrorIcon } from '../../icons/ErrorIcon'
import styles from './ErrorBanner.module.scss'

interface ErrorBannerProps {
  error: string
}
export const ErrorBanner = (props: ErrorBannerProps) => {
  const { error } = props

  return (
    <div className={styles.bannerWrapper} data-testid="error-banner">
      <ErrorIcon />
      <div role="alert" className={styles.error}>
        {error}
      </div>
    </div>
  )
}
