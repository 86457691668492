import React, { useCallback } from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { route } from '@core/shared/route'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { BodyItemsType, SpecialtiesItemsType } from '../../../specialitiesMap'
import { RightChevronIcon } from '../../../icons/RightChevron'
import Background from './assets/gp-consultation.jpg'
import styles from './GenericConsultation.module.scss'
import { setPopulateReasonForBookingAction } from '@core/app/symptomsAssessment/actions'
import { useAction } from '@core/shared/useAction'
import { Service, useFeatures } from '../../features/features'
import { CPSpecialties } from '@core/shared/types/bookings'

interface Props {
  specialtiesItem: SpecialtiesItemsType
  isUserEmailVerified: boolean
  image?: any
  isGpConsultation?: boolean
  hasEligibility?: boolean
  hasTranslation?: boolean
}

export const GenericConsultation = ({
  specialtiesItem,
  isUserEmailVerified,
  image,
  isGpConsultation = false,
  hasEligibility = false,
  hasTranslation = false,
}: Props) => {
  const history = useHistory()
  const setPopulateReasonForBooking = useAction(
    setPopulateReasonForBookingAction,
  )
  const { HeaderIcon, headerTitle, description, bodyIcons, specialtiesType } =
    specialtiesItem

  const handleClick = useCallback(() => {
    setPopulateReasonForBooking({ populateReasonForBooking: false })
    if (!isUserEmailVerified) {
      return
    }
    if (hasEligibility) {
      history.push(
        route(ScreenRoutes.BOOKING_ELIGIBILITY, {
          cpSpecialtiesId: specialtiesType,
        }),
      )
    } else {
      history.push(
        route(ScreenRoutes.CHOOSE_CONSULTATION, {
          cpSpecialtiesId: specialtiesType,
        }),
      )
    }
  }, [
    setPopulateReasonForBooking,
    isUserEmailVerified,
    hasEligibility,
    history,
    specialtiesType,
  ])

  const BackgroundImage = isGpConsultation ? image || Background : undefined

  const { bookings } = useFeatures()

  const service = CPSpecialties[specialtiesType] as Service
  const canBookUsingPhone =
    bookings?.services?.value?.[service]?.bookingTypes?.phone ?? true

  const showTranslationText = hasTranslation && canBookUsingPhone

  return (
    <div
      className={clsx(
        styles.genericConsultation,
        isGpConsultation && styles.gpConsultation,
        !isUserEmailVerified && styles.specialtiesDisabled,
      )}
      style={{ backgroundImage: `url(${BackgroundImage})` }}
      onClick={isUserEmailVerified ? handleClick : undefined}
    >
      <div className={styles.wrapper} data-testid="service-card">
        <header>
          <div className={styles.serviceIcon}>
            <HeaderIcon />
          </div>
          <h3>{headerTitle}</h3>
        </header>

        <div className={styles.content} data-testid="service-card--content">
          {isGpConsultation && <h3>{headerTitle}</h3>}
          <p>{description}</p>
        </div>

        <div className={styles.icons} data-testid="service-card--icons">
          {bodyIcons?.map((item: BodyItemsType, index: React.Key) => (
            <div aria-hidden="true" className={styles.iconBlock} key={index}>
              <item.Icon />
              <p>{item.title}</p>
            </div>
          ))}
        </div>
        <div className={styles.bookLink} data-testid="service-card--footer">
          <button
            aria-label={`Book a ${specialtiesItem.specialtiesTypeName}`}
            className="btn-link action-cursor"
          >
            Book
            <span className="ml-1">
              <RightChevronIcon />
            </span>
          </button>
          {showTranslationText && (
            <p className={styles.translation}>Translation services available</p>
          )}
        </div>
      </div>
    </div>
  )
}
