import { memo, useCallback } from 'react'
import ReactHtmlParser from 'react-html-parser'
import parseNHSUrl from '@core/shared/parseNHSUrl'

interface Props {
  recursive?: boolean
  item: any
}

export const ArticleItem = memo(function ArticleItem({
  item,
  recursive,
}: Props) {
  const createScript = (node: any) => {
    const script = document.createElement('script')
    script.src = node.attribs.src
    script.id = node.attribs.id
    document.body.appendChild(script)
  }

  const transformHTML = useCallback((node: any) => {
    if (node.type === 'tag' && node.name === 'a') {
      node.attribs.href = parseNHSUrl(node.attribs.href)

      node.attribs.target = '_blank'
    }

    if (node.name === 'video') {
      return null
    }

    if (node.type === 'script') {
      createScript(node)
    }
  }, [])

  return (
    <div className="mb-2">
      <div className="mb-1">
        <h2>{recursive && item?.headline}</h2>
        {recursive && item?.text && item?.name !== 'link'
          ? ReactHtmlParser(item.text, {
              transform: transformHTML,
            })
          : null}
        {recursive && item?.name === 'link' ? (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={parseNHSUrl(item.url)}
          >
            {ReactHtmlParser(item.text, {
              transform: transformHTML,
            })}
          </a>
        ) : null}
      </div>
      {item.mainEntityOfPage?.length > 0
        ? item.mainEntityOfPage.map((subItem: any, index: number) => (
            <ArticleItem item={subItem} recursive key={index} />
          ))
        : null}
    </div>
  )
})
