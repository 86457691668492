import { useEffect, useState } from 'react'
import { useClientVariables } from '../app/features/variables'
import { IconArrowDown, IconArrowUp, InfoIcon } from '../icons'
import styles from './Warning.module.scss'

export const Warning = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [isReady, setIsReady] = useState(false)
  const {
    clientVariables: { baseCountryCode },
  } = useClientVariables()

  useEffect(() => {
    const preference = localStorage.getItem('warningStrepA')
    preference && setIsExpanded(preference === 'true')
    setIsReady(true)
  }, [])

  const handleCollapse = () => {
    if (isExpanded) {
      localStorage.setItem('warningStrepA', 'false')
    } else {
      localStorage.setItem('warningStrepA', 'true')
    }
    setIsExpanded(!isExpanded)
  }

  if (baseCountryCode !== 'GB') return null

  return (
    <div className="section" role="alert">
      {isReady ? (
        <div className={styles.warning}>
          <div className={styles.icon}>
            <InfoIcon type="regularWhite" fillBg={true} />
          </div>
          <div className={styles.content}>
            <header onClick={handleCollapse}>
              <h2>Group A Streptococcus</h2>
              <div>{isExpanded ? <IconArrowUp /> : <IconArrowDown />}</div>
            </header>
            {isExpanded ? (
              <p className="text-body-m">
                If you are concerned that your child might have Group A
                Streptococcus please visit{' '}
                <a
                  href="https://www.healthhero.com/customer-information-strep-a"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://www.healthhero.com/customer-information-strep-a
                </a>{' '}
                for advice on the suitability of our service for this condition.
                Due to the surge of cases of Group A Streptococcus in the UK, we
                are experiencing high volumes of appointment requests which may
                result in longer wait times for a consultation.
              </p>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  )
}
