import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const GpConsultationIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      className={cx('gp-consultation-icon', className)}
      aria-hidden="true"
    >
      <g>
        <g>
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="2"
            d="M5.636 14.949v0l1.422 1.422a25.042 25.042 0 0 0 4.256 3.417l.008.005a3.75 3.75 0 0 0 4.667-.512l.524-.525a1.25 1.25 0 0 0 0-1.768l-2.21-2.21a1.252 1.252 0 0 0-1.768 0v0a1.25 1.25 0 0 1-1.767 0l-3.537-3.537a1.25 1.25 0 0 1 0-1.768v0a1.248 1.248 0 0 0 0-1.768l-2.21-2.209a1.25 1.25 0 0 0-1.767 0l-.525.524a3.75 3.75 0 0 0-.512 4.667l.005.008a25.012 25.012 0 0 0 3.414 4.254z"
          />
        </g>
        <g>
          <path
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="20"
            strokeWidth="2"
            d="M20.38 4.755a.625.625 0 0 0-.625-.625H17.88v0-1.875a.625.625 0 0 0-.625-.625h-2.5a.625.625 0 0 0-.625.625V4.13h-1.875a.625.625 0 0 0-.625.625v2.5c0 .345.28.625.625.625h1.875v1.875c0 .345.28.625.625.625h2.5c.345 0 .625-.28.625-.625V7.88v0h1.875c.345 0 .625-.28.625-.625z"
          />
        </g>
      </g>
    </svg>
  )
}
