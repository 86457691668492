export const getAge = (birthday: string) => {
  // it will accept two types of format yyyy-mm-dd and yyyy/mm/dd
  const optimizedBirthday = birthday.replace(/-/g, '/')

  //set date based on birthday at 01:00:00 hours GMT+0100 (CET)
  const myBirthday = new Date(optimizedBirthday)

  // set current day on 01:00:00 hours GMT+0100 (CET)
  const currentDate = `${new Date()
    .toJSON()
    .slice(0, 10)
    .replace(/-/g, '/')} 01:00:00`

  // calculate age comparing current date and birthday
  const age = ~~(
    (new Date(currentDate).getTime() - myBirthday.getTime()) /
    31557600000
  )

  return age
}

export const underAgeValidate = (birthday: string) => {
  if (!birthday || typeof birthday !== 'string') {
    return false
  }
  const age = getAge(birthday)

  if (age >= 16) {
    return false
  } else if (age < 0) {
    return false
  }

  return true
}

export const ageAfter16 = (birthday: string) => {
  if (!birthday || typeof birthday !== 'string') {
    return false
  }

  const age = getAge(birthday)

  if (age < 16) {
    return false
  } else if (age > 150) {
    return false
  }

  return true
}

export const isoDateFormatTransform = (date: string) => {
  let newDate = date

  if (!/\d{4}-\d{2}-\d{2}/gm.test(newDate)) {
    if (/\d{2}\/\d{2}\/\d{4}/gm.test(newDate)) {
      const splitValue = newDate.split('/')
      newDate = `${splitValue[2]}-${splitValue[1]}-${splitValue[0]}`
    }
  }

  return newDate
}
