import { BookingItem } from './components/BookingItem'
import { BookingType } from '@core/shared/types/bookings'
import { SpecialtiesItemsType } from '../../specialitiesMap'
import styles from './UpcomingBookings.module.scss'
import { FillInProfileBanner } from '../Banners/FillInProfileBanner'
import { useFeatures } from '../features/features'

interface UpcomingBookingProps {
  bookings: BookingType[]
  specialtiesItems: Map<string, SpecialtiesItemsType>
}

export const UpcomingBookings = (props: UpcomingBookingProps) => {
  const { bookings, specialtiesItems } = props
  const featureFlags = useFeatures()

  const hasGPService =
    featureFlags.bookings.services.value.GeneralPractitioner.isEnabled

  return bookings.length ? (
    <div className={styles.upcomingBookings}>
      <h2 className="text-heading-m font-bold mb-2">Upcoming Consultations</h2>
      {hasGPService ? <FillInProfileBanner /> : null}

      {bookings.map((booking) => (
        <BookingItem
          key={booking.id}
          booking={booking}
          specialtiesItems={specialtiesItems}
        />
      ))}
    </div>
  ) : null
}
