export const required = (val: string) => val.trim().length !== 0

export const requiredIf = (expr: (...args: any[]) => boolean) => {
  return (val: string) => {
    if (expr()) {
      return required(val)
    }
    return true
  }
}
