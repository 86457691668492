import React, { memo } from 'react'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import logo from '../../assets/images/HealthHeroLogo.svg'
import towergateLogo from '../../assets/images/towergate-insurance-logo.png'
import { SideMenu } from '@core/components/app/SideMenu'
export const AboutPage = memo(function AboutPage() {
  return (
    <div className="page about-page">
      <PageHeader showBg backBtn title="About" />

      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="section">
            <div className="logo-wrapper mt-2 mb-2 flex-center">
              <img src={logo} alt="HealthHero logo" />
            </div>
          </div>

          <div className="section">
            <h3 className="paper-header text-left about-page-title">
              This service is arranged by Towergate Health &amp; Protection on
              behalf of your employer and powered by HealthHero, specialists in
              24/7 Private GP services.
            </h3>

            <p className="about-page-text mt-2 text-left">
              HealthHero is built upon the powerful combination of digital
              knowhow and telehealth expertise with over 20 years’ experience in
              connecting members with doctors remotely.
            </p>

            <p className="about-page-text mt-1 text-left">
              All of our GPs are experienced, practising doctors who have
              exactly the same qualifications as your NHS GP. We select our
              doctors carefully and engage with them personally at HealthHero.
            </p>
          </div>

          <div className="section">
            <ul className="about-page-links">
              <li className="about-page-link-item">
                <a
                  href="https://www.healthhero.com/privacy-policy/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Read HealthHero's Privacy Policy
                </a>
              </li>
              <li className="about-page-link-item">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.healthhero.com/terms-and-conditions/"
                >
                  Read HealthHero's Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="section">
            <div className="logo-wrapper mt-2 mb-2 flex-center">
              <img
                style={{ height: '85px' }}
                src={towergateLogo}
                alt="Towergate logo"
              />
            </div>
          </div>
          <div className="section about-page-link-item">
            <p className="about-page-text mt-2 mb-1">
              The Kinetic GP service has been arranged by Towergate Health &amp;
              Protection on behalf of your employer, for the benefit of their
              employees. Towergate Health &amp; Protection are specialists in
              all areas of health and protection insurance and wellbeing.
            </p>
            <p className="about-page-text mt-2 mb-1">
              To find out more about how we help our customers protect their
              health and wellbeing please visit:
            </p>
            <p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="http://www.towergatehealthandprotection.co.uk"
              >
                www.towergatehealthandprotection.co.uk
              </a>
            </p>

            <p className="about-page-text mt-1 mb-1">or call:</p>
            <p>
              <a className="action-link" href="tel: 0800 389 7723">
                0800 389 7723
              </a>
            </p>
          </div>
        </div>
      </div>

      <PageFooter stickyBottom />
    </div>
  )
})
