import { Action } from '@core/shared/types/Action'

export enum ArticlesActionTypes {
  GET_MEDICAL_INFORMATION = 'GET_MEDICAL_INFORMATION',
  GET_MEDICAL_INFORMATION_SUCCESS = 'GET_MEDICAL_INFORMATION_SUCCESS',
  GET_MEDICAL_INFORMATION_FAIL = 'GET_MEDICAL_INFORMATION_FAIL',

  GET_MEDICAL_INFORMATION_MORE = 'GET_MEDICAL_INFORMATION_MORE',
  GET_MEDICAL_INFORMATION_MORE_SUCCESS = 'GET_MEDICAL_INFORMATION_MORE_SUCCESS',
  GET_MEDICAL_INFORMATION_MORE_FAIL = 'GET_MEDICAL_INFORMATION_MORE_FAIL',

  GET_MEDICAL_INFORMATION_BY_URL = 'GET_MEDICAL_INFORMATION_BY_URL',
  GET_MEDICAL_INFORMATION_BY_URL_SUCCESS = 'GET_MEDICAL_INFORMATION_BY_URL_SUCCESS',
  GET_MEDICAL_INFORMATION_BY_URL_FAIL = 'GET_MEDICAL_INFORMATION_BY_URL_FAIL',

  GET_LIVE_WELL = 'GET_LIVE_WELL',
  GET_LIVE_WELL_SUCCESS = 'GET_LIVE_WELL_SUCCESS',
  GET_LIVE_WELL_FAIL = 'GET_LIVE_WELL_FAIL',
}

export type GetMedicalInformationRequestAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION,
  {
    search: string
  }
>

export type GetMedicalInformationSuccessAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_SUCCESS,
  {
    search: string
    item: any
  }
>

export type GetMedicalInformationFailAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_FAIL
>

export type GetLiveWellRequestAction = Action<
  typeof ArticlesActionTypes.GET_LIVE_WELL,
  {
    path: string
    subPaths?: string[]
  }
>

export type GetLiveWellSuccessAction = Action<
  typeof ArticlesActionTypes.GET_LIVE_WELL_SUCCESS
>

export type GetLiveWellFailAction = Action<
  typeof ArticlesActionTypes.GET_LIVE_WELL_FAIL
>

export type GetMedicalInformationByUrlRequestAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL,
  {
    url: string
  }
>

export type GetMedicalInformationByUrlSuccessAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL_SUCCESS
>

export type GetMedicalInformationByUrlFailAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_BY_URL_FAIL
>

export type GetMedicalInformationMoreRequestAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE,
  {
    url: string
  }
>

export type GetMedicalInformationMoreSuccessAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE_SUCCESS,
  {
    relatedLink: any
    significantLink: any
  }
>

export type GetMedicalInformationMoreFailAction = Action<
  typeof ArticlesActionTypes.GET_MEDICAL_INFORMATION_MORE_FAIL
>
