import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const AllergyReminderIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      className={cx('allergy-reminder-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <g>
            <circle
              fill="#f4616b"
              fillRule="nonzero"
              cx="16.5"
              cy="16.5"
              r="16.5"
            ></circle>
            <g
              transform="translate(7.531120, 7.531120)"
              fill="#FFFFFF"
              fillRule="evenodd"
              strokeWidth="1"
            >
              <g>
                <path
                  d="M3.37636903,5.40600868 C3.64646239,5.40500834 3.89564852,5.26696062 4.03129541,5.04288316 C4.39532124,4.40966428 4.93700848,3.88648344 5.59483586,3.53336138 C5.83311823,3.41031885 5.98367027,3.1732369 5.98887207,2.91414734 C5.99407387,2.65405744 5.85312515,2.41197376 5.61994454,2.27992812 C5.38686398,2.14788247 5.09776405,2.14588178 4.86338303,2.27592673 C3.96297179,2.75709305 3.22101533,3.4703396 2.72214288,4.3356387 C2.51977293,4.68275868 2.6487175,5.12091014 3.01024247,5.31597756 C3.12178102,5.37599831 3.2481247,5.40800937 3.37636903,5.40600868 Z"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M4.82556996,0.517318849 C4.70542843,0.136187106 4.28708382,-0.0788872369 3.89074683,0.036152528 C2.02460177,0.549329914 0.565897552,1.94881366 0.0322130777,3.74043296 C-0.0255068739,3.92349623 -0.00509981994,4.12056435 0.0889326835,4.28962279 C0.182865152,4.45768088 0.342820443,4.58372445 0.533386314,4.63874347 C0.603710623,4.65875038 0.676735865,4.66975419 0.750161245,4.66975419 C1.08117566,4.66975419 1.37287649,4.46168227 1.46820945,4.1565768 C1.85734396,2.82711726 2.93951802,1.78875834 4.32439672,1.41462901 C4.51496259,1.35961 4.67481785,1.23456677 4.76885035,1.06550834 C4.86288286,0.897450246 4.88328991,0.699381781 4.82556996,0.517318849 Z"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M14.3643672,3.16423379 C14.0017418,2.81611345 13.5906997,2.51801041 13.142945,2.27892777 C12.9082638,2.13487798 12.6092605,2.12687521 12.3666766,2.25892085 C12.1241928,2.3909665 11.9781423,2.64205329 11.9874455,2.90914561 C11.9968488,3.17723828 12.1601052,3.41732127 12.4113921,3.53436173 C13.0687193,3.8854831 13.6106066,4.4056629 13.9757328,5.03688109 C14.1824043,5.37899935 14.6341604,5.50104153 14.9960855,5.31297653 C15.3575104,5.11890944 15.4865551,4.67975765 15.2841851,4.33263766 C15.0346989,3.90448966 14.7252919,3.51135377 14.3643672,3.16423379 Z"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M17.9742149,3.74043296 C17.4405305,1.94881366 15.9817262,0.548329568 14.1156812,0.036152528 C13.8567917,-0.0458758261 13.5720933,0.0141449208 13.3718241,0.191206124 C13.1715548,0.368267327 13.0873257,0.636359997 13.151848,0.890447825 C13.2162703,1.14453565 13.4192405,1.34560516 13.6820313,1.41462901 C15.06681,1.78875834 16.1490841,2.82711726 16.5382186,4.1565768 C16.663762,4.53270682 17.0771048,4.74578047 17.4730417,4.63874347 C17.6636076,4.58372445 17.8234628,4.45768088 17.9174953,4.28962279 C18.0115278,4.12056435 18.0319349,3.92349623 17.9742149,3.74043296 Z"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M10.128503,15.4904945 L7.87772497,15.4904945 C7.67055336,15.4904945 7.5025953,15.6515502 7.5025953,15.850619 C7.5025953,16.6458939 8.17442753,17.2911169 9.00311398,17.2911169 C9.83180042,17.2911169 10.5036326,16.6458939 10.5036326,15.850619 C10.5036326,15.6515502 10.3356746,15.4904945 10.128503,15.4904945 Z"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M13.8797997,9.83353911 L13.8797997,9.36837832 C13.8797997,6.85250868 12.3199605,4.70176525 10.128503,4.13156816 L10.128503,3.24626214 C10.128503,2.64905571 9.62462881,2.1658887 9.00311398,2.1658887 C8.38159914,2.1658887 7.87772497,2.64905571 7.87772497,3.24626214 L7.87772497,4.13156816 C5.68616743,4.70176525 4.12642829,6.85250868 4.12642829,9.36837832 L4.12642829,9.83353911 C4.1247277,11.3660688 3.75309925,12.876591 3.04075302,14.248065 C2.98253289,14.3601038 2.988635,14.4921494 3.05695862,14.5991864 C3.12528223,14.7052231 3.246124,14.7702455 3.37616896,14.7702455 L14.630059,14.7702455 C14.7601039,14.7702455 14.8809457,14.7052231 14.9492693,14.5991864 C15.017593,14.4921494 15.0236951,14.3601038 14.9653749,14.248065 C14.2532287,12.8775913 13.8816003,11.3660688 13.8797997,9.83353911 Z"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
