import { memo, useCallback, useRef } from 'react'
import cx from 'clsx'
import getDate from 'date-fns/getDate'
import { formatDate, isDayDisabled, isSameDay, isWeekend } from '../../utils'

export interface DayProps {
  date: Date
  selectedDate: Date
  onSelect: (date: Date) => void
  min?: Date
  max?: Date
  inline?: boolean
  ariaLabelPrefixWhenEnabled?: string
  ariaLabelPrefixWhenDisabled?: string
  month: number
  locale: string | Locale
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dayRenderer?: (props: DayProps) => any
  isOutOfMonth?: boolean
  renderOutOfMonth?: boolean
}

const dayClassNames = {
  static: 'datepicker-month__day',
  selected: 'datepicker-month__day--selected',
  weekend: 'datepicker-month__day--weekend',
  disabled: 'datepicker-month__day--disabled',
  hidden: 'datepicker-month__day--hidden',
}

function getClassNames({
  min,
  max,
  selectedDate,
  date,
  isOutOfMonth,
  renderOutOfMonth,
}: DayProps) {
  const isDisabled = isDayDisabled(date, { min, max })
  return cx(
    dayClassNames.static,
    !isDisabled && isSameDay(date, selectedDate) && dayClassNames.selected,
    isDisabled && dayClassNames.disabled,
    isWeekend(date) && dayClassNames.weekend,
    !renderOutOfMonth && isOutOfMonth && dayClassNames.hidden,
  )
}

function getAriaLabel(props: DayProps) {
  const {
    date,
    ariaLabelPrefixWhenEnabled = 'Choose',
    ariaLabelPrefixWhenDisabled = 'Not available',
    min,
    max,
    locale,
  } = props

  const prefix = isDayDisabled(date, { min, max })
    ? ariaLabelPrefixWhenDisabled
    : ariaLabelPrefixWhenEnabled

  return `${prefix} ${formatDate(date, 'PPPP', locale)}`
}

function getTabIndex({
  date,
  selectedDate,
  isOutOfMonth,
  renderOutOfMonth,
}: DayProps) {
  const tabIndex =
    isSameDay(date, selectedDate) || (isOutOfMonth && !renderOutOfMonth)
      ? -1
      : 0

  return tabIndex
}

function defaultDayRenderer(props: DayProps) {
  const { date, isOutOfMonth, renderOutOfMonth } = props

  return !renderOutOfMonth && isOutOfMonth ? null : getDate(date)
}

export const Day = memo<DayProps>(function Day(props) {
  const dayCellRef = useRef<HTMLButtonElement | null>(null)
  const renderDay = props.dayRenderer || defaultDayRenderer
  const { date, onSelect, month, isOutOfMonth } = props
  const handleOnCLick = useCallback(() => onSelect(date), [date, onSelect])

  return (
    <button
      ref={dayCellRef}
      type="button"
      tabIndex={getTabIndex(props)}
      className={getClassNames(props)}
      aria-label={getAriaLabel(props)}
      disabled={
        isDayDisabled(props.date, { min: props.min, max: props.max }) ||
        isOutOfMonth
      }
      onClick={handleOnCLick}
    >
      {renderDay(props)}
    </button>
  )
})
