import { memo, useState, useCallback, useMemo, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { scroller, Events, scrollSpy } from 'react-scroll'
import clsx from 'clsx'

import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { SideMenu } from '@core/components/app/SideMenu'
import { GoogleMapComponent } from '@core/components/app/GoogleMapComponent'
import { ServiceCard } from '@core/components/app/ServiceCard'
import { ServiceType } from '@core/shared/types/patient/PatientRecord'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { selectTemporaryUserRecord } from '@core/app/user/selectors'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Banner } from '@core/components/app/Banners/banner/Banner'

type Service = {
  [key: string]: {
    pageName: string
    searchValue: string
  }
}

const serviceTypes: Service = {
  gpsurgery: {
    pageName: 'Nominate GP',
    searchValue: 'GP Surgery',
  },
  pharmacy: {
    pageName: 'Nominate Pharmacy',
    searchValue: 'Pharmacy',
  },
}

export const ServicePage = memo(function ServicePage() {
  const { serviceType } = useParams<{ serviceType: string }>()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const temporaryUserRecord = useSelector(selectTemporaryUserRecord)

  const history = useHistory()

  const [services, setServices] = useState<google.maps.places.PlaceResult[]>([])
  const [pageIsReady, setPageIsReady] = useState<boolean>(false)

  const [selectedDetails, setSelectedDetails] = useState<string[]>([])
  const [details, setDetails] = useState<google.maps.places.PlaceResult[]>([])

  if (!temporaryUserRecord) {
    history.push(ScreenRoutes.HOME)
  }

  const emptyDetails = useMemo(
    () =>
      selectedDetails.filter(
        (el) => !details.some((item) => item.place_id === el),
      ),
    [details, selectedDetails],
  )

  const handleDetails = useCallback(
    (newDetails) => {
      setDetails([...details, newDetails])
    },
    [details],
  )

  const handleSelectedDetails = useCallback(
    (placeId: string) => {
      if (selectedDetails?.includes(placeId)) {
        setSelectedDetails(() => selectedDetails.filter((el) => el !== placeId))
      } else {
        setSelectedDetails([...selectedDetails, placeId])
      }
    },
    [selectedDetails],
  )

  const handleServices = useCallback(
    (items: google.maps.places.PlaceResult[]) => {
      setServices(items)
    },
    [],
  )

  const goBack = useCallback(() => {
    history.goBack()
  }, [history])

  const scrollToElem = useCallback((placeId) => {
    scroller.scrollTo(placeId, {
      smooth: true,
      offset: -110,
      delay: 500,
    })
  }, [])

  useEffect(() => {
    Events.scrollEvent.register('begin', () => ({}))

    Events.scrollEvent.register('end', () => ({}))

    scrollSpy.update()
  }, [])

  useEffect(() => {
    return () => {
      Events.scrollEvent.remove('begin')
      Events.scrollEvent.remove('end')
    }
  }, [])

  useEffect(() => {
    services?.length && setPageIsReady(true)
  }, [services])

  return (
    <div
      className={clsx('page health-services-page', !isLoggedIn && 'logged-out')}
    >
      <PageHeader showBg backBtn title={serviceTypes[serviceType]?.pageName} />
      <div className={clsx('page-wrapper', !isLoggedIn && 'no-sidebar')}>
        {isLoggedIn ? <SideMenu /> : null}
        <div
          className={clsx(
            'page-content',
            !isLoggedIn && 'flex-justify-center loggedOutContent',
          )}
          role="main"
        >
          <div className="content">
            <GoogleMapComponent
              selectedType={serviceTypes[serviceType]?.searchValue}
              handleServices={handleServices}
              handleDetails={handleDetails}
              detailsIds={emptyDetails}
              scrollToElem={scrollToElem}
            />

            <div className="section mb-2">
              {services?.length
                ? services.map(
                    (item: google.maps.places.PlaceResult, index: number) => (
                      <ServiceCard
                        key={index}
                        item={item}
                        isPharmacy={serviceType === ServiceType.PHARMACY}
                        handleSelectedDetails={handleSelectedDetails}
                        placeDetail={details?.find(
                          (el) => el.place_id === item.place_id,
                        )}
                        goBack={goBack}
                      />
                    ),
                  )
                : pageIsReady && (
                    <Banner
                      type="warning"
                      header="No results found for this location."
                    />
                  )}
            </div>

            <div className="section">
              <p className="mb-2 text-sm">
                Please note: Locations displayed are those available via Google
                Maps, and do not necessarily indicate all available services in
                your area.
              </p>
              <p className="text-sm">
                Location services can sometimes produce inaccurate results, or
                can sometimes fail to detect your location. If no map appears,
                your browser or device may not support location services.
              </p>
            </div>
          </div>
        </div>
      </div>

      {isLoggedIn && <PageFooter stickyBottom />}
    </div>
  )
})
