import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { saveNewPasswordAction } from '@core/app/user/actions'
import { useActionAsync, useAction } from '@core/shared/useAction'
import { NewPasswordComponent } from '@core/components/app/NewPasswordComponent'
import { showNotificationAction } from '@core/app/common/NotificationItem/actions'
import { PageHeader } from '@core/components/app/PageHeader'
import { SideMenu } from '@core/components/app/SideMenu'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'

export const ChangePasswordPage = () => {
  const [error, setError] = useState<string>('')

  const showNotification = useAction(showNotificationAction)
  const saveNewPassword = useActionAsync(saveNewPasswordAction)
  const history = useHistory()

  const onSubmit = useCallback(
    async (data: any) => {
      if (error) {
        setError('')
      }
      try {
        await saveNewPassword(data)

        showNotification({
          type: 'success',
          title: 'Password Updated',
        })
        history.push(ScreenRoutes.DASHBOARD)
      } catch (e) {
        setError((e as string) || 'Something went wrong')
      }
    },
    [error, saveNewPassword, showNotification],
  )

  const onCancel = useCallback(() => history.goBack(), [history])

  return (
    <div className="page change-password-page">
      <PageHeader showBg backBtn title="New password" />
      <div className="page-wrapper">
        <SideMenu />
        <div role="main" className="page-content">
          <NewPasswordComponent
            onSubmit={onSubmit}
            onCancel={onCancel}
            error={error}
          />
        </div>
      </div>
    </div>
  )
}
