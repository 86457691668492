import { useFeatures } from '../app/features/features'

// eslint-disable-next-line no-control-regex
const pattern =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i

export const isValidEmail = (text: string) => (text ? pattern.test(text) : true)

export const isValidEmailStrict = (text: string) => pattern.test(text)

const isValidDomain = (domains: string[], email: string) => {
  const heheDomains = ['@healthhero.com', '.mailosaur.net']
  const validDomains = heheDomains.concat(domains)
  return validDomains.some((domain) => email.endsWith(domain))
}

export const useEmailValidation = () => {
  const featureFlags = useFeatures()
  const isFilterEmailEnabled = featureFlags.filterEmail.isEnabled
  const filterList = featureFlags.filterEmail?.filterList || []

  const validateEmail = (email: string) =>
    isFilterEmailEnabled ? isValidDomain(filterList, email) : true

  return validateEmail
}
