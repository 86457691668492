import {
  AppConfig,
  baseAppConfigDevelopment,
  baseAppConfigProduction,
  baseAppConfigUAT,
} from '@core/shared/configuration'

const recaptchaSiteKey = '6Lft5YMcAAAAAEJ8k3lVq6dfUPHGav7rShpeka-1'
const appConfig: AppConfig = {
  development: {
    ...baseAppConfigDevelopment,
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
  },
  uat: {
    ...baseAppConfigUAT,
    api: {
      ...baseAppConfigUAT.api,
      clientId: '10633',
    },
    auth: {
      ...baseAppConfigUAT.auth,
      clientId: '10633.towergateKinetic',
      clientSecret: '4979f0b9-9de4-cf38-8332-a12ef2d37a70',
    },
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
    clientURL: 'https://towergatekinetic-test.azurewebsites.net',
  },
  production: {
    ...baseAppConfigProduction,
    api: {
      ...baseAppConfigProduction.api,
      clientId: '830',
    },
    auth: {
      ...baseAppConfigProduction.auth,
      clientId: '830.Towergate Kinetic',
      clientSecret: 'd3d59a43-8732-1221-bcd3-6e2ab99af91b',
    },
    recaptcha: {
      siteKey: recaptchaSiteKey,
    },
    clientURL: 'https://kinetic.gp-24.com',
  },
}

export default appConfig
