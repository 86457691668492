import { FeatureFlagOptions } from '@core/components/app/features/features'
import { ClientVariables } from '@core/components/app/features/variables'
import { makeCountriesList, countryCodes } from '@core/shared/data/countryCodes'

const features: FeatureFlagOptions = {
  articles: true,
  servicesNearYou: true,
  dependants: true,
  messageGp: false,
  clientIdentifierPin: true,
  clientIdentifierField: false,
  clientIdentifierToSAPI: true,
  confirmEmailAddress: false,
  gpPharmacyMap: true,
  hasSymptomsField: true,
  symptomAssessment: false,
  isPharmacyRequired: false,
  hasStandardAuth: true,
  hasStandardBookingEntry: true,
  bookings: {
    services: {
      value: {
        GeneralPractitioner: {
          isEnabled: true,
        },
      },
    },
  },
}

const clientVariables: ClientVariables = {
  clientVariables: {
    phoneNumber: '0330 043 5192',
    phoneInputPlaceholder: 'e.g. +44 0330 043 5192',
    clientIdentifierLabel: 'Your Company Number',
    pinLength: 8,
    countryList: makeCountriesList(countryCodes, ['GB', 'IE']),
    baseCountryCode: 'GB',
    clientIdentifierParam: 'clientcode',
    clientName: 'Kinetic GP',
  },
  dashboardVariables: {
    logoAlt: 'kinetic GP',
    logoLargeWidth: 56,
    logoSmallWidth: 70,
    specialtiesLayout: 'GpConsultation',
    hasBackground: true,
    backgroundStyles: {
      backgroundPosition: 'inherit',
    },
  },
  landingVariables: {
    logoAlt: 'Kinetic GP',
    mainHeader: 'Welcome to Kinetic GP, powered by HealthHero',
    backgroundStyles: {
      borderRadius: '0 0 80px 0',
      backgroundSize: 'cover',
    },
    landingSlider: {
      numSlides: 2,
      slide2Content: [
        'Unlimited access to experienced practising NHS GPs',
        'Private prescription or a open referral letter',
        'Book a consultation online in minutes',
      ],
    },
  },
}

export const getFeatures = () => features

export const getClientVariables = () => clientVariables
