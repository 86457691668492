import { ofType, Epic, StateObservable } from 'redux-observable'
import { mergeMap } from 'rxjs/operators'

import {
  PatientRecordActionTypes,
  GetUserPatientRecordAction,
  UpdateUserPatientRecordAction,
  GetDependentPatientRecordAction,
  UpdateDependentPatientRecordAction,
  CreateDependentPatientRecordAction,
} from './actionTypes'
import {
  getUserPatientRecordSuccessAction,
  getUserPatientRecordFailAction,
  updateUserPatientRecordSuccessAction,
  updateUserPatientRecordFailAction,
  getDependentPatientRecordSuccessAction,
  getDependentPatientRecordFailAction,
  updateDependentPatientRecordSuccessAction,
  updateDependentPatientRecordFailAction,
  createDependentPatientRecordSuccessAction,
  createDependentPatientRecordFailAction,
} from './actions'
import {
  getUserPatientRecord,
  updateUserPatientRecord,
  getDependentsPatientRecords,
  updateDependentPatientRecord,
  createDependentPatientRecord,
} from '@core/api/patientRecord'
import {
  notifySuccess,
  notifyFail,
  concatActions,
} from '@core/shared/epicHelpers'
import { AppState } from '../'
import dayjs from 'dayjs'

export const getUserPatientRecordEpic: Epic = (action$) =>
  action$.pipe(
    ofType(PatientRecordActionTypes.GET_USER_PATIENT_RECORD),
    mergeMap(async (action: GetUserPatientRecordAction) => {
      const userId = action.payload.userId
      try {
        const resp = await getUserPatientRecord(userId)
        notifySuccess(action)
        return getUserPatientRecordSuccessAction(resp.data.data)
      } catch (e) {
        notifyFail(action)
        return getUserPatientRecordFailAction()
      }
    }),
    concatActions(),
  )

export const updateUserPatientRecordEpic: Epic = (action$) =>
  action$.pipe(
    ofType(PatientRecordActionTypes.UPDATE_USER_PATIENT_RECORD),
    mergeMap(async (action: UpdateUserPatientRecordAction) => {
      const { patientRecord, data } = action.payload
      const updatedRecord = Object.assign({}, patientRecord, {
        attributes: data,
      })

      try {
        //@ts-ignore
        const resp = await updateUserPatientRecord(updatedRecord)
        notifySuccess(action)
        return updateUserPatientRecordSuccessAction(resp.data.data)
      } catch (e) {
        const errorMessage =
          e.response.data?.errors?.map((el: any) => el?.detail)?.join(' ') ||
          'Request failed'

        notifyFail(action, errorMessage)
        return updateUserPatientRecordFailAction()
      }
    }),
    concatActions(),
  )

export const getDependentsPatientRecordsEpic: Epic = (
  action$,
  state$: StateObservable<AppState>,
) =>
  action$.pipe(
    ofType(PatientRecordActionTypes.GET_DEPENDENT_PATIENT_RECORD),
    mergeMap(async (action: GetDependentPatientRecordAction) => {
      const userId = state$.value.user.me?.id
      try {
        if (!userId) {
          throw new Error('missing user in store for current session.')
        }
        const resp = await getDependentsPatientRecords(userId)
        const records = resp.data.data.map((record) => {
          return {
            ...record,
            attributes: {
              ...record.attributes,
              dateOfBirth: dayjs(record.attributes.dateOfBirth).format(
                'YYYY-MM-DD',
              ),
            },
          }
        })

        notifySuccess(action)
        return getDependentPatientRecordSuccessAction(records)
      } catch (e) {
        console.log(e)
        notifyFail(action)
        return getDependentPatientRecordFailAction()
      }
    }),
    concatActions(),
  )

export const updateDependentPatientRecordEpic: Epic = (
  action$,
  state$: StateObservable<AppState>,
) =>
  action$.pipe(
    ofType(PatientRecordActionTypes.UPDATE_DEPENDENT_PATIENT_RECORD),
    mergeMap(async (action: UpdateDependentPatientRecordAction) => {
      const { patientRecord, data } = action.payload
      const userId = state$.value.user.me?.id
      const updatedRecord = Object.assign({}, patientRecord, {
        attributes: data,
      })

      try {
        if (!userId) {
          throw new Error('missing user in store for current session.')
        }
        const resp = await updateDependentPatientRecord(userId, updatedRecord)
        notifySuccess(action)
        return updateDependentPatientRecordSuccessAction(resp.data.data)
      } catch (e) {
        const errorMessage =
          e.response.data?.errors?.map((el: any) => el?.detail)?.join(' ') ||
          'Request failed'

        notifyFail(action, errorMessage)
        return updateDependentPatientRecordFailAction()
      }
    }),
    concatActions(),
  )

export const createDependentPatientRecordEpic: Epic = (
  action$,
  state$: StateObservable<AppState>,
) =>
  action$.pipe(
    ofType(PatientRecordActionTypes.CREATE_DEPENDENT_PATIENT_RECORD),
    mergeMap(async (action: CreateDependentPatientRecordAction) => {
      const data = action.payload
      const userId = state$.value.user.me?.id

      try {
        if (!userId) {
          throw new Error('missing user in store for current session.')
        }
        const resp = await createDependentPatientRecord(userId, data)
        notifySuccess(action)
        return createDependentPatientRecordSuccessAction(resp.data.data)
      } catch (e) {
        const errorMessage =
          e.response.data?.errors?.map((el: any) => el?.detail)?.join(' ') ||
          'Request failed'

        notifyFail(action, errorMessage)
        return createDependentPatientRecordFailAction()
      }
    }),
    concatActions(),
  )

export default [
  getUserPatientRecordEpic,
  updateUserPatientRecordEpic,
  getDependentsPatientRecordsEpic,
  getDependentsPatientRecordsEpic,
  createDependentPatientRecordEpic,
  updateDependentPatientRecordEpic,
]
