import client from './client'
import base64 from '@core/shared/base64'
import qs from 'query-string'
import { User } from '@core/shared/types/user/User'
import { JsonApiResponse } from '@core/shared/types/JsonApi'
import { env } from '@core/shared/configuration'
import Cookies from 'js-cookie'
import sapiClient from './sapiClient'

const FORM_URLENCODED_HEADER = 'application/x-www-form-urlencoded'

const config = env()
const { registrationEndpoint, clientId } = config.api

export const register = (data: any) => {
  const registerUrl = `${registrationEndpoint}/ms-msapi-function-registration?clientId=${clientId}`

  const {
    firstName,
    lastName,
    gender,
    userName,
    phoneNumber,
    addressLine1,
    addressLine2,
    city,
    postCode,
    countryCode,
    clientIdentifier,
    dateOfBirth,
    password,
    recaptchaToken,
    gp,
    nominatedPharmacy,
  } = data

  const hasGP = gp !== null
  const hasPharmacy = nominatedPharmacy !== null
  const gpData = hasGP && {
    gpName: gp?.gpName,
    surgery: {
      name: gp?.surgery?.name,
      address: {
        addressLine1: gp?.surgery?.address?.addressLine1,
        addressLine2: gp?.surgery?.address?.addressLine2,
        postCode: gp?.surgery?.address?.postCode,
        city: gp?.surgery?.address?.city,
        countryCode: gp?.surgery?.address?.countryCode,
      },
      phoneNumber: gp?.surgery?.phoneNumber || null,
      email: gp?.surgery?.email || null,
    },
  }

  const pharmacyData = hasPharmacy && {
    name: nominatedPharmacy?.name,
    address: {
      addressLine1: nominatedPharmacy?.address?.addressLine1,
      addressLine2: nominatedPharmacy?.address?.addressLine2,
      postCode: nominatedPharmacy?.address?.postCode,
      city: nominatedPharmacy?.address?.city,
      countryCode: nominatedPharmacy?.address?.countryCode,
    },
    phoneNumber: nominatedPharmacy?.phoneNumber || null,
    email: nominatedPharmacy?.email || null,
  }

  const patientRecord = {
    firstName,
    lastName,
    gender,
    dateOfBirth,
    phoneNumber,
    email: userName,
    address: {
      addressLine1,
      addressLine2,
      postCode,
      countryCode,
      city,
    },
    ...(gpData && { gp: gpData }),
    ...(pharmacyData && { nominatedPharmacy: pharmacyData }),
  }

  const payload = {
    data: {
      type: 'users',
      attributes: {
        email: userName,
        password,
        userName,
        patientRecord,
        clientIdentifier,
      },
    },
  }
  return sapiClient.post(registerUrl, payload, {
    headers: {
      'x-recaptcha-code': recaptchaToken,
    },
  })
}

export interface LoginResponse {
  access_token: string
  expires_in: number
  refresh_token: string
}

export const login = (data: { userName: string; password: string }) => {
  const params = {
    grant_type: 'password',
    username: data.userName,
    password: data.password,
    scope: 'MSAPI_api offline_access',
  }

  const basicAuthCredentials = base64.encode(
    `${config.auth.clientId}:${config.auth.clientSecret}`,
  )

  return client.post<LoginResponse>(
    `${config.api.apiHost}/v${config.api.apiVersion}/clients/${config.api.clientId}/oauth2/token`,
    qs.stringify(params),
    {
      headers: {
        'Content-Type': FORM_URLENCODED_HEADER,
        Authorization: `Basic ${basicAuthCredentials}`,
      },
    },
  )
}

export const getCurrentUser = () => {
  return client.get<JsonApiResponse<User>>('/currentUser')
}

export const getUserById = (userId: string) => {
  return client.get<JsonApiResponse<User>>(`/users/${userId}`)
}

export const verifyEmail = (token: string) => {
  return client.post(
    `${config.api.sapiHost}/v${config.api.apiVersion}/verifyEmail`,
    {
      data: {
        type: 'emailVerifications',
        attributes: {
          token,
        },
      },
    },
  )
}

export const changePassword = ({
  currentPassword,
  newPassword,
}: {
  currentPassword: string
  newPassword: string
}) => {
  return client.post(
    `${config.api.apiHost}/v${config.api.apiVersion}/clients/${config.api.clientId}/changePassword`,
    {
      data: {
        type: 'changePasswords',
        attributes: {
          currentPassword,
          newPassword,
        },
      },
    },
  )
}

export const resetPasswordStart = ({ userName }: { userName: string }) => {
  return client.post(
    `${config.api.sapiHost}/v${config.api.apiVersion}/resetPassword/start`,
    {
      data: {
        type: 'passwordResetRequests',
        attributes: {
          userName,
          clientId: config.api.clientId,
        },
      },
    },
  )
}

export const resetPasswordComplete = ({
  tokenId,
  token,
  password,
}: {
  tokenId: string
  token: string
  password: string
}) => {
  return client.post(
    `${config.api.sapiHost}/v${config.api.apiVersion}/resetPassword/complete`,
    {
      data: {
        type: 'passwordResets',
        attributes: {
          tokenId,
          token,
          password,
        },
      },
    },
  )
}

export const acceptInitialUserTerms = async (userId: string) => {
  const pendingTermsResponse = await getPendingTerms(userId)
  const userTerm = pendingTermsResponse.data.data.find(
    (t) => t.type === 'userTerms',
  )
  if (!userTerm) {
    return
  }
  return Promise.all(
    pendingTermsResponse.data.data.map((term) => acceptTerms(userId, term?.id)),
  )
}
export type Term = {
  type: string
  id: string
  attributes: {
    accepted: boolean
  }
}
export const getAcceptedTerms = (userId: string) => {
  return client.get<JsonApiResponse<Term[]>>(
    `/users/${userId}/terms?filter[status]=Accepted&include=term`,
  )
}
export const getPendingTerms = (userId: string) => {
  return client.get<JsonApiResponse<Term[]>>(
    `/users/${userId}/terms?filter[status]=Pending&include=term`,
  )
}
export const acceptTerms = (userId: string, termId: string) => {
  return client.patch(`/users/${userId}/terms/${termId}`, {
    data: {
      type: 'userTerms',
      attributes: {
        accepted: true,
      },
      id: termId,
    },
  })
}

export const resendEmailConfirmation = ({ userId }: { userId: string }) => {
  return client.post(`/users/${userId}/resendVerifyEmail`, {})
}

export const logoutCookies = () => {
  const domain =
    process.env.REACT_APP_APP_ENV === 'development' ? 'localhost' : '.gp-24.com'
  Cookies.remove('UserInfo', { path: '', domain })
  return client.get(`${config.api.apiHostname}/Authentication/Logout`)
}
