import { produce } from 'immer'

import {
  InitAppSuccessAction,
  InitAppAction,
  AppActionTypes,
} from './actionTypes'

export interface RApp {
  ready: boolean
}

export const appInitialState = (): RApp => ({
  ready: false,
})

type AppActions = InitAppAction | InitAppSuccessAction

const appReducer = produce(function (draft: RApp, action: AppActions) {
  switch (action.type) {
    case AppActionTypes.APP_INIT_SUCCESS: {
      return {
        ready: true,
      }
    }
  }
}, appInitialState())

export default appReducer
