import { ErrorIcon } from '../../../icons/ErrorIcon'
import { useServiceValidation } from '../useServiceValidation'
import { ServiceValues } from '../ServiceDetailOptions'

export const GpServiceError = () => {
  const hasError = useServiceValidation(ServiceValues.GP)

  if (!hasError) {
    return null
  }

  return (
    <div className="section">
      <div className="validation-errors flex-row-center">
        <ErrorIcon />
        <div role="alert" className="tooltip warning ml-1">
          Please input GP details or make sure all required fields of GP details
          are filled in.
        </div>
      </div>
    </div>
  )
}
