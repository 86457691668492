import {
  AppointmentActionTypes,
  GetAppointmentBookingDatesAction,
  GetAppointmentBookingDatesSuccessAction,
  GetAppointmentBookingSlotsAction,
  GetAppointmentBookingSlotsSuccessAction,
  SubmitAppointmentBookingStep1Action,
  SubmitAppointmentBookingStep2Action,
  GetAppointmentBookingDatesFailAction,
  HoldAppointmentAction,
  HoldAppointmentFailAction,
  HoldAppointmentSuccessAction,
  ReleaseAppointmentAction,
  ReleaseAppointmentFailAction,
  ReleaseAppointmentSuccessAction,
  SelectBookingDate,
} from './actionTypes'
import { createAction } from '@core/shared/createAction'

export const chooseBookingDateAction = createAction<SelectBookingDate>(
  AppointmentActionTypes.SELECT_BOOKING_DATE,
)

// getBookingDates
export const getBookingDatesAction =
  createAction<GetAppointmentBookingDatesAction>(
    AppointmentActionTypes.GET_BOOKING_DATES,
  )

export const getBookingDatesSuccessAction =
  createAction<GetAppointmentBookingDatesSuccessAction>(
    AppointmentActionTypes.GET_BOOKING_DATES_SUCCESS,
  )

export const getBookingDatesFailAction =
  createAction<GetAppointmentBookingDatesFailAction>(
    AppointmentActionTypes.GET_BOOKING_DATES_FAIL,
  )

// getBookingSlots
export const getBookingSlotsAction =
  createAction<GetAppointmentBookingSlotsAction>(
    AppointmentActionTypes.GET_BOOKING_SLOTS,
  )

export const getBookingSlotsSuccessAction =
  createAction<GetAppointmentBookingSlotsSuccessAction>(
    AppointmentActionTypes.GET_BOOKING_SLOTS_SUCCESS,
  )

// submitAppointmentBooking

export const submitAppointmentBookingStep1Action =
  createAction<SubmitAppointmentBookingStep1Action>(
    AppointmentActionTypes.SUBMIT_BOOKING_STEP_1,
  )

export const submitAppointmentBookingStep2Action =
  createAction<SubmitAppointmentBookingStep2Action>(
    AppointmentActionTypes.SUBMIT_BOOKING_STEP_2,
  )

// holdAppointmentAction
export const holdAppointmentAction = createAction<HoldAppointmentAction>(
  AppointmentActionTypes.HOLD_APPOINTMENT,
)

export const holdAppointmentSuccessAction =
  createAction<HoldAppointmentSuccessAction>(
    AppointmentActionTypes.HOLD_APPOINTMENT_SUCCESS,
  )

export const holdAppointmentFailAction =
  createAction<HoldAppointmentFailAction>(
    AppointmentActionTypes.HOLD_APPOINTMENT_FAIL,
  )

// releaseAppointmentActtion
export const releaseAppointmentAction = createAction<ReleaseAppointmentAction>(
  AppointmentActionTypes.RELEASE_APPOINTMENT,
)

export const releaseAppointmentSuccessAction =
  createAction<ReleaseAppointmentSuccessAction>(
    AppointmentActionTypes.RELEASE_APPOINTMENT_SUCCESS,
  )

export const releaseAppointmentFailAction =
  createAction<ReleaseAppointmentFailAction>(
    AppointmentActionTypes.RELEASE_APPOINTMENT_FAIL,
  )
