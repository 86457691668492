import { memo } from 'react'
import { ChevronLeftIcon } from '../../../icons/ChevronLeftIcon'
import { ChevronRightIcon } from '../../../icons/ChevronRightIcon'
import { DatePickerView } from '../../DatePicker'
import { DatepickerNav } from '../../useDatepickerNavigation'
import { formatDate } from '../../utils'

export interface DateViewNavigationProps {
  nav: DatepickerNav
  locale: string | Locale
  currentView: DatePickerView
  setCurrentView: (view: DatePickerView) => void
}

export const DateViewNavigation = memo<DateViewNavigationProps>(
  function DateViewNavigation(props) {
    const nav = props.nav

    return (
      <div className="date-view-header__navigation--wrapper">
        <button
          type="button"
          disabled={!nav.canNavigateToPrevMonth()}
          onClick={() => nav.navigateToPrevMonth()}
          className="date-view-header__navigation--button"
        >
          <ChevronLeftIcon />
        </button>
        <div className="date-view-header__navigation--date">
          <button onClick={() => props.setCurrentView('month')} type="button">
            {formatDate(nav.current, 'MMM', props.locale)}
          </button>
          <button onClick={() => props.setCurrentView('year')} type="button">
            {formatDate(nav.current, 'yyy', props.locale)}
          </button>
        </div>
        <button
          type="button"
          onClick={() => nav.navigateToNextMonth()}
          disabled={!nav.canNavigateToNextMonth()}
          className="date-view-header__navigation--button"
        >
          <ChevronRightIcon />
        </button>
      </div>
    )
  },
)
