import { memo } from 'react'
import addDays from 'date-fns/addDays'

import {
  getDefaultLocale,
  getStartOfWeek,
  getWeekdayMinInLocale,
  getWeekdayShortInLocale,
} from '../../utils'

const weekDayNumbers = [0, 1, 2, 3, 4, 5, 6]

const formatWeekday = (
  day: Date,
  locale: string | Locale,
  formatOptions?: {
    useWeekdaysShort?: boolean
    formatWeekDay?: (date: Date, locale?: string | Locale) => string
  },
) => {
  if (formatOptions?.formatWeekDay) {
    return formatOptions.formatWeekDay(day, locale)
  }
  return formatOptions?.useWeekdaysShort
    ? getWeekdayShortInLocale(day, locale)
    : getWeekdayMinInLocale(day, locale)
}

export interface DayNamesHeaderProps {
  date: Date
  locale?: Locale | string
}

export const DayNamesHeader = memo<DayNamesHeaderProps>(
  function DateViewHeader({ date, locale = getDefaultLocale() }) {
    const startOfWeek = getStartOfWeek(date, locale)

    return (
      <div className="date-view-header__day-name--wrapper">
        {weekDayNumbers.map((offset) => {
          const day = addDays(startOfWeek, offset)
          const weekDayName = formatWeekday(day, locale)

          return (
            <div key={offset} className="date-view-header__day-name">
              {weekDayName}
            </div>
          )
        })}
      </div>
    )
  },
)
