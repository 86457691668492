import { memo } from 'react'

import startOfMonth from 'date-fns/startOfMonth'
import addWeeks from 'date-fns/addWeeks'
import getMonth from 'date-fns/getMonth'

import { getStartOfWeek, isWeekInMonth } from '../utils'
import { Week } from './DateView/Week'
import { DayNamesHeader } from './DateView/DayNamesHeader'
import { DatepickerNav } from '../useDatepickerNavigation'
import { DateViewNavigation } from './DateView/DateViewNavigation'
import { DatePickerView } from '../DatePicker'
import { DayProps } from './DateView/Day'

const FIXED_HEIGHT_STANDARD_WEEK_COUNT = 6

const makeMonthWeeks = (
  date: Date,
  locale: string | Locale,
  options: { fixedHeight?: boolean; peekNextMonth?: boolean } = {},
) => {
  const weeks: Date[] = []

  let currentWeekStart = getStartOfWeek(startOfMonth(date), locale)
  let i = 0
  let breakAfterNextPush = false
  while (true) {
    weeks.push(currentWeekStart)

    if (breakAfterNextPush) break
    i++
    currentWeekStart = addWeeks(currentWeekStart, 1)

    // If one of these conditions is true, we will either break on this week
    // or break on the next week
    const isFixedAndFinalWeek =
      options.fixedHeight && i >= FIXED_HEIGHT_STANDARD_WEEK_COUNT
    const isNonFixedAndOutOfMonth =
      !options.fixedHeight && !isWeekInMonth(currentWeekStart, date)

    if (isFixedAndFinalWeek || isNonFixedAndOutOfMonth) {
      if (options.peekNextMonth) {
        breakAfterNextPush = true
      } else {
        break
      }
    }
  }

  return weeks
}

export interface DateViewProps {
  date: Date
  selectedDate: Date
  onSelect: (date: Date) => void
  ariaLabelPrerfix?: string
  weekAriaLabelPrefix?: string
  chooseDayAriaLabelPrefix?: string
  disabledDayAriaLabelPrefix?: string
  min?: Date
  max?: Date
  inline?: boolean
  locale: string | Locale
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dayRenderer?: (props: DayProps) => any
  fixedHeight?: boolean
  peekNextMonth?: boolean
  nav: DatepickerNav
  currentView: DatePickerView
  setCurrentView: (view: DatePickerView) => void
}

export const DateView = memo<DateViewProps>(function DateView(props) {
  const monthWeeks = makeMonthWeeks(props.date, props.locale)
  // const month = getMonth()
  return (
    <div className="datepicker-month__container">
      <div className="datepicker__header">
        <DateViewNavigation
          setCurrentView={props.setCurrentView}
          currentView={props.currentView}
          locale={props.locale}
          nav={props.nav}
        />
      </div>

      <div className="datepicker__date-month-view datepicker-view">
        <DayNamesHeader date={props.date} />
        {monthWeeks.map((monthWeekDate, idx) => (
          <Week
            key={idx}
            date={monthWeekDate}
            month={getMonth(props.nav.current)}
            locale={props.locale}
            selectedDate={props.selectedDate}
            onSelect={props.onSelect}
            min={props.min}
            max={props.max}
            inline={props.inline}
            dayRenderer={props.dayRenderer}
            chooseDayAriaLabelPrefix={props.chooseDayAriaLabelPrefix}
            disabledDayAriaLabelPrefix={props.disabledDayAriaLabelPrefix}
          />
        ))}
      </div>
    </div>
  )
})
