import React from 'react'
import cx from 'clsx'
import { IconProps } from '@core/shared/types/Icons'

export const TermsAndConditionsIcon = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46 46"
      className={cx('terms-and-conditions-icon', className)}
      aria-hidden="true"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle fill="#f4616b" cx="23" cy="23" r="23"></circle>
          <path
            d="M32.2905 35.4368H13.4929C12.1787 35.4368 11.1034 34.3615 11.1034 33.0473V11.7009C11.1034 11.3823 11.3822 11.1035 11.7008 11.1035H32.2905C32.6091 11.1035 32.8879 11.3823 32.8879 11.7009V34.8394C32.8879 35.1581 32.6091 35.4368 32.2905 35.4368ZM12.2982 12.2983V33.0075C12.2982 33.6845 12.8557 34.2022 13.4929 34.2022H31.6533V12.2983H12.2982Z"
            fill="white"
          />
          <path
            d="M32.2907 35.4375C31.9721 35.4375 31.6535 35.1587 31.6535 34.8401V23.9279C31.6535 23.6093 31.9323 23.3306 32.2509 23.3306H34.3616C34.6802 23.3306 34.959 23.6093 34.959 23.9279V31.8134C34.959 33.8445 33.8041 35.4375 32.2907 35.4375ZM32.8881 24.5253V34.0436C33.4058 33.6453 33.8041 32.809 33.8041 31.8134V24.5253H32.8881Z"
            fill="white"
          />
          <path
            d="M28.9053 22.8125H15.046V24.0073H28.9053V22.8125Z"
            fill="white"
          />
          <path
            d="M28.9053 26.5161H15.046V27.7109H28.9053V26.5161Z"
            fill="white"
          />
          <path
            d="M28.9053 30.2197H15.046V31.4145H28.9053V30.2197Z"
            fill="white"
          />
          <path
            d="M21.9755 20.2398C21.9357 20.2398 21.8958 20.2398 21.856 20.2398C21.8162 20.2398 21.7764 20.2398 21.7365 20.2C21.6967 20.1601 21.6569 20.1601 21.6171 20.1601C21.5772 20.1601 21.5374 20.1203 21.5374 20.0805C21.4976 20.0407 21.4976 20.0407 21.4578 20.0008C21.4179 19.961 21.4179 19.9212 21.4179 19.8814C21.4179 19.8415 21.3781 19.8017 21.3781 19.7619C21.3781 19.7221 21.3781 19.6822 21.3781 19.6424C21.3781 19.4831 21.4578 19.3238 21.5374 19.2043C21.5772 19.1645 21.6171 19.1645 21.6171 19.1247C21.6569 19.0849 21.6967 19.0849 21.7365 19.0849C21.7764 19.0849 21.8162 19.045 21.856 19.045C22.0551 19.0052 22.2543 19.0849 22.4136 19.2043C22.4534 19.2442 22.4534 19.2442 22.4932 19.284C22.533 19.3238 22.533 19.3636 22.533 19.4035C22.533 19.4433 22.5729 19.4831 22.5729 19.5229C22.5729 19.5628 22.5729 19.6026 22.5729 19.6424C22.5729 19.6822 22.5729 19.7221 22.5729 19.7619C22.5729 19.8017 22.5729 19.8415 22.533 19.8814C22.4932 19.9212 22.4932 19.961 22.4932 20.0008C22.4932 20.0407 22.4534 20.0805 22.4136 20.0805C22.2941 20.1601 22.1348 20.2398 21.9755 20.2398Z"
            fill="white"
          />
          <path
            d="M21.9759 18.8299C21.6573 18.8299 21.3785 18.5511 21.3785 18.2325V15.2058C21.3785 14.8872 21.6573 14.6084 21.9759 14.6084C22.2945 14.6084 22.5732 14.8872 22.5732 15.2058V18.2325C22.5732 18.5511 22.3343 18.8299 21.9759 18.8299Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  )
}
