import axios from 'axios'

import { BFFConfigType, env } from '@core/shared/configuration'

const config = env()

const client = axios.create({
  baseURL: `${config.api.apiHost}/v${config.api.apiVersion}/clients/${config.api.clientId}`,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json',
  },
  // Used for SSO clients:
  // if the user is UNauthenticated (when auth session via curity runs out), we are reathenticated by curity, the BFF returns client a cookie
  // on subsequent requests client makes to the BFF we send the BFF this cookie - withCredentials enables the passing of this cookie in the request
  withCredentials: config.auth.bff !== BFFConfigType.Disabled,
})

export default client
