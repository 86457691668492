import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import clsx from 'clsx'

import { TextInput } from '../../../form/TextInput'
import { requiredIf, required } from '@core/shared/validation/required'
import { NativeSelect } from '../../../form/NativeSelect'
import { isValidPhoneNumber } from '@core/shared/validation/phone'
import { isValidEmail } from '../../../validation/email'
import { useFeatures } from '../../features/features'
import { useClientVariables } from '../../features/variables'
import {
  isPostCodeRequired,
  getPostCodePlaceholder,
  getError,
} from '@core/shared/utils/formUtils'
import styles from './ServiceDetailsInput.module.scss'
import { serviceIsNotEmpty } from '../../../healthServiceDataUtils'
import { ServiceValues } from '../ServiceDetailOptions'

const errorsMap: Record<string, string> = {
  phone: 'Enter valid phone number. Must include country code and + symbol',
  email: 'Enter valid email address',
  required: 'Required field',
}

export const GPDetailsInput = () => {
  const { register, getValues, errors, trigger } = useFormContext()
  const isRegisteredWithGP = useWatch<unknown>({ name: 'isRegisteredWithGP' })
  const surgeryIsNotEmpty = serviceIsNotEmpty(getValues()?.gp, ServiceValues.GP)
  const { isGPRequired } = useFeatures()
  const {
    clientVariables: {
      baseCountryCode,
      phoneInputPlaceholder,
      countryList = [],
    },
  } = useClientVariables()

  const isRequired = isGPRequired && isRegisteredWithGP

  function setOptionalValidation() {
    return register({
      validate: {
        required: requiredIf(() => surgeryIsNotEmpty),
      },
    })
  }

  function setPostcodeValidation() {
    const countryCode = getValues().gp?.surgery?.address?.countryCode
    if (isRequired) {
      return register({ validate: { required } })
    } else {
      return register({
        validate: {
          required: requiredIf(() => {
            return (
              isPostCodeRequired(countryCode, baseCountryCode) &&
              surgeryIsNotEmpty
            )
          }),
        },
      })
    }
  }

  const [postCodePlaceholder, setPostCodePlaceholder] = useState('')

  const setInitialPostCodePlaceholder = () => {
    const defaultCountryCode =
      getValues()?.gp?.surgery?.address?.countryCode ?? baseCountryCode
    const initialPlaceholder = getPostCodePlaceholder(defaultCountryCode)
    setPostCodePlaceholder(initialPlaceholder)
  }

  useEffect(() => {
    setInitialPostCodePlaceholder()
  }, [])

  function handleCountryCodeChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const placeholder = getPostCodePlaceholder(event.target.value)
    setPostCodePlaceholder(placeholder)
    trigger('gp.surgery.address.postCode')
  }

  return (
    <>
      <div className="user-data-block mb-1" data-testid="your-gp-section">
        <TextInput
          label="GP surgery name"
          placeholder="Add your GP surgery name"
          className="w-full"
          name="gp.surgery.name"
          inputRef={
            isRequired
              ? register({ validate: { required } })
              : setOptionalValidation()
          }
          error={getError(errors.gp?.surgery || {}, 'name', errorsMap)}
          required={surgeryIsNotEmpty}
        />
        <TextInput
          label="GP surgery address"
          placeholder="Address line 1"
          className="w-full"
          name="gp.surgery.address.addressLine1"
          inputRef={
            isRequired
              ? register({ validate: { required } })
              : setOptionalValidation()
          }
          error={getError(
            errors.gp?.surgery?.address || {},
            'addressLine1',
            errorsMap,
          )}
          required={surgeryIsNotEmpty}
        />
        <TextInput
          label="Second line of address"
          placeholder="Address line 2 (optional)"
          className="w-full"
          name="gp.surgery.address.addressLine2"
          inputRef={register}
          withoutLabel={true}
        />
        <div className="details-row">
          <div className="flex-row city-post-line">
            <div className="flex-row flex-1">
              <TextInput
                withoutLabel={true}
                label=""
                placeholder="City"
                className="w-full"
                name="gp.surgery.address.city"
                inputRef={
                  isRequired
                    ? register({ validate: { required } })
                    : setOptionalValidation()
                }
                error={getError(
                  errors.gp?.surgery?.address || {},
                  'city',
                  errorsMap,
                )}
                required={surgeryIsNotEmpty}
              />
            </div>
            <div className="ml-2 width-30">
              <TextInput
                withoutLabel={true}
                label=""
                placeholder={postCodePlaceholder}
                className="postcode-input"
                name="gp.surgery.address.postCode"
                inputRef={setPostcodeValidation()}
                error={getError(
                  errors.gp?.surgery?.address || {},
                  'postCode',
                  errorsMap,
                )}
                required={
                  isPostCodeRequired(
                    getValues().gp?.surgery?.address?.countryCode,
                    baseCountryCode,
                  ) && surgeryIsNotEmpty
                }
              />
            </div>
          </div>
          <NativeSelect
            withoutLabel={true}
            onChange={(event) => handleCountryCodeChange(event)}
            className="w-full"
            label=""
            options={countryList}
            name="gp.surgery.address.countryCode"
            placeholder="Country"
            aria-label="Country"
            selectRef={
              isRequired
                ? register({ validate: { required } })
                : setOptionalValidation()
            }
            error={getError(
              errors.gp?.surgery?.address || {},
              'countryCode',
              errorsMap,
            )}
          />
        </div>
        <div className={clsx(styles.contactFields, 'details-row')}>
          <TextInput
            data-testid="gp-surgery-phonenumber"
            label="GP surgery phone number (please include the country code and + symbol)"
            placeholder={phoneInputPlaceholder}
            className="w-full"
            name="gp.surgery.phoneNumber"
            inputRef={register({
              validate: {
                phone: isValidPhoneNumber,
              },
            })}
            error={getError(errors.gp?.surgery || {}, 'phoneNumber', errorsMap)}
          />
          <div className={styles.email}>
            <TextInput
              data-testid="gp-surgery-email"
              label="GP surgery email address"
              placeholder="If known, add your GP surgery email address"
              className="w-full mt-1-5"
              name="gp.surgery.email"
              inputRef={register({
                validate: {
                  email: isValidEmail,
                },
              })}
              error={getError(errors.gp?.surgery || {}, 'email', errorsMap)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
