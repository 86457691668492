import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import cx from 'clsx'
import { UseFormMethods } from 'react-hook-form'

import { PatientRecord } from '@core/shared/types/patient/PatientRecord'
import {
  selectUserPatientRecord,
  selectDependentsRecordsList,
} from '@core/app/patientRecord/selectors'
import { HorizontalScroll } from '@core/components/HorizontalScroll'
import { LabelPaper } from '@core/components/form/LabelPaper'
import { PaperContent, PaperHeader } from '@core/components/paper'
import { Radio } from '@core/components/form/Radio'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { useHistory } from 'react-router-dom'
import { Feature } from '@core/components/app/features/features'

type Props = {
  handleChange: (patient: PatientRecord) => void | Promise<void>
  register: UseFormMethods['register']
}
export const PatientPicker: React.FC<Props> = (props) => {
  const { handleChange, register } = props
  const patientRecord = useSelector(selectUserPatientRecord)
  const dependents = useSelector(selectDependentsRecordsList)

  const [currentValue, setCurrentValue] = useState(patientRecord)
  const isMe = currentValue === patientRecord
  const history = useHistory()
  const goToNewContact = () => history.push(ScreenRoutes.ADD_NEW_CONTACT)

  const dependentButtonRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    // focus dynamically appeared radiogroup for ally reasons
    if (!isMe) {
      dependentButtonRef?.current?.focus()
    }
  }, [isMe, dependentButtonRef])

  useEffect(() => {
    if (patientRecord) {
      handleChange(patientRecord)
      if (!currentValue) {
        setCurrentValue(patientRecord)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!patientRecord])

  return (
    <div role="region" aria-live="polite">
      <div
        className="flex-row"
        role="radiogroup"
        aria-label="Who is the consultation for?"
      >
        <button
          onClick={() => {
            setCurrentValue(patientRecord)
            if (patientRecord) {
              handleChange(patientRecord)
            }
          }}
          className={cx('paper color-paper action-cursor', isMe && 'selected')}
          role="radio"
          type="button"
          aria-checked={isMe ? 'true' : 'false'}
        >
          <div className="paper-content">
            <div className="paper-header text-md">Me</div>
            <div className="text-color-secondary text-weight-md text-sm">
              {patientRecord?.attributes.firstName}{' '}
              {patientRecord?.attributes.lastName}
            </div>
          </div>
        </button>
        <Feature name="dependants">
          <button
            type="button"
            onClick={() => setCurrentValue(undefined)}
            className={cx(
              'paper color-paper action-cursor ml-1',
              !isMe && 'selected',
            )}
            role="radio"
            aria-checked={isMe ? 'false' : 'true'}
          >
            <div className="paper-content">
              <div className="paper-header text-md">Somebody else</div>
              <div className="text-color-secondary text-weight-md text-sm">
                Select or add someone
              </div>
            </div>
          </button>
        </Feature>
      </div>
      <Feature name="dependants">
        {!isMe ? (
          <div className="flex-row mt-2 overflow-auto">
            <button
              ref={dependentButtonRef}
              onClick={goToNewContact}
              className="paper patient-btn"
            >
              <div className="paper-content flex-center">
                <div className="paper-header text-sm">+ Add new dependant</div>
              </div>
            </button>
            <div role="radiogroup">
              <HorizontalScroll className="inline ml-1 pad-items-left">
                {dependents.map((patient, idx) => (
                  <LabelPaper className="patient-btn" key={idx}>
                    <PaperContent className="flex-row-center">
                      <PaperHeader className="text-weight-md text-sm flex-row-center space-between w-full">
                        {patient.attributes.firstName}{' '}
                        {patient.attributes.lastName}
                        <Radio
                          inputRef={register({ required: !isMe })}
                          name="patient"
                          checked={patient === currentValue}
                          onChange={() => {
                            setCurrentValue(patient)
                            handleChange(patient)
                          }}
                        />
                      </PaperHeader>
                    </PaperContent>
                  </LabelPaper>
                ))}
              </HorizontalScroll>
            </div>
          </div>
        ) : null}
      </Feature>
    </div>
  )
}
