import { memo, createRef, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import clsx from 'clsx'
import { PageHeader } from '@core/components/app/PageHeader'
import { PageFooter } from '@core/components/app/PageFooter'
import { TextInput } from '@core/components/form/TextInput'
import { SideMenu } from '@core/components/app/SideMenu'
import { RightChevronIcon } from '@core/components/icons/RightChevron'
import { CircularLoader } from '@core/components/app/CircularLoader'
import { useActionAsync } from '@core//shared/useAction'
import { getMedicalInformationRequestAction } from '@core/app/articles/actions'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Feature, useFeatures } from '@core/components/app/features/features'
import { useTrackingPixelNHS } from '@core/components/external/useTrackingPixelNHS'

type ArticlesLandingProps = {
  text: {
    pageHeader: string
    pageTitle: string
    medicalInformation: {
      title: string
      subTitle: string
      searchPlaceholder: string
    }
    healthWellbeing: {
      title: string
      subTitle: string
      ctaLabel: string
    }
    services: {
      title: string
      subTitle: string
      ctaLabel: string
    }
  }
}

export const ArticlesLanding = memo(function ArticlesLanding(
  props: ArticlesLandingProps,
) {
  const { text } = props
  const slider = createRef<any>()
  const { trackingPixelRef } = useTrackingPixelNHS<HTMLDivElement>()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState<boolean>(false)

  const getMedicalInformationRequest = useActionAsync(
    getMedicalInformationRequestAction,
  )

  const { servicesNearYou } = useFeatures()

  const pagerDots = servicesNearYou ? [0, 1, 2] : [0, 1]
  const [slideNum, setSlideNum] = useState<number>(0)
  const [searchValue, setSearchValue] = useState<string>('')

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    beforeChange: () => setIsDragging(true),
    afterChange: (num: number) => {
      setIsDragging(false)
      setSlideNum(num)
    },
  }
  const history = useHistory()

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      await getMedicalInformationRequest({
        search: searchValue,
      })
      setLoading(false)
      history.push(ScreenRoutes.MEDICAL_INFORMATION_RESULTS)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }, [getMedicalInformationRequest, history, searchValue])

  const onInputChange = useCallback(
    (event: any) => setSearchValue(event.target.value),
    [],
  )

  const handleOnPressKey = useCallback(
    (event: any) => {
      if (event.keyCode === 13) {
        onSubmit()
      }
    },
    [onSubmit],
  )

  const navigateToPage = useCallback(
    (event, route: string) => {
      if (isDragging) {
        event.preventDefault()
        return
      }

      history.push(route)
    },
    [history, isDragging],
  )

  return (
    <div className="page articles-page" ref={trackingPixelRef}>
      <PageHeader showBg backBtn title={text.pageHeader} />
      <div className="page-wrapper">
        <SideMenu />
        <div className="page-content" role="main">
          <div className="page-title">
            <h2 className="text-weight-md text-color-primary">
              {text.pageTitle}
            </h2>
          </div>
          <div className="content">
            {/* Mobile, Tablet views */}
            <div className="slide-container">
              <Slider ref={slider} {...settings}>
                <div className="slide-content">
                  <div className="slide-content-top slide-one">
                    <div className="slide-content-top-title">
                      <h3 className="text-weight-md">
                        {text.medicalInformation.title}
                      </h3>
                    </div>
                    <div className="slide-content-top-subtitle mt-1">
                      <p className="text-sm">
                        {text.medicalInformation.subTitle}
                      </p>
                    </div>
                  </div>
                  <div className="slide-content-bottom text-smd text-color-secondary">
                    <div className="flex-row">
                      <TextInput
                        label="Search medical conditions"
                        placeholder={text.medicalInformation.searchPlaceholder}
                        className="w-full"
                        name="search"
                        value={searchValue}
                        onChange={onInputChange}
                        onKeyDown={handleOnPressKey}
                        withoutLabel
                      />
                      <button
                        onClick={onSubmit}
                        className="btn"
                        aria-label="Search"
                      >
                        <RightChevronIcon />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="slide-content">
                  <div
                    className="article-link article-link-full"
                    onClick={(event: any) =>
                      navigateToPage(event, ScreenRoutes.HEALTH_WELLBEING)
                    }
                  >
                    <div className="slide-content-top slide-two">
                      <div className="slide-content-top-title">
                        <h3 className="text-weight-md">
                          {text.healthWellbeing.title}
                        </h3>
                      </div>
                      <div className="slide-content-top-subtitle mt-1">
                        <p className="text-sm">
                          {text.healthWellbeing.ctaLabel}
                          <RightChevronIcon className="ml-1" fill="#fff" />
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="slide-content-bottom text-smd text-color-secondary">
                    <p>{text.healthWellbeing.subTitle}</p>
                  </div>
                </div>

                {servicesNearYou ? (
                  <div className="slide-content">
                    <div
                      className="article-link article-link-full"
                      onClick={(event: any) =>
                        navigateToPage(event, ScreenRoutes.HEALTH_SERVICES)
                      }
                    >
                      <div className="slide-content-top slide-three">
                        <div className="slide-content-top-title">
                          <h3 className="text-weight-md">
                            {text.services.title}
                          </h3>
                        </div>
                        <div className="slide-content-top-subtitle mt-1">
                          <p className="text-sm">
                            {text.services.ctaLabel}
                            <RightChevronIcon className="ml-1" fill="#fff" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content-bottom text-smd text-color-secondary">
                      <p>{text.services.subTitle}</p>
                    </div>
                  </div>
                ) : null}
              </Slider>
            </div>
            {/* Desktop view */}
            <div className="non-slide-container">
              <div className="non-slide-item-top mb-2">
                <div className="slide-content">
                  <div className="slide-content-top slide-one">
                    <div className="slide-content-top-title">
                      <h3
                        data-testid="medical-information-title"
                        className="text-weight-md"
                      >
                        {text.medicalInformation.title}
                      </h3>
                    </div>
                    <div className="slide-content-top-subtitle mt-1">
                      <p
                        data-testid="medical-information-subtitle"
                        className="text-sm"
                      >
                        {text.medicalInformation.subTitle}
                      </p>
                    </div>
                  </div>
                  <div className="slide-content-bottom text-smd text-color-secondary">
                    <div className="flex-row">
                      <TextInput
                        label="Search medical conditions"
                        placeholder={text.medicalInformation.searchPlaceholder}
                        className="w-full"
                        name="search"
                        value={searchValue}
                        onChange={onInputChange}
                        onKeyDown={handleOnPressKey}
                        withoutLabel
                      />
                      <button
                        onClick={onSubmit}
                        className="btn"
                        aria-label="Search"
                      >
                        <RightChevronIcon />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="non-slide-item-bottom">
                <div
                  className={`slide-content ${servicesNearYou ? 'mr-2' : ''}`}
                >
                  <Link
                    to={ScreenRoutes.HEALTH_WELLBEING}
                    data-testid="health-wellbeing-link"
                    className="article-link-primary active-article-link"
                  >
                    <div className="slide-content-top slide-two">
                      <div className="slide-content-top-title">
                        <h3
                          data-testid="health-wellbeing-title"
                          className="text-weight-md"
                        >
                          {text.healthWellbeing.title}
                        </h3>
                      </div>
                      <div className="slide-content-top-subtitle mt-1">
                        <p data-testid="health-wellbeing-subtitle">
                          {text.healthWellbeing.subTitle}
                        </p>
                      </div>
                    </div>
                    <div className="slide-content-bottom text-smd text-color-secondary">
                      <p data-testid="health-wellbeing-cta" className="text-sm">
                        {text.healthWellbeing.ctaLabel}
                        <RightChevronIcon className="ml-1" fill="#242d38" />
                      </p>
                    </div>
                  </Link>
                </div>
                <Feature name="servicesNearYou">
                  <div className="slide-content">
                    <Link
                      to={ScreenRoutes.HEALTH_SERVICES}
                      data-testid="health-services-link"
                      className="article-link-primary active-article-link"
                    >
                      <div className="slide-content-top slide-three">
                        <div className="slide-content-top-title">
                          <h3
                            data-testid="health-services-title"
                            className="text-weight-md"
                          >
                            {text.services.title}
                          </h3>
                        </div>
                        <div className="slide-content-top-subtitle mt-1">
                          <p data-testid="health-services-subtitle">
                            {text.services.subTitle}
                          </p>
                        </div>
                      </div>
                      <div className="slide-content-bottom text-smd text-color-secondary">
                        <p
                          data-testid="health-services-cta"
                          className="text-sm"
                        >
                          {text.services.ctaLabel}
                          <RightChevronIcon className="ml-1" fill="#242d38" />
                        </p>
                      </div>
                    </Link>
                  </div>
                </Feature>
              </div>
            </div>
          </div>
          <div className="dots-wrapper">
            <div className="pager-dots mt-1">
              {pagerDots.map((idx) => (
                <div
                  onClick={() => {
                    setSlideNum(idx)
                    slider.current.slickGoTo(idx)
                  }}
                  className={clsx('pager-dot', idx === slideNum && 'active')}
                />
              ))}
            </div>
            <div className="dots-wrapper-text">
              <span>Swipe for more</span>
            </div>
          </div>
        </div>
      </div>

      {isLoading ? <CircularLoader /> : null}
      <PageFooter stickyBottom />
    </div>
  )
})
