import { memo } from 'react'
import addDays from 'date-fns/addDays'
import { getStartOfWeek } from '../../utils'
import { Day, DayProps } from './Day'

const weekDayNumbers = [0, 1, 2, 3, 4, 5, 6]

export interface WeekProps {
  date: Date
  selectedDate: Date
  onSelect: (date: Date) => void
  min?: Date
  max?: Date
  inline?: boolean
  chooseDayAriaLabelPrefix?: string
  disabledDayAriaLabelPrefix?: string
  month: number
  locale: string | Locale
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dayRenderer?: (props: DayProps) => any
}

export const Week = memo<WeekProps>(function Week(props) {
  const startOfWeek = getStartOfWeek(props.date, props.locale)
  return (
    <div className="datepicker-month__week">
      {weekDayNumbers.map((offset) => {
        const day = addDays(startOfWeek, offset)
        const isOutOfMonth = day.getMonth() !== props.month
        return (
          <Day
            ariaLabelPrefixWhenEnabled={props.chooseDayAriaLabelPrefix}
            ariaLabelPrefixWhenDisabled={props.disabledDayAriaLabelPrefix}
            key={day.valueOf()}
            date={day}
            selectedDate={props.selectedDate}
            month={props.month}
            min={props.min}
            max={props.max}
            dayRenderer={props.dayRenderer}
            inline={props.inline}
            onSelect={props.onSelect}
            locale={props.locale}
            isOutOfMonth={isOutOfMonth}
            // shouldFocusDayInline={this.props.shouldFocusDayInline}
            // monthShowsDuplicateDaysEnd={this.props.monthShowsDuplicateDaysEnd}
            // monthShowsDuplicateDaysStart={
            //   this.props.monthShowsDuplicateDaysStart
            // }
            // handleOnKeyDown={this.props.handleOnKeyDown}
            // isInputFocused={this.props.isInputFocused}
            // containerRef={props.containerRef}
            // onClick={this.handleDayClick.bind(this, day)}
          />
        )
      })}
    </div>
  )
})
