import cx from 'clsx'

import { IconProps } from '@core/shared/types/Icons'

export const IconLock = (props: IconProps) => {
  const { className } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      className={cx('icon-lock', className)}
      aria-hidden="true"
    >
      <path
        fill="#242D38"
        stroke="#242D38"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.8 14.7c0-2.146 0-3.219.4-4.046a4 4 0 0 1 1.854-1.853C7.881 8.4 8.954 8.4 11.1 8.4h1.8c2.146 0 3.219 0 4.045.4a4 4 0 0 1 1.854 1.854c.4.827.4 1.9.4 4.046 0 2.146 0 3.219-.4 4.046a4 4 0 0 1-1.854 1.853c-.826.4-1.9.4-4.045.4h-1.8c-2.146 0-3.22 0-4.046-.4a4 4 0 0 1-1.853-1.854C4.8 17.92 4.8 16.846 4.8 14.7Z"
      />
      <path
        stroke="#242D38"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M7.197 8.74V7.697a4.697 4.697 0 1 1 9.393 0V8.74"
      />
      <circle
        cx="12"
        cy="13.8"
        r="1.8"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path fill="#fff" d="M11 18.3a1 1 0 1 0 2 0h-2Zm0-2.7v2.7h2v-2.7h-2Z" />
    </svg>
  )
}
