import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageHeader } from '@core/components/app/PageHeader'
import { isLoggedInSelector } from '@core/app/auth/selectors'
import { verifyEmail } from '@core/api/user'
import { ScreenRoutes } from '@core/shared/ScreenRoutes'
import { Button } from '@core/components/Button/Button'

const expiredToken = 'Token has expired.'

type VerifiedProps = {
  isLoggedIn: boolean
  goToPage: () => void
}

type ExpiredProps = {
  onClick: () => void
}

const Verified = (props: VerifiedProps) => {
  const { isLoggedIn, goToPage } = props
  return (
    <>
      <h2 className="section-title">
        Your email has been confirmed successfully
      </h2>
      <p>You can now access services</p>

      <div className="btn-container">
        <Button
          type="primary"
          label={isLoggedIn ? 'Go To Dashboard' : 'Log in'}
          onClick={goToPage}
          testid="expired-login"
        />
      </div>
    </>
  )
}
const Expired = (props: ExpiredProps) => {
  const { onClick } = props
  return (
    <>
      <h2 className="section-title">
        Your email confirmation link has expired
      </h2>
      <p data-testid="expired-copy">
        Please log in to resend the link. You have 24 hours to verify your email
        before the link expires.
      </p>
      <div className="verify-page-button-container">
        <Button
          type="primary"
          label="Log in"
          onClick={onClick}
          testid="expired-login"
        />
      </div>
    </>
  )
}

const failed = <h2 className="section-title">Email confirmation failed</h2>
const pending = <h2 className="section-title">Please wait...</h2>

export const VerifyEmailPage = () => {
  const history = useHistory()
  const isLoggedIn = useSelector(isLoggedInSelector)

  const goToPage = useCallback(() => {
    const route = isLoggedIn ? ScreenRoutes.DASHBOARD : ScreenRoutes.LOGIN
    history.push(route)
  }, [history, isLoggedIn])
  const goToLogin = () => history.push(ScreenRoutes.LOGIN)
  const [isPending, setIsPending] = useState(true)
  const [verified, setVerified] = useState(false)
  const [expired, setExpired] = useState(false)
  const token = new URLSearchParams(window.location.search).get('token') || ''

  const verifyEmailOnMount = useCallback(async () => {
    try {
      await verifyEmail(token)
      setVerified(true)
    } catch (error) {
      const err: any = error
      if (err?.response?.data?.errors[0]?.detail === expiredToken) {
        setExpired(true)
      } else {
        setVerified(false)
      }
    } finally {
      setIsPending(false)
    }
  }, [token])

  useEffect(() => {
    verifyEmailOnMount()
  }, [verifyEmailOnMount])

  return (
    <div className="page verify-email-page">
      <PageHeader backBtn title="Email confirmation" showBg />
      <div className="page-wrapper no-sidebar">
        <div className="page-content" style={{ paddingTop: 80 }} role="main">
          <div className="section">
            {isPending && pending}
            {!isPending && verified && (
              <Verified isLoggedIn={isLoggedIn} goToPage={goToPage} />
            )}
            {!isPending && expired && <Expired onClick={goToLogin} />}
            {!isPending && !verified && !expired && failed}
          </div>
        </div>
      </div>
    </div>
  )
}
