import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useMatomoPageViews = () => {
  const location = useLocation()

  useEffect(() => {
    const _paq = ((window as any)._paq = (window as any)._paq || [])
    _paq.push(['setCustomUrl', location.pathname])
    _paq.push(['setDocumentTitle', location.pathname])
    _paq.push(['trackPageView'])
  }, [location])
}

export const trackEvent = (category: string, action: string, name?: string) => {
  const _paq = ((window as any)._paq = (window as any)._paq || [])
  _paq.push(['trackEvent', category, action, name])
}

export const trackGoal = (goal: number) => {
  const _paq = ((window as any)._paq = (window as any)._paq || [])
  _paq.push(['trackGoal', goal])
}
